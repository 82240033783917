import styled from 'styled-components';

export default styled.div`
  ${props =>
    props.preview
      ? ''
      : `
    border-radius: 2px;
    width: inherit;
    height: inherit;
    background: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1) 10px,
      rgba(0, 0, 0, 0.1) 10px,
      rgba(0, 0, 0, 0.1) 20px
    );
  `}
`;
