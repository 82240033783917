import * as Sentry from '@sentry/react';
import { Alert, Button, message, Modal, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { t } from 'ttag';

import LinkEventsForm from './LinkEventsForm';
import { useUpdateConnectionMutation } from './queries';

const LinkEventsModal = ({ onClose, connection }) => {
  const linkEventsFormRef = useRef(null);
  const { mutate: mutateUpdate, isLoading: isUpdating, isError } = useUpdateConnectionMutation();

  const handleLinkEvents = async () => {
    const payload = await linkEventsFormRef.current.submit();

    mutateUpdate(
      { id: connection.id, payload },
      {
        onSuccess: () => {
          onClose();
          message.success(t`Successfully updated Linked Events.`);
        },
        onError: err => {
          Sentry.captureException(err);
        },
      }
    );
  };

  return (
    <Modal
      title={t`Linked Events`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={isUpdating}>
          {t`Cancel`}
        </Button>,
        <Button key="save" type="primary" onClick={handleLinkEvents} loading={isUpdating}>
          {t`Save`}
        </Button>,
      ]}
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        {isError && (
          <Alert
            showIcon
            type="error"
            message={t`Error linking events to connection. Please try again.`}
          />
        )}

        <LinkEventsForm
          formRef={linkEventsFormRef}
          initialValues={connection}
          disabled={isUpdating}
        />
      </Space>
    </Modal>
  );
};

LinkEventsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default LinkEventsModal;
