import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Badge, Button, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import Loading from 'common/components/Loading';

import TimeAgo from '@components/TimeAgo';

import Podium from './Podium';
import { get as getFn } from './service';
import Table from './Table';

const TOTAL_PLAYERS = 21;
const REFRESH_INTERVAL = 3000;

const Base = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;

  background: #383e48;
`;

const Sticky = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const Wrap = styled.div`
  width: 80%;
  margin: auto;
  padding: 0 20px;
`;

const TableContainer = styled.div`
  width: 100%;
  margin: auto;
  padding: 0 60px;
`;

const EmptyTitle = styled(Typography.Title)`
  && {
    font-size: 80px;
    color: white;
  }
`;

const SubText = styled(Typography.Text)`
  && {
    font-size: 35px;
    font-weight: 600;
    color: white;
  }
`;

const TextLink = styled(Typography.Text)`
  color: #00bbff;
`;

const EmptyContainer = styled.div`
  margin: 80px 15px;
  text-align: left;

  ${SubText} {
    font-size: 65px;
    font-weight: normal;
    color: black;
  }
`;

const useLeaderboard = (eventId, hashVal, playersLimit, intervalMs) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null);
  const [refreshError, setRefreshError] = useState(false);

  useEffect(() => {
    async function effect() {
      setLoading(true);

      const response = await getFn(eventId, hashVal, playersLimit);
      setData(response);
      setLastUpdatedAt(new Date());

      setLoading(false);
    }

    if (eventId && hashVal) {
      effect();
    }
  }, [eventId, hashVal, playersLimit]);

  useEffect(() => {
    async function effect() {
      if (!eventId || !hashVal || loading) {
        return;
      }

      try {
        const response = await getFn(eventId, hashVal, playersLimit);
        setData(response);
        setLastUpdatedAt(new Date());
        setRefreshError(false);
      } catch {
        setRefreshError(true);
      }
    }
    const intervalId = setInterval(effect, intervalMs);

    return () => clearInterval(intervalId);
  }, [eventId, hashVal, loading, playersLimit, intervalMs]);

  return {
    loading,
    data,
    lastUpdatedAt,
    refreshError,
  };
};

const Leaderboard = () => {
  const { eventId, hashVal, shortcode } = useParams();
  const { loading, data, lastUpdatedAt, refreshError } = useLeaderboard(
    eventId,
    hashVal,
    TOTAL_PLAYERS,
    REFRESH_INTERVAL
  );
  const screen = useFullScreenHandle();

  const eventURL = <TextLink key="event-link">eventmobi.com/{shortcode}</TextLink>;

  if (loading) {
    return <Loading />;
  }

  return (
    <FullScreen handle={screen}>
      <Base>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Wrap>
            <Podium players={data} />
          </Wrap>

          {data.length !== 0 && (
            <TableContainer>
              <Table players={data} start={3} end={TOTAL_PLAYERS} size={6} />
            </TableContainer>
          )}

          <Wrap>
            {data.length === 0 ? (
              <EmptyContainer>
                <EmptyTitle>{t`Start playing now and compete for the top spot!`}</EmptyTitle>
                <SubText>{jt`Play the Game at ${eventURL}`}</SubText>
              </EmptyContainer>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <SubText>{jt`Play the Game at ${eventURL}`}</SubText>
              </div>
            )}
          </Wrap>
        </Space>

        <Sticky>
          <Space size="large">
            <Button
              icon={!screen.active ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
              onClick={screen.active ? screen.exit : screen.enter}
            />

            {!refreshError && (
              <Badge
                color="red"
                text={<Typography.Text style={{ color: 'white' }}>{t`Live`}</Typography.Text>}
              />
            )}

            {refreshError && (
              <Badge
                color="yellow"
                text={
                  <Typography.Text style={{ color: 'white' }}>
                    {t`Last updated`}: <TimeAgo time={lastUpdatedAt} />
                  </Typography.Text>
                }
              />
            )}
          </Space>
        </Sticky>
      </Base>
    </FullScreen>
  );
};

export default Leaderboard;
