import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/organizers`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const STAFF_ID = '8d9cb26f-0fb2-42ea-84a2-914a1e051466';
export const ADMIN_ID = '7df2240d-1ab3-4f65-bfc2-81bde135a6b1';
export const COLLABORATOR_ID = '8b4858d5-5838-4250-be8d-93bb1d336f86';

export const STATUS_ACTIVE = 'active';
export const STATUS_PENDING = 'pending';

export const ENTITY_ID_ALL = 'all';

// Flatten the response to make it more usable on component
const transform = organizer => {
  const { roleId } = organizer.roleAccessGrants[0] ?? {};

  return {
    ...organizer,
    roleId,
    eventIds: organizer.roleAccessGrants[0]?.accessGrants[0]?.accessGrantEntityIds.map(x =>
      x === ENTITY_ID_ALL ? x : Number(x)
    ),
  };
};

// Reverse the transformation for saving
const transformForSave = ({ roleId, eventIds, eventAccess, ...rest }) => ({
  ...rest,
  roleAccessGrants: [
    {
      roleId,
      accessGrants: [
        {
          accessGrantEntityType: 'events',
          accessGrantEntityIds:
            roleId !== ADMIN_ID && eventAccess !== 'all'
              ? eventIds?.map(eventId => eventId.toString())
              : ['all'],
        },
      ],
    },
  ],
});

export function list(organizationId, status) {
  return api(organizationId)
    .get('', { params: { status } })
    .then(response => response.data.data.map(transform));
}

export function invite(organizationId, payload) {
  const { protocol, host } = window.location;
  const baseUrl = `${protocol}//${host}/accept-invite?token={token}&email={email}&organization_id={organization_id}`;

  return api(organizationId)
    .post(null, {
      ...transformForSave(payload),
      baseUrl,
    })
    .then(response => response.data.data);
}

export function update(organizationId, id, payload) {
  return api(organizationId)
    .patch(id, transformForSave(payload))
    .then(response => response.data.data);
}

export function remove(organizationId, id) {
  return api(organizationId)
    .delete(id)
    .then(response => response.data.data);
}

export function resendInvite(organizationId, id) {
  return api(organizationId)
    .post(`${id}/resend-organizer-invite`)
    .then(response => response.data.data);
}

export function acceptInvite(organizationId, payload) {
  return api(organizationId)
    .post('accept-organizer-invite', payload)
    .then(response => response.data.data);
}

export function checkInviteStatus(organizationId, payload) {
  return api(organizationId)
    .post('organizer-invite-status', payload)
    .then(response => response.data.data);
}
