import TextWidget from '@eventmobi/widgets/Text';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Take the whole height of the parent and
// Override the global RTE styles conflict
const Container = styled.div`
  height: 100%;
  & .jodit-workplace {
    height: auto !important;
  }
`;

const Text = params => {
  return (
    <Container>
      <TextWidget {...params} image={params?.image?.url} />
    </Container>
  );
};

Text.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  // Text widget props
};

export default Text;
