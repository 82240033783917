import Drawer from './Drawer';
import Provider, {
  useUploadingDrawer,
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PROCESSING,
  STATUS_WAITING,
  STATUS_UPLOADING,
} from './Provider';

export default Drawer;
export {
  Provider,
  useUploadingDrawer,
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PROCESSING,
  STATUS_WAITING,
  STATUS_UPLOADING,
};
