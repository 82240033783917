import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { t, jt } from 'ttag';

import { isEnabled as isFlagEnabled } from 'common/state/flags';

import { useKBArticle } from '@domains/Organization/EmailDomain/constants';
import { useEmailDomainList } from '@domains/Organization/EmailDomain/queries';
import { useShouldShowFeatureQuery } from '@domains/Organization/queries';
import usePermission, { READ_SENDER_EMAIL_DOMAINS } from '@hooks/usePermission';

const useOrgAlerts = organizationId => {
  const isEmailDomainsFFEnabled = useSelector(state =>
    isFlagEnabled(state, 'exp_email_spam_improvements')
  );
  const { loading: isLoadingAuth, authorized } = usePermission();

  const {
    data: isEmailDomainEnabled,
    isLoading: isLoadingEmailDomainEnabled,
  } = useShouldShowFeatureQuery(organizationId, 'is_whitelabel_sender_email_domain_enabled', {
    enabled:
      !!organizationId &&
      isEmailDomainsFFEnabled &&
      !isLoadingAuth &&
      authorized(READ_SENDER_EMAIL_DOMAINS),
  });

  const { data, isLoading } = useEmailDomainList(
    organizationId,
    {
      limit: 1,
      verificationStatus: 'not_started,pending,failed',
      hasLinkedEvents: true,
    },
    !!organizationId &&
      !!isEmailDomainEnabled &&
      !isLoadingAuth &&
      authorized(READ_SENDER_EMAIL_DOMAINS)
  ); // !! because it will be undefined if the feature flag is not enabled

  const kbArticle = useKBArticle();
  const kbArticleLink = (
    <Typography.Link href={kbArticle} target="_blank">
      {t`Click here to learn more`}
    </Typography.Link>
  );
  const eventEmails = <Typography.Text strong>event-emails.com</Typography.Text>;

  if (isLoading || isLoadingEmailDomainEnabled) {
    return { isLoading: true, alerts: [] };
  }

  // If there are no records then there are no alerts
  if (!organizationId || data.meta.pagination.totalItemsCount === 0) {
    return { isLoading: false, alerts: [] };
  }

  // If there is one record, the alert should mention the domain
  if (data.meta.pagination.totalItemsCount === 1) {
    const domain = <Typography.Text strong>{data.data[0].name}</Typography.Text>;

    return {
      isLoading: false,
      alerts: [
        {
          id: 'emailDomain',
          content: jt`Your Email Domain ${domain} has been disconnected and emails will now be sent from the default EventMobi ${eventEmails} domain. Please check the Email Domains page and consult your IT team on reconfiguring records in your DNS provider. ${kbArticleLink}`,
          type: 'error',
          scope: 'organization',
        },
      ],
    };
  }

  return {
    isLoading: false,
    alerts: [
      {
        id: 'emailDomain',
        content: jt`Multiple Email Domains have been disconnected and emails will now be sent from the default EventMobi ${eventEmails} domain. Please check the Email Domains page and consult your IT team on reconfiguring records in your DNS provider. ${kbArticleLink}`,
        type: 'error',
        scope: 'organization',
      },
    ],
  };
};

export default useOrgAlerts;
