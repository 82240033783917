import PropTypes from 'prop-types';
import React from 'react';

import { SHAPE_TRIANGLE, SHAPE_CIRCLE, SHAPE_RECT, COLUMN_SIZE } from '../constants';

const Rectangle = ({ width, height, lineHeight, lineColor, fillColor }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        stroke={lineColor}
        fill={fillColor}
        strokeWidth={lineHeight}
      />
    </svg>
  );
};

const Circle = ({ width, height, lineHeight, lineColor, fillColor }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx={width / 2}
        cy={height / 2}
        rx={(width - lineHeight) / 2}
        ry={(height - lineHeight) / 2}
        stroke={lineColor}
        fill={fillColor}
        strokeWidth={lineHeight}
      />
    </svg>
  );
};

const Triangle = ({ width, height, lineHeight, lineColor, fillColor }) => {
  const outerPoints = [
    [0, height].join(','),
    [width, height].join(','),
    [width / 2, 0].join(','),
  ].join(' ');

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <defs>
        <clipPath id="inner">
          <polygon points={outerPoints} />
        </clipPath>
      </defs>

      <polygon
        points={outerPoints}
        stroke={lineColor}
        strokeWidth={lineHeight * 2}
        fill={fillColor}
        clipPath="url(#inner)"
      />
    </svg>
  );
};

const Shape = ({ width, height, shape, fillColor, lineColor, lineHeight }) => {
  const props = {
    width: width * COLUMN_SIZE,
    height: height * COLUMN_SIZE,
    shape,
    fillColor,
    lineColor,
    lineHeight,
  };

  switch (shape) {
    case SHAPE_TRIANGLE:
      return <Triangle {...props} />;
    case SHAPE_CIRCLE:
      return <Circle {...props} />;
    case SHAPE_RECT:
      return <Rectangle {...props} />;
    default:
      return null;
  }
};

const ShapeDefaultType = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fillColor: PropTypes.string,
  lineColor: PropTypes.string,
  lineHeight: PropTypes.number,
};

Shape.propTypes = {
  ...ShapeDefaultType,
  shape: PropTypes.oneOf([SHAPE_TRIANGLE, SHAPE_CIRCLE, SHAPE_RECT]).isRequired,
};
Triangle.propTypes = ShapeDefaultType;
Rectangle.propTypes = ShapeDefaultType;
Circle.propTypes = ShapeDefaultType;

export default Shape;
