import { useEffect, useState } from 'react';

import { getBasicData as getEventFn } from 'common/services/events/api';

const useEvent = eventId => {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const effect = async () => {
      try {
        const {
          data: [data],
        } = await getEventFn(eventId);

        setEvent(data);
      } finally {
        setLoading(false);
      }
    };
    effect();
  }, [eventId]);

  return { loading, event };
};

export default useEvent;
