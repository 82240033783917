import { Tabs as AntTabs, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Profile from 'common/components/Profile';
import useTranslation from 'common/hooks/useTranslation';

export const TAB_PROFILE = 'profile';

const { Title: AntTitle } = Typography;

const Title = styled(AntTitle)`
  margin-left: 45px;
  margin-top: 40px;
  padding-bottom: 40px;
  && {
    font-weight: 400;
  }
`;

const Tabs = styled(AntTabs)`
  overflow: 'visible';

  .ant-tabs-tab {
    cursor: unset;
  }
`;

const Settings = () => {
  useTranslation();

  return (
    <>
      <Title style={{ color: '#595959' }}>{t`Settings`}</Title>
      <Tabs
        type="card"
        tabBarStyle={{ marginBottom: 0, paddingLeft: 45 }}
        items={[
          {
            label: t`Profile`,
            key: TAB_PROFILE,
            children: <Profile />,
          },
        ]}
      />
    </>
  );
};

export default Settings;
