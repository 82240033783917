import DiscussionWidget, { Footer, Item } from '@eventmobi/widgets/Discussion';
import Chance from 'chance';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

const noop = () => {};

export const Discussion = ({ title, footer, items }) => (
  <DiscussionWidget title={title} footer={<Footer {...footer} onClick={noop} />}>
    {items.map(item => (
      <Item {...item} onClick={noop} />
    ))}
  </DiscussionWidget>
);

Discussion.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.shape({
    title: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      userName: PropTypes.string,
      name: PropTypes.string,
      message: PropTypes.string,
      timeSince: PropTypes.string,
    })
  ),
};

const chance = new Chance();

const generate = () => ({
  key: chance.hash(),
  imageUrl: `https://i.pravatar.cc/200?u=${chance.guid()}`,
  userName: chance.name(),
  name: chance.sentence({ words: chance.integer({ min: 6, max: 15 }) }),
  message: chance.sentence({ words: chance.integer({ min: 10, max: 30 }) }),
  timeSince: chance.pickone([
    `${chance.second({ min: 2 })} seconds ago`,
    `${chance.minute({ min: 2 })} minutes ago`,
    `${chance.hour({ min: 2 })} hours ago`,
    `${chance.integer({ min: 2, max: 30 })} days ago`,
  ]),
});

const items = chance.n(generate, 5);

const Mock = ({ title }) => (
  <Discussion title={title} footer={{ title: t`View Discussions` }} items={items} />
);

Mock.propTypes = {
  title: PropTypes.string,
};

export default Mock;
