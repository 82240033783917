import { t } from 'ttag';

const TZ = () => ({
  '(GMT-12:00)': [
    {
      value: 'Kwajalein',
      label: t`Kwajalein`,
    },
  ],
  '(GMT-11:00)': [
    {
      value: 'Pacific/Midway',
      label: t`Pacific/Midway`,
    },
    {
      value: 'Pacific/Samoa',
      label: t`Pacific/Samoa`,
    },
  ],
  '(GMT-10:00)': [
    {
      value: 'Pacific/Honolulu',
      label: t`Pacific/Honolulu`,
    },
  ],
  '(GMT-09:00)': [
    {
      value: 'America/Anchorage',
      label: t`America - Anchorage`,
    },
  ],
  '(GMT-08:00)': [
    {
      value: 'America/Los_Angeles',
      label: t`America - Los Angeles`,
    },
    {
      value: 'America/Tijuana',
      label: t`America - Tijuana`,
    },
  ],
  '(GMT-07:00)': [
    {
      value: 'America/Denver',
      label: t`America - Denver`,
    },
    {
      value: 'America/Chihuahua',
      label: t`America - Chihuahua`,
    },
    {
      value: 'America/Mazatlan',
      label: t`America - Mazatlan`,
    },
    {
      value: 'America/Phoenix',
      label: t`America - Phoenix`,
    },
  ],
  '(GMT-06:00)': [
    {
      value: 'America/Regina',
      label: t`America - Regina`,
    },
    {
      value: 'America/Tegucigalpa',
      label: t`America - Tegucigalpa`,
    },
    {
      value: 'America/Chicago',
      label: t`America - Chicago`,
    },
    {
      value: 'America/Mexico_City',
      label: t`America - Mexico City`,
    },
    {
      value: 'America/Monterrey',
      label: t`America - Monterrey`,
    },
  ],
  '(GMT-05:00)': [
    {
      value: 'America/New_York',
      label: t`America - New York`,
    },
    {
      value: 'America/Bogota',
      label: t`America - Bogota`,
    },
    {
      value: 'America/Lima',
      label: t`America - Lima`,
    },
    {
      value: 'America/Rio_Branco',
      label: t`America - Rio Branco`,
    },
    {
      value: 'America/Indiana/Indianapolis',
      label: t`America - Indiana/Indianapolis`,
    },
  ],
  '(GMT-04:30)': [
    {
      value: 'America/Caracas',
      label: t`America - Caracas`,
    },
  ],
  '(GMT-04:00)': [
    {
      value: 'America/Halifax',
      label: t`America - Halifax`,
    },
    {
      value: 'America/Manaus',
      label: t`America - Manaus`,
    },
    {
      value: 'America/Santiago',
      label: t`America - Santiago`,
    },
    {
      value: 'America/La_Paz',
      label: t`America - La Paz`,
    },
  ],
  '(GMT-03:30)': [
    {
      value: 'America/St_Johns',
      label: t`America - St Johns`,
    },
  ],
  '(GMT-03:00)': [
    {
      value: 'America/Argentina/Buenos_Aires',
      label: t`America - Argentina/Buenos Aires`,
    },
    {
      value: 'America/Sao_Paulo',
      label: t`America - Sao Paulo`,
    },
    {
      value: 'America/Godthab',
      label: t`America - Godthab`,
    },
    {
      value: 'America/Montevideo',
      label: t`America - Montevideo`,
    },
  ],
  '(GMT-02:00)': [
    {
      value: 'Atlantic/South_Georgia',
      label: t`Atlantic - South Georgia`,
    },
  ],
  '(GMT-01:00)': [
    {
      value: 'Atlantic/Azores',
      label: t`Atlantic - Azores`,
    },
    {
      value: 'Atlantic/Cape_Verde',
      label: t`Atlantic - Cape Verde`,
    },
  ],
  '(GMT)': [
    {
      value: 'Europe/Dublin',
      label: t`Europe - Dublin`,
    },
    {
      value: 'Europe/Lisbon',
      label: t`Europe - Lisbon`,
    },
    {
      value: 'Europe/London',
      label: t`Europe - London`,
    },
    {
      value: 'Africa/Monrovia',
      label: t`Africa - Monrovia`,
    },
    {
      value: 'Atlantic/Reykjavik',
      label: t`Atlantic - Reykjavik`,
    },
    {
      value: 'Africa/Casablanca',
      label: t`Africa - Casablanca`,
    },
  ],
  '(GMT+01:00)': [
    {
      value: 'Europe/Belgrade',
      label: t`Europe - Belgrade`,
    },
    {
      value: 'Europe/Bratislava',
      label: t`Europe - Bratislava`,
    },
    {
      value: 'Europe/Budapest',
      label: t`Europe - Budapest`,
    },
    {
      value: 'Europe/Ljubljana',
      label: t`Europe - Ljubljana`,
    },
    {
      value: 'Europe/Prague',
      label: t`Europe - Prague`,
    },
    {
      value: 'Europe/Sarajevo',
      label: t`Europe - Sarajevo`,
    },
    {
      value: 'Europe/Skopje',
      label: t`Europe - Skopje`,
    },
    {
      value: 'Europe/Warsaw',
      label: t`Europe - Warsaw`,
    },
    {
      value: 'Europe/Zagreb',
      label: t`Europe - Zagreb`,
    },
    {
      value: 'Europe/Brussels',
      label: t`Europe - Brussels`,
    },
    {
      value: 'Europe/Copenhagen',
      label: t`Europe - Copenhagen`,
    },
    {
      value: 'Europe/Madrid',
      label: t`Europe - Madrid`,
    },
    {
      value: 'Europe/Paris',
      label: t`Europe - Paris`,
    },
    {
      value: 'Africa/Algiers',
      label: t`Africa - Algiers`,
    },
    {
      value: 'Europe/Amsterdam',
      label: t`Europe - Amsterdam`,
    },
    {
      value: 'Europe/Berlin',
      label: t`Europe - Berlin`,
    },
    {
      value: 'Europe/Rome',
      label: t`Europe - Rome`,
    },
    {
      value: 'Europe/Stockholm',
      label: t`Europe - Stockholm`,
    },
    {
      value: 'Europe/Vienna',
      label: t`Europe - Vienna`,
    },
  ],
  '(GMT+02:00)': [
    {
      value: 'Europe/Minsk',
      label: t`Europe/Minsk`,
    },
    {
      value: 'Africa/Cairo',
      label: t`Africa/Cairo`,
    },
    {
      value: 'Europe/Helsinki',
      label: t`Europe/Helsinki`,
    },
    {
      value: 'Europe/Riga',
      label: t`Europe/Riga`,
    },
    {
      value: 'Europe/Sofia',
      label: t`Europe/Sofia`,
    },
    {
      value: 'Europe/Tallinn',
      label: t`Europe - Tallinn`,
    },
    {
      value: 'Europe/Vilnius',
      label: t`Europe - Vilnius`,
    },
    {
      value: 'Europe/Athens',
      label: t`Europe - Athens`,
    },
    {
      value: 'Europe/Bucharest',
      label: t`Europe - Bucharest`,
    },
    {
      value: 'Europe/Istanbul',
      label: t`Europe - Istanbul`,
    },
    {
      value: 'Asia/Jerusalem',
      label: t`Asia - Jerusalem`,
    },
    {
      value: 'Asia/Amman',
      label: t`Asia - Amman`,
    },
    {
      value: 'Asia/Beirut',
      label: t`Asia - Beirut`,
    },
    {
      value: 'Africa/Windhoek',
      label: t`Africa - Windhoek`,
    },
    {
      value: 'Africa/Harare',
      label: t`Africa - Harare`,
    },
  ],
  '(GMT+03:00)': [
    {
      value: 'Asia/Kuwait',
      label: t`Asia - Kuwait`,
    },
    {
      value: 'Asia/Riyadh',
      label: t`Asia - Riyadh`,
    },
    {
      value: 'Asia/Baghdad',
      label: t`Asia - Baghdad`,
    },
    {
      value: 'Africa/Nairobi',
      label: t`Asia - Nairobi`,
    },
    {
      value: 'Asia/Tbilisi',
      label: t`Asia - Tbilisi`,
    },
    {
      value: 'Europe/Moscow',
      label: t`Europe - Moscow`,
    },
    {
      value: 'Europe/Volgograd',
      label: t`Europe - Volgograd`,
    },
  ],
  '(GMT+03:30)': [
    {
      value: 'Asia/Tehran',
      label: t`Asia - Tehran`,
    },
  ],
  '(GMT+04:00)': [
    {
      value: 'Asia/Muscat',
      label: t`Asia - Muscat`,
    },
    {
      value: 'Asia/Baku',
      label: t`Asia - Baku`,
    },
    {
      value: 'Asia/Yerevan',
      label: t`Asia - Yerevan`,
    },
  ],
  '(GMT+05:00)': [
    {
      value: 'Asia/Yekaterinburg',
      label: t`Asia - Yekaterinburg`,
    },
    {
      value: 'Asia/Karachi',
      label: t`Asia - Karachi`,
    },
    {
      value: 'Asia/Tashkent',
      label: t`Asia - Tashkent`,
    },
  ],
  '(GMT+05:30)': [
    {
      value: 'Asia/Kolkata',
      label: t`Asia - Kolkata`,
    },
    {
      value: 'Asia/Colombo',
      label: t`Asia - Colombo`,
    },
  ],
  '(GMT+05:45)': [
    {
      value: 'Asia/Katmandu',
      label: t`Asia - Kathmandu`,
    },
  ],
  '(GMT+06:00)': [
    {
      value: 'Asia/Dhaka',
      label: t`Asia - Dhaka`,
    },
    {
      value: 'Asia/Almaty',
      label: t`Asia - Almaty`,
    },
    {
      value: 'Asia/Novosibirsk',
      label: t`Asia - Novosibirsk`,
    },
  ],
  '(GMT+06:30)': [
    {
      value: 'Asia/Rangoon',
      label: t`Asia - Rangoon`,
    },
  ],
  '(GMT+07:00)': [
    {
      value: 'Asia/Krasnoyarsk',
      label: t`Asia - Krasnoyarsk`,
    },
    {
      value: 'Asia/Bangkok',
      label: t`Asia - Bangkok`,
    },
    {
      value: 'Asia/Jakarta',
      label: t`Asia - Jakarta`,
    },
  ],
  '(GMT+08:00)': [
    {
      value: 'Asia/Brunei',
      label: t`Asia - Brunei`,
    },
    {
      value: 'Asia/Chongqing',
      label: t`Asia - Chongqing`,
    },
    {
      value: 'Asia/Hong_Kong',
      label: t`Asia - Hong Kong`,
    },
    {
      value: 'Asia/Urumqi',
      label: t`Asia - Urumqi`,
    },
    {
      value: 'Asia/Irkutsk',
      label: t`Asia - Irkutsk`,
    },
    {
      value: 'Asia/Ulaanbaatar',
      label: t`Asia - Ulaan Bataar`,
    },
    {
      value: 'Asia/Kuala_Lumpur',
      label: t`Asia - Kuala Lumpur`,
    },
    {
      value: 'Asia/Singapore',
      label: t`Asia - Singapore`,
    },
    {
      value: 'Asia/Taipei',
      label: t`Asia - Taipei`,
    },
    {
      value: 'Australia/Perth',
      label: t`Australia - Perth`,
    },
  ],
  '(GMT+09:00)': [
    {
      value: 'Asia/Seoul',
      label: t`Asia - Seoul`,
    },
    {
      value: 'Asia/Tokyo',
      label: t`Asia - Tokyo`,
    },
    {
      value: 'Asia/Yakutsk',
      label: t`Asia - Yakutsk`,
    },
  ],
  '(GMT+09:30)': [
    {
      value: 'Australia/Darwin',
      label: t`Australia - Darwin`,
    },
    {
      value: 'Australia/Adelaide',
      label: t`Australia - Adelaide`,
    },
  ],
  '(GMT+10:00)': [
    {
      value: 'Australia/Canberra',
      label: t`Australia - Canberra`,
    },
    {
      value: 'Australia/Melbourne',
      label: t`Australia - Melbourne`,
    },
    {
      value: 'Australia/Sydney',
      label: t`Australia - Sydney`,
    },
    {
      value: 'Australia/Brisbane',
      label: t`Australia - Brisbane`,
    },
    {
      value: 'Australia/Hobart',
      label: t`Australia - Hobart`,
    },
    {
      value: 'Asia/Vladivostok',
      label: t`Asia - Vladivostok`,
    },
    {
      value: 'Pacific/Guam',
      label: t`Pacific - Guam`,
    },
    {
      value: 'Pacific/Port_Moresby',
      label: t`Pacific - Port Moresby`,
    },
  ],
  '(GMT+11:00)': [
    {
      value: 'Asia/Magadan',
      label: t`Asia - Magadan`,
    },
  ],
  '(GMT+12:00)': [
    {
      value: 'Pacific/Fiji',
      label: t`Pacific - Fiji`,
    },
    {
      value: 'Asia/Kamchatka',
      label: t`Asia - Kamchatka`,
    },
    {
      value: 'Pacific/Auckland',
      label: t`Pacific - Auckland`,
    },
  ],
  '(GMT+13:00)': [
    {
      value: 'Pacific/Tongatapu',
      label: t`Pacific - Tongatapu`,
    },
  ],
});

export default TZ;

export const getTZLabel = tz => {
  const tzFound = Object.values(TZ())
    .flat()
    .find(({ value }) => value === tz);

  return tzFound?.label;
};

export const list = () => TZ();
