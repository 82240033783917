var sizes = [
    "bytes",
    "KB",
    "MB",
    "GB",
    "TB"
];
// eslint-disable-next-line import/prefer-default-export
export var formatBytes = function(bytes) {
    if (bytes === 0) return "n/a";
    if (!bytes) return null;
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i === 0) return "".concat(bytes, " ").concat(sizes[i]);
    return "".concat((bytes / Math.pow(1024, i)).toFixed(0), " ").concat(sizes[i]);
};
