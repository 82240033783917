import { faBroadcastTower, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSelector } from '@reduxjs/toolkit';
import { Alert, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import Empty from 'common/components/Empty';
import Header from 'common/components/Header';
import { SUPPORT_EMAIL } from 'common/services/support/types';
import { getId, getLicenseType } from 'common/state/organization';

import EmptyImage from '@images/illustrations/integrations.svg';
import {
  get as getFn,
  LIVESTREAM_PLAYBACK_HOURS,
  LIVESTREAM_BROADCAST_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
  PEOPLE_CREDITS,
} from '@services/data-usage-service';

import {
  STATUS_ACTIVE,
  STATUS_EXPIRED,
  getLatestContract as getLatestContractFn,
} from '../contracts-service';
import { LICENSE_TYPE_USER_CREDIT } from '../service';

import UsageLimitCollapse from './UsageLimitCollapse';

const Wrap = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;
const Section = styled.div`
  padding: 0px 45px;
`;
const Item = styled.div`
  margin: 16px 0px;
`;

const Title = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 400;
`;

const TitleIcon = styled(FontAwesomeIcon)`
  color: #595959;
  margin-right: 18px;
`;

const selector = createSelector(getId, getLicenseType, (organizationId, licenseType) => ({
  organizationId,
  licenseType,
}));

const useDataUsage = () => {
  const { organizationId, licenseType } = useSelector(selector);
  const [livestream, setLivestream] = useState({ isEmpty: true });
  const [video, setVideo] = useState({ isEmpty: true });
  const [loading, setLoading] = useState(true);
  const [recentActiveContract, setRecentActiveContract] = useState();

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const [
        streamBroadcastHours,
        streamPlaybackHours,
        videoStorageHours,
        videoPlaybackHours,
        contract,
      ] = await Promise.all([
        getFn(organizationId, LIVESTREAM_BROADCAST_HOURS),
        getFn(organizationId, LIVESTREAM_PLAYBACK_HOURS),
        getFn(organizationId, VIDEO_STORAGE_HOURS),
        getFn(organizationId, VIDEO_PLAYBACK_HOURS),
        licenseType === LICENSE_TYPE_USER_CREDIT
          ? getLatestContractFn(organizationId, {
              status: [STATUS_ACTIVE, STATUS_EXPIRED],
            })
          : null,
      ]);
      setLivestream({
        isEmpty: streamBroadcastHours.isEmpty && streamPlaybackHours.isEmpty,
        [LIVESTREAM_BROADCAST_HOURS]: streamBroadcastHours,
        [LIVESTREAM_PLAYBACK_HOURS]: streamPlaybackHours,
      });
      setVideo({
        isEmpty: videoStorageHours.isEmpty && videoPlaybackHours.isEmpty,
        [VIDEO_STORAGE_HOURS]: videoStorageHours,
        [VIDEO_PLAYBACK_HOURS]: videoPlaybackHours,
      });
      setRecentActiveContract(contract);
    } finally {
      setLoading(false);
    }
  }, [organizationId, licenseType]);

  useEffect(() => {
    if (organizationId && licenseType) {
      load();
    }
  }, [organizationId, licenseType, load]);

  return {
    livestream,
    video,
    loading,
    licenseType,
    recentActiveContract,
  };
};

const DataUsage = () => {
  const { loading, livestream, video, licenseType, recentActiveContract } = useDataUsage();

  if (loading) {
    return null;
  }

  const contactUsLink = (
    <a key="contact-us" href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer noopener">
      {t`contact us`}
    </a>
  );

  const isEmpty = livestream.isEmpty && video.isEmpty;
  return (
    <>
      {licenseType === LICENSE_TYPE_USER_CREDIT && (
        <>
          <Header title={t`User Credit License Usage`} padding="10px 45px" />
          <Section>
            {recentActiveContract?.status !== STATUS_ACTIVE && (
              <Alert
                message={jt`Your organization is under User Credit License, but there is no active contract. Please ${contactUsLink} to resolve this.`}
                type="warning"
                style={{ marginBottom: '24px', width: '100%', border: 0 }}
                showIcon
              />
            )}
            {recentActiveContract && (
              <Wrap>
                <Item>
                  <UsageLimitCollapse
                    type={PEOPLE_CREDITS}
                    used={recentActiveContract.creditsUsageByOrganization}
                    limit={recentActiveContract.userCredits}
                    {...recentActiveContract}
                  />
                </Item>
              </Wrap>
            )}
          </Section>
        </>
      )}
      <Header title={t`Media Content`} padding="10px 45px" />
      <Section>
        {isEmpty && (
          <Empty
            image={EmptyImage}
            title={t`You do not have any data packages`}
            body={t`Contact your Sales Representative to get started and learn how data packages can help improve your event.`}
          />
        )}
        {!isEmpty && (!livestream.isEmpty || video[VIDEO_PLAYBACK_HOURS]) && (
          <>
            <Typography.Text type="secondary">* = {t`Data is delayed by 12 hours`}</Typography.Text>
            <br />
            <Typography.Text type="secondary">
              {t`Additional charges may apply if you go over your data plan.`}
            </Typography.Text>
          </>
        )}
        {!livestream.isEmpty && (
          <Wrap>
            <Title level={4}>
              <TitleIcon icon={faBroadcastTower} />
              {t`Live Stream`}
            </Title>
            {!livestream[LIVESTREAM_BROADCAST_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse
                  type={LIVESTREAM_BROADCAST_HOURS}
                  {...livestream[LIVESTREAM_BROADCAST_HOURS]}
                />
              </Item>
            )}
            {!livestream[LIVESTREAM_PLAYBACK_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse
                  type={LIVESTREAM_PLAYBACK_HOURS}
                  {...livestream[LIVESTREAM_PLAYBACK_HOURS]}
                />
              </Item>
            )}
          </Wrap>
        )}
        {!video.isEmpty && (
          <Wrap>
            <Title level={4}>
              <TitleIcon icon={faVideo} />
              {t`Video On Demand`}
            </Title>
            {!video[VIDEO_STORAGE_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse type={VIDEO_STORAGE_HOURS} {...video[VIDEO_STORAGE_HOURS]} />
              </Item>
            )}
            {!video[VIDEO_PLAYBACK_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse type={VIDEO_PLAYBACK_HOURS} {...video[VIDEO_PLAYBACK_HOURS]} />
              </Item>
            )}
          </Wrap>
        )}
      </Section>
    </>
  );
};

export default DataUsage;
