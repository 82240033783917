import apiFn from 'common/services/api-service';

const postFn = eventId =>
  apiFn(`/api/uapi/events/${eventId}/activity-feed/posts`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });
const tokenFn = eventId =>
  apiFn(`/api/uapi/events/${eventId}/activity-feed/moderation/token`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const list = (eventId, token, limit, offset) =>
  postFn(eventId).get(null, {
    params: {
      moderationToken: token,
      page: offset / limit,
      limit,
      sort: '-created_at',
    },
  });
export const update = (eventId, id, payload = {}, token) =>
  postFn(eventId).patch(id, payload, { params: { moderationToken: token } });

export const getToken = eventId =>
  tokenFn(eventId)
    .get()
    .then(response => response.data.data.moderationToken);
