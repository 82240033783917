import { faBroadcastTower, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'ttag';

import Empty from 'common/components/Empty';
import Header from 'common/components/Header';
import { getId } from 'common/state/organization';

import EmptyImage from '@images/illustrations/integrations.svg';
import {
  LIVESTREAM_PLAYBACK_HOURS,
  LIVESTREAM_BROADCAST_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
} from '@services/data-usage-service';

import UsageLimitCollapse from '../UsageLimitCollapse';

import { useVideoUsage } from './queries';

const Wrap = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;
const Section = styled.div`
  padding: 0px 45px;
`;
const Item = styled.div`
  margin: 16px 0px;
`;

const Title = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 400;
`;

const TitleIcon = styled(FontAwesomeIcon)`
  color: #595959;
  margin-right: 18px;
`;

const Video = () => {
  const organizationId = useSelector(getId);
  const queriesData = useVideoUsage(organizationId);

  const isLoading = queriesData.some(query => query.isLoading);
  if (isLoading) {
    return null;
  }

  const [
    { data: streamBroadcastHours },
    { data: streamPlaybackHours },
    { data: videoStorageHours },
    { data: videoPlaybackHours },
  ] = queriesData;

  const liveStream = {
    isEmpty: streamBroadcastHours.isEmpty && streamPlaybackHours.isEmpty,
    [LIVESTREAM_BROADCAST_HOURS]: streamBroadcastHours,
    [LIVESTREAM_PLAYBACK_HOURS]: streamPlaybackHours,
  };
  const video = {
    isEmpty: videoStorageHours.isEmpty && videoPlaybackHours.isEmpty,
    [VIDEO_STORAGE_HOURS]: videoStorageHours,
    [VIDEO_PLAYBACK_HOURS]: videoPlaybackHours,
  };

  const isEmpty = liveStream.isEmpty && video.isEmpty;

  return (
    <>
      <Header title={t`Media Content`} padding="10px 45px" />
      <Section>
        {isEmpty && (
          <Empty
            image={EmptyImage}
            title={t`You do not have any data packages`}
            body={t`Contact your Sales Representative to get started and learn how data packages can help improve your event.`}
          />
        )}
        {!isEmpty && (!liveStream.isEmpty || video[VIDEO_PLAYBACK_HOURS]) && (
          <>
            <Typography.Text type="secondary">* = {t`Data is delayed by 12 hours`}</Typography.Text>
            <br />
            <Typography.Text type="secondary">
              {t`Additional charges may apply if you go over your data plan.`}
            </Typography.Text>
          </>
        )}
        {!liveStream.isEmpty && (
          <Wrap>
            <Title level={4}>
              <TitleIcon icon={faBroadcastTower} />
              {t`Live Stream`}
            </Title>
            {!liveStream[LIVESTREAM_BROADCAST_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse
                  type={LIVESTREAM_BROADCAST_HOURS}
                  {...liveStream[LIVESTREAM_BROADCAST_HOURS]}
                />
              </Item>
            )}
            {!liveStream[LIVESTREAM_PLAYBACK_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse
                  type={LIVESTREAM_PLAYBACK_HOURS}
                  {...liveStream[LIVESTREAM_PLAYBACK_HOURS]}
                />
              </Item>
            )}
          </Wrap>
        )}
        {!video.isEmpty && (
          <Wrap>
            <Title level={4}>
              <TitleIcon icon={faVideo} />
              {t`Video On Demand`}
            </Title>
            {!video[VIDEO_STORAGE_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse type={VIDEO_STORAGE_HOURS} {...video[VIDEO_STORAGE_HOURS]} />
              </Item>
            )}
            {!video[VIDEO_PLAYBACK_HOURS].isEmpty && (
              <Item>
                <UsageLimitCollapse type={VIDEO_PLAYBACK_HOURS} {...video[VIDEO_PLAYBACK_HOURS]} />
              </Item>
            )}
          </Wrap>
        )}
      </Section>
    </>
  );
};

export default Video;
