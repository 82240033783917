import { createGlobalStyle } from 'styled-components';

import slashBg from '@images/slash-bg.svg';

export default createGlobalStyle`
.ant-table .anticon {
  svg {
    max-height: unset;
  }
}

.ant-table .ant-table-row {
  &.editable-row > td {
    vertical-align: middle;
  }
}

.ant-table-header-column {
    font-weight: 600;
}

.ant-table-filter-dropdown, .ant-table-pagination {
  a {
    text-decoration: none;
  }
}

.ant-column-vertical-align {
  vertical-align: middle;
}

.ant-form-vertical .ant-form-item-row {
  flex-direction: row;
}

.editable-row  {
  .ant-form-item-explain {
    position: absolute;
    font-size: 12px;
  }
}

.clickable-row {
  cursor: pointer;
}

.ant-table-tbody > tr.ant-table-row.warning-row {
  background-color: #fffbe6;

  :not(&.ant-table-row-selected) { // we do not want to color the row when the row is selected
    & > td.ant-table-column-sort, &:hover > td, &.ant-table-row-selected:hover > td {
      background-color: #fff1b8;
    }
  }
}

.ant-table-tbody > tr.ant-table-row.hidden-row {
  &, span:not(button *):not(.ant-tag) {
    color: #bfbfbf;
  }

  &, &.ant-table-row-selected > td, &:hover > td, &.ant-table-row-selected:hover > td {
    background-image: url(${slashBg});
  }

  background-color: #f8f8f8;

  &:hover > td {
    background-color: #fafafa;
  }

  &.ant-table-row-selected > td {
    background-color: #e6f7ff;
  }

  &.ant-table-row-selected:hover > td {
    background-color: #dcf4ff;
  }
}

.ant-table-tbody > tr.ant-table-row.disabled-row {
  &, span:not(button *):not(.ant-tag) {
    color: #bfbfbf;
  }
}

/**
  Filter Icon in the Table is placed on the right side of the column,
  when it is the last column, scrollbar overlaps the icon and it's
  hard to use.
*/
.ant-table-thead > tr > th.ant-table-column-has-filters:last-child {
  & > i.anticon-filter {
    padding: 0 35px;
  }
}

.ant-table-tbody > tr.ant-table-row.warning-row {
  background-color: #fffbe6;
  &:not(.ant-table-row-selected) > td.ant-table-column-sort {
    background-color:#fffbe6;
  }
  &:not(.ant-table-row-selected):hover > td, &:not(.ant-table-row-selected):hover > td.ant-table-column-sort {
    background-color: #fff7cd;
  }
}
`;
