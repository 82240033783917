import apiFn from 'common/services/api-service';

const api = (eventId, headers = {}) => apiFn(`/api/uapi/events/${eventId}/sessions/roles`, headers);

export const list = async (eventId, payload = {}, query = null) => {
  const params = [];

  if (query !== null && query.length > 1) {
    params.push(query.substr(1, query.length));
  }

  if (typeof payload.offset !== 'undefined' && payload.offset > 0) {
    params.push(`page=${payload.offset / payload.limit}`);
  }

  return api(eventId)
    .get(`?${params.join('&')}`)
    .then(response => response?.data);
};

export const create = (eventId, payload = {}) =>
  api(eventId)
    .post('', payload)
    .then(response => response?.data?.data);

export const update = (eventId, roleId, payload = {}) =>
  api(eventId)
    .patch(roleId, payload)
    .then(response => response?.data?.data);

export const remove = (eventId, roleId) =>
  api(eventId)
    .delete(roleId)
    .then(response => response?.data?.data);

export const saveOrder = (eventId, payload) =>
  api(eventId, { Accept: 'application/vnd.eventmobi+json; version=p.7' })
    .patch('', payload)
    .then(response => response?.data);
