// Any query generated by `toQuery` can be revert back to object using `fromQuery` function
// Note: Query returned is URL safe.
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
/**
 * @deprecated We should not be manually creating queryStrings, and should instead generate
 * a flattened object and pass it to `new URLSearchParams(obj)` which has a reliable
 * .toString() method to generate the query string.
 */ export var toQuery = function(payload) {
    var limit = payload.limit, offset = payload.offset, search = payload.search, filter = payload.filter, sort = payload.sort, preserved = _objectWithoutProperties(payload, [
        "limit",
        "offset",
        "search",
        "filter",
        "sort"
    ]);
    var hasSearch = (search === null || search === void 0 ? void 0 : search.length) > 0;
    var hasFilter = filter && Object.keys(filter).length > 0;
    var hasSort = (sort === null || sort === void 0 ? void 0 : sort.order) && (sort === null || sort === void 0 ? void 0 : sort.field);
    var hasLimit = !!limit;
    var hasOffset = offset && offset > 1;
    var hasPreserved = Object.entries(preserved).length > 0;
    var q = "?";
    if (hasLimit) {
        q += "limit=".concat(limit, "&");
    }
    if (hasOffset) {
        q += "offset=".concat(offset, "&");
    }
    if (hasSearch) {
        q += "search=".concat(search, "&");
    }
    if (hasSort) {
        if (sort.order === "ascend") {
            q += "sort=".concat(sort.field, "&");
        }
        if (sort.order === "descend") {
            q += "sort=-".concat(sort.field, "&");
        }
    }
    if (hasFilter) {
        // iterate through the `filter` object, append all key and value sets to the query.
        // for example:
        // `{ foo: [1, 2, 3], bar: [3]}` => `foo=1,2,3&bar=3&`
        q += Object.entries(filter).reduce(function(prev, param) {
            var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
            return "".concat(prev === "" ? prev : "".concat(prev, "&")).concat(key, "=").concat(value.join(","));
        }, "");
        if (hasPreserved) {
            // iterate through the `preserved` object, append all key and value sets to the query.
            // for example:
            // `{ foo: 'a', bar: 'b'}` => `foo=a&bar=b`
            q += "&".concat(Object.entries(preserved).map(function(param) {
                var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
                return "".concat(key, "=").concat(value);
            }).join("&"));
        }
    }
    // check whether or not the last character in the newly formed query is either `&` or `?`,
    // then remove it as query may not have any params that we are setting and in that case it
    // would be just a `?` which is an edge case, similarly since all params are being checked
    // and added to the query sequentially, so it may have a leftover `&` character.
    if (q[q.length - 1] === "&" || q[q.length - 1] === "?") {
        q = q.slice(0, -1);
    }
    return encodeURI(q);
};
/**
 * @deprecated We should not be manually parsing query strings, and should instead pass it
 * to `new URLSearchParams(queryString)` and then either use that object, or reduce the entries
 * back into an object, after which we can restructure it from a flat object as needed.
 */ export var fromQuery = function(query) {
    if (query.length === 0) {
        return {};
    }
    var q = decodeURI(query);
    // remove the first `?` character from the query
    if (q[0] === "?") {
        q = q.substr(1, q.length);
        if (q.length === 0) {
            // query has nothing to parse, return an empty object
            return {};
        }
    }
    // remove the last `&` character from the query
    if (q[q.length - 1] === "&") {
        q = q.substr(0, -1);
    }
    // create an array of array by splitting the query using `&` character and then all
    // the parts using `=` character so that we have entries for each query param
    // for example:
    // `foo=bar&baz=qux` => [['foo', 'bar'], ['baz', 'qux']]
    var parts = q.split("&").map(function(part) {
        return part.split("=");
    });
    // check if the provided key is a field API understands
    var isDefined = function(key) {
        return [
            "search",
            "sort",
            "limit",
            "offset"
        ].includes(key);
    };
    // check if the provided key should be preserved in query
    var shouldPreserve = function(key) {
        return [
            "tab"
        ].includes(key);
    };
    // create an object from all the parts which have their keys defined
    var _parts_filter_reduce = parts// filter through all keys, only look for defined keys
    .filter(function(param) {
        var _param = _slicedToArray(param, 1), key = _param[0];
        return isDefined(key) || shouldPreserve(key);
    })// reduce the remaining keys and values to form an object
    .reduce(function(object, param) {
        var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
        return _objectSpread(_defineProperty({}, key, value), object);
    }, {}), sort = _parts_filter_reduce.sort, payload = _objectWithoutProperties(_parts_filter_reduce, [
        "sort"
    ]);
    // check if `sort` key is defined in the query – in the case of it being defined, further
    // validate if sort is defined in a particular format which API follow:
    // `sort=field`   -> sort the records using `field` in ascending order
    // `sort=-field`  -> sort the records using `field` in descending order
    // replace the string for `sort` key to an explicit format
    // for example:
    // `sort=-foo` => { order:  'descend', field: 'foo'}
    var sortPayload;
    if (sort) {
        // check if the string for sort starts with `-` character
        if (sort[0] === "-") {
            sortPayload = {
                sort: {
                    order: "descend",
                    field: sort.substr(1, sort.length)
                }
            };
        } else {
            sortPayload = {
                sort: {
                    order: "ascend",
                    field: sort
                }
            };
        }
    }
    // create filter object using the remaining keys from the query parts
    var filter = parts// filter through all the keys, look for only undefined keys
    .filter(function(param) {
        var _param = _slicedToArray(param, 1), key = _param[0];
        return !isDefined(key) && !shouldPreserve(key);
    })// filter again to remove any incomplete or invalid key/value pairs
    // an edge case only for following _valid_ queries:
    // – `?foo`
    // – `?foo=`
    // – `?=bar`
    // – `?=`
    .filter(function(param) {
        var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
        return typeof key !== "undefined" && typeof value !== "undefined";
    })// reduce the key/value pairs into an object
    // decodeURIComponent is required mainly because the way useTable uses toQuery function, below is the flow
    // 1 - useTable uses toQuery to get the query params that encode the params using encodeURI
    // 2 - useTable then pass this query params to the URLSearchParams and uses its toString() method to send the new query params to the URL. But this toString() method serializes the query params which defeats the purpose of doing encodeURI in step 1 by toQuery function and now the decodeURI in fromQuery won't work anymore, hence we have to use decodeURIComponent explicitly to individual query param
    .reduce(function(object, param) {
        var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
        return _objectSpread(_defineProperty({}, key, decodeURIComponent(value).split(",")), object);
    }, {});
    // check if there are no filters defined and for such cases, return the `payload`
    // without `filter` object
    if (Object.keys(filter).length === 0) {
        return _objectSpread({}, payload, sortPayload);
    }
    return _objectSpreadProps(_objectSpread({}, payload, sortPayload), {
        filter: filter
    });
};
// mergeQueries combines all the provided N queries
// it will remove duplicate keys and if they have different values
// they will be overridden by the latest value
export var mergeQueries = function() {
    for(var _len = arguments.length, queries = new Array(_len), _key = 0; _key < _len; _key++){
        queries[_key] = arguments[_key];
    }
    var source = queries// create new `URLSearchParams` from raw string queries
    .map(function(query) {
        return new URLSearchParams(query);
    })// reduce all `URLSearchParams` to entries with keys and values
    .reduce(function(prev, next) {
        return _toConsumableArray(prev).concat(_toConsumableArray(Array.from(next.entries())));
    }, [])// create an object from key/value pairs
    .reduce(function(prev, param) {
        var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
        return _objectSpreadProps(_objectSpread({}, prev), _defineProperty({}, key, value));
    }, {});
    var params = new URLSearchParams(source);
    params.sort();
    return params;
};
/**
 * compareQuery takes two queries and returns if they're the same
 * regardless of their alphabetical order.
 */ export var compareQuery = function(left, right) {
    var leftParams = new URLSearchParams(left);
    var rightParams = new URLSearchParams(right);
    leftParams.sort();
    rightParams.sort();
    return leftParams.toString() === rightParams.toString();
};
