import { PlusOutlined } from '@ant-design/icons';
import { Table as AntTable, Button, Divider, Row, Col, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { t, ngettext, msgid } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import Empty from 'common/components/Empty';
import DeleteModal from 'common/components/Modal/DeleteModal';
import { getId } from 'common/state/organization';
import { getIsStaff } from 'common/state/user';

import usePermission, { CREATE_APPS, DELETE_APPS, UPDATE_APPS } from '@hooks/usePermission';
import EmptyImage from '@images/illustrations/multi-event-app.svg';

import Modal from './Modal';
import { list as listFn, remove as removeFn, MODE_MULTI, MODE_SINGLE } from './service';

const { Column } = AntTable;
const Base = styled.div`
  /* accommodate deleteBar component's height */
  margin-bottom: 40px;
`;

const LABELS = () => ({
  [MODE_SINGLE]: t`Single-Event`,
  [MODE_MULTI]: t`Multi-Event`,
});

const Table = ({
  dataSource,
  onRowSelection,
  loading,
  selectedRowKeys,
  onView,
  onEdit,
  onDelete,
}) => {
  const { authorized, authorizedAny } = usePermission();
  const sorter = (row1, row2, fieldName) => {
    return row1[fieldName]?.localeCompare(row2[fieldName]);
  };

  const rowSelection = {
    columnWidth: 50,
    selectedRowKeys,
    onChange: onRowSelection,
  };

  return (
    <AntTable
      rowKey="id"
      pagination={false}
      dataSource={dataSource}
      rowSelection={authorized(DELETE_APPS) && onRowSelection ? rowSelection : null}
      rowClassName="clickable-row"
      tableLayout="auto"
      loading={loading}
      onRow={record => ({
        onClick: () => onView(record.id),
      })}
    >
      <Column
        title={t`Name`}
        dataIndex="name"
        sorter={(a, b) => sorter(a, b, 'name')}
        defaultSortOrder="ascend"
      />
      <Column title={t`Code`} dataIndex="shortcode" sorter={(a, b) => sorter(a, b, 'shortcode')} />
      <Column
        title={t`Mode`}
        dataIndex="launchEventId"
        filters={[
          { text: LABELS()[MODE_SINGLE], value: MODE_SINGLE },
          { text: LABELS()[MODE_MULTI], value: MODE_MULTI },
        ]}
        onFilter={(value, record) => !!record.launchEventId === (value === MODE_SINGLE)}
        render={value => LABELS()[value ? MODE_SINGLE : MODE_MULTI]}
      />
      <Column
        title={t`# of Events`}
        dataIndex="numEvents"
        render={(_, row) => row.events?.length ?? 0}
        sorter={(a, b) => a.numEvents - b.numEvents}
      />
      {authorizedAny(UPDATE_APPS, DELETE_APPS) && (
        <Column
          title={t`Action`}
          dataIndex="action"
          width="15%"
          onCell={() => ({ onClick: event => event.stopPropagation() })}
          render={(_, row) => {
            return (
              <Space size="small" split={<Divider type="vertical" />}>
                {authorized(UPDATE_APPS) && (
                  <Button type="link" onClick={() => onEdit(row.id, row)} style={{ padding: 0 }}>
                    {t`Edit`}
                  </Button>
                )}
                {authorized(DELETE_APPS) && onDelete && (
                  <Button
                    type="link"
                    danger
                    onClick={() => onDelete(row.id, row)}
                    style={{ padding: 0 }}
                  >
                    {t`Delete`}
                  </Button>
                )}
              </Space>
            );
          }}
        />
      )}
    </AntTable>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  onRowSelection: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

const useApps = organizationId => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const load = async () => {
    try {
      setLoading(true);
      setList(await listFn(organizationId));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      load();
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return {
    list,
    loading,
    forceReload: load,
  };
};

const selector = createSelector(getId, getIsStaff, (organizationId, isStaff) => ({
  organizationId,
  isStaff,
}));

const Apps = () => {
  const { organizationId, isStaff } = useSelector(selector);
  const { list, loading, forceReload } = useApps(organizationId);
  const history = useHistory();
  const [appId, setAppId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { authorized } = usePermission();

  const handleDelete = keys => {
    const { name } = list.find(app => app.id === keys[0]);

    DeleteModal.confirm({
      label: {
        singular: t`App`,
        plural: t`Apps`,
      },
      itemName: name,
      count: keys.length,
      async onDelete() {
        await Promise.all(keys.map(key => removeFn(organizationId, key)));

        forceReload();
        setSelectedRows([]);
      },
    });
  };

  const handleView = key => {
    history.push(`apps/${key}`);
  };

  const handleEdit = key => {
    setAppId(key);
    setShowModal(true);
  };

  const handleNew = () => {
    setAppId(null);
    setShowModal(true);
  };

  const handleModalClose = reload => {
    setShowModal(false);
    if (reload) {
      forceReload();
    }
  };

  return (
    <Base>
      <Row type="flex" css="margin: 20px 0;">
        {authorized(CREATE_APPS) && (
          <Col span={8}>
            <Button key="add" type="primary" icon={<PlusOutlined />} onClick={handleNew}>
              {t`Add App`}
            </Button>
          </Col>
        )}
      </Row>

      {!loading && list.length === 0 ? (
        <Empty
          image={EmptyImage}
          title={t`You don't have any apps yet`}
          body={t`Connect better with attendees and improve engagement by adding an app.`}
        />
      ) : (
        <Table
          dataSource={list}
          loading={loading}
          onRowSelection={isStaff ? keys => setSelectedRows([...keys]) : null}
          selectedRowKeys={selectedRows}
          onView={handleView}
          onEdit={handleEdit}
          onDelete={isStaff ? key => handleDelete([key]) : null}
        />
      )}

      {authorized(DELETE_APPS) && (
        <DeleteBar
          style={{ left: 0 }}
          open={selectedRows.length > 0}
          buttonText={ngettext(
            msgid`Delete ${selectedRows.length} App`,
            `Delete ${selectedRows.length} Apps`,
            selectedRows.length
          )}
          onClick={() => handleDelete(selectedRows)}
        />
      )}

      {showModal && (
        <Modal organizationId={organizationId} appId={appId} onClose={handleModalClose} />
      )}
    </Base>
  );
};

export default Apps;
