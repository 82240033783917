import { toQuery } from 'common/hooks/query-service';
import { list as getCompaniesFn } from 'common/services/companies/api';

import { HOMEPAGE_TYPE_ACTIVITY_FEED, get as getHomepageFn } from '@domains/HomePage/service';

export const checkLeadCaptureLicenseLimitExceeded = async (eventId, payload) => {
  const isLeadCaptureEnabled = payload.is_lead_capture_enabled.value;
  if (!isLeadCaptureEnabled) {
    return false;
  }
  const licenseLimit = payload.lead_capture_license_limit.value;
  const {
    meta: {
      pagination: { totalItemsCount: licenseCount },
    },
  } = await getCompaniesFn(
    eventId,
    undefined,
    toQuery({
      filter: { leadCapture: ['true'] },
      limit: 0,
    })
  );

  return licenseCount > licenseLimit;
};

export const checkActivityFeedSetAsHomepage = async (eventId, payload) => {
  const isActivityFeedEnabled = payload.is_activity_feed_enabled.value;
  if (isActivityFeedEnabled) {
    return false;
  }

  const { entityType: currentHomepageEntityType } = await getHomepageFn(eventId);
  return currentHomepageEntityType === HOMEPAGE_TYPE_ACTIVITY_FEED;
};
