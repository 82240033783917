import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useTableQuery } from 'common/hooks/useTableQuery';

import { CONNECTION_STATUS } from './constants';
import {
  archiveConnection as archiveConnectionFn,
  createConnection as createConnectionFn,
  listConnections as listConnectionsFn,
  listSupportedGateways as listSupportedGatewaysFn,
  updateConnection as updateConnectionFn,
  testConnection as testConnectionFn,
  listSupportedCurrencies as listSupportedCurrenciesFn,
} from './service';

export const CONNECTION_LIST_REFETCH_INTERVAL = 5 * 1000;

const getConnectionsBaseQueryKey = organizationId => [
  'organization',
  organizationId,
  'payments',
  'connections',
];

export const useInvalidateConnections = () => {
  const queryClient = useQueryClient();
  const { organizationId } = useParams();

  return useCallback(() => {
    queryClient.invalidateQueries(getConnectionsBaseQueryKey(organizationId));
  }, [queryClient, organizationId]);
};

export const useSupportedGateways = enabled => {
  const { organizationId } = useParams();

  return useQuery({
    queryKey: ['organization', organizationId, 'payments', 'supported-gateways'],
    queryFn: () => listSupportedGatewaysFn(organizationId),
    select: data => data.data,
    enabled,
  });
};

export const useSupportedCurrencyQuery = () => {
  const { organizationId } = useParams();

  return useQuery({
    queryKey: ['organization', organizationId, 'payments', 'supported-currencies'],
    queryFn: () => listSupportedCurrenciesFn(organizationId),
    select: data => data.data.supportedCurrencies,
  });
};

export const useAddConnectionMutation = () => {
  const { organizationId } = useParams();
  const invalidate = useInvalidateConnections();

  return useMutation({
    mutationKey: ['organization', organizationId, 'payments', 'add-connection'],
    mutationFn: ({ payload }) => createConnectionFn(organizationId, payload),
    onSuccess: () => invalidate(),
  });
};

export const useUpdateConnectionMutation = () => {
  const { organizationId } = useParams();
  const invalidate = useInvalidateConnections();

  return useMutation({
    mutationKey: ['organization', organizationId, 'payments', 'edit-connection'],
    mutationFn: ({ id, payload }) => updateConnectionFn(organizationId, id, payload),
    onSuccess: () => invalidate(),
  });
};

export const useArchiveConnectionMutation = () => {
  const { organizationId } = useParams();

  /*
    We don't want to automatically invalidate here, because deleting N rows will
    call this mutationFn N times and trigger the invalidation multiple times. The
    parent can invalidate when all of them are resolved.
  */
  return useMutation({
    mutationKey: ['organization', organizationId, 'payments', 'archive-connection'],
    mutationFn: ({ id }) => archiveConnectionFn(organizationId, id),
  });
};

export const useConnectionsTableQuery = (queryOptions, tableOptions) => {
  const { organizationId } = useParams();

  return useTableQuery(
    apiParams => ({
      queryKey: [...getConnectionsBaseQueryKey(organizationId), apiParams],
      queryFn: () => {
        const { events, ...transformedParams } = apiParams;

        if (events) {
          transformedParams.event_id = events;
        }

        return listConnectionsFn(organizationId, transformedParams);
      },
      refetchInterval: prev =>
        prev?.data.some(row => row.status === CONNECTION_STATUS.Processing) &&
        CONNECTION_LIST_REFETCH_INTERVAL,
      ...queryOptions,
    }),
    tableOptions
  );
};

// Used by the Link Events form, which needs to know all events that have a
// linked gateway.
export const useConnectedEventIdsQuery = () => {
  const { organizationId } = useParams();

  return useQuery({
    queryKey: [...getConnectionsBaseQueryKey(organizationId), 'all'],
    queryFn: () => listConnectionsFn(organizationId, { limit: 1000 }),
    select: useCallback(data => {
      if (!data) {
        return new Set();
      }

      const eventIds = data.data.map(connection => connection.events.map(event => event.id)).flat();

      return new Set(eventIds);
    }, []),
  });
};

export const useEventConnectionQuery = eventId => {
  const { organizationId } = useParams();

  return useQuery({
    queryKey: [...getConnectionsBaseQueryKey(organizationId), { eventId }],
    queryFn: () => listConnectionsFn(organizationId, { events: [eventId] }),
    select: data => data.data[0],
  });
};

export const useCheckStatusMutation = () => {
  const queryClient = useQueryClient();
  const { organizationId } = useParams();

  return useMutation({
    mutationFn: ({ gatewayId }) => testConnectionFn(organizationId, gatewayId),
    onSuccess: () => {
      queryClient.invalidateQueries(getConnectionsBaseQueryKey(organizationId));
    },
  });
};
