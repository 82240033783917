import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { Menu, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

const Wrap = styled.div`
  position: absolute;
  z-index: 5;

  height: inherit;
  width: inherit;

  display: flex;
  justify-content: flex-end;
  padding: 10px;

  // Generated this dash border style from https://kovart.github.io/dashed-border-generator/
  &:hover {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237D7D7DFF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  & > ul.ant-menu.ant-menu-horizontal {
    width: ${props => props?.width ?? 124}px;
    line-height: 32px;
    height: 34px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    & > li.ant-menu-item {
      padding: 0 8px;
      margin: 0;
    }

    & > .ant-menu-item-active {
      border-bottom: 2px solid;
    }

    /*
      danger active color gets overriden by ant
    */
    & > li.danger-item.ant-menu-item-active {
      color: #ff4d4f;
      border-bottom-color: #ff4d4f;
    }
  }
`;

const Actions = ({
  showClone = true,
  showEdit = true,
  showDelete = true,
  showVisible = true,
  showMenu,
  isVisible,
  onClone = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onVisible = () => {},
}) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  // fancy way of calculating the width based on the items visible
  const width = [showClone, showEdit, showDelete, showVisible].reduce(
    (prev, flag) => (flag ? prev + 32 : prev),
    0
  );

  const isVisibleTooltip = isVisible ? (
    <Tooltip title={t`Visible on Mobile`}>
      <EyeOutlined data-testid="visible-on" />
    </Tooltip>
  ) : (
    <Tooltip title={t`Hidden on Mobile`}>
      <EyeInvisibleOutlined data-testid="visible-off" />
    </Tooltip>
  );

  return (
    <Wrap
      width={width}
      data-testid="actions"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showMenu && hover && (
        <Menu
          mode="horizontal"
          selectable={false}
          style={{ minWidth: 64 }}
          items={[
            showVisible && {
              key: 'visible',
              onClick: onVisible,
              label: isVisibleTooltip,
            },
            showClone && {
              key: 'clone',
              onClick: onClone,
              label: (
                <Tooltip title={t`Clone`}>
                  <CopyOutlined data-testid="clone" />
                </Tooltip>
              ),
            },
            showEdit && {
              key: 'edit',
              onClick: onEdit,
              label: (
                <Tooltip title={t`Edit`}>
                  <EditOutlined data-testid="edit" />
                </Tooltip>
              ),
            },
            showDelete && {
              key: 'delete',
              onClick: onDelete,
              danger: true,
              className: 'danger-item',
              label: (
                <Tooltip title={t`Delete`}>
                  <DeleteOutlined data-testid="delete" />
                </Tooltip>
              ),
            },
          ].filter(Boolean)}
        />
      )}
    </Wrap>
  );
};

Actions.propTypes = {
  showClone: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  showMenu: PropTypes.bool,
  showVisible: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClone: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onVisible: PropTypes.func,
};

export default Actions;
