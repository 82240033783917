import Portal from "../../types/Portal";
import apiFn from "../api-service";
var companyPortalApi = function() {
    return apiFn("/api/uapi/company-portal/support-chat/token");
};
var expApi = function() {
    return apiFn("/api/uapi/organizers/support-chat/token");
};
// eslint-disable-next-line import/prefer-default-export
export var getVisitorToken = function(payload, portalType) {
    var tokenApi = portalType === Portal.Experience ? expApi : companyPortalApi;
    return tokenApi().post("", payload).then(function(response) {
        var _response_data, _response_data_data;
        return (_response_data = response.data) === null || _response_data === void 0 ? void 0 : (_response_data_data = _response_data.data) === null || _response_data_data === void 0 ? void 0 : _response_data_data.token;
    });
};
