import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { t } from 'ttag';

import Modal from 'common/components/Modal';

// useNativeSafeExit shows a browser native confirmation dialog when the `isSafe` condition
// is not met and the user attempts to close or refresh the browser
const useNativeSafeExit = (isSafe, global = window) => {
  useEffect(() => {
    function beforeUnloadlistener(event) {
      if (!isSafe) {
        event.preventDefault();

        // if you're wondering why do we have to do that then take your fight
        // to w3c or whoever designed this stupid API. We _have_ to set the return
        // value to be any string to show the default native confirmation popup.

        // eslint-disable-next-line no-param-reassign
        event.returnValue = '¯\\_(ツ)_/¯';
      }
    }

    global.addEventListener('beforeunload', beforeUnloadlistener);

    return () => {
      global.removeEventListener('beforeunload', beforeUnloadlistener);
    };
  }, [isSafe, global]);
};

const SafeExit = ({ isSafe, title, content }) => {
  useNativeSafeExit(isSafe);
  const history = useHistory();
  const modalShown = useRef(false);

  // handlePrompt should return true when user is safe to exit
  const handlePrompt = location => {
    if (isSafe) {
      return true;
    }

    // handlePrompt is called twice
    // 1. when user navigates away
    // 2. when user confirms navigate away (because we call history.push)
    if (modalShown.current) {
      return true;
    }

    Modal.confirm({
      title: title ?? t`Continue without saving?`,
      content: (
        <>
          {content ?? t`There are unsaved changes on this page.`}
          <br />
          {t`Do you still want to leave this page?`}
        </>
      ),
      okText: t`Yes, Continue`,
      key: 'safe-exit-modal',
      onOk: () => history.push(location),
      onCancel: () => {
        modalShown.current = false;
      },
    });
    modalShown.current = true;

    return false;
  };

  return <Prompt message={handlePrompt} />;
};

SafeExit.propTypes = {
  isSafe: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default SafeExit;
