import * as Sentry from '@sentry/react';
import { Alert, Button, message, Modal, Space, Steps } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { t } from 'ttag';

import ConnectionForm from './ConnectionForm';
import { ERR_GATEWAY_CONN_EXISTS } from './constants';
import LinkEventsForm from './LinkEventsForm';
import { useAddConnectionMutation, useUpdateConnectionMutation } from './queries';

const STEP_ADD_CONNECTION = 0;
const STEP_LINK_EVENTS = 1;

const AddModal = ({ onClose }) => {
  const [gatewayId, setGatewayId] = useState(null);
  const connectionFormRef = useRef(null);
  const linkEventsFormRef = useRef(null);
  const { mutate: mutateAdd, isLoading: isAdding, error: addError } = useAddConnectionMutation();
  const {
    mutate: mutateUpdate,
    isLoading: isUpdating,
    isError: updateError,
  } = useUpdateConnectionMutation();

  const handleAddConnection = async () => {
    const payload = await connectionFormRef.current.submit();

    mutateAdd(
      { payload },
      {
        onSuccess: ({ data: gateway }) => {
          setGatewayId(gateway.id);
          message.success(t`Successfully added connection!`);
        },
        onError: err => {
          const code = err.errors?.[0].code;
          if (code === ERR_GATEWAY_CONN_EXISTS) {
            connectionFormRef.current.setFields([
              {
                name: 'name',
                errors: [
                  t`A connection with this name already exists. Please enter a different name.`,
                ],
              },
            ]);
          } else {
            Sentry.captureException(err);
          }
        },
      }
    );
  };

  const handleLinkEvents = async () => {
    const payload = await linkEventsFormRef.current.submit();

    mutateUpdate(
      { id: gatewayId, payload },
      {
        onSuccess: () => {
          onClose();
          message.success(t`Successfully updated Linked Events.`);
        },
        onError: err => {
          Sentry.captureException(err);
        },
      }
    );
  };

  const currentStep = gatewayId ? STEP_LINK_EVENTS : STEP_ADD_CONNECTION;
  const error =
    (addError &&
      addError.errors?.[0]?.code !== ERR_GATEWAY_CONN_EXISTS &&
      t`Error adding connection. Please try again.`) ||
    (updateError && t`Error linking events to connection. Please try again.`);

  return (
    <Modal
      title={t`Add Connection`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Button onClick={onClose} disabled={isAdding || isUpdating}>
              {currentStep === STEP_ADD_CONNECTION ? t`Cancel` : t`Skip, I'll do it later`}
            </Button>
          </Space>
          <Space>
            {currentStep === STEP_ADD_CONNECTION && (
              <Button type="primary" onClick={handleAddConnection} loading={isAdding}>
                {t`Add Connection`}
              </Button>
            )}
            {currentStep === STEP_LINK_EVENTS && (
              <Button type="primary" onClick={handleLinkEvents} loading={isUpdating}>
                {t`Link to Events`}
              </Button>
            )}
          </Space>
        </div>
      }
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Steps
            size="small"
            current={currentStep}
            items={[{ title: t`Add Connection` }, { title: t`Link to Events` }]}
            style={{ width: 420 }}
          />
        </div>

        {error && <Alert showIcon type="error" message={error} />}

        {currentStep === STEP_ADD_CONNECTION && (
          <ConnectionForm formRef={connectionFormRef} disabled={isAdding} />
        )}
        {currentStep === STEP_LINK_EVENTS && (
          <LinkEventsForm formRef={linkEventsFormRef} disabled={isUpdating} />
        )}
      </Space>
    </Modal>
  );
};

AddModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddModal;
