import { Alert } from 'antd';
import moment from 'moment';
import React, { useReducer } from 'react';
import { useParams } from 'react-router-dom';

import useEventAlerts from './useEventAlerts';
import useOrgAlerts from './useOrgAlerts';
import useOrgUsageAlerts from './useOrgUsageAlerts';

/*
  We can't delete cookies; the browser just cleans them up once they expire.
  Setting the minimum possible date so it will always be less than Now.
*/
const COOKIE_EXPIRY = new Date(0);

export const COOKIE_PREFIX = 'em-hide-alert';

const getAlertCookieName = (resourceId, alertId, type) =>
  `${COOKIE_PREFIX}-${resourceId}-${alertId}-${type}`;

const setAlertCookie = (resourceId, alertId, type) => {
  const k = getAlertCookieName(resourceId, alertId, type);
  const expires = moment().add(24, 'hours').toDate();

  document.cookie = `${k}=true;path=/;expires=${expires}`;
};

const alertCookieExists = (resourceId, alertId, type) => {
  const k = getAlertCookieName(resourceId, alertId, type);

  return document.cookie.includes(k);
};

export const clearAlertCookies = () => {
  const cookies = document.cookie.split(/;\s*/);

  cookies.forEach(str => {
    const [k] = str.split('=');

    if (k.startsWith(COOKIE_PREFIX)) {
      document.cookie = `${k}='';path=/;expires=${COOKIE_EXPIRY}`;
    }
  });
};

const filterActiveAlerts = alert => !alertCookieExists(alert.resourceId, alert.id, alert.type);

const AppAlerts = () => {
  const { organizationId, eventId } = useParams();
  const { loading: orgUsageLoading, alerts: orgUsageAlerts } = useOrgUsageAlerts(organizationId);
  const { loading: orgLoading, alerts: orgAlerts } = useOrgAlerts(organizationId);
  const { alerts: eventAlerts } = useEventAlerts(eventId);
  const [, forceRender] = useReducer(n => !n, false);

  const handleClose = (scope, id, type) => {
    setAlertCookie(scope === 'event' ? eventId : organizationId, id, type);
    forceRender();
  };

  if (orgLoading || orgUsageLoading) {
    return null;
  }

  return (
    <div>
      {[...eventAlerts, ...orgAlerts, ...orgUsageAlerts]
        // Filtering here instead of in the hooks so if there are >3 banners and
        // one is closed, the next will be shown.
        .filter(filterActiveAlerts)
        .slice(0, 3)
        .map(({ id, content, type, scope }) => {
          return (
            <Alert
              data-testid="app-alert"
              key={id}
              type={type}
              message={content}
              showIcon
              closable
              onClose={() => handleClose(scope, id, type)}
            />
          );
        })}
    </div>
  );
};

export default AppAlerts;
