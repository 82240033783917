import apiFn from 'common/services/api-service';

const api = (contextType, contextId) =>
  apiFn(`/api/uapi/${contextType}/${contextId}/exports`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export function create(contextType, contextId, type, payload) {
  return api(contextType, contextId)
    .post(null, {
      type,
      arguments: payload,
    })
    .then(response => response?.data?.data);
}

export function get(contextType, contextId, exportId) {
  return api(contextType, contextId)
    .get(exportId)
    .then(response => response?.data?.data);
}
