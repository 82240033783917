/* global EXP_GOOGLE_MAPS_API_KEY */
import OverrideLinkStyles from '@eventmobi/rich-text/OverrideLinkStyles';
import * as Widgets from '@eventmobi/widgets';
import { isExternalLink } from '@eventmobi/widgets/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { t } from 'ttag';

import { Icons } from 'common/components/IconPicker/Icons';
import { getEvent } from 'common/state/event';

import SafeExit from '@components/SafeExit';

import { ACTION_OPEN_MODAL, ACTION_UPDATE_WIDGETS } from '../reducer';

import Actions from './Actions';
import {
  TYPE_AGENDA,
  TYPE_BANNER,
  TYPE_BUTTON,
  TYPE_COUNTDOWN,
  TYPE_GAMIFICATION,
  TYPE_GOOGLE_MAP,
  TYPE_GROUP_DISCUSSION,
  TYPE_IMAGE,
  TYPE_RICH_TEXT,
  TYPE_SPACE,
  TYPE_VIDEO,
  BUTTON_TYPE_WEBSITE,
} from './constants';
import Grid from './Grid';
import * as Mocks from './Mocks';

const Body = styled.div`
  z-index: 0;
  margin: 0 auto;
  min-height: calc(100vh - 210px);

  position: relative;
  top: ${props => (props.readonly ? '0px' : '64px')};
`;

const Spinner = styled.div`
  position: fixed;
  height: 100%;
  width: calc(100% - 230px);
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Base = styled.div`
  ${props =>
    !props.readonly &&
    css`
      cursor: move;
      /* override for MUI button which defaults to a pointer */

      & .MuiButtonBase-root {
        cursor: move;
      }
    `}
`;

export default function Builder({
  state,
  readonly,
  dispatch,
  bottomRef,
  handleDelete,
  handleClone,
  handleVisibleChange,
}) {
  const { eventUrls } = useSelector(getEvent);

  const handleChange = (layout, isMobile = false) => {
    if (!dispatch) {
      return;
    }

    const dict = isMobile
      ? layout.reduce(
          (prev, { i, x, y, w, h }) => ({ ...prev, [i]: { mX: x, mY: y, mW: w, mH: h } }),
          {}
        )
      : layout.reduce((prev, { i, x, y, w, h }) => ({ ...prev, [i]: { x, y, w, h } }), {});

    const widgets = state.present.widgets.map(widget => ({
      ...widget,
      layout: { ...widget.layout, ...dict[widget.id] },
    }));

    dispatch({ type: ACTION_UPDATE_WIDGETS, payload: { widgets } });
  };

  const bodyProps = {
    readonly,
  };

  const visibleWidgets = useMemo(
    () =>
      state.present.isMobile ? state.present.widgets.filter(w => w.visible) : state.present.widgets,
    [state.present.widgets, state.present.isMobile]
  );

  const mobileFocused = !state.present.responsive;

  const children = useMemo(
    () =>
      visibleWidgets.map(({ id: key, type, params, visible }) => (
        <Base key={key} readonly={readonly}>
          <div style={{ width: 'inherit', height: 'inherit' }}>
            {!readonly && (
              <Actions
                key={`actions-${key}`}
                showMenu={!state.present.isMobile || !state.present.responsive}
                showEdit={type !== TYPE_SPACE}
                onClone={() => handleClone(key)}
                onDelete={() => handleDelete(key)}
                onVisible={() => handleVisibleChange(key)}
                onEdit={() =>
                  dispatch({
                    type: ACTION_OPEN_MODAL,
                    payload: { type, isEdit: true, id: key },
                  })
                }
                isVisible={visible}
                showVisible={!mobileFocused}
              />
            )}
            {type === TYPE_IMAGE && (
              <Widgets.Image {...params} key={key} src={params?.image?.url} />
            )}
            {type === TYPE_BANNER && <Mocks.Banner {...params} key={key} />}
            {type === TYPE_RICH_TEXT && <Mocks.Text key={key} {...params} />}
            {type === TYPE_GAMIFICATION && <Mocks.Leaderboard key={key} {...params} />}
            {type === TYPE_GROUP_DISCUSSION && <Mocks.Discussion key={key} {...params} />}
            {type === TYPE_AGENDA && <Mocks.Agenda key={key} {...params} />}
            {type === TYPE_COUNTDOWN && <Mocks.Countdown key={key} params={params} />}
            {type === TYPE_SPACE && <Mocks.Space key={key} {...params} preview={readonly} />}
            {type === TYPE_GOOGLE_MAP && (
              <Widgets.GoogleMaps key={key} {...params} apiKey={EXP_GOOGLE_MAPS_API_KEY} />
            )}
            {type === TYPE_VIDEO && params.videoId && <Mocks.Video key={key} {...params} />}
            {type === TYPE_VIDEO && params.url && <Widgets.Video key={key} {...params} preview />}
            {type === TYPE_BUTTON && (
              <Widgets.Button
                key={key}
                {...params}
                icon={params.icon && <FontAwesomeIcon icon={Icons[params.icon]} />}
                external={
                  params?.link?.type === BUTTON_TYPE_WEBSITE &&
                  isExternalLink(params?.link?.url, eventUrls) && (
                    <FontAwesomeIcon icon={Icons.externalLink} />
                  )
                }
              />
            )}
          </div>
        </Base>
      )),
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleWidgets, state.present.isMobile]
  );

  return (
    <>
      <SafeExit
        isSafe={state.present.pristine}
        title={t`Continue without publishing?`}
        content={t`There are unpublished changes on this page.`}
      />
      {state.present.loading && (
        <Spinner>
          <Spin />
        </Spinner>
      )}
      <OverrideLinkStyles urls={eventUrls} icon={Icons.externalLink} />
      <Body {...bodyProps}>
        <Grid
          background={state.present.background}
          widgets={state.present.widgets}
          isMobile={state.present.isMobile}
          mobileFocused={mobileFocused}
          onChange={handleChange}
          readonly={readonly}
        >
          {children}
        </Grid>
      </Body>
      <div ref={bottomRef} />
    </>
  );
}

Builder.propTypes = {
  readonly: PropTypes.bool,
  state: PropTypes.shape({
    // whole state object because who cares and it's an internal component
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bottomRef: PropTypes.object,
  dispatch: PropTypes.func,
  handleDelete: PropTypes.func,
  handleClone: PropTypes.func,
  handleVisibleChange: PropTypes.func,
};
