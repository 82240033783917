import { Layout as AntLayout, Typography, Badge } from 'antd';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';

import Loading from 'common/components/Loading';

import {
  DATE_TIME_FORMAT,
  QUESTION_TYPE_AAQ,
  QUESTION_TYPE_LIVE_POLL,
  QUESTION_TYPE_SURVEY,
} from '../constants';
import useEvent from '../useEvent';

const QUESTION_TYPE_LABELS = surveyName => ({
  [QUESTION_TYPE_SURVEY]: surveyName && `${t`Survey`} / ${surveyName}`,
  [QUESTION_TYPE_LIVE_POLL]: t`Live Poll`,
  [QUESTION_TYPE_AAQ]: t`Ask a Question`,
});

const { Text, Title } = Typography;
const { Header: AntHeader } = AntLayout;
const Header = styled(AntHeader)`
  position: fixed;
  z-index: 1000;
  width: 100%;
  background: #fff;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
`;

const HeaderLogo = styled.img`
  height: 26px;
  margin-right: 8px;
`;

const HeaderTitle = styled(Title)`
  display: inline;
  padding-right: 8px;
`;

const Body = styled.div`
  padding: 80px 50px 100px 50px;
  background: #fff;
`;

const BasicDetailsContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 27px;
  width: 100%;
`;

const PageTitle = styled(Title)`
  && {
    margin-right: 12px;
    max-width: 100%;
    margin-bottom: 0px;
  }
`;

const FullWidthFlex = styled.div`
  display: flex;
  align-items: center;
`;

const RightBadge = styled(Badge)`
  flex-shrink: 0;
`;

const PageHeader = ({ title, logo }) => {
  return (
    <Header>
      {logo && <HeaderLogo alt={title} src={logo} />}
      <HeaderTitle level={5}>{title}</HeaderTitle>
      <Text>{t`Moderating`}</Text>
    </Header>
  );
};
PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
};

const Layout = ({ liveUpdates, session, surveyName, children }) => {
  const { eventId, questionType } = useParams();
  const { loading, event } = useEvent(eventId);

  const questionTypeLabel = QUESTION_TYPE_LABELS(surveyName)[questionType];

  if (loading) {
    return <Loading />;
  }

  const startDateTime =
    session &&
    moment.tz(`${session.date}T${session.startTime}`, event.eventTimezone).format(DATE_TIME_FORMAT);
  const endDateTime =
    session && moment.tz(`${session.date}T${session.endTime}`, event.eventTimezone).format('LT');

  return (
    <AntLayout>
      <PageHeader title={event.eventName} logo={event.appIconUrl} />
      <Body>
        <Text type="secondary">{questionTypeLabel}</Text>
        <BasicDetailsContainer>
          <FullWidthFlex>
            {session && <PageTitle ellipsis>{session.name}</PageTitle>}
            {questionType === QUESTION_TYPE_SURVEY && (
              <PageTitle ellipsis>{t`Survey Question`}</PageTitle>
            )}
            <RightBadge
              color={!liveUpdates ? '#BFBFBF' : '#F5222D'}
              text={<Text>{!liveUpdates ? t`Live Updates not available` : t`Live Updates`}</Text>}
            />
          </FullWidthFlex>
          {session && <Text>{t`${startDateTime} to ${endDateTime}`}</Text>}
        </BasicDetailsContainer>
        {children}
      </Body>
    </AntLayout>
  );
};

Layout.propTypes = {
  liveUpdates: PropTypes.bool,
  session: PropTypes.shape({
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  }),
  surveyName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
