import localforage from 'localforage';

import apiFn from 'common/services/api-service';

const api = (eventId, badgeId) =>
  apiFn(`/api/uapi/events/${eventId}/badges/${badgeId}/export`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

export const create = (eventId, badgeId, payload, clientConfig = {}) =>
  api(eventId, badgeId)
    .post(null, {
      // config is what we receive back in CapturePDF route
      config: payload,
      // client_config is what we send to flux so they can pass to playwright
      clientConfig,
    })
    .then(response => response.data.data);

const local = () =>
  localforage.createInstance({
    name: 'experience',
    storeName: 'badge-designer',
    description: 'local store to keep badge designer related information',
  });

const CONFIG_STORAGE_KEY = 'configs';

export const loadConfig = async id => {
  const src = local();

  const allConfigs = await src.getItem(CONFIG_STORAGE_KEY);
  const config = allConfigs?.[id];

  return config;
};

export const saveConfig = async (id, config) => {
  const src = local();

  const allConfigs = (await src.getItem(CONFIG_STORAGE_KEY)) ?? {};
  allConfigs[id] = config;

  await src.setItem(CONFIG_STORAGE_KEY, allConfigs);
};

export const clearAllConfigs = async () => {
  const src = local();

  await src.removeItem(CONFIG_STORAGE_KEY);
};
