import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/sender-email-domains`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const list = (organizationId, payload = {}) => {
  const params = new URLSearchParams(payload);

  params.set('include', 'linked_events');

  return api(organizationId)
    .get(null, { params })
    .then(response => response.data);
};

export const get = (organizationId, id) =>
  api(organizationId)
    .get(id, { params: { include: 'dns_records' } })
    .then(response => response.data);

// Returns an error of `sender_email_domain_already_exists` on duplicate domain
export const create = (organizationId, payload) =>
  api(organizationId)
    .post(null, payload)
    .then(response => response.data);

export const bulkDelete = (organizationId, ids) =>
  api(organizationId)
    .patch(
      null,
      ids.map(id => ({ id, deleted: true }))
    )
    .then(response => response.data);

export const verify = (organizationId, id) =>
  api(organizationId)
    .post(`${id}/verify`)
    .then(response => response.data);

/*
interface EmailDomain {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  verificationStatus: 'not_started' | 'pending' | 'verified' | 'failed';
  verificationStartedAt: string | null;
  verificationCompletedAt: string | null;
  dnsRecords: EmailDomainDNSRecord[];
}

interface EmailDomainDNSRecord {
  createdAt: string;
  updatedAt: string;
  id: string;
  type: 'txt' | 'cname';
  host: string;
  value: string;
  order: number;
  verificationStatus: 'not_started' | 'pending' | 'verified' | 'failed';
}
*/
