/* eslint-disable no-param-reassign */ import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    staffId: ""
};
var transformPayload = function(user) {
    var payload = null;
    if (user) {
        var id = user.id, staffId = user.staffId, firstName = user.firstName, lastName = user.lastName, email = user.email, userRole = user.userRole;
        payload = {
            id: id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            staffId: staffId,
            userRole: userRole
        };
    }
    return {
        payload: payload,
        meta: null,
        error: null
    };
};
var slice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        updateUser: {
            prepare: transformPayload,
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state.firstName = payload.firstName;
                state.lastName = payload.lastName;
                state.email = payload.email;
                state.userRole = payload.userRole;
            }
        },
        setUser: {
            prepare: transformPayload,
            // eslint-disable-next-line consistent-return
            reducer: function reducer(state, param) {
                var payload = param.payload;
                if (!payload) {
                    return initialState;
                }
                state.id = payload.id;
                var _payload_staffId;
                state.staffId = (_payload_staffId = payload === null || payload === void 0 ? void 0 : payload.staffId) !== null && _payload_staffId !== void 0 ? _payload_staffId : state.staffId;
                state.firstName = payload.firstName;
                state.lastName = payload.lastName;
                state.email = payload.email;
                state.userRole = payload.userRole;
            }
        }
    }
});
export default slice.reducer;
export var getUser = function(state) {
    return state[slice.name];
};
export var getFirstName = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.firstName;
};
export var getLastName = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.lastName;
};
export var getEmail = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.email;
};
export var getIsStaff = function(state) {
    var _state_slice_name;
    return !!((_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.staffId);
};
export var getStaffId = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.staffId;
};
var _slice_actions = slice.actions;
export var setUser = _slice_actions.setUser, updateUser = _slice_actions.updateUser;
