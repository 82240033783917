import stringify from 'json-stable-stringify';
import JSSHA from 'jssha';
import XXH from 'xxhashjs';

export default payload => {
  const string = stringify(payload);

  return XXH.h32(string, 0x3bac15ad).toString(36);
};

export function computeSHA(str) {
  const shaObj = new JSSHA('SHA-256', 'TEXT');
  shaObj.update(`${str}`); // use back-ticks to keep it string
  return shaObj.getHash('HEX');
}
