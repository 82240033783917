/* eslint-disable no-param-reassign */ function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { createSlice } from "@reduxjs/toolkit";
export var State;
(function(State) {
    State["User"] = "users";
    State["Organization"] = "organizations";
    State["Event"] = "events";
    State["System"] = "sys";
})(State || (State = {}));
var _obj;
var initialState = (_obj = {}, _defineProperty(_obj, State.User, {}), _defineProperty(_obj, State.Event, {}), _defineProperty(_obj, State.Organization, {}), _defineProperty(_obj, State.System, {}), _obj);
var prepare = function(flags) {
    var payload = null;
    if (flags) {
        payload = flags.reduce(function(prev, param) {
            var value = param.enabled, key = param.keyId;
            return _objectSpreadProps(_objectSpread({}, prev), _defineProperty({}, key, value));
        }, {});
    }
    return {
        payload: payload,
        meta: null,
        error: null
    };
};
var slice = createSlice({
    name: "flags",
    initialState: initialState,
    reducers: {
        setUser: {
            prepare: prepare,
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state[State.User] = payload || initialState[State.User];
            }
        },
        setOrganization: {
            prepare: prepare,
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state[State.Organization] = payload || initialState[State.Organization];
            }
        },
        setEvent: {
            prepare: prepare,
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state[State.Event] = payload || initialState[State.Event];
            }
        },
        setSystem: {
            prepare: prepare,
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state[State.System] = payload || initialState[State.System];
            }
        }
    }
});
export default slice.reducer;
var _slice_actions = slice.actions;
export var setUser = _slice_actions.setUser, setOrganization = _slice_actions.setOrganization, setEvent = _slice_actions.setEvent, setSystem = _slice_actions.setSystem;
export var getUser = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name[State.User];
};
export var getOrganization = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name[State.Organization];
};
export var getEvent = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name[State.Event];
};
export var getSystem = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name[State.System];
};
var _state_slice_name_State_User_key, _ref, _ref1, _ref2;
export var isEnabled = function(state, key) {
    var _state_slice_name, _state_slice_name_State_User, _state_slice_name1, _state_slice_name_State_Organization, _state_slice_name2, _state_slice_name_State_Event, _state_slice_name3, _state_slice_name_State_System;
    return (_ref2 = (_ref1 = (_ref = (_state_slice_name_State_User_key = (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : (_state_slice_name_State_User = _state_slice_name[State.User]) === null || _state_slice_name_State_User === void 0 ? void 0 : _state_slice_name_State_User[key]) !== null && _state_slice_name_State_User_key !== void 0 ? _state_slice_name_State_User_key : (_state_slice_name1 = state[slice.name]) === null || _state_slice_name1 === void 0 ? void 0 : (_state_slice_name_State_Organization = _state_slice_name1[State.Organization]) === null || _state_slice_name_State_Organization === void 0 ? void 0 : _state_slice_name_State_Organization[key]) !== null && _ref !== void 0 ? _ref : (_state_slice_name2 = state[slice.name]) === null || _state_slice_name2 === void 0 ? void 0 : (_state_slice_name_State_Event = _state_slice_name2[State.Event]) === null || _state_slice_name_State_Event === void 0 ? void 0 : _state_slice_name_State_Event[key]) !== null && _ref1 !== void 0 ? _ref1 : (_state_slice_name3 = state[slice.name]) === null || _state_slice_name3 === void 0 ? void 0 : (_state_slice_name_State_System = _state_slice_name3[State.System]) === null || _state_slice_name_State_System === void 0 ? void 0 : _state_slice_name_State_System[key]) !== null && _ref2 !== void 0 ? _ref2 : false;
};
export var isDisabled = function(state, key) {
    return !isEnabled(state, key);
};
