import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

export const SearchFilterIcon = filtered => (
  <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
);

const Wrap = styled.div`
  padding: 8px;

  .ant-input {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

  .ant-btn {
    width: 90px;
  }
`;

const SearchFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <Wrap>
    <Input
      placeholder={t`Search`}
      value={selectedKeys?.[0]}
      onChange={e => setSelectedKeys?.(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => confirm()}
    />
    <Space>
      <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small">
        {t`Search`}
      </Button>
      <Button onClick={clearFilters} size="small">
        {t`Clear`}
      </Button>
    </Space>
  </Wrap>
);

SearchFilter.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
};

export default SearchFilter;
