/* global RELEASE_INFO, DEVELOPMENT */
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';

const isPreview = !!RELEASE_INFO.match(/-preview#\d+/);

const ReactQueryDevtoolsPRP = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(d => ({
    default: d.ReactQueryDevtools,
  }))
);

const RQDevTools = () => {
  const [disabled, setDisabled] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth - 70,
    y: window.innerHeight - 70,
  });
  const dragStartPos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (DEVELOPMENT || isPreview) {
      window.toggleRQDevTools = () => setDisabled(prevDisabled => !prevDisabled);
    }
    return () => {
      delete window.toggleRQDevTools;
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setPosition({
        x: window.innerWidth - 70,
        y: window.innerHeight - 70,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDragStart = event => {
    dragStartPos.current = { x: event.clientX, y: event.clientY };
  };

  const handleDrag = event => {
    if (event.clientX === 0 && event.clientY === 0) {
      return;
    }

    const newPos = {
      x: Math.min(
        Math.max(position.x + event.clientX - dragStartPos.current.x, 0),
        window.innerWidth - 70
      ),
      y: Math.min(
        Math.max(position.y + event.clientY - dragStartPos.current.y, 0),
        window.innerHeight - 70
      ),
    };
    setPosition(newPos);
    dragStartPos.current = { x: event.clientX, y: event.clientY };
  };

  const props = {
    initialIsOpen: false,
    toggleButtonProps: {
      style: {
        height: 'fit-content',
        left: position.x,
        top: position.y,
      },
      draggable: true,
      onDragStart: handleDragStart,
      onDrag: handleDrag,
      onDragOver: event => event.preventDefault(),
    },
  };

  if (disabled) return null;
  return (
    <>
      <ReactQueryDevtools {...props} />
      <Suspense fallback={null}>{isPreview && <ReactQueryDevtoolsPRP {...props} />}</Suspense>
    </>
  );
};

export default RQDevTools;
