import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
body {
  height: auto;
}

/* for places where js based capitalize is not option */
.capitalize {
  text-transform: capitalize;
}
`;
