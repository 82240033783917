import apiFn from 'common/services/api-service';
import { crop, remove, duplicate } from 'common/services/image-service';

import { TYPE_BANNER, TYPE_IMAGE } from './Builder/constants';

const api = (eventId, token) => {
  let header = {
    Accept: 'application/vnd.eventmobi+json; version=p.4',
  };
  const withCredentials = !token;

  if (token) {
    header = { ...header, Authorization: `Basic ${token}` };
  }

  return apiFn(`/api/uapi/events/${eventId}/widgets-pages`, header, withCredentials);
};

const TEMPLATE_TYPE_GENERAL = 'general';

// transform the data into a structure store expects
export const transform = data => {
  const background = {
    image: {
      id: data.backgroundImage?.id ?? null,
      url: data.backgroundImage?.fullSizeUrl ?? null,
    },
    color: data.backgroundColor,
    isFixed: data.isBackgroundFixed,
    size: data.backgroundSize,
  };

  const widgets =
    data.widgets?.map(widget => ({
      new: false,
      type: widget.type,
      id: widget.id,
      visible: widget.mobile.visible,
      layout: {
        x: widget.x,
        y: widget.y,
        w: widget.width,
        h: widget.height,
        mX: widget.mobile.x,
        mY: widget.mobile.y,
        mW: widget.mobile.width,
        mH: widget.mobile.height,
      },
      params: {
        image: {
          id: widget?.backgroundImage?.id ?? null,
          url: widget?.backgroundImage?.fullSizeUrl ?? null,
          thumb: widget?.backgroundImage?.thumbnailUrl ?? null,
        },
        foreground: {
          id: widget?.foregroundImage?.id ?? null,
          url: widget?.foregroundImage?.fullSizeUrl ?? null,
          thumb: widget?.foregroundImage?.thumbnailUrl ?? null,
        },
        richText: widget?.foregroundRichText ?? null,
        ...JSON.parse(widget?.configuration ?? null),
      },
    })) ?? [];

  const { hasResponsiveView: responsive, type } = data;

  return {
    background,
    widgets,
    id: data.id,
    name: data.name,
    updatedAt: data.updatedAt,
    createdAt: data.createdAt,
    responsive: responsive ?? type === TEMPLATE_TYPE_GENERAL,
  };
};

// prepare the payload for the update API call
export const prepare = payload => {
  const { widgets, background, name } = payload;
  const data = {
    name,
    backgroundColor: background.color,
    isBackgroundFixed: background.isFixed,
    backgroundSize: background.size,
  };

  if (background.image?.id) {
    data.backgroundImage = { id: background.image.id };
  } else {
    data.backgroundImage = null;
  }

  data.widgets = widgets.map(widget => {
    const { type, layout, params, visible } = widget;
    const next = {
      type,
      x: layout.x,
      y: layout.y,
      width: layout.w,
      height: layout.h,
      mobile: {
        x: layout.mX,
        y: layout.mY,
        width: layout.mW,
        height: layout.mH,
        visible,
      },
    };

    if (!widget.new && !widget.deleted) {
      next.id = widget.id;
    }

    if (params.image?.id) {
      next.backgroundImage = {
        id: params.image.id,
      };
    } else {
      next.backgroundImage = null;
    }

    if (params.foreground?.id) {
      next.foregroundImage = {
        id: params.foreground.id,
      };
    } else {
      next.foregroundImage = null;
    }

    if (params.richText !== null) {
      next.foregroundRichText = params.richText;
    }

    // remove image and foreground from the params as they have their dedicated attributes
    // defined for each widget
    const { image, foreground, richText, ...rest } = params;

    next.configuration = JSON.stringify(rest);

    return next;
  });

  return data;
};

const IMAGE_TYPES = [TYPE_BANNER, TYPE_IMAGE];

// handles cropping/deleting images for the page builder
// exported only for testing purposes
export const images = payload => {
  const { origin, widgets, background } = payload;

  // filter image widgets that are currently on the page and going to be updated
  const next = widgets.filter(w => IMAGE_TYPES.includes(w.type));
  // filter image widgets that were on the grid before changes
  const prev = origin.widgets.filter(w => IMAGE_TYPES.includes(w.type));

  const extractImg = prop =>
    next
      // check if image for the provided `prop` is cropped or not
      .filter(w => w?.params?.[prop]?.isCropped ?? false)
      // make a new array with the id of the image and its crop parameters
      .map(w => [w.params[prop].id, { ...w.params[prop].crop }]);

  const cropQueue = [...extractImg('image'), ...extractImg('foreground')];

  if (background?.image?.isCropped) {
    // in case background image has been cropped, add to the queue
    cropQueue.push([background.image.id, { ...background.image.crop }]);
  }

  const extractIdx = list =>
    list
      // take id for both background and foreground images
      .flatMap(w => [w.params?.image?.id ?? null, w.params?.foreground?.id ?? null])
      // remove all undefined and nulls
      .filter(id => typeof id !== 'undefined' && id !== null);

  // get the idx for all the images that are currently on the page
  const idx = extractIdx(next);

  const deleteQueue = extractIdx(prev)
    // make a list of all original images that are NOT in the widgets anymore
    .reduce((list, id) => {
      if (idx.includes(id)) {
        return list;
      }

      return [...list, id];
    }, []);

  if (origin.background?.image?.id && origin.background?.image?.id !== background.image?.id) {
    // only add to the delete queue when image existed before but it has been modified
    deleteQueue.push(origin.background.image.id);
  }

  return [cropQueue, deleteQueue];
};

export const get = async (eventId, id, token) => {
  const response = await api(eventId, token).get(id);

  return transform(response?.data?.data ?? {});
};

export const clone = async (eventId, payload) => {
  const { widgets } = payload;

  const isCloned = widget => IMAGE_TYPES.includes(widget.type) && widget.params?.isCloned;

  if (widgets.filter(isCloned).length === 0) {
    // no images have been cloned
    return widgets;
  }

  return Promise.all(
    widgets.map(async widget => {
      if (!isCloned(widget)) {
        return widget;
      }

      const promises = [];

      if (widget?.params?.image?.id) {
        promises.push(duplicate(eventId, widget.params.image.id));
      }

      if (widget?.params?.foreground?.id) {
        promises.push(duplicate(eventId, widget.params.foreground.id));
      }

      const [image, foreground] = await Promise.all(promises);

      const { isCloned: _, ...params } = widget.params;

      return {
        ...widget,
        params: {
          ...params,
          image: { ...widget.params.image, id: image?.id ?? null },
          foreground: { ...widget.params.foreground, id: foreground?.id ?? null },
        },
      };
    })
  );
};

export const update = async (eventId, id, payload) => {
  const widgets = await clone(eventId, payload);
  const source = { ...payload, widgets };

  const [cropQueue, deleteQueue] = images(source);

  await Promise.all([
    ...cropQueue.map(args => crop(eventId, ...args)),
    ...deleteQueue.map(img => remove(eventId, img)),
  ]);

  const response = await api(eventId).patch(id, prepare(source));

  return transform(response?.data?.data ?? {});
};
