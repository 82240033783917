import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { get as getSSOFn, setup as setupSSOFn } from './service';

export const ssoOptions = (organizationId, queryOptions = {}) => ({
  ...queryOptions,
  queryKey: ['organization', organizationId, 'sso'],
  queryFn: () => getSSOFn(organizationId),
});

export const useSSOQuery = (organizationId, queryOptions = {}) =>
  useQuery(ssoOptions(organizationId, queryOptions));

export const useSetupSSOMutation = organizationId => {
  const queryClient = useQueryClient();

  return useMutation(() => setupSSOFn(organizationId), {
    onSuccess: data => {
      queryClient.setQueryData(ssoOptions(organizationId).queryKey, data);
    },
  });
};
