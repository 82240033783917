import { message } from 'antd';
import { useEffect, useReducer } from 'react';
import { t } from 'ttag';

import useConfig from 'common/hooks/useConfig';

import {
  ACTION_SET_CONFIG,
  ACTION_SET_DATA,
  ACTION_SET_IMAGE_ID,
  ACTION_SET_SAVING,
  OWNER_TYPE,
  SPONSOR_BANNER_IMAGE_ID,
  ALLOW_ATTENDEES_TO_CHANGE_THEIR_RESPONSES,
  SHOW_UNMODERATED_TEXT_ANSWERS,
} from './constants';
import { get as getFn, put as putFn } from './service';

const initialValues = {
  loading: true,
  loadingConfig: true,
  saving: false,
  config: null,
  dataSource: null,
  imageId: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_SET_DATA:
      return { ...state, loading: false, dataSource: action.payload };
    case ACTION_SET_CONFIG:
      return { ...state, loadingConfig: false, config: action.payload };
    case ACTION_SET_IMAGE_ID:
      return { ...state, imageId: action.payload };
    case ACTION_SET_SAVING:
      return { ...state, saving: action.payload };
    default:
      return initialValues;
  }
};

const useAudienceResponse = eventId => {
  const [state, dispatch] = useReducer(reducer, initialValues);
  const config = useConfig();

  useEffect(() => {
    async function effect() {
      const payload = await getFn(eventId);
      dispatch({ type: ACTION_SET_DATA, payload });
    }
    if (state.loading) {
      effect();
    }
  }, [state.loading, eventId]);

  useEffect(() => {
    if (!config.loading && state.loadingConfig) {
      const payload = {
        sponsorBannerImageId: config.get(SPONSOR_BANNER_IMAGE_ID),
        allowAttendees: config.get(ALLOW_ATTENDEES_TO_CHANGE_THEIR_RESPONSES) === '1',
        showUnmoderatedText: config.get(SHOW_UNMODERATED_TEXT_ANSWERS) === '1',
      };
      dispatch({ type: ACTION_SET_CONFIG, payload });
      dispatch({ type: ACTION_SET_IMAGE_ID, payload: config.get(SPONSOR_BANNER_IMAGE_ID) });
    }
  }, [config, state.loadingConfig]);

  const save = async values => {
    dispatch({ type: ACTION_SET_SAVING, payload: true });
    try {
      const promises = [];
      if (values.allowAttendees !== state.config.allowAttendees) {
        promises.push(
          config.set(ALLOW_ATTENDEES_TO_CHANGE_THEIR_RESPONSES, values.allowAttendees ? '1' : '0')
        );
      }
      if (values.showUnmoderatedText !== state.config.showUnmoderatedText) {
        promises.push(
          config.set(SHOW_UNMODERATED_TEXT_ANSWERS, values.showUnmoderatedText ? '1' : '0')
        );
      }

      if (state.imageId !== state.config.sponsorBannerImageId) {
        promises.push(config.set(SPONSOR_BANNER_IMAGE_ID, state.imageId));
      }
      const data = state.dataSource.map(item => {
        const result = { ...item };
        result.ownerType = OWNER_TYPE;
        result.value = values[item.name];
        return result;
      });

      promises.push(putFn(eventId, data));
      await Promise.all(promises);
      message.success(t`Your changes are successfully saved`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error(t`Error saving survey settings`);
    } finally {
      dispatch({ type: ACTION_SET_SAVING, payload: false });
    }
  };

  return { state, dispatch, save };
};

export default useAudienceResponse;
