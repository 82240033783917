import Cookies from 'js-cookie';
import injectScript from './injectScript';

/* Helper functions for Cookie Police */
function getCookieDesc() {
  return (
    Object.getOwnPropertyDescriptor(Document.prototype, 'cookie') ||
    Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie')
  );
}

export const BROWSER_SUPPORT = (function testSupport() {
  if (!Object.getOwnPropertyDescriptor || !Object.defineProperty) {
    return false;
  }
  const desc = getCookieDesc();
  return (
    desc && typeof desc.get === 'function' && typeof desc.set === 'function' && desc.configurable
  );
})();
const ERR_PREFIX = 'Cookie Police \uD83D\uDC6E ';

function arrayContains(array, value) {
  return Array.isArray(array) && array.indexOf(value) !== -1;
}

function defaultOnViolation(errMsg) {
  throw new Error(errMsg);
}

/* Finish helper functions of Cookie Police */

// This function is taken from https://github.com/finn-no/cookie-police
// It is used to intercept cookies and only allow cc_cookie if consent is not accepted
function cookiePolice({
  whiteList = [],
  ignoreList = [],
  onViolation = defaultOnViolation,
  disablePolice = false,
} = {}) {
  if (!BROWSER_SUPPORT) {
    return false;
  }
  const cookieDesc = getCookieDesc();
  if (!cookieDesc || !cookieDesc.get || !cookieDesc.set) {
    return false;
  }
  const isInIgnoreList = arrayContains.bind(null, ignoreList);
  const isWhiteListed = arrayContains.bind(null, whiteList);

  Object.defineProperty(document, 'cookie', {
    configurable: true,
    enumerable: cookieDesc.enumerable,
    get() {
      return cookieDesc.get.call(document);
    },
    set(cookie) {
      if (disablePolice) {
        cookieDesc.set.call(document, cookie);
        return;
      }
      const cookieName = cookie.substring(0, cookie.indexOf('=')).trim();
      if (isInIgnoreList(cookieName)) {
        return;
      }
      if (!isWhiteListed(cookieName)) {
        onViolation(
          `${ERR_PREFIX}Cookie "${cookieName}" is not in the whitelist. Blocked.`,
          cookieName
        );
        return;
      }
      cookieDesc.set.call(document, cookie);
    },
  });

  return function reset() {
    Object.defineProperty(document, 'cookie', {
      configurable: true,
      enumerable: cookieDesc.enumerable,
      get: cookieDesc.get,
      set: cookieDesc.set,
    });
  };
}

// Remove all cookies except cc_cookie
export const removeAllCookies = () => {
  window.Cookies = Cookies;
  const cookies = Object.keys(Cookies.get());
  cookies.forEach(cookie => {
    if (cookie !== 'cc_cookie') {
      // messagesUtk is a special cookie and requires extra params to be deleted
      if (cookie === 'messagesUtk') {
        Cookies.remove(cookie, { path: '/', domain: `.${window.location.host}`, sameSite: 'Lax' });
      } else {
        Cookies.remove(cookie);
      }
    }
  });
};

export const isCookieAccepted = () => {
  const cookieConsentApi = window.initCookieConsent();
  return cookieConsentApi.allowedCategory('analytics');
};

// Dynamically inject hubspot scripts
export const injectHubspotScript = async () => {
  await injectScript(
    undefined,
    `window.hsConversationsSettings = { loadImmediately: false };
function revokeConsent(){
  const _hsp = window._hsp = window._hsp || [];
  _hsp.push(['revokeCookieConsent']);
}`
  );

  await injectScript(
    `//js.hs-scripts.com/${window.EXP_HUBSPOT_TRACKING_CODE_ID}.js`,
    undefined,
    `revokeConsent()`,
    `hs-script-loader`
  );
};

export const removeHubspotFrame = () => {
  if (document.getElementById('hubspot-messages-iframe-container')) {
    document.getElementById('hubspot-messages-iframe-container').remove();
  }

  // Remove the hubspot script
  const hubspotScript = document.getElementById('hs-script-loader');
  if (hubspotScript) {
    hubspotScript.remove();
  }
};

// Intercept cookies and only allow cc_cookie if consent is not accepted
export const interceptCookies = accepted => {
  cookiePolice({
    whiteList: ['cc_cookie'],
    onViolation: () => {
      // ignore
    },
    // if accepted is true, disable cookiepolice
    disablePolice: accepted,
  });
};

// Wait until the dom element is loaded
export const waitForElement = selector =>
  new Promise(resolve => {
    const fn = () => {
      const element = document.querySelector(selector);

      if (element) {
        resolve(element);
      } else {
        setTimeout(fn, 100);
      }
    };

    fn();
  });
