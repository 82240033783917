/* eslint-disable no-param-reassign */ function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { createSlice } from "@reduxjs/toolkit";
import Portal from "../types/Portal";
import { setUser } from "./user";
var initialState = {
    isLoggedIn: false,
    portal: null
};
var slice = createSlice({
    name: "system",
    initialState: initialState,
    reducers: {
        setPortal: function setPortal(state, param) {
            var portal = param.payload;
            return _objectSpreadProps(_objectSpread({}, state), {
                portal: portal
            });
        }
    },
    extraReducers: function(builder) {
        builder.addCase(setUser, function(state, param) {
            var payload = param.payload;
            state.isLoggedIn = !!(payload === null || payload === void 0 ? void 0 : payload.email);
        });
    }
});
export default slice.reducer;
export var getIsLoggedIn = function(state) {
    return state[slice.name];
};
export var checkPortal = function(state) {
    var _state_slice_name, _state_slice_name1;
    return {
        isExperiencePortal: ((_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.portal) === Portal.Experience,
        isCompanyPortal: ((_state_slice_name1 = state[slice.name]) === null || _state_slice_name1 === void 0 ? void 0 : _state_slice_name1.portal) === Portal.CompanyPortal
    };
};
export var setPortal = slice.actions.setPortal;
