import axios from 'axios';

const Appcues = axios.create({
  baseURL: `https://api.appcues.com/v1`,
});

// category for a flow is extracted from flow name
export const CATEGORY_ROOT = null;
export const CATEGORY_ANNOUNCEMENTS = 'announcements';
export const CATEGORY_WALKTHROUGHS = 'walkthroughs';

const CATEGORY_SEPARATOR = ':';

// tranform mainly extracts the category from name which is divided by `NAME_SEPARATOR`
// If there is no NAME_SEPARATOR, or the extracted category is empty string, we mark it
// as CATEGORY_ROOT.
//
// Documentation: https://docs.appcues.com/en_US/dev-extras/1627003-launchpad-custom-install
const transform = data => {
  return data.contents.map(item => {
    let category = CATEGORY_ROOT;
    let name = item.name;

    if (name.includes(CATEGORY_SEPARATOR)) {
      const [newCategory, ...splittedName] = name.split(CATEGORY_SEPARATOR);
      if (newCategory.trim()) {
        category = newCategory.trim().toLowerCase();
      }
      name = splittedName.join(CATEGORY_SEPARATOR).trim();
    }

    return {
      id: item.id,
      name,
      category,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
    };
  });
};

export const getLaunchpad = async (accountId, userId, url) => {
  const res = await Appcues.get(`/accounts/${accountId}/users/${userId}/launchpad`, {
    params: { url },
  });

  return transform(res.data);
};
