import { PlusOutlined } from '@ant-design/icons';
import { Table as AntTable, Button, Row, Col, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';

import Empty from 'common/components/Empty';
import useSearchParams from 'common/hooks/useSearchParams';
import useTable from 'common/hooks/useTable';
import { listCmsEvents as listFn } from 'common/services/events/api';
import { getId } from 'common/state/organization';

import usePermission, { CREATE_EVENTS } from '@hooks/usePermission';
import EmptyImage from '@images/illustrations/events.svg';

import CreateModal from './CreateModal';

const { Column } = AntTable;
const { Search } = Input;

const SearchBox = styled(Search)`
  && {
    width: 250px;
  }
`;

const Table = ({ dataSource, loading, pagination, onClick, onChange }) => {
  return (
    <AntTable
      rowKey="id"
      dataSource={dataSource}
      rowClassName="clickable-row"
      tableLayout="auto"
      loading={loading}
      pagination={pagination}
      onChange={onChange}
      onRow={record => ({
        onClick: () => onClick?.(record.id),
      })}
    >
      <Column title={t`Name`} dataIndex="name" sorter />
      <Column title={t`Code`} dataIndex="shortcode" sorter />
      <Column title={t`Start Date`} dataIndex="startDate" sorter defaultSortOrder="descend" />
      <Column title={t`End Date`} dataIndex="endDate" sorter />
    </AntTable>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
  // Same as https://ant.design/components/pagination/#API
  // Note: We're only plucking the props that we're using.
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    showTotal: PropTypes.func,
  }),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

const Events = ({ active }) => {
  const history = useHistory();
  const organizationId = useSelector(getId);

  const query = useSearchParams();
  const location = useLocation();
  const listFunc = useCallback((...args) => listFn(organizationId, ...args), [organizationId]);
  const { dataSource, loading, onSearch, pagination, onChange, syncQuery } = useTable(listFunc, {
    sort: { field: 'startDate', order: 'descend' },
  });

  const [showModal, setShowModal] = useState(false);
  const { authorized } = usePermission();

  const navigateToEvent = eventId => history.push(`event/${eventId}`);

  useEffect(() => {
    if (active) {
      syncQuery(location.search);
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, active]);

  const showEmpty = !query.get('search') && dataSource.length === 0;

  return (
    <>
      <Row type="flex" css="margin: 20px 0;">
        <Col span={8}>
          {authorized(CREATE_EVENTS) && (
            <Button
              key="add"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowModal(true)}
            >
              {t`Add Event`}
            </Button>
          )}
        </Col>
        {!showEmpty && (
          <Col span={16} align="right">
            <SearchBox
              defaultValue={query.get('search')}
              placeholder={t`Search Events`}
              disabled={loading}
              onSearch={onSearch}
            />
          </Col>
        )}
      </Row>

      {!loading && showEmpty && <Empty image={EmptyImage} title={t`You don't have any event`} />}

      {!showEmpty && (
        <Table
          dataSource={dataSource}
          loading={loading}
          pagination={{
            ...pagination,
            showTotal: (count, [from, to]) => t`${from}-${to} of ${count} items`,
            showSizeChanger: false,
          }}
          onClick={navigateToEvent}
          onChange={onChange}
        />
      )}

      {showModal && (
        <CreateModal
          open
          orgId={organizationId}
          navigateToEvent={navigateToEvent}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

Events.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Events;
