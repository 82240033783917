import { CheckCircleOutlined } from '@ant-design/icons';
import { Typography, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Layout from 'common/components/Auth/Layout';
import useTranslation from 'common/hooks/useTranslation';

const { Title: AntTitle, Paragraph: AntParagraph } = Typography;

const Title = styled(AntTitle)`
  && {
    align-self: center;
    margin-bottom: 1.5rem;
  }
`;

const CheckCircle = styled(CheckCircleOutlined)`
  font-size: 50px;
  color: #6dbb83;
`;

const Paragraph = styled(AntParagraph)`
  text-align: center;
`;

export default function ResetPasswordSuccess() {
  useTranslation();

  return (
    <Layout>
      <Space align="center" direction="vertical">
        <CheckCircle />
        <Title level={4}>{t`Success`}</Title>

        <Paragraph>
          {t`Your password has been successfully updated. Please close this page and return to the app to log in with your new password.`}
        </Paragraph>
      </Space>
    </Layout>
  );
}
