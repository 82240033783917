import { Card as AntCard, Divider, Select as AntSelect, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { t, jt } from 'ttag';

import useTranslation from 'common/hooks/useTranslation';
import { ISO_LOCALE_MAP, LANGUAGE_BY_ISO } from 'common/services/translation/api';

import BGBottomRight from '@assets/login-bg-bottom-right.png';
import BGTopLeft from '@assets/login-bg-top-left.png';
import LogoImage from '@images/em-logos/EM-logo-colour-dark.svg';

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-image: url(${BGTopLeft}), url(${BGBottomRight});
  background-position: top left, bottom right;
  background-size: auto 75%;
  background-repeat: no-repeat;
  width: 100%;
`;

const Logo = styled.img`
  display: block;
  width: 250px;
  margin-bottom: 20px;
`;

const Card = styled(AntCard)`
  border-radius: 10px;
  @media (max-width: 600px) {
    border-radius: unset;
  }
  .ant-card-body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    @media (max-width: 600px) {
      width: 100vw;
      height: 100vh;
      min-height: 600px;
    }
  }
`;

const Select = styled(AntSelect)`
  position: absolute;
  top: -32px;
  right: 0;
  min-width: 135px;
  color: white;
  .ant-select-selection-item {
    height: 26px;
    text-align: right;
  }
  .ant-select-selection-search {
    margin-left: 0;
  }
  .ant-select-arrow {
    color: inherit;
  }

  @media (max-width: 600px) {
    top: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  margin-top: 24px;
  color: #595959;

  @media (max-width: 600px) {
    padding: 15px;
    flex: 1;
    align-items: flex-end;
  }
`;

const Content = styled.div`
  width: 100%;

  @media (max-width: 600px) {
    margin-top: 50px;
  }
`;

export default function Layout({ children }) {
  const { language, setLanguage } = useTranslation();

  const EMLink = (
    <a key="em" href="https://eventmobi.com" rel="nofollow noopener noreferrer" target="_blank">
      EventMobi
    </a>
  );

  return (
    <Background>
      <Card>
        <Select
          bordered={false}
          placeholder={t`Language`}
          value={language}
          onChange={lang => setLanguage(lang)}
          style={{ color: '#595959' }}
          options={Object.keys(ISO_LOCALE_MAP).map(code => ({
            value: code,
            label: LANGUAGE_BY_ISO(code),
          }))}
        />
        <Logo src={LogoImage} alt="EventMobi" />
        <Content>{children}</Content>
        <Footer>
          <Space split={<Divider type="vertical" css={{ borderLeft: '1px solid #686868' }} />}>
            <a
              href="https://eventmobi.com/terms-of-use/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >{t`Terms of Use`}</a>
            <a
              href="https://eventmobi.com/privacy-policy/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >{t`Privacy Policy`}</a>
          </Space>
          <div>{jt`Powered by ${EMLink}™`}</div>
        </Footer>
      </Card>
    </Background>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
