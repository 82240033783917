function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "English"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "French (Canada)"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "German"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "Portuguese"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "Spanish"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import axios from "axios";
import { t, addLocale, useLocale as updateLocale } from "ttag";
export var ISO_LOCALE_MAP = {
    en: "en-US",
    fr_CA: "fr-CA",
    de: "de-DE",
    pt_BR: "pt-BR",
    es: "es-ES"
};
export var LANGUAGE_BY_ISO = function(code) {
    return ({
        en: t(_templateObject()),
        fr_CA: t(_templateObject1()),
        de: t(_templateObject2()),
        pt_BR: t(_templateObject3()),
        es: t(_templateObject4())
    })[code];
};
export var getDefaultLanguage = function() {
    var browserLanguage = navigator.language.replace("-", "_");
    var hasSupportedLang = LANGUAGE_BY_ISO(browserLanguage);
    return hasSupportedLang ? browserLanguage : "en";
};
export var setLocale = function(locale) {
    var suffix = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "";
    if (locale === "en") {
        updateLocale(locale);
        return Promise.resolve();
    }
    return axios.get("/translations/".concat(locale).concat(suffix, ".json")).then(function(response) {
        addLocale(locale, response.data);
        updateLocale(locale);
    });
};
