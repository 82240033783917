import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import BorderImage from '@assets/border-image.png';
import SafeExit from '@components/SafeExit';

import { ACTION_SELECT_WIDGET, ACTION_UPDATE_WIDGETS } from '../reducer';

import {
  MODE_PREVIEW,
  TYPE_IMAGE,
  TYPE_PERSONAL_CODE,
  TYPE_RICH_TEXT,
  TYPE_SHAPE,
} from './constants';
import Grid from './Grid';
import * as Widgets from './Widgets';

const Body = styled.div`
  z-index: 0;
`;

const Spinner = styled.div`
  position: fixed;
  height: 100%;
  width: calc(100% - 230px);
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WIDGET_BORDER_OFFSET_PX = 2;

const WidgetBorder = styled.div`
  position: absolute;
  top: -${WIDGET_BORDER_OFFSET_PX}px;
  left: -${WIDGET_BORDER_OFFSET_PX}px;
  width: calc(100% + ${2 * WIDGET_BORDER_OFFSET_PX}px);
  height: calc(100% + ${2 * WIDGET_BORDER_OFFSET_PX}px);
`;

const Base = styled.div`
  ${/* eslint-disable-next-line no-nested-ternary */
  props => (props.readonly ? '&' : !props.selected ? '&:not(:hover)' : 'none-existing-selector')} {
    .react-resizable-handle::after {
      content: unset;
    }
    .react-resizable-handle {
      background-image: none;
    }
  }

  ${/* eslint-disable-next-line no-nested-ternary */
  props => (props.readonly ? 'none-existing-selector' : !props.selected ? '&:hover' : '&')} {
    ${WidgetBorder} {
      border: dashed 2px;
      border-image-slice: 2;
      border-image-repeat: round;
      border-image-source: url(${BorderImage});
    }
  }
`;

export default function Builder({ readonly, person, state, dispatch, showBorder = true }) {
  const handleChange = layout => {
    if (!dispatch) {
      return;
    }

    const dict = layout.reduce((prev, { i, x, y, w, h }) => ({ ...prev, [i]: { x, y, w, h } }), {});

    const widgets = state.present.widgets.map(widget => ({
      ...widget,
      layout: { ...widget.layout, ...dict[widget.id] },
    }));

    dispatch({ type: ACTION_UPDATE_WIDGETS, payload: { widgets } });
  };

  const handleSelectWidget = useCallback(
    id => {
      if (!readonly && state.present.selectedWidgetId !== id) {
        dispatch({ type: ACTION_SELECT_WIDGET, payload: id });
      }
    },
    [readonly, state.present.selectedWidgetId, dispatch]
  );

  const children = useMemo(
    () =>
      state.present.widgets.map(({ id: key, type, layout, params }) => (
        <Base
          key={key}
          readonly={readonly}
          selected={state.present.selectedWidgetId === key}
          onClick={() => handleSelectWidget(key)}
        >
          <div style={{ width: 'inherit', height: 'inherit' }}>
            {type === TYPE_IMAGE && (
              <Widgets.Image {...params} key={key} src={params?.image?.url} />
            )}
            {type === TYPE_RICH_TEXT && (
              <Widgets.Text key={key} readOnly={readonly} person={person} {...params} />
            )}
            {type === TYPE_SHAPE && (
              <Widgets.Shape key={key} width={layout.w} height={layout.h} {...params} />
            )}
            {type === TYPE_PERSONAL_CODE && (
              <Widgets.PersonalCode
                key={key}
                readOnly={readonly}
                width={layout.w}
                height={layout.h}
                person={person}
                {...params}
              />
            )}
          </div>
          {!readonly && <WidgetBorder />}
        </Base>
      )),
    [state.present.widgets, state.present.selectedWidgetId, handleSelectWidget, readonly, person]
  );

  return (
    <>
      <SafeExit isSafe={state.present.pristine} />
      {state.present.loading && (
        <Spinner>
          <Spin />
        </Spinner>
      )}
      {!state.present.loading && (
        <Body readonly={readonly}>
          <Grid
            background={state.present.background}
            showBorder={showBorder}
            widgets={state.present.widgets}
            size={state.present.size}
            onChange={handleChange}
            readonly={readonly}
            isBleedEnabled={state.present.isBleedEnabled}
            isPreviewMode={state.present.mode === MODE_PREVIEW}
          >
            {children}
          </Grid>
        </Body>
      )}
    </>
  );
}

Builder.propTypes = {
  readonly: PropTypes.bool,
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    title: PropTypes.string,
    checkinCode: PropTypes.string,
    customFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
      })
    ).isRequired,
  }),
  showBorder: PropTypes.bool,
  state: PropTypes.shape({
    // whole state object because who cares and it's an internal component
  }).isRequired,
  dispatch: PropTypes.func,
};
