import {
  Alert,
  Button,
  Col,
  Form,
  Modal as AntModal,
  Row,
  Select,
  message,
  Typography,
  Input,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import ImageUpload from 'common/components/ImageUpload';
import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import { listCmsEvents as eventListFn } from 'common/services/events/api';

import Switch from '@components/Switch';

import { create as createFn, update as updateFn } from './appevent-service';

const { Item, useForm } = Form;

const Description = styled(Typography.Text).attrs(() => ({ type: 'secondary' }))`
  display: block;
  font-size: 12px;
  font-style: italic;
`;

const useModal = (organizationId, appEvent, skipEventIds) => {
  const isEditing = !!appEvent;
  const [eventId, setEventId] = useState(null);
  const [showRegistration, setShowRegistration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventList, setEventList] = useState([]);
  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const events = await eventListFn(organizationId);
        setEventList(
          events.data
            ?.filter(event => !skipEventIds.includes(event.id))
            .map(event => ({ value: event.id, label: event.name }))
        );
      } finally {
        setLoading(false);
      }
    };
    if (isEditing) {
      setEventList([{ value: appEvent.eventId, label: appEvent.name }]);
    } else {
      load();
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, appEvent, isEditing]);

  useEffect(() => {
    setEventId(appEvent?.eventId);
    setShowRegistration(appEvent?.showRegistrationButton ?? false);
  }, [appEvent]);

  return {
    eventList,
    isEditing,
    loading,
    eventId,
    setEventId,
    showRegistration,
    setShowRegistration,
  };
};

const Modal = ({ onClose, organizationId, appId, appEvent, skipEventIds }) => {
  const {
    eventList,
    isEditing,
    loading,
    eventId,
    setEventId,
    showRegistration,
    setShowRegistration,
  } = useModal(organizationId, appEvent, skipEventIds);
  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = useForm();
  const { validateFields } = form;

  const onFinish = async () => {
    setError(null);
    try {
      setSaving(true);
      const rawPayload = await validateFields();
      const payload = {
        ...rawPayload,
        appIconImageId: rawPayload.icon?.id,
        bannerImageId: rawPayload.banner?.id,
      };

      if (isEditing) {
        await updateFn(organizationId, appId, appEvent.id, payload);
      } else {
        await createFn(organizationId, appId, eventId, payload);
      }
      message.success(t`Successfully saved event`);
      onClose(true);
    } catch (e) {
      if (!e.errorFields) {
        setError(e?.errors?.[0]?.message ?? t`An unknown error occurred while saving event.`);
        throw e;
      }
    } finally {
      setSaving(false);
    }
  };

  const onSave = () => {
    form.submit();
  };

  return (
    <AntModal
      title={isEditing ? t`Edit Event` : t`Add Event`}
      open
      confirmLoading={loading}
      maskClosable
      destroyOnClose
      onCancel={() => onClose(false)}
      footer={
        <>
          <Button onClick={() => onClose(false)}>{t`Cancel`}</Button>
          <Button type="primary" loading={saving} disabled={saving || loading} onClick={onSave}>
            {t`Save`}
          </Button>
        </>
      }
      width={700}
    >
      {loading && <Loading />}

      {!loading && (
        <Form.Provider onFormFinish={onFinish}>
          {error && (
            <Row css="margin-bottom: 20px">
              <Col span={24}>
                <Alert type="error" message={error} showIcon />
              </Col>
            </Row>
          )}
          <Form
            layout="vertical"
            name="info"
            requiredMark={false}
            form={form}
            initialValues={{
              ...appEvent,
              showEventDates: appEvent?.showEventDates ?? true,
              icon: { id: appEvent?.appIconImageId },
              banner: { id: appEvent?.bannerImageId },
            }}
          >
            <Item
              name="eventId"
              label={<Label isRequired>{t`Event`}</Label>}
              rules={[{ required: 'true', message: t`Event is required` }]}
            >
              <Select
                placeholder={t`Select Event`}
                optionFilterProp="label"
                options={eventList}
                onSelect={value => setEventId(value)}
                disabled={isEditing}
              />
            </Item>

            {eventId && (
              <>
                <Item name="icon" label={<Label>{t`Icon`}</Label>} trigger="onComplete">
                  <ImageUpload
                    enableCrop
                    resourceType="organizations"
                    resourceId={organizationId}
                  />
                </Item>
                <Item name="banner" label={<Label>{t`Banner`}</Label>} trigger="onComplete">
                  <ImageUpload
                    enableCrop
                    resourceType="organizations"
                    resourceId={organizationId}
                  />
                </Item>
                <Item
                  name="isPrivate"
                  valuePropName="checked"
                  extra={
                    <Description>{t`Hidden events are not publicaly listed in the app. Users must enter the Event Code for it to be visible.`}</Description>
                  }
                >
                  <Switch label={t`Hidden`} />
                </Item>
                <Item
                  name="showEventDates"
                  valuePropName="checked"
                  extra={
                    <Description>{t`Show event dates in the event listing and overview page.`}</Description>
                  }
                >
                  <Switch label={t`Show Dates`} />
                </Item>
                <Item
                  name="isFeatured"
                  valuePropName="checked"
                  extra={
                    <Description>{t`Event will show up first in the event list. Only one event can be featured at a time.`}</Description>
                  }
                >
                  <Switch label={t`Featured`} />
                </Item>
                <Item
                  name="showRegistrationButton"
                  valuePropName="checked"
                  extra={
                    <Description>{t`Registration option will appear on event card.`}</Description>
                  }
                >
                  <Switch
                    label={t`Show Registration`}
                    onChange={value => setShowRegistration(value)}
                  />
                </Item>
                {showRegistration && (
                  <Item
                    name="customRegistrationUrl"
                    label={<Label isRequired>{t`Registration Link`}</Label>}
                    rules={[
                      { required: true, message: t`Registration Link is required` },
                      { type: 'url', message: 'Enter valid url' },
                    ]}
                  >
                    <Input placeholder="https://www.eventbrite.ca" />
                  </Item>
                )}
              </>
            )}
          </Form>
        </Form.Provider>
      )}
    </AntModal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  appEvent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    appIconImageId: PropTypes.string,
    bannerImageId: PropTypes.string,
    isPrivate: PropTypes.bool.isRequired,
    isFeatured: PropTypes.bool.isRequired,
    showEventDates: PropTypes.bool.isRequired,
    showRegistrationButton: PropTypes.bool.isRequired,
    customRegistrationUrl: PropTypes.string,
  }),
  skipEventIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Modal;
