import { captureException } from '@sentry/react';
import { message } from 'antd';
import { useCallback } from 'react';
import { msgid, ngettext, t } from 'ttag';

import DeleteModal from 'common/components/Modal/DeleteModal';

import { useArchiveConnectionMutation, useInvalidateConnections } from './queries';

const useArchiveConnections = onDelete => {
  const { mutateAsync: archiveFn } = useArchiveConnectionMutation();
  const invalidateConnections = useInvalidateConnections();

  /*
    With useTableQuery, the columns + props are defined before we're able to
    access the table data.

    This callback needs to support both the array form, from selectedRowKeys, or
    a row object so it can display the gateway's name when deleting a single item.
  */
  return useCallback(
    /**
     * @param {string[] | { id: string, name: string }} payload An array of row IDs, or a single row object
     */
    payload => {
      const isRow = !Array.isArray(payload);

      DeleteModal.confirm({
        count: isRow ? 1 : payload.length,
        label: {
          singular: t`Payment Gateway`,
          plural: t`Payment Gateways`,
        },
        itemName: isRow ? payload.name : undefined,
        additionalInfo:
          isRow || payload.length === 1
            ? t`Events linked to this gateway will no longer be able to process payments.`
            : t`Events linked to these gateways will no longer be able to process payments.`,
        onDelete: async () => {
          const ids = isRow ? [payload.id] : payload;
          const result = await Promise.allSettled(
            ids.map(id =>
              archiveFn({ id }).catch(err => {
                captureException(err);
                throw err;
              })
            )
          );

          if (result.some(({ status }) => status === 'rejected')) {
            message.error(
              ngettext(
                msgid`Error deleting Payment Gateway.`,
                `Error deleting Payment Gateways.`,
                ids.length
              )
            );
          } else {
            message.success(
              ngettext(
                msgid`Successfully deleted Payment Gateway.`,
                `Successfully deleted Payment Gateways.`,
                ids.length
              )
            );
          }

          invalidateConnections();

          if (onDelete) {
            onDelete();
          }
        },
      });
    },
    [archiveFn, invalidateConnections, onDelete]
  );
};

export default useArchiveConnections;
