/**
 * This hook handles integrating React Query with an AntD Table and the URLSearchParams. It uses
 * the URLSearchParams as a source of truth for the state of the table, and when AntD calls the
 * onChange callback for filters/sorts/pagination, it updates the URLSearchParams.
 */ function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "An unknown error occurred"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { useCallback, useEffect, useMemo } from "react";
import { message } from "antd";
// TODO: When we've updated webpack/babel, we should switch to change-case instead as these
// packages have been deprecated by the author
import { snakeCase } from "snake-case";
import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";
import useSyncWithUrlSearchParams from "./useSyncWithUrlSearchParams";
var DEFAULT_PAGE_LIMIT = 20;
/**
 * Use the URLSearchParams as the source of truth for the table state. This will convert the URL
 * parameters into a TrackedState object that is subsequently transformed into the correct formats
 * for the AntD Table's props and React Query APIs. One of the table props returned is the
 * `onChange` function that AntD's Table expects, which will synchronize those values to the URL,
 * which will then be derived into table state again.
 */ var useTableUrlSearchParams = function() {
    var _ref = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, _ref_searchValue = _ref.searchValue, searchValue = _ref_searchValue === void 0 ? "" : _ref_searchValue, _ref_defaultPageLimit = _ref.defaultPageLimit, defaultPageLimit = _ref_defaultPageLimit === void 0 ? DEFAULT_PAGE_LIMIT : _ref_defaultPageLimit;
    var _useSyncWithUrlSearchParams = _slicedToArray(useSyncWithUrlSearchParams(), 2), urlParams = _useSyncWithUrlSearchParams[0], setUrlParams = _useSyncWithUrlSearchParams[1];
    var onTableChange = useCallback(function(nextPagination, nextFilter, nextSort, param) {
        var action = param.action;
        setUrlParams(function(prevState) {
            var nextState = _objectSpread({}, prevState);
            if (action === "filter") {
                nextState.filter = Object.entries(nextFilter).reduce(function(acc, param) {
                    var _param = _slicedToArray(param, 2), key = _param[0], values = _param[1];
                    if ((values === null || values === void 0 ? void 0 : values.length) > 0) {
                        acc[key] = values.join(",");
                    }
                    return acc;
                }, {});
                // When the filter is changed, reset the current page
                delete nextState.page;
                return nextState;
            }
            if (action === "sort") {
                if (Array.isArray(nextSort)) {
                    throw new Error("Multiple column sorting is not supported");
                }
                if (typeof nextSort.order === "undefined") {
                    delete nextState.sort;
                } else {
                    nextState.sort = "".concat(nextSort.order === "descend" ? "-" : "").concat(nextSort.columnKey || nextSort.field);
                }
                // When the sort is changed, reset the current page
                delete nextState.page;
                return nextState;
            }
            if (action === "paginate") {
                nextState.page = "".concat(nextPagination.current);
                nextState.limit = "".concat(nextPagination.pageSize);
                return nextState;
            }
            // This should never be reached, but guards against a future action being added in AntD
            return nextState;
        });
    }, [
        setUrlParams
    ]);
    // When the search value changes, update the URL and reset the current page
    useEffect(function() {
        setUrlParams(function(prevState) {
            var nextState = _objectSpreadProps(_objectSpread({}, prevState), {
                search: searchValue !== "" ? searchValue : undefined
            });
            if ((searchValue || "") !== (prevState.search || "")) {
                delete nextState.page;
            }
            return nextState;
        });
    }, [
        setUrlParams,
        searchValue
    ]);
    // Parse the current URL into a TrackedState object
    var trackedState = useMemo(function() {
        var parsed = {
            pagination: {
                // If either of these are not in the urlParams or are not numbers, they will be parsed
                // as `NaN || ...` which will result in the correct default values.
                limit: Number(urlParams.limit) || defaultPageLimit,
                // AntD uses 1-based indexing for pages, but we use 0-based indexing. We default the URL
                // parameter and subtract 1 from it to ensure consistency regardless of whether the page
                // is set in the URL
                page: (Number(urlParams.page) || 1) - 1
            }
        };
        if (urlParams.filter) {
            parsed.filter = Object.fromEntries(Object.entries(urlParams.filter).map(function(param) {
                var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
                return [
                    key,
                    value.split(",")
                ];
            }));
        }
        if (urlParams.sort) {
            if (typeof urlParams.sort !== "string") {
                throw new Error("Multiple column sorting is not supported");
            }
            var order = urlParams.sort.startsWith("-") ? "descend" : "ascend";
            var field = order === "descend" ? urlParams.sort.slice(1) : urlParams.sort;
            parsed.sort = {
                field: field,
                order: order
            };
        }
        if (urlParams.search) {
            parsed.search = urlParams.search;
        }
        return parsed;
    }, [
        urlParams,
        defaultPageLimit
    ]);
    var tableProps = useMemo(function() {
        return {
            onChange: onTableChange,
            pagination: {
                pageSize: trackedState.pagination.limit,
                // AntD uses 1-based indexing for pages, but we use 0-based indexing
                current: trackedState.pagination.page + 1
            }
        };
    }, [
        onTableChange,
        trackedState.pagination
    ]);
    return {
        tableProps: tableProps,
        trackedState: trackedState
    };
};
/**
 * Call useTableQuery with your query and table options, and it will return everything required
 * for an AntD Table to be a controlled table, with querying, filtering, sorting, and pagination.
 * It does not handle any functionality that does not exist in an AntD table, such as search bars
 * or custom field filters, which can be added through some of the returned utility functions like
 * `transformApiParams`.
 */ export var useTableQuery = function(/**
   * A query options factory that will be called with the derived API parameters and the tracked
   * state. This should return the options that will be passed to `useQuery`, and expects that the
   * queryFn will return an APIResponsePayload object using camelCase keys.
   */ getQueryOptions, /**
   * The props that would normally be passed to the AntD table. This is used to derive the columns
   * and pagination settings for the table. The columns will be updated with the filteredValue and
   * sortOrder from the tracked state, and the pagination will be updated with the totalItemsCount
   * from the API response.
   */ sourceTableProps, /**
   * If a search value is provided, it will be used to update the URLSearchParams and reset the
   * current page. This is useful for search bars that are outside of the table component.
   */ searchValue) {
    var _sourceTableProps_pagination;
    // Pagination can be disabled and hidden by <Table pagination={false} /> or disabled and visible
    // by <Table pagination={{ disabled: true }} />. We never use the latter option, but we need to
    // account for it.
    var isPaginationDisabled = (sourceTableProps === null || sourceTableProps === void 0 ? void 0 : sourceTableProps.pagination) === false || (sourceTableProps === null || sourceTableProps === void 0 ? void 0 : (_sourceTableProps_pagination = sourceTableProps.pagination) === null || _sourceTableProps_pagination === void 0 ? void 0 : _sourceTableProps_pagination.disabled) || false;
    // If pagination is disabled, and a page limit is not provided, default to 1000 so that all data
    // is loaded at once. If pagination is enabled, default to the provided size or fall back to 20.
    var defaultPageLimit = (sourceTableProps === null || sourceTableProps === void 0 ? void 0 : sourceTableProps.pagination) && sourceTableProps.pagination.pageSize || (isPaginationDisabled ? 1000 : DEFAULT_PAGE_LIMIT);
    var _useTableUrlSearchParams = useTableUrlSearchParams({
        searchValue: searchValue,
        defaultPageLimit: defaultPageLimit
    }), tableUrlProps = _useTableUrlSearchParams.tableProps, trackedState = _useTableUrlSearchParams.trackedState;
    // Get a reference to the onChange function destined for AntD's table, since we'll use that when
    // we need to update the current page when there is no data available for a page.
    var onChange = tableUrlProps.onChange;
    // Derive the API parameters from the tracked state, since we need to use CSV for filters and
    // snake_case for sorts.
    var apiParams = useMemo(function() {
        var params = {
            limit: "".concat(trackedState.pagination.limit),
            page: "".concat(trackedState.pagination.page)
        };
        if (trackedState.filter) {
            Object.entries(trackedState.filter).forEach(function(param) {
                var _param = _slicedToArray(param, 2), key = _param[0], values = _param[1];
                if (values.length > 0) {
                    params[key] = values.join(",");
                }
            });
        }
        if (trackedState.sort) {
            var snakeField = snakeCase(trackedState.sort.field);
            params.sort = "".concat(trackedState.sort.order === "descend" ? "-" : "").concat(snakeField);
        }
        if (trackedState.search) {
            params.search = trackedState.search;
        }
        return params;
    }, [
        trackedState
    ]);
    var queryResult = useQuery(getQueryOptions(apiParams, trackedState));
    // This is intentionally written this way to avoid rest destructuring of the query result
    // https://tanstack.com/query/latest/docs/eslint/no-rest-destructuring
    var data = queryResult.data, error = queryResult.error, isLoading = queryResult.isLoading, isFetching = queryResult.isFetching;
    // When the data changes, we need to ensure that the response is using the camelCase key
    // transformer, and that there is data for the current page.
    useEffect(function() {
        var _data_meta;
        if (isLoading || !(data === null || data === void 0 ? void 0 : (_data_meta = data.meta) === null || _data_meta === void 0 ? void 0 : _data_meta.pagination)) {
            return;
        }
        var pagination = data.meta.pagination;
        // Check if the returned data is using camelCase, since this will break all of the other table
        // logic as well
        if (typeof pagination.totalItemsCount === "undefined" && typeof pagination.total_items_count !== "undefined") {
            throw new Error("Called with a queryFn that returns snake_case, but expected camelCase");
        }
        // If there is no data on this page, move to the last page
        var maxPages = Math.floor(pagination.totalItemsCount / pagination.limit);
        if (pagination.pageItemsCount === 0 && pagination.page > maxPages) {
            // This mimics the behavior of AntD when changing the current page in the table.
            onChange({
                current: maxPages + 1,
                pageSize: pagination.limit
            }, {}, {}, {
                action: "paginate",
                currentDataSource: (data === null || data === void 0 ? void 0 : data.data) || []
            });
        }
    }, [
        isLoading,
        data,
        onChange
    ]);
    useEffect(function() {
        if (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            message.error(t(_templateObject()));
        }
    }, [
        error
    ]);
    // Update the columns we received with the filteredValue and sortOrder from the trackedState.
    // This ensures that the UX always reflects what is being tracked in the URL and queried. This
    // also allows us to use the derivedFilters function to set filter options based on the data,
    // like we do in the `Used By` column for organization email domains.
    var columns = useMemo(function() {
        return sourceTableProps.columns.map(function(_param) {
            var derivedFilters = _param.derivedFilters, column = _objectWithoutProperties(_param, [
                "derivedFilters"
            ]);
            var _trackedState_filter, _trackedState_sort;
            // Confirm that pagination is disabled, since we want to avoid showing filter values that
            // are based on a subset of the data (the current page).
            if (derivedFilters && !isPaginationDisabled) {
                throw new Error("Derived filters are only supported when pagination is disabled");
            }
            var columnId = column.key || column.dataIndex;
            if (!columnId) {
                return column;
            }
            var result = _objectSpreadProps(_objectSpread({}, column), {
                filteredValue: ((_trackedState_filter = trackedState.filter) === null || _trackedState_filter === void 0 ? void 0 : _trackedState_filter[columnId]) || [],
                sortOrder: ((_trackedState_sort = trackedState.sort) === null || _trackedState_sort === void 0 ? void 0 : _trackedState_sort.field) === columnId ? trackedState.sort.order : null
            });
            if (derivedFilters) {
                result.filters = derivedFilters((data === null || data === void 0 ? void 0 : data.data) || []);
            }
            return result;
        });
    }, [
        sourceTableProps.columns,
        trackedState,
        data,
        isPaginationDisabled
    ]);
    // Merge in our query results with the tableProps
    var tableProps = useMemo(function() {
        var _data_meta, _data_meta_pagination;
        return _objectSpreadProps(_objectSpread({}, sourceTableProps, tableUrlProps), {
            pagination: !isPaginationDisabled && _objectSpreadProps(_objectSpread({}, sourceTableProps.pagination || {}, tableUrlProps.pagination), {
                total: (data === null || data === void 0 ? void 0 : (_data_meta = data.meta) === null || _data_meta === void 0 ? void 0 : (_data_meta_pagination = _data_meta.pagination) === null || _data_meta_pagination === void 0 ? void 0 : _data_meta_pagination.totalItemsCount) || 0
            }),
            columns: columns,
            onChange: onChange,
            loading: isLoading || isFetching,
            dataSource: (data === null || data === void 0 ? void 0 : data.data) || []
        });
    }, [
        tableUrlProps,
        onChange,
        isLoading,
        isFetching,
        data,
        sourceTableProps,
        columns,
        isPaginationDisabled
    ]);
    return {
        tableProps: tableProps,
        query: queryResult
    };
};
