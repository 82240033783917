function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import apiFn from "../api-service";
var eventsApi = function() {
    return apiFn("/api/uapi/events");
};
var cmsEventsApi = function(organizationId) {
    return apiFn("/api/cms/v1/organizations/".concat(organizationId, "/events"));
};
var basicEventDataApi = function(eventId) {
    return apiFn("/api/cms/views/events/".concat(eventId, "/basic-data"));
};
export var get = function(eventId) {
    return eventsApi().get(eventId).then(function(response) {
        return response.data;
    });
};
export var listCmsEvents = function(organizationId) {
    var payload = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, query = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : null;
    var params = new URLSearchParams(query !== null && query !== void 0 ? query : {});
    if (typeof payload.offset !== "undefined" && payload.offset > 0) {
        params.append("page", String(payload.offset / payload.limit));
    }
    return cmsEventsApi(organizationId).get("", {
        params: params
    }).then(function(response) {
        return response === null || response === void 0 ? void 0 : response.data;
    });
};
// transform data to match the UAPI response
var transformCmsEvent = function(data) {
    return _objectSpreadProps(_objectSpread({}, data), {
        data: _objectSpreadProps(_objectSpread({}, data.data), {
            configuration: {
                timezone: data.data.timezone
            }
        })
    });
};
export var getCmsEvent = function(organizationId, eventId) {
    return cmsEventsApi(organizationId).get(eventId).then(function(response) {
        return transformCmsEvent(response.data);
    });
};
export var search = function(organizationId, content) {
    var params = {
        params: {
            search: content,
            limit: 10,
            offset: 0
        }
    };
    return cmsEventsApi(organizationId).get("", params).then(function(response) {
        return response.data;
    });
};
export var updateCmsEvent = function(organizationId, eventId, data) {
    return cmsEventsApi(organizationId).patch(String(eventId), data).then(function(response) {
        return transformCmsEvent(response.data);
    });
};
// This endpoint does not require authentication and returns very basic information,
// used for pages like Live Results and Moderation
export var getBasicData = function(eventId) {
    return basicEventDataApi(eventId).get("").then(function(response) {
        return response.data;
    });
};
