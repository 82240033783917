import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { getIsStaff } from 'common/state/user';

import PrivateRoute from './PrivateRoute';

const StaffOnly = ({ children, ...props }) => {
  const isStaff = useSelector(getIsStaff);
  const location = useLocation();

  return (
    <PrivateRoute {...props}>
      {isStaff ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: `/forbidden`,
            state: { from: location },
          }}
        />
      )}
    </PrivateRoute>
  );
};

StaffOnly.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StaffOnly;
