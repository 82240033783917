import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import hashFn from '@services/hash-service';

export const UNDO = 'undo';
export const REDO = 'redo';
export const UPDATE_REMOVED_WIDGETS = 'update_removed_widgets';

export default function undoable(reducer, skip = () => false, ignoreProps = []) {
  // Call the reducer with empty action to populate the initial state
  const initialState = {
    past: [],
    present: reducer(undefined, {}),
    future: [],
  };

  // Return a reducer that handles undo and redo
  return (state = initialState, action) => {
    const { past, present, future, type: prevType } = state;
    const { type, payload } = action;

    switch (type) {
      case UNDO: {
        const previous = past[past.length - 1];

        previous.history = present.history;
        const lookup = previous?.widgets?.reduce(
          (prev, current) => ({ ...prev, [current.id]: { ...current } }),
          {}
        );
        const checksum = hashFn({ lookup, background: previous.background });
        previous.pristine = previous.checksum === null || previous.history?.[0] === checksum;

        const newPast = past.slice(0, past.length - 1);
        return {
          past: newPast,
          present: previous,
          future: [present, ...future],
        };
      }
      case REDO: {
        const next = future[0];

        next.history = present.history;
        const lookup = next?.widgets?.reduce(
          (prev, current) => ({ ...prev, [current.id]: { ...current } }),
          {}
        );
        const checksum = hashFn({ lookup, background: next.background });
        next.pristine = next.checksum === null || next.history?.[0] === checksum;

        const newFuture = future.slice(1);
        return {
          past: [...past, present],
          present: next,
          future: newFuture,
        };
      }
      case UPDATE_REMOVED_WIDGETS: {
        const update = items => {
          return items.map(item => {
            const widgets = item.widgets.map(widget => {
              const exist = payload.widgets.find(({ id }) => id === widget.id);
              if (!exist) {
                return { ...widget, deleted: true };
              }
              return widget;
            });
            return { ...item, widgets };
          });
        };
        return { ...state, past: [...update(state.past)], future: [...update(state.future)] };
      }
      default: {
        const oldPresent = cloneDeep(omit(present, ignoreProps));
        const newPresent = reducer(present ?? state, action);
        if (present === newPresent) {
          return state;
        }

        if (skip({ type, prevType })) {
          return {
            type,
            past,
            present: newPresent,
            future,
          };
        }

        return {
          type,
          past: [...(past ?? []), oldPresent],
          present: newPresent,
          future: [],
        };
      }
    }
  };
}
