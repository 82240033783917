import { useQueries } from '@tanstack/react-query';

import {
  get as getFn,
  LIVESTREAM_PLAYBACK_HOURS,
  LIVESTREAM_BROADCAST_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
} from '@services/data-usage-service';

export const videoUsageOptions = organizationId => ({
  queries: [
    {
      queryKey: ['livestream-broadcast-hours', organizationId],
      queryFn: () => getFn(organizationId, LIVESTREAM_BROADCAST_HOURS),
      enabled: !!organizationId,
    },
    {
      queryKey: ['livestream-playback-hours', organizationId],
      queryFn: () => getFn(organizationId, LIVESTREAM_PLAYBACK_HOURS),
      enabled: !!organizationId,
    },
    {
      queryKey: ['video-storage-hours', organizationId],
      queryFn: () => getFn(organizationId, VIDEO_STORAGE_HOURS),
      enabled: !!organizationId,
    },
    {
      queryKey: ['video-playback-hours', organizationId],
      queryFn: () => getFn(organizationId, VIDEO_PLAYBACK_HOURS),
      enabled: !!organizationId,
    },
  ],
});

export const useVideoUsage = organizationId => useQueries(videoUsageOptions(organizationId));
