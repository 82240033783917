import { RightOutlined } from '@ant-design/icons';
import { Collapse, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { isEnabled as isFlagEnabled } from 'common/state/flags';

import {
  LIVESTREAM_PLAYBACK_HOURS,
  LIVESTREAM_BROADCAST_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
  PEOPLE_CREDITS,
} from '@services/data-usage-service';

import { LEVEL_ERROR, LEVEL_INFO, LEVEL_WARN } from './constants';
import UsageLimitAlert from './UsageLimitAlert';
import UsageLimitDetails from './UsageLimitDetails';
import UsageLimitHeader from './UsageLimitHeader';

const { Panel: AntPanel } = Collapse;

const Panel = styled(AntPanel)`
  background: white;
`;

const ExpandIcon = styled(RightOutlined)`
  &&&& {
    top: 17px;
  }
`;

// Remove isUserCreditLicensingEnabled while removing the user_credit_licensing_v2 FF
export const getLevel = (type, percent, isUserCreditLicensingEnabled = false) => {
  if (percent < 80) {
    return LEVEL_INFO;
  }

  if (
    percent > 100 ||
    (percent === 100 && type === VIDEO_PLAYBACK_HOURS) ||
    // Remove isUserCreditLicensingEnabled while removing the user_credit_licensing_v2 FF
    (percent === 100 && isUserCreditLicensingEnabled && type === PEOPLE_CREDITS)
  ) {
    return LEVEL_ERROR;
  }

  return LEVEL_WARN;
};

const UsageLimitCollapse = ({ type, used, limit, disabled = false, ...entity }) => {
  const isUserCreditLicensingEnabled = useSelector(state =>
    isFlagEnabled(state, 'user_credit_licensing_v2')
  );

  const percent = limit ? Math.round((used / limit) * 100) : 0;
  const level = getLevel(type, percent, isUserCreditLicensingEnabled);

  return (
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        // wrap the icon in a div to align it horizontally with the header
        <div>
          <ExpandIcon rotate={isActive ? 90 : 0} />
        </div>
      )}
      collapsible={disabled ? 'disabled' : undefined}
    >
      <Panel
        key={type}
        header={
          <UsageLimitHeader
            type={type}
            used={used}
            limit={limit}
            percent={percent}
            level={level}
            disabled={disabled}
            {...entity}
          />
        }
      >
        <Space size="middle" direction="vertical" style={{ width: '100%' }}>
          <UsageLimitAlert type={type} percent={percent} level={level} />
          <UsageLimitDetails type={type} entity={entity} />
        </Space>
      </Panel>
    </Collapse>
  );
};

UsageLimitCollapse.propTypes = {
  type: PropTypes.oneOf([
    LIVESTREAM_BROADCAST_HOURS,
    LIVESTREAM_PLAYBACK_HOURS,
    VIDEO_STORAGE_HOURS,
    VIDEO_PLAYBACK_HOURS,
    PEOPLE_CREDITS,
  ]).isRequired,
  used: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  entity: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default UsageLimitCollapse;
