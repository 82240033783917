import apiFn from 'common/services/api-service';

export const HOMEPAGE_TYPE_WIDGET_PAGE = 'widget_page';
export const HOMEPAGE_TYPE_ACTIVITY_FEED = 'activity_feed';

const api = eventId =>
  apiFn(`/api/uapi/events/${eventId}/event-space`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export function get(eventId) {
  return api(eventId)
    .get('')
    .then(response => response.data.data.homepage);
}

export function update(eventId, entityType, entityId) {
  return api(eventId)
    .patch('', {
      homepage: {
        entityType,
        entityId,
      },
    })
    .then(response => response.data.data.homepage);
}
