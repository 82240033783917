import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'ttag';

import { useTableQuery } from 'common/hooks/useTableQuery';
import { getId } from 'common/state/organization';

import usePermission, { DELETE_SENDER_EMAIL_DOMAINS } from '@hooks/usePermission';

import { list } from './queries';
import DomainStatusTag from './StatusTag';

// Add a little bit of margin on the top and bottom to make it look better
const UsedByTag = styled(Tag)`
  .ant-tooltip-inner & {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

const EmailDomainTable = ({ viewRecords, onDelete, onRowSelection, selectedRowKeys }) => {
  const organizationId = useSelector(getId);
  const { authorized } = usePermission();

  const columns = useMemo(
    () => [
      {
        title: t`Domain Name`,
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: t`Used By`,
        dataIndex: 'linkedEvents',
        key: 'linkedEventId',
        derivedFilters: data =>
          Object.entries(
            data.reduce((acc, domain) => {
              domain.linkedEvents.forEach(event => {
                acc[event.id] = event.code;
              });
              return acc;
            }, {})
          ).map(([id, code]) => ({ text: <Tag>{code}</Tag>, value: id })),
        render: linkedEvents => {
          const eventTags = linkedEvents.map(event => (
            <UsedByTag key={event.id}>{event.code}</UsedByTag>
          ));
          if (eventTags.length <= 3) {
            return eventTags;
          }
          // We set the tooltip to white, since dark mode has a dark background by default, but we
          // don't actually support dark mode and the Tag looks bad on a dark background
          return (
            <>
              {eventTags.slice(0, 3)}
              <Tooltip title={eventTags} color="white">
                <Typography.Text>+{eventTags.length - 3} </Typography.Text>
                <InfoCircleOutlined />
              </Tooltip>
            </>
          );
        },
      },
      {
        title: t`Verification Status`,
        dataIndex: 'verificationStatus',
        render: status => <DomainStatusTag status={status} />,
        filters: [
          {
            text: <DomainStatusTag status="not_started" />,
            value: 'not_started',
          },
          {
            text: <DomainStatusTag status="pending" animate={false} />,
            value: 'pending',
          },
          {
            text: <DomainStatusTag status="failed" />,
            value: 'failed',
          },
          {
            text: <DomainStatusTag status="verified" />,
            value: 'verified',
          },
        ],
      },
      {
        title: t`Action`,
        key: 'action',
        // eslint-disable-next-line react/prop-types
        render: row => (
          <Space>
            <Button type="link" onClick={() => viewRecords(row.id)}>{t`View Records`}</Button>
            {authorized(DELETE_SENDER_EMAIL_DOMAINS) && (
              <Button type="link" onClick={() => onDelete([row])} danger>{t`Delete`}</Button>
            )}
          </Space>
        ),
      },
    ],
    [onDelete, viewRecords]
  );

  const { tableProps } = useTableQuery(
    apiParams => ({
      queryKey: list.getQueryKey(organizationId, apiParams),
      queryFn: list.queryFn,
    }),
    {
      pagination: false,
      columns,
      rowKey: 'id',
      rowSelection: authorized(DELETE_SENDER_EMAIL_DOMAINS)
        ? {
            onChange: onRowSelection,
            selectedRowKeys,
          }
        : null,
    }
  );

  return <Table {...tableProps} />;
};

EmailDomainTable.propTypes = {
  onDelete: PropTypes.func.isRequired,
  viewRecords: PropTypes.func.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EmailDomainTable;
