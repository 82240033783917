import { Col as AntColUnstyled, Row as AntRow } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// this is to prevent wanrings from `styled-component` about using an unstyled component in Row
const AntCol = styled(AntColUnstyled)``;

const Row = styled.div`
  display: flex;
  align-items: center;

  padding: 0 20px;
  height: 80px;
  font-size: 34px;
  font-weight: 600;

  &:not(:last-child) {
    border-bottom: 1px solid #bbb;
  }

  background: #f4f8fb;
  ${AntCol} &:nth-child(odd) {
    background: #e1ecf4;
  }
`;

const Col = styled.div`
  flex: ${props => props.flex};
`;

const ColName = styled(Col)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColPoints = styled(Col)`
  text-align: right;
  color: #0093c7;
`;

const Item = ({ rank, points, name }) => {
  return (
    <Row>
      <Col flex="0 0 60px">{rank}</Col>
      <ColName flex="1 1 auto">{name}</ColName>
      <ColPoints flex="0 0 115px">{points}</ColPoints>
    </Row>
  );
};

Item.propTypes = {
  rank: PropTypes.number.isRequired,
  points: PropTypes.number,
  name: PropTypes.string,
};

const Table = ({ players, start, end, size }) => {
  // make all the columns of equal size
  const lastColumn = (end - start) % size;
  const actualEnd = end + (lastColumn === 0 ? 0 : size - lastColumn);

  if (players.length < actualEnd) {
    for (let i = players.length; i < actualEnd; i += 1) {
      players.push({});
    }
  }

  const p = players.slice(start, actualEnd);

  const cols = p.reduce((chunks, player, i) => {
    const col = Math.floor(i / size);

    if (!chunks[col]) {
      // eslint-disable-next-line no-param-reassign
      chunks[col] = [player];
    } else {
      chunks[col].push(player);
    }

    return chunks;
  }, []);

  return (
    <AntRow gutter="40">
      {cols.map((colPlayers, colIndex) => (
        <AntCol key={Math.random() * 1000} span={24 / cols.length}>
          {colPlayers.map((player, i) => (
            <Item
              key={Math.random() * 1000}
              rank={start + size * colIndex + i + 1}
              points={player.points}
              name={player.name}
            />
          ))}
        </AntCol>
      ))}
    </AntRow>
  );
};

Table.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object).isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
};

export default Table;
