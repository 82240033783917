import { Button, Typography, Space as AntSpace } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import { SUPPORT_EMAIL } from 'common/services/support/types';

import AuthLayout from '@domains/Auth/Layout';
import constructionImage from '@images/illustrations/construction.svg';

const ScopedLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 569px;
  padding: 32px;
  margin: 0 auto;
  text-align: center;
  position: relative;
`;

const Image = styled.img`
  width: 400px;
  height: 275px;
  align-self: center;
`;

const Title = styled(Typography.Title)`
  && {
    align-self: center;
    margin-bottom: 1.5rem;
  }
`;

const Text = styled(Typography.Text)`
  margin-bottom: 24px;
`;

const Support = styled.div`
  text-align: center;

  .ant-typography {
    font-size: 12px;
    margin-top: 16px;
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: ${props => (props.scoped ? 'absolute' : 'relative')};
  left: 50%;
  transform: translateX(-50%);
`;

const Space = styled(AntSpace)`
  width: 100%;
  align-items: center;
`;

function Layout({ scoped, support, children, title, body, image }) {
  const contactSupport = <a key="email" href={`mailto:${SUPPORT_EMAIL}`}>{t`Contact Support.`}</a>;

  if (scoped) {
    return (
      <ScopedLayout>
        <Image src={image} alt="" />
        <Title level={3}>{title}</Title>
        <Text>{body}</Text>
        <div>
          <CTA scoped={scoped}>
            {children}
            {support ? (
              <Button type="secondary" href={`mailto:${SUPPORT_EMAIL}`}>
                {t`Contact Support`}
              </Button>
            ) : null}
          </CTA>
        </div>
      </ScopedLayout>
    );
  }

  return (
    <AuthLayout>
      <Space direction="vertical" size="middle">
        <Title level={4} style={{ textAlign: 'center' }}>
          {title}
        </Title>
        <Text>{body}</Text>
        <div>
          <CTA>{children}</CTA>
        </div>
        {support ? (
          <Support>
            <Text>{jt`Need help? ${contactSupport}`}</Text>
          </Support>
        ) : null}
      </Space>
    </AuthLayout>
  );
}

Layout.propTypes = {
  scoped: PropTypes.bool,
  support: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.string,
};

Layout.defaultProps = {
  scoped: false,
  support: true,
  children: null,
  image: constructionImage,
};

export default Layout;
