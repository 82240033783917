import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

/**
 * A component that displays the status of a domain
 * @param {string} props.status The status (pending, failed, verified, not_started) that should
 *   be displayed
 * @param {boolean} props.animate Whether the pending spinner should animate
 * @param {boolean} props.pendingIsVerifying Whether the pending status should be titled as
 *   'Verifying'. This is used in the View Records modal to tie in with the Verify Now button
 */
const DomainStatusTag = ({ status, animate = true, pendingIsVerifying = false }) => {
  switch (status) {
    case 'pending':
      return (
        <Tag color="processing" icon={<SyncOutlined spin={animate} />}>
          {pendingIsVerifying ? t`Verifying` : t`Pending`}
        </Tag>
      );
    case 'failed':
      return <Tag color="error" icon={<CloseCircleOutlined />}>{t`Failed`}</Tag>;
    case 'verified':
      return <Tag color="success" icon={<CheckCircleOutlined />}>{t`Connected`}</Tag>;
    // The real state is `not_started`, but we'll use default for this to prevent any crashes
    default:
      return <Tag>{t`N/A`}</Tag>;
  }
};

DomainStatusTag.propTypes = {
  status: PropTypes.oneOf(['pending', 'failed', 'verified', 'not_started']).isRequired,
  animate: PropTypes.bool,
  pendingIsVerifying: PropTypes.bool,
};

export default DomainStatusTag;
