import useTranslation from 'common/hooks/useTranslation';

export const kbArticle = {
  german:
    'https://help.eventmobi.com/de/knowledge/wie-lege-ich-meine-eigene-absender-adresse-fur-e-mails-von-der-event-app-fest',
  english:
    'https://help.eventmobi.com/en/knowledge/how-can-i-make-sure-that-emails-from-the-app-use-my-preferred-email-address',
};

export const useKBArticle = () => {
  const { locale } = useTranslation();
  return /de/.test(locale) ? kbArticle.german : kbArticle.english;
};
