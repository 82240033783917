import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import BarChart from '@domains/LiveResults/BarChart';
import Chart from '@domains/LiveResults/Chart';

const CHART_BAR = 'bar';

const Container = styled.div`
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #e8e8e8;
  width: 600px;
  height: 100%;
  margin-bottom: 30px;
`;

const Header = styled.header`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  height: 60px;
  padding: 20px;

  color: ${props => props.colors?.headerTextColor};
  background-color: ${props => props.colors?.headerBackgroundColor};
`;

const Main = styled.main`
  position: relative;
  height: 100%;
  color: ${props => props.colors?.bodyTextColor};
  background-color: ${props => props.colors?.bodyBackgroundColor};
`;

const getRandomValue = size => parseInt(Math.random() * 80 + 0.2 / size, 10);

const createDataSourceFromSelectOptions = (dataSource, selectOptions) => {
  return selectOptions.map((option, index) => ({
    ...dataSource[index],
    id: option.id ? `${option.id}` : `${option.optionText}_${index}`,
    order: option.order ?? index,
    title: option.optionText,
    label: String.fromCharCode(65 + index),
    color: option.color,
    value: dataSource[index]?.value ?? getRandomValue(selectOptions.length),
    count: dataSource[index]?.count ?? getRandomValue(selectOptions.length),
  }));
};

const ChartPreview = ({ chartType = CHART_BAR, colors = {}, selectOptions = [] }) => {
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    setDataSource(dataSourceLocal =>
      createDataSourceFromSelectOptions(dataSourceLocal, selectOptions)
    );
  }, [selectOptions]);

  return (
    <Container chart={chartType}>
      <Header colors={colors}>{t`Preview Title Text`}</Header>
      <Main chart={chartType} colors={colors}>
        <div style={{ padding: 12 }}>
          {chartType === CHART_BAR && (
            <BarChart textColor={colors.bodyTextColor} dataSource={dataSource} />
          )}
          {chartType !== CHART_BAR && (
            <Chart
              height={340}
              width={280}
              fontSize={12}
              type={chartType}
              textColor={colors.bodyTextColor}
              dataSource={dataSource}
            />
          )}
        </div>
      </Main>
    </Container>
  );
};

ChartPreview.propTypes = {
  chartType: PropTypes.string,
  colors: PropTypes.shape({}),
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      optionText: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default ChartPreview;
