import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Take the whole height of the parent and
// Override the global RTE styles conflict
const Container = styled.div`
  height: 100%;
  & .jodit-workplace {
    height: auto !important;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Video = params => (
  <Container>
    <Image src={params.thumbnail} alt={params.videoId} />
  </Container>
);

Video.propTypes = {
  image: PropTypes.shape({
    thumbnail: PropTypes.string,
  }),
};

export default Video;
