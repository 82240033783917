import React from 'react';
import { useParams } from 'react-router-dom';

import CaptureBadges from '@domains/Badges/Capture';
import CaptureWidgetPages from '@domains/WidgetPages/Capture';

import { BADGES, BADGES_TEMPLATES, WIDGET_PAGES, WIDGET_PAGE_TEMPLATES } from './constants';

const Capture = () => {
  const { resourceType } = useParams();

  if ([WIDGET_PAGES, WIDGET_PAGE_TEMPLATES].includes(resourceType)) {
    return <CaptureWidgetPages />;
  }

  if ([BADGES, BADGES_TEMPLATES].includes(resourceType)) {
    return <CaptureBadges />;
  }

  return <>Unknown resource type</>;
};

export default Capture;
