import apiFn from 'common/services/api-service';

const api = () =>
  apiFn(`/api/uapi/organizations`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const LICENSE_TYPE_EVENT_CREDIT = 'event_credit';
export const LICENSE_TYPE_USER_CREDIT = 'user_credit';

export const list = () => {
  return api()
    .get()
    .then(response => response.data.data);
};

export const get = (organizationId, include = ['configuration']) => {
  const params = new URLSearchParams();

  if (include.length > 0) {
    params.set('include', include.join(','));
  }

  return api()
    .get(organizationId, { params })
    .then(response => response.data.data);
};

export const update = (organizationId, payload) => {
  return api()
    .patch(organizationId, payload)
    .then(response => response.data.data);
};

export const listEventsWithFeature = (organizationId, feature, params) =>
  api()
    .get(`${organizationId}/events`, { params: { ...params, featureEnabled: feature } })
    .then(response => response.data);
