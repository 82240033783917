import BannerWidget from '@eventmobi/widgets/Banner';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Override the global RTE styles conflict
const Container = styled.div`
  height: inherit;
  width: inherit;
  & .jodit-workplace {
    height: auto !important;
  }

  // TODO: once we remove all previous variants of builders, we can move this into toolkit.
  & .Mui-WidgetBanner-bgContainer {
    width: 100%;
    left: 0%;
  }
`;

const Banner = props => {
  const { image, foreground } = props;
  return (
    <Container>
      <BannerWidget {...props} background={image?.url} foreground={foreground?.url} />
    </Container>
  );
};

Banner.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  foreground: PropTypes.shape({
    url: PropTypes.string,
  }),
  // Banner widget props
};

export default Banner;
