import { useHistory, useLocation } from 'react-router-dom';

import useSearchParams from 'common/hooks/useSearchParams';

export default (defaultTab, tabs = [], resetParams = true, queryParamName = 'tab') => {
  const history = useHistory();
  const location = useLocation();

  const query = useSearchParams();

  const active = tabs.includes(query.get(queryParamName)) ? query.get(queryParamName) : defaultTab;

  const onChange = key => {
    const newTab = tabs.includes(key) ? key : defaultTab;

    if (active !== newTab) {
      if (resetParams) {
        // when set to true, `resetParams` will make sure to remove all the other query params
        history.replace({ ...location, search: `?${queryParamName}=${newTab}` });

        return;
      }

      query.set(queryParamName, newTab);
      query.sort();

      history.replace({ ...location, search: query.toString() });
    }
  };

  return [active, onChange];
};
