import { Alert, Button, Form, message, Modal, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';

import { list as organizersFn } from '@domains/Organization/Organizer/service-old';

import { generate as generateFn } from './service';

const ERROR_MESSAGES_BY_CODE = () => ({
  'a53e1179-1fae-47ee-b20c-2a388f0275a8': t`This user already has an API Key for this organization.`,
});

const { Item, useForm } = Form;

const useAddModal = organizationId => {
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      try {
        const result = await organizersFn(organizationId).then(response =>
          response.map(({ id, firstName, lastName, email }) => ({
            value: id,
            label: `${firstName} ${lastName} (${email})`,
          }))
        );
        setOrganizers(result);
      } finally {
        setLoading(false);
      }
    }
    if (organizationId) {
      load();
    }
  }, [organizationId]);

  return {
    organizers,
    loading,
  };
};

const AddModal = ({ organizationId, onClose }) => {
  const { organizers, loading } = useAddModal(organizationId);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  const [form] = useForm();
  const { validateFields } = form;

  const handleSave = async () => {
    const { organizerId } = await validateFields();

    setError(null);
    setSaving(true);
    try {
      await generateFn(organizationId, organizerId);
      message.success(t`Successfully generated API Key`);

      onClose(true);
    } catch (err) {
      const code = err?.errors?.[0]?.code;

      const errorMessage =
        ERROR_MESSAGES_BY_CODE()[code] ?? t`Unknown error occurred while sending invite.`;
      setError(errorMessage);

      setSaving(false);
    }
  };

  return (
    <Modal
      title={t`Generate API Key`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={() => onClose(false)}
      footer={
        <>
          <Button disabled={saving} onClick={() => onClose(false)}>{t`Cancel`}</Button>
          <Button
            type="primary"
            loading={saving}
            onClick={handleSave}
          >{t`Generate API Key`}</Button>
        </>
      }
      width={700}
    >
      {error && <Alert showIcon message={error} type="error" style={{ marginBottom: '20px' }} />}

      {loading && <Loading />}

      {!loading && (
        <>
          <Typography style={{ marginBottom: '20px' }}>
            {t`This API key can act as the selected organizer and have full read and write permissions to all events within this organization.`}
          </Typography>

          <Form form={form} layout="vertical" hideRequiredMark>
            <Item
              label={<Label isRequired>{t`Organizer`}</Label>}
              name="organizerId"
              rules={[{ required: true, message: t`Organizer is required` }]}
            >
              <Select
                placeholder={t`Select Organizer`}
                optionFilterProp="label"
                options={organizers}
                showSearch
                filterOption={(search, record) =>
                  record?.label?.toLowerCase().indexOf(search.toLowerCase()) >= 0
                }
                filterSort={(recordA, recordB) =>
                  recordA?.label?.toLowerCase().localeCompare(recordB?.label?.toLowerCase())
                }
              />
            </Item>
          </Form>
        </>
      )}
    </Modal>
  );
};

AddModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddModal;
