import { Button, Form, Input, message, Modal, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { t } from 'ttag';

import { useCreateEmailDomainMutation } from './queries';

const { useForm } = Form;

const AddModal = ({ organizationId, onClose }) => {
  const { mutate, isLoading } = useCreateEmailDomainMutation();
  const [form] = useForm();
  const [existingDomain, setExistingDomain] = useState('');

  const onFinish = payload =>
    mutate(
      { organizationId, payload },
      {
        onSuccess: resp => onClose(resp.data.id),
        onError: err => {
          if (err.type === 'sender_email_domain_already_exists') {
            setExistingDomain(payload.name);
            form.validateFields(['name']);
          } else {
            message.error(t`An unknown error occurred`);
          }
        },
      }
    );

  return (
    <Modal
      title={t`Add Email Domain`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={() => onClose(false)}
      footer={<Button onClick={() => onClose(false)}>{t`Close`}</Button>}
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
          <Form.Item
            label={t`Domain`}
            htmlFor="domainNameField"
            name="name"
            rules={[
              {
                required: true,
                message: t`Please provide a valid domain name (e.g. example.com)`,
                // Based on the flux domain name regex
                // https://github.com/EventMobi/Flux/blob/92b228e41bc614799003439d6ea5978d3cead4f0/flux/utils/url.py#L43-L59
                // ^(?!localhost)                            # Not 'localhost'
                // (?=.*\.[A-Za-z]{2,}$)                     # Lookahead for '.' followed by TLD
                // (?:[a-zA-Z0-9]                            # Alphanumeric start of domain label
                //   (?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.    # Middle part can have hyphens, ends with dot
                //   )+                                      # One or more labels for domain and subdomains
                //   [A-Za-z]{2,}                            # TLD, at least 2 letters
                // $                                         # End of string
                pattern: /^(?!localhost)(?=.*\.[A-Za-z]{2,}$)(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[A-Za-z]{2,}$/,
              },
              {
                validator(_, value) {
                  if (existingDomain && value === existingDomain) {
                    return Promise.reject(
                      t`This domain already exists. Please remove the domain to generate new records.`
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Space.Compact block>
              <Input id="domainNameField" />
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t`Generate Records`}
              </Button>
            </Space.Compact>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

AddModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddModal;
