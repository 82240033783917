import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  list as listFn,
  get as getFn,
  create as createFn,
  verify as verifyFn,
  bulkDelete as bulkDeleteFn,
} from './service';

export const list = {
  // We only append params if its truthy, since this queryKey is also used for invalidating
  // queries, and we want to invalidate regardless of params/filters
  getQueryKey: (organizationId, params = null) =>
    ['organization', organizationId, 'emailDomains', 'list'].concat(params ? [params] : []),
  queryFn: ({ queryKey: [, organizationId, , , params] }) => listFn(organizationId, params),
};

export const useEmailDomainList = (organizationId, params = null, enabled = true) =>
  useQuery({
    queryKey: list.getQueryKey(organizationId, params),
    queryFn: list.queryFn,
    enabled,
  });

export const get = {
  getQueryKey: (organizationId, id) => ['organization', organizationId, 'emailDomains', id],
  queryFn: ({ queryKey: [, organizationId, , id] }) => getFn(organizationId, id),
};

export const useEmailDomain = (organizationId, id) =>
  useQuery({
    queryKey: get.getQueryKey(organizationId, id),
    queryFn: get.queryFn,
    // Use a 1s stale time to allow creating a domain to set the state, without the modal
    // immediately refetching the data.
    staleTime: 1000,
  });

// Returns an error of `sender_email_domain_already_exists` on duplicate domain
export const useCreateEmailDomainMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['emailDomain', 'create'],
    mutationFn: ({ organizationId, payload }) => createFn(organizationId, payload),
    onSuccess: (data, { organizationId }) => {
      // Invalidate the list, and update the specific domain's query
      queryClient.invalidateQueries({ queryKey: list.getQueryKey(organizationId) });
      queryClient.setQueryData(get.getQueryKey(organizationId, data.data.id), data);
    },
  });
};

export const useBulkDeleteEmailDomainMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['emailDomain', 'bulkDelete'],
    mutationFn: ({ organizationId, ids }) => bulkDeleteFn(organizationId, ids),
    onSuccess: (data, { organizationId }) => {
      queryClient.invalidateQueries({ queryKey: list.getQueryKey(organizationId) });
    },
  });
};

export const useVerifyEmailDomainMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['emailDomain', 'verify'],
    mutationFn: ({ organizationId, id }) => verifyFn(organizationId, id),
    onSuccess: (data, { organizationId, id }) => {
      // Invalidate the list, and update the specific domain's query
      queryClient.invalidateQueries({ queryKey: list.getQueryKey(organizationId) });
      queryClient.setQueryData(get.getQueryKey(organizationId, id), data);
    },
  });
};
