import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { msgid, ngettext, t } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import Empty from 'common/components/Empty';
import Loading from 'common/components/Loading';
import DeleteModal from 'common/components/Modal/DeleteModal';
import useTable from 'common/hooks/useTable';
import { getId as getOrganizationId } from 'common/state/organization';

import usePermission, { CREATE_SSO_CONNECTIONS } from '@hooks/usePermission';
import EmptyImage from '@images/illustrations/sessions.svg';

import AddModal from './AddModal';
import EditModal from './EditModal';
import { useSetupSSOMutation, useSSOQuery } from './queries';
import { list as listIdp, remove as removeFn } from './service';
import Table from './Table';

const Base = styled.div`
  margin-top: 20px;

  /* accommodate deleteBar component's height */
  margin-bottom: 40px;
`;

const SetupSSO = ({ organizationId }) => {
  const { isLoading, mutate } = useSetupSSOMutation(organizationId);
  const { authorized } = usePermission();

  const setupSSO = () =>
    mutate(
      { organizationId },
      {
        onError: () => message.error(t`An unknown error occurred while setting up Single Sign-On.`),
      }
    );

  return (
    <Empty
      image={EmptyImage}
      title={t`Setup Single Sign-On`}
      body={
        <Space direction="vertical" size="large">
          {t`Single Sign-On is an authentication process that allows users to securely access the Event Space using just one set of credentials through your Identity Provider.`}
          {authorized(CREATE_SSO_CONNECTIONS) && (
            <Button type="primary" loading={isLoading} onClick={setupSSO}>
              {t`Setup Single Sign-On`}
            </Button>
          )}
        </Space>
      }
    />
  );
};

SetupSSO.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

const SSOConnections = ({ organizationId, sso, active }) => {
  const location = useLocation();
  const { authorized } = usePermission();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [connection, setConnection] = useState(null);

  const fn = useCallback((...args) => listIdp(organizationId, ...args), [organizationId]);
  const { pagination, dataSource, loading, onChange, syncQuery, forceReload } = useTable(fn, {
    sort: { field: 'displayName', order: 'ascend' },
  });

  useEffect(() => {
    if (active) {
      syncQuery(location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, active]);

  const onCloseAddModal = reload => {
    setShowAddModal(false);

    if (reload) {
      forceReload();
    }
  };

  const onCloseEditModal = reload => {
    setShowEditModal(false);
    setConnection(null);

    if (reload) {
      forceReload();
    }
  };

  const onEdit = row => {
    setConnection(row);
    setShowEditModal(true);
  };

  const handleDelete = row => {
    const item = selectedRows.length === 1 && dataSource.find(({ id }) => id === selectedRows[0]);
    const count = selectedRows.length || 1;
    DeleteModal.confirm({
      label: {
        singular: t`SSO`,
        plural: t`SSO`,
      },
      count,
      itemName: row?.displayName || item?.displayName,
      async onDelete() {
        try {
          if (row) {
            await removeFn(organizationId, row.id);
          } else {
            await Promise.all(selectedRows.map(id => removeFn(organizationId, id)));
          }

          message.success(
            ngettext(
              msgid`The SSO Connection has been successfully deleted.`,
              `The SSO Connections have been successfully deleted.`,
              count
            )
          );

          forceReload();
          setSelectedRows([]);
        } catch {
          message.error(
            ngettext(
              msgid`Error deleting SSO Connection.`,
              `Error deleting SSO Connections.`,
              count
            )
          );
        }
      },
    });
  };

  return (
    <>
      <Row type="flex" css="margin-bottom: 20px;">
        {authorized(CREATE_SSO_CONNECTIONS) && (
          <Col span={8}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowAddModal(true)}>
              {t`Add SSO Connection`}
            </Button>
          </Col>
        )}
      </Row>

      <Table
        loading={loading}
        dataSource={dataSource}
        events={sso.ssoEnabledEvents}
        pagination={pagination}
        onChange={onChange}
        onEdit={onEdit}
        onDelete={handleDelete}
        onRowSelection={keys => setSelectedRows([...keys])}
        selectedRowKeys={selectedRows}
      />

      {showAddModal && (
        <AddModal organizationId={organizationId} sso={sso} onClose={onCloseAddModal} />
      )}

      {showEditModal && (
        <EditModal
          organizationId={organizationId}
          sso={sso}
          connection={connection}
          onClose={onCloseEditModal}
        />
      )}

      <DeleteBar
        style={{ left: 0 }}
        open={selectedRows.length > 0}
        buttonText={ngettext(
          msgid`Delete ${selectedRows.length} SSO Connection`,
          `Delete ${selectedRows.length} SSO Connections`,
          selectedRows.length
        )}
        onClick={() => handleDelete()}
      />
    </>
  );
};

SSOConnections.propTypes = {
  organizationId: PropTypes.string.isRequired,
  sso: PropTypes.shape({
    oidcSignOnUrl: PropTypes.string.isRequired,
    samlSignOnUrl: PropTypes.string.isRequired,
    audienceUri: PropTypes.string.isRequired,
    ssoEnabledEvents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

const Index = ({ active }) => {
  const organizationId = useSelector(getOrganizationId);
  const { data: sso, isLoading } = useSSOQuery(organizationId, { enabled: active });

  if (!active) {
    return null;
  }

  if (isLoading) {
    return (
      <Base>
        <Loading />
      </Base>
    );
  }

  return (
    <Base>
      {!sso ? (
        <SetupSSO organizationId={organizationId} />
      ) : (
        <SSOConnections organizationId={organizationId} sso={sso} active={active} />
      )}
    </Base>
  );
};

Index.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Index;
