import { CheckCircleOutlined } from '@ant-design/icons';
import { Alert, Form, Input, Radio, Select, Space, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { c, jt, t } from 'ttag';

import Label from 'common/components/Label';
import useConfig from 'common/hooks/useConfig';
import { SUPPORT_EMAIL } from 'common/services/support/types';
import { isEnabled as isFlagEnabled } from 'common/state/flags';

import EmptyCircle from '@components/EmptyCircle';
import FeatureContactSalesLink from '@components/FeatureContactSalesLink';
import { useKBArticle } from '@domains/Organization/EmailDomain/constants';
import { useEmailDomainList } from '@domains/Organization/EmailDomain/queries';
import usePermission, { READ_SENDER_EMAIL_DOMAINS } from '@hooks/usePermission';

import { Field, Description } from './StyledComponents';

const radioButtonCommon = css`
  width: 600px;
  text-align: left;
  height: 40px;
  padding: 5px 14px 10px 32px;
`;

const RadioButtonTop = styled(Radio.Button)`
  && {
    ${radioButtonCommon}
    border-bottom-width: 0.5px;
    border-radius: 3px 3px 0 0;
  }
`;

const RadioButtonBottom = styled(Radio.Button)`
  && {
    ${radioButtonCommon}
    border-top-width: 0.5px;
    border-radius: 0 0 3px 3px;
  }
`;

const CheckCircle = styled(CheckCircleOutlined)`
  padding-right: 10px;
`;

// TODO: Delete when `exp_email_spam_improvements` is removed in EXP-16189
const EmailFieldOld = ({ event, isDisabled, isStaff }) => {
  const useCustomOutboundEmailAddress = Form.useWatch('useCustomOutboundEmailAddress');
  const lowerCaseEventShortcode = event?.shortcode?.toLowerCase();

  const supportEmailLink = (
    <a
      key="supportEmail"
      href={`mailto:${SUPPORT_EMAIL}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {SUPPORT_EMAIL}
    </a>
  );

  const emailSenderGuidelinesLink = (
    <a
      key="emailSenderGuidelinesLink"
      href="https://help.eventmobi.com/en/knowledge/how-can-i-make-sure-that-emails-from-the-app-use-my-preferred-email-address"
      target="_blank"
      rel="noreferrer noopener"
    >{t`Knowledge Base article`}</a>
  );

  return (
    <>
      <Field shouldUpdate label={<Label>{t`Email To Send From`}</Label>}>
        {() => [
          <Field key="field" name="useCustomOutboundEmailAddress" noStyle>
            <Radio.Group disabled={isDisabled}>
              <RadioButtonTop value={false}>
                {useCustomOutboundEmailAddress ? <EmptyCircle /> : <CheckCircle />}
                {t`Send from ${lowerCaseEventShortcode}@event-emails.com`}
              </RadioButtonTop>
              <RadioButtonBottom value>
                {useCustomOutboundEmailAddress ? <CheckCircle /> : <EmptyCircle />}
                {t`Send From a Custom Email Address`}
              </RadioButtonBottom>
            </Radio.Group>
          </Field>,
          <Description key="description">
            {t`System emails like alert emails, missed chat message notification emails, or any other email from EventMobi will be sent to attendees from this address.`}
          </Description>,
        ]}
      </Field>

      <Field
        noStyle
        shouldUpdate={(prevValue, curValue) =>
          curValue.useCustomOutboundEmailAddress !== prevValue.useCustomOutboundEmailAddress
        }
      >
        {() =>
          useCustomOutboundEmailAddress && [
            <Field
              key="field"
              label={<Label isRequired>{t`Custom From Address`}</Label>}
              name="outboundEmailAddress"
              rules={[
                { required: true, message: t`Outbound Email Address is required.` },
                {
                  type: 'email',
                  message: t`Invalid email address.`,
                },
              ]}
              extra={
                <Alert
                  showIcon
                  type="warning"
                  style={{ marginTop: '8px' }}
                  message={t`SPF and/or DKIM setup is required for reliable email delivery`}
                  description={c(
                    'As in: ...guidelines in this Knowledge Base article and contact support.'
                  )
                    .jt`You need to configure SPF and/or DKIM records for custom email address in order to comply with some email service provider regulations. If you want to setup custom email address please read more about email sender guidelines in this ${emailSenderGuidelinesLink} and ${supportEmailLink}.`}
                />
              }
            >
              <Input disabled={!isStaff || isDisabled} />
            </Field>,
          ]
        }
      </Field>
    </>
  );
};

EmailFieldOld.propTypes = {
  event: PropTypes.shape({
    shortcode: PropTypes.string.isRequired,
  }),
  isDisabled: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired,
};

const DisabledText = styled(Typography.Text)`
  && {
    cursor: pointer;
  }
`;

const GrowEmailSpace = styled(Space)`
  width: 100%;
  & > *:first-child,
  & > *:last-child {
    flex-grow: 1;
  }
`;

const RadioGroup = styled(Radio.Group)`
  display: block;
`;

const RadioContainer = styled.div`
  width: 100%;
  border: 1px solid ${props => (props.$selected ? '#1890ff' : '#d9d9d9')};
  padding: 16px;
`;

const EmailField = ({ event, isDisabled }) => {
  const { organizationId } = useParams();
  const { get: getConfig, loading: isConfigLoading } = useConfig();
  const canUseCustomOutboundEmailAddress =
    !isConfigLoading && getConfig('is_whitelabel_sender_email_domain_enabled') === '1';
  const useCustomOutboundEmailAddress = Form.useWatch('useCustomOutboundEmailAddress');
  const selectedDomain = Form.useWatch('customEmailDomain');
  const { loading: isLoadingAuth, authorized } = usePermission();
  const { data, isLoading } = useEmailDomainList(
    organizationId,
    {
      verificationStatus: 'verified',
    },
    !!canUseCustomOutboundEmailAddress && !isLoadingAuth && authorized(READ_SENDER_EMAIL_DOMAINS)
  );
  const selectTooltipRef = useRef();
  const lowerCaseEventShortcode = event?.shortcode?.toLowerCase();

  const eventEmailsBold = (
    <Typography.Text strong key="domain">
      event-emails.com
    </Typography.Text>
  );

  let currentIsUnverified = false;
  const [, currentDomain] = event?.outboundEmailAddress?.split('@') || [];
  const domainOptions = data?.data.map(({ name }) => ({ value: name, label: name })) || [];
  // If the current domain isn't in the list, it must be unverified, so we'll add it as disabled
  if (!isLoading && !!currentDomain && !data.data.find(({ name }) => name === currentDomain)) {
    currentIsUnverified = true;
    domainOptions.unshift({
      value: currentDomain,
      label: (
        <Tooltip
          title={t`This domain, ${currentDomain}, has been disconnected. Please reverify this domain to use it again.`}
          placement="top"
          getPopupContainer={() => selectTooltipRef.current}
        >
          {currentDomain}
          &nbsp;
          <DisabledText disabled>({t`unverified`})</DisabledText>
        </Tooltip>
      ),
      disabled: true,
    });
  }
  const selectedIsUnverified = !data?.data.find(({ name }) => name === selectedDomain);

  const hasEmailDomains = !isLoading && (domainOptions.length > 0 || currentIsUnverified);

  const form = Form.useFormInstance();
  useEffect(() => {
    if (useCustomOutboundEmailAddress && !canUseCustomOutboundEmailAddress) {
      // If the user has selected to use a custom email address, but the feature is disabled, we'll
      // reset the value to false
      form.setFieldsValue({ useCustomOutboundEmailAddress: false });
    }
  }, [useCustomOutboundEmailAddress, canUseCustomOutboundEmailAddress, form]);

  const manageDomainsLink = (
    <Link
      to={generatePath('/organization/:organizationId/email-domain', { organizationId })}
    >{t`You can manage your custom email domains here.`}</Link>
  );
  const kbArticle = useKBArticle();

  return (
    <>
      <Field shouldUpdate label={<Label>{t`Email To Send From`}</Label>}>
        <Description>
          {t`System emails like alert emails, missed chat message notification emails, or any other email from EventMobi will be sent to attendees from this address.`}
        </Description>
        <Field name="useCustomOutboundEmailAddress" noStyle>
          <RadioGroup disabled={isDisabled}>
            <RadioContainer $selected={!useCustomOutboundEmailAddress}>
              <Radio value={false}>
                {t`Send from ${lowerCaseEventShortcode}@event-emails.com`}
              </Radio>
            </RadioContainer>
            <RadioContainer $selected={useCustomOutboundEmailAddress}>
              <Radio value disabled={!canUseCustomOutboundEmailAddress || !hasEmailDomains}>
                {t`Send From a Custom Email Address`}
                {!canUseCustomOutboundEmailAddress && (
                  <>
                    <br />
                    {t`Personalize your emails by using a custom email address.`}{' '}
                    {<FeatureContactSalesLink featureName="Custom Email Domain" />}
                  </>
                )}
                {canUseCustomOutboundEmailAddress && !hasEmailDomains && (
                  <>
                    <br />
                    {jt`No email domains have been registered.`} {manageDomainsLink}
                  </>
                )}
              </Radio>
              {useCustomOutboundEmailAddress && (
                <Field
                  label={<Label isRequired>{t`Email Address`}</Label>}
                  $padding="8px 24px"
                  $marginBottom="0"
                >
                  <GrowEmailSpace>
                    <Field
                      noStyle
                      name="customEmailLocal"
                      rules={[
                        { required: true, message: t`Outbound Email Address is required.` },
                        {
                          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/,
                          message: t`Invalid email address.`,
                        },
                      ]}
                    >
                      <Input
                        disabled={
                          isDisabled || !canUseCustomOutboundEmailAddress || selectedIsUnverified
                        }
                      />
                    </Field>
                    @
                    <Field
                      noStyle
                      name="customEmailDomain"
                      rules={[{ required: true, message: t`Outbound Email Address is required.` }]}
                    >
                      <Select
                        loading={isLoading}
                        options={domainOptions}
                        disabled={isLoading || isDisabled || !canUseCustomOutboundEmailAddress}
                      />
                    </Field>
                  </GrowEmailSpace>
                  <div ref={selectTooltipRef} />
                  <Description>{manageDomainsLink}</Description>
                  <br />
                  <Description>
                    {t`Need Help?`}{' '}
                    <Typography.Link
                      href={kbArticle}
                      target="_blank"
                    >{t`Learn about email sender guidelines here.`}</Typography.Link>
                  </Description>
                  {currentIsUnverified && (
                    <Alert
                      showIcon
                      type="warning"
                      style={{ marginTop: '8px' }}
                      message={jt`Domain has been disconnected. Emails will be sent from the default EventMobi ${eventEmailsBold} domain.`}
                    />
                  )}
                </Field>
              )}
            </RadioContainer>
          </RadioGroup>
        </Field>
      </Field>
    </>
  );
};

EmailField.propTypes = {
  event: PropTypes.shape({
    shortcode: PropTypes.string.isRequired,
  }),
  isDisabled: PropTypes.bool.isRequired,
};

// TODO: Delete when `exp_email_spam_improvements` is removed in EXP-16189
const EmailFieldSwitcher = ({ event, isDisabled, isStaff }) => {
  const isEmailDomainFFEnabled = useSelector(state =>
    isFlagEnabled(state, 'exp_email_spam_improvements')
  );
  return isEmailDomainFFEnabled ? (
    <EmailField event={event} isDisabled={isDisabled} />
  ) : (
    <EmailFieldOld event={event} isDisabled={isDisabled} isStaff={isStaff} />
  );
};

EmailFieldSwitcher.propTypes = {
  event: PropTypes.shape({
    shortcode: PropTypes.string.isRequired,
  }),
  isDisabled: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired,
};

export default EmailFieldSwitcher;
