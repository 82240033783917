import apiFn from 'common/services/api-service';

const api = eventId =>
  apiFn(`/api/uapi/events/${eventId}/custom-domain`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

export const STATUS_INACTIVE = 'INACTIVE';
export const STATUS_PENDING = 'PENDING';
export const STATUS_VALIDATING = 'PENDING_VALIDATION';
export const STATUS_VALIDATION_TIMEDOUT = 'VALIDATION_TIMED_OUT';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_EXPIRED = 'EXPIRED';
export const STATUS_ERROR = 'ERROR';

export const SUPPORT_EMAIL = 'support@eventmobi.com';

export const SUPPORT_HELP_URL = {
  en:
    'https://help.eventmobi.com/en/knowledge/can-i-change-my-app-address-or-use-a-custom-domain-1',
  de:
    'https://help.eventmobi.com/de/knowledge/kann-ich-meine-app-adresse-%C3%A4ndern-oder-eine-eigene-domain-verwenden-1',
  es:
    'https://help.eventmobi.com/es-mx/knowledge/puedo-cambiar-la-direcci%C3%B3n-de-mi-app-o-usar-un-dominio-personalizado-1',
  pt_BR:
    'https://help.eventmobi.com/pt-br/knowledge/posso-alterar-o-endere%C3%A7o-do-meu-aplicativo-ou-utilizar-um-dom%C3%ADnio-personalizado-1',
};

export const get = async eventId => {
  return api(eventId)
    .get()
    .then(response => response.data.data);
};

export const create = async (eventId, domain) => {
  return api(eventId)
    .post(null, { customDomain: domain })
    .then(response => response.data.data);
};

export function remove(eventId, id) {
  return api(eventId)
    .delete(id)
    .then(response => response.data.data);
}
