import { DeleteOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Alert, Button, message, Modal, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { t } from 'ttag';

import usePermission, { ARCHIVE_PAYMENT_GATEWAY } from '@hooks/usePermission';

import ConnectionForm from './ConnectionForm';
import { ERR_GATEWAY_CONN_EXISTS } from './constants';
import { useUpdateConnectionMutation } from './queries';
import useArchiveConnections from './useArchiveConnections';

const EditModal = ({ onClose, onDelete, connection }) => {
  const { authorized } = usePermission();

  const connectionFormRef = useRef(null);
  const {
    mutate: mutateUpdate,
    isLoading: isUpdating,
    error: updateError,
  } = useUpdateConnectionMutation();

  const handleArchiveConnections = useArchiveConnections(() => {
    onDelete();
    onClose();
  });

  const handleUpdateConnection = async () => {
    const payload = await connectionFormRef.current.submit();

    // Remove gateway type from payload as it is not needed for update
    delete payload.type;

    mutateUpdate(
      { id: connection.id, payload },
      {
        onSuccess: () => {
          onClose();
          message.success(t`Successfully updated connection.`);
        },
        onError: err => {
          const code = err.errors?.[0].code;
          if (code === ERR_GATEWAY_CONN_EXISTS) {
            connectionFormRef.current.setFields([
              {
                name: 'name',
                errors: [
                  t`A connection with this name already exists. Please enter a different name.`,
                ],
              },
            ]);
          } else {
            Sentry.captureException(err);
          }
        },
      }
    );
  };

  const error =
    updateError &&
    updateError.errors?.[0]?.code !== ERR_GATEWAY_CONN_EXISTS &&
    t`Error updating connection. Please try again.`;

  return (
    <Modal
      title={t`Edit Connection`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            {authorized(ARCHIVE_PAYMENT_GATEWAY) && (
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                disabled={isUpdating}
                onClick={() => handleArchiveConnections(connection)}
              >
                {t`Delete`}
              </Button>
            )}
          </Space>
          <Space>
            <Button onClick={onClose} disabled={isUpdating}>
              {t`Cancel`}
            </Button>
            <Button type="primary" onClick={handleUpdateConnection} loading={isUpdating}>
              {t`Save`}
            </Button>
          </Space>
        </div>
      }
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        {error && <Alert showIcon type="error" message={error} />}

        <ConnectionForm
          formRef={connectionFormRef}
          initialValues={connection}
          disabled={isUpdating}
        />
      </Space>
    </Modal>
  );
};

EditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditModal;
