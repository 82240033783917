import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/cms/v1/organizations/${organizationId}/organizer-details`);

const inviteApi = organizationId =>
  apiFn(`/api/cms/v1/organizations/${organizationId}/organizer-invitees`);

export function list(organizationId) {
  return api(organizationId)
    .get()
    .then(response => response.data.data);
}

export function remove(organizationId, id) {
  return api(organizationId)
    .delete(id)
    .then(response => response.data.data);
}

export function listInvites(organizationId) {
  return inviteApi(organizationId)
    .get()
    .then(response => response.data.data);
}

export function invite(organizationId, payload) {
  const { protocol, host } = window.location;
  const baseUrl = `${protocol}//${host}/accept-invite?token={token}&email={email}&organization_id={organization_id}`;

  return inviteApi(organizationId)
    .post(null, {
      ...payload,
      baseUrl,
    })
    .then(response => response.data.data);
}

export function cancelInvite(organizationId, id) {
  return inviteApi(organizationId)
    .delete(id)
    .then(response => response.data.data);
}

export function resendInvite(organizationId, payload) {
  return inviteApi(organizationId)
    .delete(null, { params: { email: payload.email } })
    .then(() => invite(organizationId, payload));
}
