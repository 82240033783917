import { ArrowUpOutlined } from '@ant-design/icons';
import { List, Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const { Text: AntText } = Typography;

const Text = styled(AntText)`
  color: ${({ color }) => color};
  font-size: calc(14px + 6 * ((100vw - 320px) / 680));
  @media screen and (min-width: 1920px) {
    font-size: 42px;
  }
`;
const Container = styled(Row)`
  width: 100%;
`;
const CounterText = styled(Text)`
  font-weight: 800;
  margin-bottom: 0px;
`;
const ArrowUpOutlinedStyled = styled(ArrowUpOutlined)`
  padding-left: 5px;
  color: ${({ color }) => color};
  font-size: calc(14px + 6 * ((100vw - 320px) / 680));
  @media screen and (min-width: 1920px) {
    font-size: 42px;
  }
`;

const TextOption = ({ dataSource, textColor }) => {
  return (
    <List
      dataSource={dataSource}
      renderItem={item => (
        <List.Item>
          <Container justify="space-between">
            <Col span={item.count !== undefined ? 18 : 24}>
              <Text color={textColor}>{item.title}</Text>
            </Col>
            {item.count !== undefined && (
              <Col>
                <Row align="middle">
                  <Col>
                    <CounterText color={textColor}>{item.count}</CounterText>
                  </Col>
                  <Col>
                    <ArrowUpOutlinedStyled color={textColor} />
                  </Col>
                </Row>
              </Col>
            )}
          </Container>
        </List.Item>
      )}
    />
  );
};

TextOption.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      count: PropTypes.number,
    })
  ),
  textColor: PropTypes.string,
};

export default TextOption;
