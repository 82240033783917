import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

import useTranslation from 'common/hooks/useTranslation';

import Layout from './Layout';

function HTTP404({ scoped, ctaText, ctaHref }) {
  useTranslation();
  return (
    <Layout
      scoped={scoped}
      title={t`Page not found`}
      body={t`The page you requested could not be found. Please double check the URL and try again or contact our support team for assistance.`}
    >
      {ctaText && ctaHref ? (
        <Button type="primary" onClick={() => window.location.replace(ctaHref)}>
          {ctaText}
        </Button>
      ) : null}
    </Layout>
  );
}

HTTP404.propTypes = {
  scoped: PropTypes.bool,
  ctaText: PropTypes.string,
  ctaHref: PropTypes.string,
};

HTTP404.defaultProps = {
  scoped: false,
  ctaText: 'Go to Home',
  ctaHref: '/',
};

export default HTTP404;
