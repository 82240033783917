import { createGlobalStyle } from 'styled-components';

// overriding some styles of the RichTextEditor
export default createGlobalStyle`


// Adds the error border to whole RTE workspace
.jodit-rte-error{
  && {
    border: 1px solid #f5222d;
  }
}

// The whole textarea, without this content overflows
// !important required here
.jodit-workplace{
  height: 300px !important;
}

// Give the whole container ant like look
.jodit-container{
  &&&{
    border: none;
    &:focus-within {
      border: none;
      outline: 0;
    }
  }
}

// Adds box shadow according to error or general state on focus
// !important is required here as specifity is not working here to add error box shadow
.ant-form-item-has-error .ant-input .jodit-container{
  &:focus{
    box-shadow: 0 0 0 3px rgba(255, 77, 79, 0.2) !important;
  }
  &:focus-within{
    box-shadow: 0 0 0 3px rgba(255, 77, 79, 0.2) !important;
  }
}

.ant-form-item .ant-input .jodit-container{
  &:focus,
  &:focus-within{
    box-shadow: 0 0 0 3px rgba(24,144,255,0.2);
  }
}

.form .ant-input .jodit-container{
  &:focus,
  &:focus-within{
    box-shadow: 0 0 0 3px rgba(24,144,255,0.2);
  }
}

.form--error .ant-input .jodit-container{
  &:focus,
  &:focus-within{
    box-shadow: 0 0 0 3px rgba(255, 77, 79, 0.2);
  }
}

// Overriding the ant global styles
.jodit-wysiwyg{
  & p{
    margin-top: revert;
    margin-bottom: revert;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: revert;
    margin-bottom: revert;
    color: revert;
    font-weight: revert;
  }
  ol,
  ul,
  dl{
    margin-top: revert;
    margin-bottom: revert;
  }
  ol ol,
  ul ul,
  ol ul,
  ul ol{
    margin-bottom: revert;
  }
  b,
  strong {
    font-weight: revert;
  }
}

`;
