import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import {
  GRAY_BORDER_COLOR,
  RED_BORDER_COLOR,
  UNIT_BLEED_LENGTH,
  UNIT_CROP_MARK_LENGTH,
  normalizeUnit,
} from '../Builder/constants';
import { UNIT_INCH, UNIT_MM } from '../constants';

import Grid from './BadgeGrid';

const CONTENT_PADDING_X = 24;
const CONTENT_PADDING_Y = 32;

const useRefDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const getDimensions = useCallback(() => {
    if (!ref.current) {
      return;
    }

    if (
      dimensions.width === ref.current.clientWidth &&
      dimensions.height === ref.current.clientHeight
    ) {
      return;
    }

    setDimensions({
      width: ref.current.clientWidth,
      height: ref.current.clientHeight,
    });
  }, [ref, dimensions]);

  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    // execute first time
    getDimensions();

    // execute everytime window resizes
    window.addEventListener('resize', getDimensions);
    return () => {
      window.removeEventListener('resize', getDimensions);
    };
  }, [ref, getDimensions]);
  return dimensions;
};

const PreviewContent = styled.div`
  background: gray;
  width: 100%;
  height: 100%;

  padding: ${CONTENT_PADDING_Y}px ${CONTENT_PADDING_X}px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    transform: scale(${props => props.scaleContent});

    // the height of the div, even after transform-scale, stays original in the dom
    // this is fixed by adding negative margins of both sides
    margin: calc(-${props => props.contentHeight}px * 0.5 * (1 - ${props => props.scaleContent}))
      auto calc(-${props => props.contentHeight}px * 0.5 * (1 - ${props => props.scaleContent}));
  }
`;

export const Page = styled.div`
  background: white;
  overflow: hidden;
  ${props =>
    props.cropMarks &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  width: ${props => props.width}px;
  height: ${props => props.height}px;
  min-width: ${props => props.width}px;
  min-height: ${props => props.height}px;
  max-width: ${props => props.width}px;
  max-height: ${props => props.height}px;

  padding-top: ${props => props.margin?.top ?? 0}px;
  padding-left: ${props => props.margin?.left ?? 0}px;
  padding-bottom: ${props => props.margin?.bottom ?? 0}px;
  padding-right: ${props => props.margin?.right ?? 0}px;

  page-break-after: always;
`;

const PageAlertContainer = styled(Space).attrs({ direction: 'vertical', size: 'middle' })`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BleedMockBadgeWrap = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: 1px dashed ${RED_BORDER_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MockBadge = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: ${props =>
    props.border ? `1px solid ${GRAY_BORDER_COLOR}` : `1px dashed ${GRAY_BORDER_COLOR}`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CropMarksContainer = styled.div`
  position: relative;
`;

const CropMark = styled.div`
  position: absolute;
  background: #00000073;

  ${({ vertical, placementX, placementY, bleedGap, cropMarkLength, unit = 'px' }) => {
    // Setting default unit as px because we are using all the values in px while rendering the mock badge in Preview Modal
    // eslint-disable-next-line no-nested-ternary
    const cssUnit = unit === UNIT_INCH ? 'in' : unit === UNIT_MM ? 'mm' : 'px';
    return vertical
      ? `
          width: 1px;
          height: ${cropMarkLength}${cssUnit};
          ${placementX}: ${bleedGap}${cssUnit};
          ${placementY}: -${cropMarkLength}${cssUnit};
        `
      : `
          height: 1px;
          width: ${cropMarkLength}${cssUnit};
          ${placementX}: -${cropMarkLength}${cssUnit};
          ${placementY}: ${bleedGap}${cssUnit};
        `;
  }}
`;

export const CropMarks = ({ bleedGap, cropMarkLength, unit }) =>
  [true, false].map(vertical =>
    ['top', 'bottom'].map(placementY =>
      ['left', 'right'].map(placementX => (
        <CropMark
          key={`${vertical ? 'v' : 'h'}-${placementY}-${placementX}`}
          vertical={vertical}
          placementY={placementY}
          placementX={placementX}
          unit={unit}
          bleedGap={bleedGap}
          cropMarkLength={cropMarkLength}
        />
      ))
    )
  );

const ErrorState = () => (
  <PageAlertContainer>
    <ExclamationCircleOutlined style={{ fontSize: 64, color: 'rgba(0, 0, 0, 0.54)' }} />
    <Typography.Text strong>{t`Unable to generate preview and PDF`}</Typography.Text>
    <span>
      <Typography.Text>{t`Your badge is larger than the selected paper size.`}</Typography.Text>
      <br />
      <Typography.Text>{t`Please change the paper size, layout or margins to continue.`}</Typography.Text>
    </span>
  </PageAlertContainer>
);

const PagePreview = ({ config, badgeSize, gridProps, isBleedEnabled }) => {
  const divRef = createRef();
  const divSize = useRefDimensions(divRef);
  const maxContentSize = {
    width: divSize.width - 2 * CONTENT_PADDING_X,
    height: divSize.height - 2 * CONTENT_PADDING_Y,
  };

  const {
    pageSize: { width: pageWidth, height: pageHeight },
    layout: { margins },
    fill,
    unit,
    border,
    cropMarks,
  } = config;

  const scaleContent = Math.min(
    2, // prevent small page to be scaled up too much
    pageHeight / pageWidth > maxContentSize.height / maxContentSize.width
      ? maxContentSize.height / pageHeight
      : maxContentSize.width / pageWidth
  );

  const badgeCount = gridProps.rows * gridProps.cols;
  if (badgeCount <= 0) {
    return (
      // Page Size is made twice and Scale factor made half, in order to render the ErrorState in reasonable scale
      <PreviewContent ref={divRef} scaleContent={scaleContent / 2} contentHeight={pageHeight * 2}>
        <Page width={pageWidth * 2} height={pageHeight * 2}>
          <ErrorState />
        </Page>
      </PreviewContent>
    );
  }

  const badges = new Array(badgeCount).fill(0).map((_, cellIndex) => {
    const mockBadge = (
      <MockBadge
        key={Math.random()}
        width={badgeSize.innerBadgeSize.width}
        height={badgeSize.innerBadgeSize.height}
        border={border}
      >
        {t`Badge`} {cellIndex + 1}
      </MockBadge>
    );

    if (isBleedEnabled) {
      const mockBadgeWithBleed = (
        <BleedMockBadgeWrap key={Math.random()} width={badgeSize.width} height={badgeSize.height}>
          {mockBadge}
        </BleedMockBadgeWrap>
      );

      if (cropMarks) {
        const bleedGap = normalizeUnit(UNIT_BLEED_LENGTH[unit], unit);
        const cropMarkLength = normalizeUnit(UNIT_CROP_MARK_LENGTH[unit], unit);
        return (
          <CropMarksContainer key={Math.random()}>
            <CropMarks bleedGap={bleedGap} cropMarkLength={cropMarkLength} />
            {mockBadgeWithBleed}
          </CropMarksContainer>
        );
      }

      return mockBadgeWithBleed;
    }
    return mockBadge;
  });

  return (
    <PreviewContent ref={divRef} scaleContent={scaleContent} contentHeight={pageHeight}>
      <Page width={pageWidth} height={pageHeight} margin={margins} cropMarks={cropMarks}>
        <Grid {...gridProps} fill={fill}>
          {badges}
        </Grid>
      </Page>
    </PreviewContent>
  );
};

PagePreview.propTypes = {
  config: PropTypes.shape({
    pageSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    layout: PropTypes.shape({
      margins: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
      spacing: PropTypes.shape({
        column: PropTypes.number.isRequired,
        row: PropTypes.number.isRequired,
      }).isRequired,
    }),
    unit: PropTypes.string,
    border: PropTypes.bool,
    cropMarks: PropTypes.bool,
  }),
  badgeSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  gridProps: PropTypes.shape({
    rows: PropTypes.number.isRequired,
    cols: PropTypes.number.isRequired,
  }),
  isBleedEnabled: PropTypes.bool.isRequired,
};

export default PagePreview;
