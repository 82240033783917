import apiFn from 'common/services/api-service';

const api = token => {
  let header = { Accept: 'application/vnd.eventmobi+json; version=p.5' };
  const withCredentials = !token;

  if (token) {
    header = { ...header, Authorization: `Basic ${token}` };
  }

  return apiFn('/api/uapi/badge-templates', header, withCredentials);
};

export function get(id, token) {
  return api(token)
    .get(id)
    .then(response => response.data.data);
}

export function list(size, onlyPublished = true, include = [], isBleedEnabled = false) {
  const params = {
    width: size.width,
    height: size.height,
    unitType: size.unitType,
    isBleedEnabled,
  };

  if (onlyPublished > 0) {
    params.isPublished = true;
  }

  if (include.length > 0) {
    params.include = include.join(',');
  }

  return api()
    .get(null, { params })
    .then(response => response.data.data);
}
