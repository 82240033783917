import { Button } from 'antd';
import React, { useEffect } from 'react';
import { t } from 'ttag';

import useTranslation from 'common/hooks/useTranslation';
import { clean as cleanSession } from 'common/services/session-service';

import Layout from './Layout';

function HTTP401() {
  useTranslation();
  const next = new URLSearchParams(window.location.search).get('next');

  useEffect(() => {
    cleanSession();
  }, []);

  return (
    <Layout title={t`Log In Required`} body={t`Please log in and try again`}>
      <Button
        type="primary"
        onClick={() =>
          window.location.replace(`/login?next=${next && next !== '/login' ? next : ''}`)
        }
      >
        {t`Log In`}
      </Button>
    </Layout>
  );
}

export default HTTP401;
