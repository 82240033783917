export const PAGE_SIZE_LETTER = 'letter';
export const PAGE_SIZE_A4 = 'a4';
export const PAGE_SIZE_BADGE = 'badge';

export const LAYOUT_PORTRAIT = 'portrait';
export const LAYOUT_LANDSCAPE = 'landscape';

export const MARGIN_DEFAULT = 'default';
export const MARGIN_CUSTOM = 'custom';

export const SORT_FIRST_NAME = 'first_name,last_name';
export const SORT_LAST_NAME = 'last_name,first_name';

export const FILL_ACROSS = 'across';
export const FILL_DOWN = 'down';
