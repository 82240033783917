import apiFn from 'common/services/api-service';
import { listCmsEvents as eventListFn } from 'common/services/events/api';

import { addEvent } from './service';

const api = (organizationId, appId) =>
  apiFn(
    `/api/cms/multieventapp/v1/organizations/${organizationId}/multi-event-apps/${appId}/event-apps`
  );

export async function list(organizationId, appId) {
  const eventApps = await api(organizationId, appId)
    .get()
    .then(response => response?.data?.data);
  const eventIds = eventApps.map(appEvent => appEvent.eventId);

  const events = await eventListFn(
    organizationId,
    undefined,
    eventIds.length > 0 ? { ids: eventIds } : null
  );

  return eventApps.map(appEvent => {
    const event = events.data.find(e => e.id === appEvent.eventId);
    const { id } = appEvent;
    const { id: eventId } = event;

    return {
      ...appEvent,
      ...event,
      id,
      eventId,
    };
  });
}

export function update(organizationId, appId, appEventId, payload) {
  return api(organizationId, appId)
    .patch(appEventId, payload)
    .then(response => response?.data?.data);
}

export function create(organizationId, appId, eventId, payload) {
  return addEvent(organizationId, appId, eventId).then(appEvent =>
    update(organizationId, appId, appEvent.id, payload)
  );
}

export function remove(organizationId, appId, appEventId) {
  return api(organizationId, appId)
    .delete(appEventId)
    .then(response => response?.data?.data);
}
