/* eslint-disable no-param-reassign */ import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    crmId: null,
    id: null,
    isInternal: false,
    name: null,
    licenseType: null,
    configuration: [],
    contract: null
};
var slice = createSlice({
    name: "organization",
    initialState: initialState,
    reducers: {
        unsetOrganization: function unsetOrganization(state) {
            Object.assign(state, initialState);
        },
        setOrganization: {
            prepare: function prepare() {
                var organization = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
                var crmId = organization.crmOrganizationId, _organization_isInternal = organization.isInternal, isInternal = _organization_isInternal === void 0 ? false : _organization_isInternal, id = organization.id, name = organization.name, licenseType = organization.licenseType, configuration = organization.configuration;
                var payload = {
                    crmId: crmId,
                    id: id,
                    isInternal: isInternal,
                    name: name,
                    licenseType: licenseType,
                    configuration: configuration
                };
                return {
                    payload: payload,
                    meta: null,
                    error: null
                };
            },
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state.crmId = payload.crmId;
                state.id = payload.id;
                state.isInternal = payload.isInternal;
                state.name = payload.name;
                state.licenseType = payload.licenseType;
                state.configuration = payload.configuration;
            }
        },
        setContract: {
            prepare: function prepare() {
                var contract = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
                return {
                    payload: contract !== null && contract !== void 0 ? contract : null,
                    meta: null,
                    error: null
                };
            },
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state.contract = payload;
            }
        }
    }
});
export default slice.reducer;
export var getOrganization = function(state) {
    return state[slice.name];
};
export var getId = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.id;
};
export var getName = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.name;
};
export var getCrmId = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.crmId;
};
export var getLicenseType = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.licenseType;
};
export var getConfiguration = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.configuration;
};
export var getContract = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.contract;
};
var _slice_actions = slice.actions;
export var unsetOrganization = _slice_actions.unsetOrganization, setOrganization = _slice_actions.setOrganization, setContract = _slice_actions.setContract;
