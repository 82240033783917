import PropTypes from 'prop-types';
import React, { useRef, useEffect, useReducer } from 'react';
import styled, { css } from 'styled-components';

import { RichTextRenderer } from 'common/components/RichTextEditor';

import { fixedFields } from '../../dynamicFields';

export const OVERFLOW_MODE_TRUNCATE = 'truncate';
export const OVERFLOW_MODE_WRAP = 'wrap';
export const OVERFLOW_MODE_SCALE = 'scale';

// Take the whole height of the parent and
// Override the global RTE styles conflict
const Container = styled.div`
  height: 100%;
  overflow: hidden;
  & .jodit-workplace {
    height: auto !important;
  }

  .jodit-wysiwyg,
  .jodit-wysiwyg p {
    ${props =>
      props.overflowMode === OVERFLOW_MODE_TRUNCATE &&
      css`
        word-wrap: break-word;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `}
    ${props =>
      props.overflowMode === OVERFLOW_MODE_WRAP &&
      css`
        word-wrap: break-word;
        overflow: hidden;
      `}
  }

  .jodit-wysiwyg {
    ${props =>
      props.overflowMode === OVERFLOW_MODE_SCALE &&
      css`
        white-space: nowrap;
        transform-origin: top left;
        transform: scale(${props.scale ?? 1});

        // In RTE, when text is in center, it is achieve by jodit using <p> tag
        // however, <p> by default has "display: block;" css, which takes all of
        // the width available to it.
        //
        // However, when we apply the above "transform: scale(x)", the width of
        // <p> tag also shrink as a whole..
        // This is not a problem when text is left aligned.
        // The issue with <p> tag appears when center or right text-align is
        // applied using RTE and has multiple lines inside <p>.
        // Since we have "white-space: nowrap" to prevent text from falling to
        // next line, the long line inside <p> is unaffected.
        // But the small line in the same <p> is incorrectly aligned because
        // of the shrunk width.
        // Example:
        // <p style="text-align: center">
        //  this is long line which is unaffected
        //  <br>
        //  this is small line
        // </p>
        // <p style="text-align: right">
        //  this is long line which is unaffected
        //  <br>
        //  this is small line
        // </p>
        //
        // the following style recorrects the width to inverse of scaling
        // applied so that correctly aligning can be achieved
        p {
          width: ${((1 / props.scale) * 100).toFixed(2)}%;
        }
      `}
  }
`;

const HiddenContainer = styled(Container).attrs({ scale: 1 })`
  visibility: hidden;
`;

const Text = ({ readOnly, overflowMode, richText, person }) => {
  const container = useRef(null);
  const [, forceRender] = useReducer(x => x + 1, 0);

  const scale = !container.current
    ? 1
    : Math.min(
        container.current.clientWidth / container.current.scrollWidth,
        container.current.clientHeight / container.current.scrollHeight
      );

  const dynamicFields = (!readOnly || !person
    ? []
    : [...fixedFields().map(f => ({ ...f, value: f.value({ person }) })), ...person.customFields]
  ).map(f => ({ ...f, value: f.value ?? '' }));

  useEffect(() => {
    if (overflowMode === OVERFLOW_MODE_SCALE) {
      forceRender();
    }
  }, [scale, overflowMode, richText, person]);

  return (
    <>
      <Container overflowMode={overflowMode} scale={scale}>
        <RichTextRenderer content={richText} dynamicFields={dynamicFields} />
      </Container>
      {
        /*
          We have a "hidden" copy of the same with scale=1, so that we can
          find the unscaled scrollWidth/Height of the container
        */
        overflowMode === OVERFLOW_MODE_SCALE && (
          <HiddenContainer overflowMode={overflowMode} ref={container}>
            <RichTextRenderer content={richText} dynamicFields={dynamicFields} />
          </HiddenContainer>
        )
      }
    </>
  );
};

Text.propTypes = {
  readOnly: PropTypes.bool,
  overflowMode: PropTypes.oneOf([OVERFLOW_MODE_TRUNCATE, OVERFLOW_MODE_WRAP, OVERFLOW_MODE_SCALE]),
  richText: PropTypes.string,
  person: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    title: PropTypes.string,
    customFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
      })
    ),
  }),
};

export default Text;
