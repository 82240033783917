import apiFn from 'common/services/api-service';

const orgApi = id => apiFn(`/api/cms/v1/organizations/${id}`);
const creditApi = apiFn(`/api/cms/v1/packages`);

export const CREDITS_STATUS_AVAILABLE = 'available';
export const CREDITS_STATUS_EXPIRED = 'expired';

const prepareCreditPayload = (data, orgId) => ({
  creditId: data.credit?.id,
  organizationId: orgId,
  eventName: data.name,
  eventShortcode: data.code,
  eventStartDate: data.startDate,
  eventEndDate: data.endDate,
  sourceEventId: data.sourceEvent?.id || null,
});

export const getCredits = orgId =>
  creditApi
    .get(`/organization-credits`, {
      params: {
        organizationId: orgId,
        includeExpired: true,
      },
    })
    .then(response => response.data.data);

export const getBlueprints = () =>
  creditApi.get('/blueprints').then(response => response.data.data);

export const searchEvents = (search, orgId, limit = 10, offset = 0) =>
  orgApi(orgId)
    .get('/events', {
      params: {
        search,
        limit,
        offset,
      },
    })
    .then(response => response.data.data);

export const redeemCredit = (data, orgId) => {
  const payload = prepareCreditPayload(data, orgId);

  return creditApi.post('/redeem-event-credit', payload).then(response => response.data.data);
};
