import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { faAndroid } from '@fortawesome/free-brands-svg-icons/faAndroid';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table as AntTable,
  Alert,
  Button,
  Row,
  Col,
  message,
  Divider,
  Typography,
  Dropdown,
  Space,
  Modal as AntModal,
} from 'antd';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { t, jt } from 'ttag';

import Empty from 'common/components/Empty';
import Modal from 'common/components/Modal';
import { getTimezone } from 'common/state/event';

import EmptyImage from '@images/illustrations/analytics.svg';

import BuildModal from './BuildModal';
import {
  cancel as cancelFn,
  list as listFn,
  get as getFn,
  refresh as refreshFn,
} from './builds-service';
import { get as getNativeAppFn } from './configuration-service';

const { Column } = AntTable;

const DATE_TIME_FORMAT = 'MMM DD, YYYY HH:mm:ss';

const ANDROID = 'android';
const IOS = 'ios';

const FINISHED = 'finished';
const CANCELED = 'canceled';
const QUEUED = 'queued';
const PREPARING = 'preparing';
const FETCHING = 'fetching';
const BUILDING = 'building';
const PUBLISHING = 'publishing';
const FAILED = 'failed';

const platformMap = type => {
  switch (type) {
    case ANDROID:
      return t`Android`;
    case IOS:
      return t`iOS`;
    default:
      return null;
  }
};

const statusMap = type => {
  switch (type) {
    case FINISHED:
      return { label: t`Completed`, icon: faCheckCircle, color: '#389E0D' };
    case CANCELED:
      return { label: t`Cancelled`, icon: faTimesCircle, color: '#8C8C8C' };
    case QUEUED:
      return { label: t`In Queue`, icon: faStopwatch, color: '#FAAD14' };
    case PREPARING:
    case FETCHING:
    case BUILDING:
      return { label: t`In Progress`, icon: faStopwatch, color: '#FAAD14' };
    case PUBLISHING:
      return { label: t`Publishing`, icon: faStopwatch, color: '#FAAD14' };
    case FAILED:
      return { label: t`Error`, icon: faExclamationTriangle, color: '#F5222D' };
    default:
      return {};
  }
};
const IN_PROGRESS_STATES = [QUEUED, PREPARING, FETCHING, BUILDING, PUBLISHING];

const Download = ({ buildPlatform, artifactPublishUrl, artifactPackageUrl, onDownload }) => {
  const handleItemsClick = event => {
    if (event.key === 'aab') {
      onDownload(artifactPublishUrl);
    } else {
      onDownload(artifactPackageUrl);
    }
  };

  const items = [
    {
      label: (
        <Space direction="horizontal" size="middle">
          <div>
            <Typography.Text strong>{t`Android Application Bundle`}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{t`The commonly required file type .aab`}</Typography.Text>
          </div>
        </Space>
      ),
      key: 'aab',
    },
    {
      label: (
        <Space direction="horizontal" size="middle">
          <div>
            <Typography.Text strong>{t`APK`}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{t`Legacy file type which is no longer supported by Google Play Store.`}</Typography.Text>
          </div>
        </Space>
      ),
      key: 'apk',
    },
  ];

  if (buildPlatform === ANDROID && artifactPublishUrl && artifactPackageUrl) {
    return (
      <Dropdown menu={{ items, onClick: handleItemsClick }}>
        <Typography.Link>
          {t`Download`}
          <DownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
        </Typography.Link>
      </Dropdown>
    );
  }
  if (artifactPublishUrl) {
    return (
      <Button type="link" onClick={() => onDownload(artifactPublishUrl)} style={{ padding: 0 }}>
        {t`Download`}
      </Button>
    );
  }
  return null;
};

Download.propTypes = {
  buildPlatform: PropTypes.oneOf([ANDROID, IOS]).isRequired,
  artifactPublishUrl: PropTypes.string,
  artifactPackageUrl: PropTypes.string,
  onDownload: PropTypes.func.isRequired,
};

const Table = ({
  dataSource,
  loading,
  timezone,
  onCancel,
  onDetails,
  onDownload,
  onRefresh,
  onRerun,
}) => {
  const sorter = (row1, row2, fieldName) => {
    return row1[fieldName]?.localeCompare(row2[fieldName]);
  };

  const dateSorter = (row1, row2, fieldName) => {
    return moment(row1[fieldName]).isAfter(moment(row2[fieldName])) ? 1 : -1;
  };

  return (
    <AntTable
      rowKey="id"
      pagination={false}
      dataSource={dataSource}
      tableLayout="auto"
      loading={loading}
    >
      <Column
        title={t`Platform`}
        dataIndex="buildPlatform"
        render={value => (
          <span>
            {value === ANDROID ? (
              <FontAwesomeIcon icon={faAndroid} style={{ color: '#3DDB85', marginRight: 8 }} />
            ) : (
              <FontAwesomeIcon icon={faApple} style={{ color: '#BFBFBF', marginRight: 8 }} />
            )}
            {platformMap(value)}
          </span>
        )}
        filters={[
          { text: platformMap(ANDROID), value: ANDROID },
          { text: platformMap(IOS), value: IOS },
        ]}
        onFilter={(value, record) => record.buildPlatform === value}
      />
      <Column
        title={t`App Version`}
        dataIndex="buildVersion"
        sorter={(a, b) => sorter(a, b, 'buildVersion')}
      />
      <Column
        title={t`Published`}
        dataIndex="publishToStore"
        filters={[
          { text: t`Yes`, value: true },
          { text: t`No`, value: false },
        ]}
        render={value => (value ? t`Yes` : t`No`)}
        onFilter={(value, record) => record.publishToStore === value}
      />
      <Column
        sorter={(a, b) => dateSorter(a, b, 'startDatetime')}
        title={t`Start Time`}
        dataIndex="startDatetime"
        render={dateTime => moment.tz(dateTime, timezone).format(DATE_TIME_FORMAT)}
      />
      <Column
        sorter={(a, b) => dateSorter(a, b, 'endDatetime')}
        title={t`End Time`}
        dataIndex="endDatetime"
        render={dateTime =>
          dateTime ? moment.tz(dateTime, timezone).format(DATE_TIME_FORMAT) : '-'
        }
      />
      <Column
        title={t`Status`}
        dataIndex="status"
        filters={[
          { text: statusMap(FINISHED).label, value: FINISHED },
          { text: statusMap(CANCELED).label, value: CANCELED },
          { text: statusMap(BUILDING).label, value: IN_PROGRESS_STATES },
          { text: statusMap(FAILED).label, value: FAILED },
        ]}
        onFilter={(value, record) => record.status === value || value?.includes(record.status)}
        render={value => (
          <>
            <FontAwesomeIcon
              icon={statusMap(value).icon}
              style={{ color: statusMap(value).color, marginRight: 8 }}
            />
            {statusMap(value).label}
          </>
        )}
      />
      <Column
        title={t`Action`}
        dataIndex="action"
        width="15%"
        onCell={() => ({ onClick: event => event.stopPropagation() })}
        render={(_, row) => (
          <Space size="small" split={<Divider type="vertical" />}>
            {row.status === FAILED && (
              <Button
                type="link"
                data-testid="button-details"
                onClick={() => onDetails(row)}
                style={{ padding: 0 }}
              >
                {t`Details`}
              </Button>
            )}
            {IN_PROGRESS_STATES.includes(row.status) && (
              <Button
                type="link"
                onClick={() => onRefresh(row.id, row.externalBuildId)}
                style={{ padding: 0 }}
              >
                {t`Refresh`}
              </Button>
            )}
            {row.status === FINISHED && (
              <Download
                status={row.status}
                buildPlatform={row.buildPlatform}
                artifactPublishUrl={row.artifactPublishUrl}
                artifactPackageUrl={row.artifactPackageUrl}
                onDownload={onDownload}
              />
            )}
            {IN_PROGRESS_STATES.includes(row.status) && (
              <Button type="link" danger onClick={() => onCancel(row)} style={{ padding: 0 }}>
                {t`Cancel`}
              </Button>
            )}
            {!IN_PROGRESS_STATES.includes(row.status) && (
              <Button
                type="link"
                onClick={() => onRerun(row)}
                style={{ padding: 0 }}
              >{t`Rerun`}</Button>
            )}
          </Space>
        )}
      />
    </AntTable>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(Object),
  loading: PropTypes.bool,
  timezone: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onDetails: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRerun: PropTypes.func.isRequired,
};

const useBuilds = (organizationId, appId) => {
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nativeApp, setNativeApp] = useState(null);

  const loadList = async () => {
    setList(await listFn(organizationId, appId));
  };

  const refreshRow = async id => {
    const item = await getFn(organizationId, id);
    const newList = list.map(el => (el.id === id ? item : el));
    setList(newList);
  };

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        const app = await getNativeAppFn(organizationId, appId);
        setNativeApp(app);
        if (app?.id) {
          await loadList();
        } else {
          setList([]);
        }
      } finally {
        setLoading(false);
      }
    }
    load();
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, appId]);

  return {
    loading,
    list,
    nativeApp,
    setLoading,
    refreshRow,
    forceReload: loadList,
  };
};

const Builds = ({ organizationId, appId }) => {
  const { loading, list, nativeApp, setLoading, refreshRow, forceReload } = useBuilds(
    organizationId,
    appId
  );
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showBuildModal, setShowBuildModal] = useState(false);
  const [details, setDetails] = useState('');
  const [errorUrl, setErrorUrl] = useState('');
  const [build, setBuild] = useState(null);
  const timezone = useSelector(getTimezone);

  const handleCancel = row => {
    const buildName = `${platformMap(row.buildPlatform)} ${row.buildVersion}, ${moment
      .tz(row.startDatetime, timezone)
      .format(DATE_TIME_FORMAT)}`;

    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      okText: t`Yes, cancel build`,
      cancelText: t`No, go back`,
      content: jt`Are you sure you want to cancel the build ${buildName}?`,
      onOk: () => {
        return (async () => {
          try {
            await cancelFn(organizationId, row.id);
            await refreshRow(row.id);
          } catch {
            message.error(t`Error while cancelling the build.`);
          }
        })();
      },
    });
  };

  const handleDetails = row => {
    setDetails({
      name: `${platformMap(row.buildPlatform)} ${row.buildVersion}, ${moment
        .tz(row.startDatetime, timezone)
        .format(DATE_TIME_FORMAT)} details`,
      errorMessage: row.errorMessage,
    });
    const json = JSON.parse(row.errorDetails ?? '{}');
    setErrorUrl(json?.failed_steps?.[0]?.log_url ?? '');
    setShowDetailsModal(true);
  };

  const handleDetailsClose = () => {
    setShowDetailsModal(false);
    setDetails('');
    setErrorUrl('');
  };

  const handleRefresh = async (id, buildId) => {
    try {
      setLoading(true);
      await refreshFn(buildId);
      await refreshRow(id);
    } catch {
      message.error(t`Error while refreshing the build.`);
    } finally {
      setLoading(false);
    }
  };

  const handleRerun = currentBuild => {
    setBuild(currentBuild);
    setShowBuildModal(true);
  };

  const handleModalClose = async refresh => {
    setBuild(null);
    setShowBuildModal(false);
    if (refresh) {
      try {
        setLoading(true);
        await forceReload();
      } finally {
        setLoading(false);
      }
    }
  };

  const link = (
    <Link key={1} to="?tab=conf">
      {t`Build Configuration`}
    </Link>
  );

  return (
    <>
      {!nativeApp?.id && !loading && (
        <Row type="flex" css="margin: 20px 0;">
          <Col span={24}>
            <Alert
              type="warning"
              message={jt`In order to create a New Build, please define the ${link} first. `}
              showIcon
            />
          </Col>
        </Row>
      )}
      <Row type="flex" css="margin: 20px 0;">
        <Col span={8}>
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            disabled={!nativeApp?.id || loading}
            onClick={() => setShowBuildModal(true)}
          >
            {t`New Build`}
          </Button>
        </Col>
      </Row>
      {list?.length === 0 ? (
        <Empty
          image={EmptyImage}
          title={t`You don’t have any builds yet`}
          body={t`Create a New Build to get started.`}
        />
      ) : (
        <Table
          dataSource={list}
          loading={loading}
          timezone={timezone}
          onCancel={handleCancel}
          onDetails={handleDetails}
          onRefresh={handleRefresh}
          onDownload={downloadURL => {
            window.open(downloadURL);
          }}
          onRerun={handleRerun}
        />
      )}
      {showDetailsModal && (
        <AntModal
          open
          title={details?.name}
          onOk={handleDetailsClose}
          onCancel={handleDetailsClose}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          {details?.errorMessage}
          <br />
          {errorUrl && (
            <Button
              type="link"
              style={{ paddingLeft: 0 }}
              onClick={() => window.open(errorUrl, '_blank')}
            >
              {t`Show Details`}
              <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: 8 }} />
            </Button>
          )}
        </AntModal>
      )}
      {showBuildModal && (
        <BuildModal
          organizationId={organizationId}
          nativeAppId={nativeApp?.id}
          platform={build?.buildPlatform}
          version={build?.buildVersion}
          publish={build?.publishToStore}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

Builds.propTypes = {
  organizationId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
};
export default Builds;
