import LeaderboardWidget, { User, InfoBox, Divider, Section } from '@eventmobi/widgets/Leaderboard';
import Chance from 'chance';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

const noop = () => {};

export const Leaderboard = ({ title, footer, users, section, current }) => (
  <LeaderboardWidget title={title} footer={<InfoBox {...footer} onClick={noop} />}>
    {users.map(({ key, ...user }) => [
      <User key={key} {...user} onProfileClick={noop} />,
      <Divider key={`divider-${key}`} />,
    ])}
    <Section {...section}>
      <>
        <User {...current} onProfileClick={noop} />
        <Divider key={`divider-${current.key}`} />
      </>
    </Section>
  </LeaderboardWidget>
);

Leaderboard.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.shape({
    text: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      avatar: PropTypes.string,
      name: PropTypes.string,
      points: PropTypes.number,
      pointsText: PropTypes.string,
    })
  ),
  section: PropTypes.shape({ text: PropTypes.string }),
  current: PropTypes.shape({
    rank: PropTypes.number,
    avatar: PropTypes.string,
    name: PropTypes.string,
    points: PropTypes.number,
    pointsText: PropTypes.string,
  }),
};

const chance = new Chance();

const generate = () => ({
  key: chance.hash(),
  avatar: `https://i.pravatar.cc/200?u=${chance.guid()}`,
  name: chance.name(),
  points: chance.integer({ min: 1000, max: 10000 }),
  pointsText: t`points`,
});

const users = chance
  .n(generate, 3)
  .sort(({ points: left }, { points: right }) => (left > right ? -1 : 1));

const current = { ...generate(), rank: chance.integer({ min: 10, max: 1000 }) };

const Mock = ({ title }) => (
  <Leaderboard
    title={title}
    footer={{ buttonText: t`View Challenges` }}
    users={users.map((user, index) => ({ ...user, rank: index + 1 }))}
    section={{ text: t`Your Position` }}
    current={current}
  />
);

Mock.propTypes = {
  title: PropTypes.string,
};

export default Mock;
