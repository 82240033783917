import React from 'react';
import { useParams } from 'react-router-dom';

import CaptureBadges from '@domains/Badges/CapturePDF';

import { BADGES } from '../constants';

const CapturePDF = () => {
  const { resourceType } = useParams();

  if ([BADGES].includes(resourceType)) {
    return <CaptureBadges />;
  }

  return <>Unknown resource type</>;
};

export default CapturePDF;
