import { PlusOutlined, SwapRightOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Form,
  Space,
  Table,
  Tag,
  message,
  DatePicker,
  InputNumber,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'ttag';

import { getId, getLicenseType, setContract } from 'common/state/organization';

import usePermission, {
  CREATE_CONTRACTS,
  DELETE_CONTRACTS,
  UPDATE_CONTRACTS,
} from '@hooks/usePermission';

import {
  STATUS_ACTIVE,
  STATUS_EXPIRED,
  STATUS_SCHEDULED,
  list as listFn,
  create as createFn,
  update as updateFn,
  remove as removeFn,
} from '../contracts-service';
import { contractOptions } from '../DataUsage/PeopleCredit/queries';
import { LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT } from '../service';

const Column = Table.Column;
const { useForm, Item: AntItem } = Form;

const Item = styled(AntItem)`
  && {
    margin: 0;
    vertical-align: middle;
  }
`;

const ActionButton = styled(Button).attrs({ type: 'link' })`
  padding: 0;
`;

const ERROR_MESSAGE_BY_CODE = () => ({
  cannot_move_contract_outside_credits_usage_dates: t`Contract cannot be moved outside credits usage dates.`,
});

const STATUS = () => ({
  [STATUS_SCHEDULED]: t`Scheduled`,
  [STATUS_ACTIVE]: t`Active`,
  [STATUS_EXPIRED]: t`Expired`,
});

const STATUS_TAG_COLOR = {
  [STATUS_SCHEDULED]: 'default',
  [STATUS_ACTIVE]: 'blue',
  [STATUS_EXPIRED]: 'default',
};

const EMPTY_EDITING_KEY = '';
const NEW_CONTRACT_KEY = 'NEW';

const MIN_CONTRACT_PERIOD_DAYS = 365;
const MAX_CONTRACT_PERIOD_MONTHS = 23;
// umm, product wants unlimited value for user credits..
// flux's INT limit is 2,147,483,647 - which is pretty much unlimited,
// so is 2,000,000,000 ¯\_(ツ)_/¯
// at least it looks cleaner if someone attempts value higher than a
// quarter of earth population
const MAX_USER_CREDITS = 2000000000;

const useContracts = () => {
  const organizationId = useSelector(getId);
  const licenseType = useSelector(getLicenseType);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const response = await listFn(organizationId);
      setDataSource(response);

      // update active contract information in the store
      if (licenseType === LICENSE_TYPE_USER_CREDIT) {
        const activeContract = response.find(contract => contract.status === STATUS_ACTIVE);
        dispatch(setContract(activeContract));
      } else if (licenseType === LICENSE_TYPE_EVENT_CREDIT) {
        dispatch(setContract(null));
      }
    } catch {
      message.error(t`Failed to load contracts`);
    } finally {
      setLoading(false);
    }
  }, [organizationId, licenseType, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    loading,
    dataSource,
    reload: load,
    hasActiveContract: !!dataSource.find(contract => contract.status === STATUS_ACTIVE),
  };
};

const Contracts = ({ licenseType }) => {
  const queryClient = useQueryClient();
  const organizationId = useSelector(getId);
  const [editingKey, setEditingKey] = useState(EMPTY_EDITING_KEY);
  const [saving, setSaving] = useState(false);
  const { loading, dataSource, reload, hasActiveContract } = useContracts();
  const localDataSource = useRef(dataSource);
  const popConfirmPopupContainerRef = useRef(null);
  const { authorized, authorizedAny } = usePermission();

  const [form] = useForm();

  useEffect(() => {
    localDataSource.current = dataSource;
  }, [dataSource]);

  const isEditing = key => key === editingKey;

  const addNewContract = () => {
    const newId = NEW_CONTRACT_KEY;
    const newContractItem = { id: NEW_CONTRACT_KEY };
    localDataSource.current = [newContractItem, ...localDataSource.current];
    form.resetFields();
    setEditingKey(newId);
  };

  const editContract = contract => {
    form.setFieldsValue({
      period: [moment(contract.startDate), moment(contract.endDate)],
      userCredits: contract.userCredits,
    });
    setEditingKey(contract.id);
  };

  const cancelEditing = () => {
    localDataSource.current = localDataSource.current.filter(({ id }) => id !== NEW_CONTRACT_KEY);
    setEditingKey(EMPTY_EDITING_KEY);
  };

  const saveContract = async () => {
    const values = await form.validateFields();
    const payload = {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
      userCredits: values.userCredits,
    };

    try {
      setSaving(true);
      if (editingKey === NEW_CONTRACT_KEY) {
        await createFn(organizationId, payload);
      } else {
        await updateFn(organizationId, editingKey, payload);
      }
      setEditingKey(EMPTY_EDITING_KEY);
      queryClient.invalidateQueries({ queryKey: contractOptions(organizationId).queryKey });

      await reload();
    } catch (err) {
      const errorMessage =
        ERROR_MESSAGE_BY_CODE()[err?.errors?.[0]?.code] ?? t`An unknown error occurred.`;
      message.error(errorMessage);
    } finally {
      setSaving(false);
    }
  };

  const removeContract = async contract => {
    setSaving(true);
    try {
      await removeFn(organizationId, contract.id);
      message.success(t`Successfully deleted a contract.`);
      queryClient.invalidateQueries({ queryKey: contractOptions(organizationId).queryKey });
    } catch {
      message.error(t`Failed to delete contract.`);
    } finally {
      setSaving(false);
    }
    reload();
  };

  const isDateDisabled = date => {
    const otherContracts = localDataSource.current.filter(({ id }) => id !== editingKey);

    return otherContracts.some(contract =>
      date
        .startOf('day')
        .isBetween(moment(contract.startDate), moment(contract.endDate), 'day', '[]')
    );
  };

  const contractPeriodMinMaxValidator = async (__, value) => {
    if (!value) return;
    const [startDate, endDate] = value;

    const minEndDate = moment(startDate).add(MIN_CONTRACT_PERIOD_DAYS - 1, 'days'); // -1 to include the start date
    const maxEndDate = moment(startDate).add(MAX_CONTRACT_PERIOD_MONTHS, 'months');

    if (endDate.isBefore(minEndDate, 'day')) {
      throw new Error(t`Minimum period required: ${MIN_CONTRACT_PERIOD_DAYS} days`);
    } else if (endDate.isAfter(maxEndDate, 'day')) {
      throw new Error(t`Maximum period required: ${MAX_CONTRACT_PERIOD_MONTHS} months`);
    }
  };

  const contractPeriodOverlapValidator = async (__, value) => {
    if (!value) return;
    const [startDate, endDate] = value;

    const otherContracts = localDataSource.current.filter(({ id }) => id !== editingKey);
    const overlap = otherContracts.some(
      contract =>
        startDate.isBefore(moment(contract.startDate), 'day') &&
        endDate.isAfter(moment(contract.endDate), 'day')
    );
    if (overlap) {
      throw new Error(t`Dates overlap with existing period.`);
    }
  };

  return (
    <Form form={form} name="contracts">
      <div ref={popConfirmPopupContainerRef} />
      <Space size="small" direction="vertical" style={{ width: '100%' }}>
        {authorized(CREATE_CONTRACTS) && (
          <Button
            icon={<PlusOutlined />}
            type="link"
            disabled={editingKey || saving}
            onClick={() => addNewContract()}
          >
            {t`Add New Contract`}
          </Button>
        )}
        {licenseType === LICENSE_TYPE_USER_CREDIT && !hasActiveContract && (
          <Alert
            type="warning"
            showIcon
            message={t`Events can be created when there is an active contract.`}
          />
        )}
        {localDataSource.current.length > 0 && (
          <Table
            dataSource={localDataSource.current}
            loading={loading}
            pagination={false}
            rowKey="id"
            rowClassName="editable-row"
            tableLayout="auto"
            data-testid="contracts-table"
          >
            <Column
              title={t`Contract Period`}
              width={300}
              render={(_, record) =>
                isEditing(record.id) ? (
                  <Item
                    name="period"
                    required
                    rules={[
                      { required: true, message: t`Required field.` },
                      {
                        validator: async (...args) => {
                          // the two validators are combined into one to prevent
                          // two validation error messages to appear at once
                          await contractPeriodMinMaxValidator(...args);
                          await contractPeriodOverlapValidator(...args);
                        },
                      },
                    ]}
                  >
                    <DatePicker.RangePicker disabled={saving} disabledDate={isDateDisabled} />
                  </Item>
                ) : (
                  <Space size="large">
                    <span>{record.startDate}</span>
                    <SwapRightOutlined />
                    <span>{record.endDate}</span>
                  </Space>
                )
              }
            />
            <Column
              title={t`User Credit`}
              width={200}
              dataIndex="userCredits"
              align="right"
              render={(value, record) =>
                isEditing(record.id) ? (
                  <Item
                    name="userCredits"
                    rules={[{ required: true, message: t`Required field.` }]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      type="number"
                      disabled={saving}
                      min={1}
                      max={MAX_USER_CREDITS}
                    />
                  </Item>
                ) : (
                  value
                )
              }
            />
            <Column
              title={t`Status`}
              dataIndex="status"
              render={value =>
                value ? <Tag color={STATUS_TAG_COLOR[value]}>{STATUS()[value]}</Tag> : '-'
              }
            />
            {authorizedAny(UPDATE_CONTRACTS, DELETE_CONTRACTS) && (
              <Column
                title={t`Action`}
                width={150}
                render={(_, record) =>
                  isEditing(record.id) ? (
                    <Space size="small">
                      <ActionButton disabled={saving} onClick={() => saveContract()}>
                        {record.id === NEW_CONTRACT_KEY ? t`Add` : t`Save`}
                      </ActionButton>
                      <ActionButton disabled={saving} onClick={() => cancelEditing()}>
                        {t`Cancel`}
                      </ActionButton>
                    </Space>
                  ) : (
                    <Space size="small">
                      {authorized(UPDATE_CONTRACTS) && (
                        <ActionButton
                          disabled={
                            saving ||
                            editingKey !== EMPTY_EDITING_KEY ||
                            ![STATUS_SCHEDULED, STATUS_ACTIVE].includes(record.status)
                          }
                          onClick={() => editContract(record)}
                        >
                          {t`Edit`}
                        </ActionButton>
                      )}
                      {authorized(DELETE_CONTRACTS) && (
                        <Popconfirm
                          disabled={
                            saving ||
                            editingKey !== EMPTY_EDITING_KEY ||
                            ![STATUS_SCHEDULED].includes(record.status)
                          }
                          onConfirm={() => removeContract(record)}
                          title={
                            <>
                              {t`Are you sure to delete this contract?`}
                              <br />
                              {t`This action cannot be undone.`}
                            </>
                          }
                          okText={t`Yes`}
                          cancelText={t`No`}
                          placement="topRight"
                          getPopupContainer={() => popConfirmPopupContainerRef.current}
                        >
                          <ActionButton
                            disabled={
                              saving ||
                              editingKey !== EMPTY_EDITING_KEY ||
                              ![STATUS_SCHEDULED].includes(record.status)
                            }
                          >
                            {t`Delete`}
                          </ActionButton>
                        </Popconfirm>
                      )}
                    </Space>
                  )
                }
              />
            )}
          </Table>
        )}
      </Space>
    </Form>
  );
};

Contracts.propTypes = {
  licenseType: PropTypes.oneOf([LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT]).isRequired,
};

export default Contracts;
