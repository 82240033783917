import apiFn from 'common/services/api-service';

export const LIVESTREAM_BROADCAST_HOURS = 'live_stream_ingress_limit_hours';
export const LIVESTREAM_PLAYBACK_HOURS = 'live_stream_egress_limit_hours';
export const VIDEO_STORAGE_HOURS = 'video_storage_limit_hours';
export const VIDEO_PLAYBACK_HOURS = 'video_egress_limit_hours';
export const PEOPLE_CREDITS = 'contract_credits_usage';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/data-usage`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

const transform = payload => ({
  ...payload,
  used: payload.limitUsed,
  limit: payload.limitTotal,
  unit: payload.limitUnit,
  isEmpty: !payload.limitTotal && !payload.limitUsed,
});

export const get = (organizationId, key) => {
  return api(organizationId)
    .get(key)
    .then(response => transform(response.data.data));
};

export const events = (organizationId, key, cutoff, payload = {}, query = '') => {
  const params = new URLSearchParams(query);

  if (payload.offset > 0) {
    params.set('page', payload.offset / payload.limit);
  }

  if (cutoff) {
    params.set('event_usage_cutoff', cutoff);
  }

  return api(organizationId)
    .get(`${key}/events`, { params })
    .then(response => response.data);
};
