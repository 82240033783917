import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/native_application_builds`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

const webhooksApi = () => apiFn(`/api/uapi/internal/webhooks/native_builds`);
const staffApi = staffId => apiFn(`/api/cms/v1/staff-details/${staffId}`);

export function list(organizationId, appId) {
  return api(organizationId)
    .get('', {
      params: {
        multiEventAppId: appId,
      },
    })
    .then(response => response?.data?.data);
}

export function cancel(organizationId, id) {
  return api(organizationId)
    .post(`${id}/cancel`)
    .then(response => response.data.data);
}

export function get(organizationId, id) {
  return api(organizationId)
    .get(id)
    .then(response => response.data.data);
}

export function getStaffDetail(staffId) {
  return staffApi(staffId)
    .get()
    .then(response => response.data.data ?? null);
}

export function refresh(id) {
  return webhooksApi().post('', { buildId: id });
}

export function create(organizationId, nativeAppId, payload) {
  return api(organizationId)
    .post('', { ...payload, nativeApplicationId: nativeAppId })
    .then(response => response.data.data);
}
