/* global ENV */
import { Alert } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';
import logger from 'use-reducer-logger';

import useSearchParams from 'common/hooks/useSearchParams';

import { WIDGET_PAGES, WIDGET_PAGE_TEMPLATES } from '@domains/Capture/constants';

import Builder from './Builder';
import { get as getWidgetPageFn } from './page-service';
import reducer, {
  ACTION_POPULATE,
  ACTION_SET_MOBILE_MODE,
  ACTION_SET_LOADING,
  initialState,
} from './reducer';
import { get as getWidgetTemplateFn } from './templates-service';

const NOT_FOUND_ERROR_CODES = ['widget_page_template_not_found', 'page_not_found'];

const Capture = () => {
  const query = useSearchParams();
  const token = query.get('token');
  const { eventId, resourceType, resourceId } = useParams();
  const [state, dispatch] = useReducer(ENV === 'development' ? logger(reducer) : reducer, {
    present: {
      ...initialState,
    },
  });
  const [error, setError] = useState();

  useEffect(() => {
    async function effect() {
      try {
        let payload;
        if (resourceType === WIDGET_PAGES) {
          payload = await getWidgetPageFn(eventId, resourceId, token);
        } else if (resourceType === WIDGET_PAGE_TEMPLATES) {
          payload = await getWidgetTemplateFn(resourceId, token);
        } else {
          throw new Error('Unknown resource type');
        }

        dispatch({ type: ACTION_POPULATE, payload });
        if (!payload.responsive) {
          dispatch({ type: ACTION_SET_MOBILE_MODE, payload: true });
        }
      } catch (err) {
        const notFound = NOT_FOUND_ERROR_CODES.includes(err?.errors?.[0]?.code);
        setError(notFound ? t`Resource was not found.` : t`Unknown error occurred.`);

        dispatch({ type: ACTION_SET_LOADING, payload: false });
      }
    }

    effect();
  }, [eventId, resourceType, resourceId, token]);

  if (error) {
    return <Alert type="error" message={error} showIcon />;
  }

  return <Builder state={state} readonly />;
};

export default Capture;
