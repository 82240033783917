function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  // should be an even number otherwise you see a hairline split between the gradients\n  --checkerboard-width: 10px;\n  --checkerboard-color: #ccc;\n\n  background-color: white;\n  background-image: linear-gradient(45deg, var(--checkerboard-color) 25%, transparent 25%),\n    linear-gradient(135deg, var(--checkerboard-color) 25%, transparent 25%),\n    linear-gradient(45deg, transparent 75%, var(--checkerboard-color) 75%),\n    linear-gradient(135deg, transparent 75%, var(--checkerboard-color) 75%);\n  background-size: var(--checkerboard-width) var(--checkerboard-width);\n  background-position: 0 0, calc(var(--checkerboard-width) / 2) 0,\n    calc(var(--checkerboard-width) / 2) calc(var(--checkerboard-width) / -2),\n    0px calc(var(--checkerboard-width) / 2);\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
// eslint-disable-next-line import/prefer-default-export
export var checkeredBackground = css(_templateObject());
