import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { Table as AntTable, Button, Row, Col, Divider, message, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { jt, t } from 'ttag';

import Empty from 'common/components/Empty';
import DeleteModal from 'common/components/Modal/DeleteModal';
import { getId } from 'common/state/organization';
import { getEmail } from 'common/state/user';

import usePermission, {
  CREATE_API_KEYS,
  DELETE_API_KEYS,
  READ_API_KEYS,
  UPDATE_API_KEYS,
} from '@hooks/usePermission';
import EmptyImage from '@images/illustrations/integrations.svg';

import AddModal from './AddModal';
import {
  deleteKey as deleteKeyFn,
  disableKey as disableKeyFn,
  emailKey as emailKeyFn,
  enableKey as enableKeyFn,
  list as listFn,
  regenerateKey as regenerateKeyFn,
} from './service';

const { Column } = AntTable;

const STATUS_ENABLED = 'enabled';
const STATUS_DISABLED = 'disabled';

const getStatus = value =>
  ({
    [STATUS_ENABLED]: t`Enabled`,
    [STATUS_DISABLED]: t`Disabled`,
  }[value]);

const useIntegrations = organizationId => {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const res = await listFn(organizationId);
      setIntegrations(res);
    } finally {
      setLoading(false);
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      load();
    }
  }, [organizationId, load]);

  return {
    integrations,
    loading,
    forceReload: load,
  };
};

const Table = ({
  dataSource,
  loading,
  organizerEmail,
  onEmail,
  onRegenerate,
  onEnable,
  onDisable,
  onDelete,
}) => {
  const { authorized, authorizedAny } = usePermission();
  return (
    <AntTable
      rowKey="id"
      dataSource={dataSource}
      rowClassName={record => (record.status === STATUS_DISABLED ? 'disabled-row' : '')}
      tableLayout="auto"
      loading={loading}
      pagination={false}
    >
      <Column
        title={t`Organizer`}
        dataIndex="organizerEmail"
        sorter={(a, b) => a.organizerEmail.localeCompare(b.organizerEmail)}
        defaultSortOrder="ascend"
      />
      <Column title={t`Key`} dataIndex="key" />
      <Column
        title={t`Status`}
        dataIndex="status"
        filters={[
          { text: getStatus(STATUS_ENABLED), value: STATUS_ENABLED },
          { text: getStatus(STATUS_DISABLED), value: STATUS_DISABLED },
        ]}
        onFilter={(value, record) => record.status === value}
        render={getStatus}
      />
      {authorizedAny(UPDATE_API_KEYS, DELETE_API_KEYS) && (
        <Column
          title={t`Action`}
          dataIndex="action"
          onCell={() => ({ onClick: event => event.stopPropagation() })}
          render={(_, row) => {
            const isOwner = row.organizerEmail === organizerEmail;
            return (
              <Space size="small" split={<Divider type="vertical" />}>
                {row.status === STATUS_ENABLED && [
                  authorized(READ_API_KEYS) && (
                    <Button
                      type="link"
                      key="email"
                      onClick={() => onEmail(row.id)}
                      style={{ padding: 0 }}
                    >
                      {t`Email`}
                    </Button>
                  ),

                  authorized(UPDATE_API_KEYS) && (
                    <Button
                      type="link"
                      key="regenerate"
                      onClick={() => onRegenerate(row.id)}
                      style={{ padding: 0 }}
                    >
                      {t`Regenerate`}
                    </Button>
                  ),

                  authorized(UPDATE_API_KEYS) && (
                    <Button
                      type="link"
                      key="disable"
                      onClick={() => onDisable(row.id)}
                      style={{ padding: 0 }}
                    >
                      {t`Disable`}
                    </Button>
                  ),
                ]}
                {row.status === STATUS_DISABLED && authorized(UPDATE_API_KEYS) && (
                  <Button
                    type="link"
                    onClick={() => onEnable(row.id)}
                    style={{ padding: 0 }}
                  >{t`Enable`}</Button>
                )}
                {isOwner && authorized(DELETE_API_KEYS) && (
                  <Button
                    type="link"
                    danger
                    onClick={() => onDelete(row.id, row.organizerEmail)}
                    style={{ padding: 0 }}
                  >
                    {t`Delete`}
                  </Button>
                )}
              </Space>
            );
          }}
        />
      )}
    </AntTable>
  );
};
Table.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
  organizerEmail: PropTypes.string.isRequired,
  onEmail: PropTypes.func.isRequired,
  onRegenerate: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const selector = createSelector(getId, getEmail, (organizationId, organizerEmail) => ({
  organizationId,
  organizerEmail,
}));

const Integrations = () => {
  const { organizationId, organizerEmail } = useSelector(selector);
  const { integrations, loading, forceReload } = useIntegrations(organizationId);
  const { authorized } = usePermission();
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = hasGeneratedKey => {
    setShowModal(false);
    if (hasGeneratedKey) {
      forceReload();
    }
  };

  const onEmail = async id => {
    try {
      await emailKeyFn(organizationId, id);
      message.success(t`Successfully emailed API Key`);
    } catch {
      message.error(t`Error emailing API Key`);
    }
  };

  const onRegenerate = async id => {
    try {
      await regenerateKeyFn(organizationId, id);
      message.success(t`Successfully regenerated API Key`);
      forceReload();
    } catch {
      message.error(t`Error regenerating API Key`);
    }
  };

  const onEnable = async id => {
    try {
      await enableKeyFn(organizationId, id);
      forceReload();
      message.success(t`Successfully enabled API Key`);
    } catch {
      message.error(t`Error enabling API Key`);
    }
  };

  const onDisable = async id => {
    try {
      await disableKeyFn(organizationId, id);
      forceReload();
      message.success(t`Successfully disabled API Key`);
    } catch {
      message.error(t`Error disabling API Key`);
    }
  };

  const onDelete = (id, email) => {
    const emailBold = (
      <Typography.Text key="userEmail" strong>
        {email}
      </Typography.Text>
    );

    DeleteModal.confirm({
      label: {
        singular: t`API Key`,
      },
      content: jt`Are you sure you want to delete the API Key for ${emailBold}? This action cannot be undone.`,
      itemName: email,
      async onDelete() {
        try {
          await deleteKeyFn(organizationId, id);
          forceReload();
          message.success(t`Successfully deleted API Key`);
        } catch {
          message.error(t`Error deleting API Key`);
        }
      },
    });
  };

  return (
    <>
      <Row type="flex" css="margin: 20px 0;">
        <Col span={8}>
          <Space>
            {authorized(CREATE_API_KEYS) && (
              <Button
                key="add"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowModal(true)}
              >
                {t`Add API Key`}
              </Button>
            )}
            <Button
              key="open-doc"
              icon={<LinkOutlined />}
              href="https://developers.eventmobi.com/docs/uapi-public"
              target="_blank"
            >
              {t`Open API Documentation`}
            </Button>
          </Space>
        </Col>
      </Row>
      {!loading && !integrations.length ? (
        <Empty
          image={EmptyImage}
          title={t`You don't have any API Keys yet`}
          body={t`Add an API Key to get started.`}
        />
      ) : (
        <Table
          loading={loading}
          dataSource={integrations}
          organizerEmail={organizerEmail}
          onEmail={onEmail}
          onRegenerate={onRegenerate}
          onEnable={onEnable}
          onDisable={onDisable}
          onDelete={onDelete}
        />
      )}
      {showModal && <AddModal organizationId={organizationId} onClose={onCloseModal} />}
    </>
  );
};

export default Integrations;
