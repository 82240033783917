import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { organization as flagFn } from 'common/services/flags/api';
import { setOrganization as setFlags } from 'common/state/flags';
import { unsetOrganization, setContract, setOrganization } from 'common/state/organization';

import { STATUS_ACTIVE, getLatestContract as getLatestContractFn } from './contracts-service';
import { LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT, get as getFn } from './service';

const useOrganization = organizationId => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!organizationId) {
      setLoading(false);
      return;
    }

    async function effect() {
      try {
        // clear up the data before loading
        dispatch([unsetOrganization(), setFlags()]);

        setLoading(true);
        setError(null);

        const [organization, { data: flags }] = await Promise.all([
          getFn(organizationId),
          flagFn(organizationId),
        ]);

        dispatch([setOrganization(organization), setFlags(flags)]);

        const { licenseType } = organization;
        if (licenseType === LICENSE_TYPE_USER_CREDIT) {
          const contract = await getLatestContractFn(organizationId, { status: [STATUS_ACTIVE] });
          dispatch(setContract(contract));
        } else if (licenseType === LICENSE_TYPE_EVENT_CREDIT) {
          dispatch(setContract(null));
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    effect();
  }, [organizationId, dispatch]);

  return { loading, error };
};

export default useOrganization;
