import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Alert, Checkbox, DatePicker, Divider, Form, Input, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';

import { useSupportedGateways } from './queries';

const DATE_FORMAT = 'll';

const FIELD_TYPE = {
  String: 'string',
  Date: 'date',
  Boolean: 'boolean',
};

const MaskedInput = props => {
  const [mask, setMask] = useState(true);

  return (
    <Input
      {...props}
      style={{ WebkitTextSecurity: mask ? 'disc' : 'inherit' }}
      suffix={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
        <span
          className="anticon ant-input-password-icon"
          onClick={() => setMask(v => !v)}
          role="button"
        >
          {mask ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </span>
      }
    />
  );
};

const CredentialFieldItem = ({ id, name, type, safe = true, required = false }) => {
  const namePath = ['credentials', id];

  switch (type) {
    case FIELD_TYPE.Date:
      return (
        <Form.Item
          name={namePath}
          label={<Label isRequired={required}>{name}</Label>}
          rules={[
            { required, message: t`${name} is required` },
            { type: 'date', message: t`Invalid date` },
          ]}
        >
          <DatePicker format={DATE_FORMAT} placeholder="" style={{ width: '100%' }} />
        </Form.Item>
      );
    case FIELD_TYPE.Boolean:
      return (
        <Form.Item name={namePath} valuePropName="checked">
          <Checkbox>{name}</Checkbox>
        </Form.Item>
      );
    case FIELD_TYPE.String:
    default:
      return (
        <Form.Item
          name={namePath}
          label={<Label isRequired={required}>{name}</Label>}
          rules={[{ required, message: t`${name} is required` }]}
        >
          {safe ? <Input /> : <MaskedInput />}
        </Form.Item>
      );
  }
};

CredentialFieldItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([FIELD_TYPE.String, FIELD_TYPE.Date, FIELD_TYPE.Boolean]).isRequired,
  safe: PropTypes.bool,
  required: PropTypes.bool,
};

const ConnectionForm = ({ initialValues = null, formRef = null, disabled = false }) => {
  const [form] = Form.useForm();
  const { data: supportedGateways, isLoading: isLoadingGateways, error } = useSupportedGateways();

  const selectedGatewayType = Form.useWatch(['type'], form);
  const selectedAuthModeType = Form.useWatch(['authModeType'], form);

  const { availableAuthModes, credentialFields } = useMemo(() => {
    const gateway = supportedGateways?.find(gtw => gtw.gatewayType === selectedGatewayType);
    const authModes = gateway?.authModes ?? [];

    const authMode = authModes.find(am => am.authModeType === selectedAuthModeType);

    return {
      availableAuthModes: authModes,
      credentialFields: authMode?.credentials ?? [],
    };
  }, [supportedGateways, selectedGatewayType, selectedAuthModeType]);

  // reset the auth mode and credentials when the gateway is changed
  useEffect(() => {
    let authModeType = null;

    // auto-select the auth mode if there is only one available
    if (availableAuthModes.length === 1) {
      authModeType = availableAuthModes[0].authModeType;
    }

    form.setFieldsValue({ authModeType, credentials: null });
  }, [form, selectedGatewayType, availableAuthModes]);

  const handleSubmit = useCallback(async () => {
    // this is just to handle the case where useSupportedGateways failed to load
    // in that case, form will not be initialized, and we should not submit empty form
    if (error) {
      throw new Error('Form is not initialized');
    }

    const values = await form.validateFields();

    return {
      name: values.name,
      type: values.type,
      authMode: values.authModeType,
      credentials: Object.fromEntries(
        // in a very rare case, where no credentials are present or required for an auth mode,
        // `values.credentials` will be `undefined`.
        // for example, may be in 'Test Gateway' where there might be no credentials
        Object.entries(values.credentials ?? {})
          // remove `null` values - necessary for date fields that are cleared after selection
          .filter(([, value]) => value !== null)
          .map(([key, value]) => {
            // return the value as string if it is a moment object
            if (moment.isMoment(value)) {
              return [key, value.format('YYYY-MM-DD')];
            }
            return [key, value];
          })
      ),
    };
  }, [error, form]);

  useImperativeHandle(formRef, () => ({ submit: handleSubmit, setFields: form.setFields }), [
    handleSubmit,
    form.setFields,
  ]);

  const isEditing = !!initialValues?.id;

  if (isLoadingGateways) {
    return <Loading />;
  }

  if (error) {
    return <Alert showIcon type="error" message={t`Oops! Something went wrong.`} />;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      requiredMark={false}
      disabled={disabled}
      onValuesChange={changedValues => {
        if (changedValues.authModeType) {
          form.setFieldsValue({ credentials: null });
        }
      }}
    >
      <Form.Item
        name="name"
        label={<Label isRequired>{t`Name`}</Label>}
        rules={[{ required: true, message: t`Name is required` }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="type"
        label={<Label isRequired>{t`Gateway`}</Label>}
        rules={[{ required: true, message: t`Gateway is required` }]}
      >
        <Select
          placeholder={t`Select`}
          disabled={disabled || isEditing}
          options={(supportedGateways ?? []).map(gateway => ({
            value: gateway.gatewayType,
            label: gateway.name,
          }))}
        />
      </Form.Item>

      <Form.Item
        name="authModeType"
        label={<Label>{t`Auth Mode`}</Label>}
        dependencies={['type']}
        rules={[{ required: true, message: t`Auth Mode is required` }]}
      >
        <Select
          placeholder={t`Select`}
          disabled={disabled || !selectedGatewayType}
          options={availableAuthModes.map(authMode => ({
            value: authMode.authModeType,
            label: authMode.name,
          }))}
        />
      </Form.Item>

      {credentialFields.length > 0 && <Divider />}
      {credentialFields.map(field => (
        <CredentialFieldItem key={field.id} {...field} />
      ))}
    </Form>
  );
};

ConnectionForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.shape({
        submit: PropTypes.func.isRequired,
        setFields: PropTypes.func.isRequired,
      }),
    }),
  ]),
  disabled: PropTypes.bool,
};

export default ConnectionForm;
