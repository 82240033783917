import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StepWrap = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 285px;
  padding: 0px 15px 0px;
  margin-top: 170px;
  // accommodate for overflow at the bottom
  margin-bottom: 25px;
  border-radius: 10px;
  background: linear-gradient(to bottom, rgba(0, 147, 199, 0) 0%, #0093c7 100%);
  text-align: center;
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 160px;
  display: flex;
  justify-content: center;

  margin-bottom: 20px;
`;

const ImageFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  width: ${props => (props.size === 'lg' ? '250px' : '175px')};
  height: ${props => (props.size === 'lg' ? '250px' : '175px')};

  border: ${props => (props.size === 'lg' ? '10px' : '8px')} solid white;
  border-radius: 50%;
  background: #0093c7;
  box-shadow: 0 1px 20px rgb(0 0 0 / 50%);

  // for initials
  font-size: ${props => (props.size === 'lg' ? '80px' : '60px')};
  font-weight: 600;
  color: white;

  // for trophy
  svg {
    font-size: ${props => (props.size === 'lg' ? '140px' : '100px')};
  }

  // for image
  img {
    min-width: 100%;
    max-width: 100%;
    border-radius: 50%;
  }
`;

const Title = styled(Typography.Title)`
  && {
    position: absolute;
    bottom: 75px;
    margin: 0;

    font-size: 42px;
    color: ${props => props.color};

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding: 0 20px;
  }
`;

const PillItem = styled.div`
  flex: ${props => props.flex};
`;

const Pill = styled.div`
  position: absolute;
  bottom: -25px;
  display: flex;
  flex-flow: row nowrap;

  width: ${props => props.width};

  font-size: 34px;
  font-weight: 600;

  ${PillItem} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    overflow: hidden;
  }

  ${PillItem}:first-child {
    border-radius: 25px 0 0 25px;
  }

  ${PillItem}:last-child {
    border-radius: 0 25px 25px 0;
  }
`;

const PodiumStep = ({ rank, points, name, profileImage }) => {
  const initials = (name ?? '')
    .replace(/\./g, '')
    .split(' ')
    .map(str => str[0])
    .join('')
    .toUpperCase();

  return (
    <StepWrap>
      <ImageContainer>
        <ImageFrame size={rank === 1 ? 'lg' : 'md'}>
          {profileImage && <img src={profileImage} alt={name} />}
          {!profileImage && initials !== '' && initials}
          {!profileImage && initials === '' && <FontAwesomeIcon color="white" icon={faTrophy} />}
        </ImageFrame>
      </ImageContainer>
      <Title color="white">{name}</Title>
      <Pill width="60%">
        <PillItem flex="0 0 55px" style={{ background: '#e1ecf4', fontWeight: 800 }}>
          {rank}
        </PillItem>
        <PillItem flex="1 1 auto" style={{ background: 'white' }}>
          {points}
        </PillItem>
      </Pill>
    </StepWrap>
  );
};

PodiumStep.propTypes = {
  rank: PropTypes.number.isRequired,
  points: PropTypes.number,
  name: PropTypes.string,
  profileImage: PropTypes.string,
};

const PODIUM_INDEX_SEQUENCE = [1, 0, 2];
const Podium = ({ players }) => {
  const sequencedChildren = PODIUM_INDEX_SEQUENCE.map(index => {
    const player = players[index];

    return (
      <PodiumStep
        rank={index + 1}
        points={player?.points}
        name={player?.name}
        profileImage={player?.profileImage}
      />
    );
  });

  return (
    <Row gutter="40">
      {sequencedChildren.map(child => (
        <Col key={Math.random() * 1000} span={8}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

Podium.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Podium;
