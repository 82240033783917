export var SUPPORT_EMAIL = "support@eventmobi.com";
export var SALES_EMAIL = "am@eventmobi.com";
export var SupportPhoneUrl;
(function(SupportPhoneUrl) {
    SupportPhoneUrl["DE"] = "tel:+49-30-5557343-0";
    SupportPhoneUrl["EN"] = "tel:1-888-296-8415";
})(SupportPhoneUrl || (SupportPhoneUrl = {}));
export var SupportPhone;
(function(SupportPhone) {
    SupportPhone["DE"] = "+49 (0)30 5557343-0";
    SupportPhone["EN"] = "1-888-296-8415 #2";
})(SupportPhone || (SupportPhone = {}));
