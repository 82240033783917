import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/payments`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const listSupportedGateways = organizationId =>
  api(organizationId)
    .get('supported-gateways')
    .then(response => response.data);

export const listSupportedCurrencies = organizationId =>
  api(organizationId)
    .get('currencies')
    .then(response => response.data);

export const listConnections = (organizationId, { events, ...apiParams }) => {
  const params = new URLSearchParams(apiParams);

  if (events) {
    params.set('event_id', Array.isArray(events) ? events.join(',') : events);
  }

  return api(organizationId)
    .get('gateways', { params })
    .then(response => response.data);
};

export const createConnection = (organizationId, payload) =>
  api(organizationId)
    .post('gateways', payload)
    .then(response => response.data);

export const updateConnection = (organizationId, id, payload) =>
  api(organizationId)
    .patch(`gateways/${id}`, payload)
    .then(response => response.data);

export const testConnection = (organizationId, gatewayId) =>
  api(organizationId)
    .get(`gateways/${gatewayId}/test-connection`)
    .then(response => response.data);

export const archiveConnection = (organizationId, id) =>
  api(organizationId)
    .post(`gateways/${id}/archive`)
    .then(response => response.data);
