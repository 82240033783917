import { Divider, Space, Table as AntTable, Button } from 'antd';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

import ShowMore from 'common/components/ShowMore';
import useSearchParams from 'common/hooks/useSearchParams';

import usePermission, {
  DELETE_SSO_CONNECTIONS,
  UPDATE_SSO_CONNECTIONS,
} from '@hooks/usePermission';

import { IDP_TYPES } from './constants';

const { Column } = AntTable;

const Table = ({
  loading,
  dataSource,
  events = [],
  pagination,
  onChange = () => {},
  onEdit = () => {},
  onDelete = () => {},
  selectedRowKeys = [],
  onRowSelection,
}) => {
  const query = useSearchParams();
  const { authorized, authorizedAny } = usePermission();

  const sortOrder = name => {
    const hasSort = query.get('sort')?.includes(name) ?? false;

    if (!hasSort) {
      return false;
    }

    return query.get('sort').substr(0, 1) === '-' ? 'descend' : 'ascend';
  };

  const rowSelection = {
    columnWidth: 50,
    selectedRowKeys,
    onChange: onRowSelection,
  };

  return (
    <AntTable
      loading={loading}
      dataSource={dataSource}
      pagination={{
        ...pagination,
        showTotal: (total, [from, to]) => t`${from}-${to} of ${total} items`,
        showSizeChanger: false,
      }}
      onChange={onChange}
      rowSelection={authorized(DELETE_SSO_CONNECTIONS) ? rowSelection : null}
      rowKey="id"
    >
      <Column
        title={t`Connection Name`}
        dataIndex="displayName"
        sorter
        sortOrder={sortOrder('displayName')}
        defaultSortOrder="ascend"
      />
      <Column
        title={t`Identity Provider Type`}
        dataIndex="type"
        render={value => IDP_TYPES()[value] ?? t`Unknown`}
        filters={Object.entries(IDP_TYPES()).map(([value, text]) => ({ value, text }))}
        filteredValue={query.get('type')?.split(',') ?? null}
        filterMultiple={false}
      />
      <Column
        title={t`Linked Events`}
        dataIndex="events"
        render={value => <ShowMore items={value} isTag />}
        filters={events.map(event => ({ value: event.id, text: event.name }))}
        filteredValue={query.get('events')?.split(',') ?? null}
        filterMultiple={false}
      />
      <Column
        title={t`Created Date`}
        dataIndex="createdAt"
        render={createdAt => format(parseISO(createdAt), 'PP')}
        sorter
        sortOrder={sortOrder('createdAt')}
      />
      {authorizedAny(UPDATE_SSO_CONNECTIONS, DELETE_SSO_CONNECTIONS) && (
        <Column
          title={t`Action`}
          render={(_, record) => (
            <Space size="small" split={<Divider type="vertical" />}>
              {authorized(UPDATE_SSO_CONNECTIONS) && (
                <Button type="link" onClick={() => onEdit(record)} style={{ padding: 0 }}>
                  {t`Edit`}
                </Button>
              )}
              {authorized(DELETE_SSO_CONNECTIONS) && (
                <Button type="link" danger onClick={() => onDelete(record)} style={{ padding: 0 }}>
                  {t`Delete`}
                </Button>
              )}
            </Space>
          )}
        />
      )}
    </AntTable>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.arrayOf(Object).isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  // Same as https://ant.design/components/pagination/#API
  // Note: We're only plucking the props that we're using.
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onChange: PropTypes.func,
  }),
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRowSelection: PropTypes.func,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
};

export default Table;
