function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import useStableLocation from "./useStableLocation";
/**
 * When the location.search parameter changes:
 * - Parse it into a URLSearchParams instance
 * - Convert it into a Record<string, string>
 * When setting a new location.search parameter:
 * - Convert it into a URLSearchParams instance
 * - Merge it with the current location.search (for things like ?tab=groups)
 * - Handle fields that have been removed (since they'll be in the merged state)
 *
 * We need to ensure that updating a parameter doesn't cause a render loop by updating the URL
 * and then updating the state, which triggers a re-render, which updates the URL, etc.
 *
 * We also need to ensure that the values we return for each field are stable, so that we don't
 * trigger unnecessary re-renders. This means that we should memoize the values we return.
 */ var useSyncWithUrlSearchParams = function() {
    var history = useHistory();
    var location = useStableLocation();
    var parsedParams = useMemo(function() {
        return qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
    }, [
        location.search
    ]);
    // We have to store some values in refs so that we can keep setUrlSearchParams as a consistent
    // function reference. This is necessary because we need to ensure the callback usage always has
    // the latest values, but we need to ensure the function can be used in a hook dependency too.
    var locationRef = useRef(location);
    var parsedParamsRef = useRef(parsedParams);
    useEffect(function() {
        locationRef.current = location;
    }, [
        location
    ]);
    useEffect(function() {
        parsedParamsRef.current = parsedParams;
    }, [
        parsedParams
    ]);
    var setUrlSearchParams = useCallback(function(state) {
        var newState = typeof state === "function" ? state(parsedParamsRef.current) : state;
        var next = qs.stringify(newState, {
            encodeValuesOnly: true
        });
        if (next !== locationRef.current.search) {
            history.replace(_objectSpreadProps(_objectSpread({}, locationRef.current), {
                search: next
            }));
        }
    }, [
        history
    ]);
    // eslint-disable-next-line @eventmobi/no-array-return-in-hook
    return [
        parsedParams,
        setUrlSearchParams
    ];
};
export default useSyncWithUrlSearchParams;
