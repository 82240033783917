import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import BodyStyle from './body';
import ButtonStyle from './button';
import CardStyle from './card';
import DropdownStyle from './dropdown';
import PaginationStyle from './pagination';
import RichTextEditorStyle from './richTextEditor';
import SelectStyle from './select';
import TableStyle from './table';

const defaultTheme = { primaryColor: '#6EBB83' };

// All component Overrides should go inside following component
//
// A `theme` can be passed down to the component as prop, when not
// provided, it will use the defaults.
const Overrides = ({ theme = defaultTheme }) => (
  <ThemeProvider theme={theme}>
    <>
      <ButtonStyle />
      <SelectStyle />
      <TableStyle />
      <CardStyle />
      <DropdownStyle />
      <PaginationStyle />
      <RichTextEditorStyle />
      <BodyStyle />
    </>
  </ThemeProvider>
);

Overrides.propTypes = {
  theme: PropTypes.shape({
    primaryColor: PropTypes.string,
  }),
};

export default Overrides;
