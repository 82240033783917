import { formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useReducer } from 'react';

import useTranslation from 'common/hooks/useTranslation';

const TimeAgo = ({ time, interval = 1000 }) => {
  const [, forceUpdate] = useReducer(v => v + 1, 0);
  const { dateLocale: locale } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (time < new Date()) {
        forceUpdate();
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [time, interval]);

  if (time > new Date()) {
    return null;
  }
  const diffTime = formatDistanceToNow(time, { includeSeconds: true, locale, addSuffix: true });
  return diffTime;
};

TimeAgo.propTypes = {
  time: PropTypes.instanceOf(Date).isRequired,
  interval: PropTypes.number,
};

export default TimeAgo;
