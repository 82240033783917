import { Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import { SALES_EMAIL } from 'common/services/support/types';
import { getShortcode as getEventCode } from 'common/state/event';

const FeatureContactSalesLink = ({ featureName }) => {
  const eventCode = useSelector(getEventCode);
  const subject = encodeURIComponent(
    t`Feature inquiry for ${featureName} - Event Code: ${eventCode}`
  );

  return (
    <Typography.Link
      style={{ whiteSpace: 'nowrap' }}
      href={`mailto:${SALES_EMAIL}?subject=${subject}`}
      target="_blank"
    >
      {t`Contact Sales`}
    </Typography.Link>
  );
};

export default FeatureContactSalesLink;

FeatureContactSalesLink.propTypes = {
  featureName: PropTypes.string.isRequired,
};
