import { Alert, Form, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useImperativeHandle } from 'react';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';

import { useEventsWithFeatureQuery } from '../queries';

import { useConnectedEventIdsQuery } from './queries';

const LinkEventsForm = ({ initialValues = null, formRef = null, disabled = false }) => {
  const [form] = Form.useForm();

  const {
    data: allowedEvents,
    isLoading: isAllowedEventsLoading,
    isError,
  } = useEventsWithFeatureQuery('reg_payments');
  const {
    data: connectedEventIds,
    isLoading: isConnectedEventIdsLoading,
  } = useConnectedEventIdsQuery();

  const handleSubmit = useCallback(async () => {
    // this is just to handle the case where useEventsWithFeatureQuery failed to load
    // in that case, form will not be initialized, and we should not submit empty form
    if (isError) {
      throw new Error('Form is not initialized');
    }
    return form.validateFields();
  }, [isError, form]);

  useImperativeHandle(formRef, () => ({ submit: handleSubmit }), [handleSubmit]);

  if (isAllowedEventsLoading || isConnectedEventIdsLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Alert showIcon type="error" message={t`Oops! Something went wrong.`} />;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        id: initialValues?.id,
        events: initialValues?.events ?? [],
      }}
      onFinish={handleSubmit}
      requiredMark={false}
      disabled={disabled}
    >
      <Form.Item
        name="events"
        label={
          <Label isRequired info={t`Assign events to use this payment gateway.`}>{t`Events`}</Label>
        }
        normalize={eventIds => eventIds.map(id => ({ id }))}
        getValueProps={events => ({
          value: events.map(event => ({ value: event.id })),
        })}
      >
        <Select
          mode="multiple"
          placeholder={t`Select`}
          options={allowedEvents.map(event => {
            const available =
              // Disabled if a gateway is already linked to this event...
              !connectedEventIds.has(event.id) ||
              // but explicitly *enabled* if it's *this* gateway so you unlink it
              // and don't see the tooltip.
              initialValues?.events.some(e => e.id === event.id);

            return {
              label: (
                <Tooltip
                  title={!available && t`This event is already linked to a payment gateway.`}
                >
                  {event.name}
                </Tooltip>
              ),
              value: event.id,
              disabled: !available,
            };
          })}
        />
      </Form.Item>
    </Form>
  );
};

LinkEventsForm.propTypes = {
  initialValues: PropTypes.shape({
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }),
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.shape({ submit: PropTypes.func.isRequired }),
    }),
  ]),
  disabled: PropTypes.bool,
};

export default LinkEventsForm;
