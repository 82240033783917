import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/cms/integration/v1/organizations/${organizationId}/organizer-keys`);

export function generate(organizationId, organizerId) {
  return api(organizationId)
    .post(null, {
      organization_id: organizationId,
      organizer_id: organizerId,
    })
    .then(response => response.data.data);
}
export function list(organizationId) {
  return api(organizationId)
    .get()
    .then(response => response.data.data);
}

export function emailKey(organizationId, id) {
  return api(organizationId)
    .post(`${id}/email`)
    .then(response => response.data.data);
}

export function regenerateKey(organizationId, id) {
  return api(organizationId)
    .post(`${id}/regenerate`)
    .then(response => response.data.data);
}

export function enableKey(organizationId, id) {
  return api(organizationId)
    .post(`${id}/enable`)
    .then(response => response.data.data);
}

export function disableKey(organizationId, id) {
  return api(organizationId)
    .post(`${id}/disable`)
    .then(response => response.data.data);
}

export function deleteKey(organizationId, id) {
  return api(organizationId)
    .delete(id)
    .then(response => response.data.data);
}
