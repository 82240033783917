import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal as AntModal,
  Radio,
  Row,
  Select,
  message,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import { listCmsEvents as eventListFn } from 'common/services/events/api';

import ColorPicker from '@components/ColorPicker';
import { listMEA as languages } from '@domains/Event/language-service';

import { list as appEventListFn } from './appevent-service';
import {
  get as getFn,
  create as createFn,
  update as updateFn,
  MODE_SINGLE,
  MODE_MULTI,
} from './service';

const { Item, useForm } = Form;

const Description = styled(Typography.Text).attrs(() => ({ type: 'secondary' }))`
  display: block;
  font-size: 12px;
  font-style: italic;
`;

const DEFAULT_LANG = 'en';
const DEFAULT_ACTION_COLOR = '#FF9E1B';
const DEFAULT_HEADER_COLOR = '#1D252D';
const DEFAULT_SECONDARY_COLOR = '#00C389';
const APP_CODE_REGEX = /^(([a-zA-Z]|[0-9-_])*[a-zA-Z-_][0-9]*)$/;

const useModal = (organizationId, appId) => {
  const isEditing = !!appId;
  const [app, setApp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [mode, setMode] = useState(MODE_MULTI);
  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        if (isEditing) {
          setApp(await getFn(organizationId, appId));
          setEventList(
            await appEventListFn(organizationId, appId).then(events =>
              events?.map(event => ({ value: event.eventId, label: event.name }))
            )
          );
        } else {
          setEventList(
            await eventListFn(organizationId).then(response =>
              response.data?.map(event => ({ value: event.id, label: event.name }))
            )
          );
        }
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [organizationId, appId, isEditing]);

  useEffect(() => {
    setMode(app?.launchEventId ? MODE_SINGLE : MODE_MULTI);
  }, [app]);

  return {
    app,
    eventList,
    isEditing,
    loading,
    mode,
    setMode,
  };
};

const Modal = ({ onClose, organizationId, appId }) => {
  const { app, eventList, isEditing, loading, mode, setMode } = useModal(organizationId, appId);
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [form] = useForm();
  const { validateFields } = form;

  const onFinish = async () => {
    setError(null);
    try {
      setSaving(true);
      const rawPayload = await validateFields();
      const payload = {
        ...rawPayload,
        lauchEventId: rawPayload.modeId === MODE_SINGLE ? rawPayload.launchEventId : null,
        primaryColor: rawPayload.primaryColor?.replace('#', ''),
        secondaryColor: rawPayload.secondaryColor?.replace('#', ''),
        secondaryActionColor: rawPayload.secondaryActionColor?.replace('#', ''),
      };

      if (isEditing) {
        await updateFn(organizationId, appId, payload);
      } else {
        await createFn(organizationId, payload);
      }
      message.success(t`Successfully saved App`);
      onClose(true);
    } catch (e) {
      if (!e.errorFields) {
        setError(e?.errors?.[0]?.message ?? t`An unknown error occurred while saving app.`);
        throw e;
      }
    } finally {
      setSaving(false);
    }
  };

  const onSave = () => {
    form.submit();
  };

  const title = isEditing ? t`Edit App` : t`Add App`;
  return (
    <AntModal
      title={title}
      open
      confirmLoading={loading}
      maskClosable
      destroyOnClose
      onCancel={() => onClose(false)}
      footer={
        <>
          <Button onClick={() => onClose(false)}>{t`Cancel`}</Button>
          <Button type="primary" loading={saving} disabled={saving} onClick={onSave}>
            {t`Save`}
          </Button>
        </>
      }
      width={700}
    >
      {loading && <Loading />}

      {!loading && (!isEditing || app) && (
        <Form.Provider onFormFinish={onFinish}>
          {error && (
            <Row css="margin-bottom: 20px">
              <Col span={24}>
                <Alert type="error" message={error} showIcon />
              </Col>
            </Row>
          )}
          <Form
            layout="vertical"
            name="info"
            requiredMark={false}
            form={form}
            initialValues={{
              name: app?.name,
              shortcode: app?.shortcode,
              modeId: app?.launchEventId ? MODE_SINGLE : MODE_MULTI,
              launchEventId: app?.launchEventId,
              localeCode: app?.localeCode ?? DEFAULT_LANG,
              primaryColor: app?.primaryColor ? `#${app?.primaryColor}` : DEFAULT_ACTION_COLOR,
              secondaryColor: app?.secondaryColor
                ? `#${app?.secondaryColor}`
                : DEFAULT_HEADER_COLOR,
              secondaryActionColor: app?.secondaryActionColor
                ? `#${app?.secondaryActionColor}`
                : DEFAULT_SECONDARY_COLOR,
            }}
          >
            <Item
              name="name"
              label={<Label isRequired>{t`Name`}</Label>}
              rules={[{ required: true, message: t`App Name is required` }]}
            >
              <Input maxLength={255} />
            </Item>
            <Item
              name="shortcode"
              label={<Label isRequired>{t`Code`}</Label>}
              rules={[
                { required: true, message: t`App Code is required` },
                { min: 3, message: t`App Code should have at least 3 characters` },
                { max: 128, message: t`App Code should have at a maximum of 128 characters` },
                {
                  pattern: new RegExp(APP_CODE_REGEX),
                  message: t` Please make sure your App Code doesn't have special characters and have at least one of the following characters: a-zA-Z, _ , -. Note that all numeric event codes are not allowed`,
                },
              ]}
              extra={<Description>{t`Used to create a unique URL for your app.`}</Description>}
            >
              <Input maxLength={128} disabled={isEditing} />
            </Item>
            <Item
              name="modeId"
              required="true"
              label={<Label>{t`Mode`}</Label>}
              extra={
                <Description>
                  {t`This controls where users will land when they launch the app. In multi-event
                  mode, users land on the event list. In single event mode, the specified event will
                  be launched and the event list will not be accessible.`}
                </Description>
              }
            >
              <Radio.Group onChange={event => setMode(event.target.value)}>
                <Radio.Button value={MODE_MULTI}>{t`Multi-Event`}</Radio.Button>
                <Radio.Button value={MODE_SINGLE}>{t`Single Event`}</Radio.Button>
              </Radio.Group>
            </Item>
            {mode === MODE_SINGLE ? (
              <Item
                name="launchEventId"
                label={<Label isRequired>{t`Event`}</Label>}
                rules={[{ required: true, message: t`Event to launch is required` }]}
              >
                <Select
                  placeholder={t`Select Event`}
                  optionFilterProp="label"
                  options={eventList}
                />
              </Item>
            ) : (
              <>
                <Item
                  name="localeCode"
                  label={<Label isRequired>{t`Language`}</Label>}
                  rules={[{ required: true, message: t`Language is required` }]}
                >
                  <Select
                    placeholder={t`Select Language`}
                    optionFilterProp="label"
                    options={languages()}
                  />
                </Item>
                <Row>
                  <Col span={8}>
                    <Item
                      name="primaryColor"
                      label={<Label>{t`Action Color`}</Label>}
                      extra={<Description>{t`Used for launch app button.`}</Description>}
                      rules={[{ required: true, message: t`Action Color is required` }]}
                    >
                      <ColorPicker />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      name="secondaryColor"
                      label={<Label>{t`Header Color`}</Label>}
                      extra={<Description>{t`Used for app title bar.`}</Description>}
                      rules={[{ required: true, message: t`Header Color is required` }]}
                    >
                      <ColorPicker />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      name="secondaryActionColor"
                      label={<Label>{t`Secondary Action Color`}</Label>}
                      extra={<Description>{t`Used for registration button.`}</Description>}
                      rules={[{ required: true, message: t`Secondary Action Color is required` }]}
                    >
                      <ColorPicker />
                    </Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Form.Provider>
      )}
    </AntModal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  appId: PropTypes.string,
};

export default Modal;
