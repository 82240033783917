import { Alert, Form, Input, message, Modal, Select, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Label from 'common/components/Label';

import { ERROR_MESSAGES_BY_CODE } from './constants';
import { update as updateFn } from './service';

const Field = styled(Form.Item)`
  && {
    max-width: 716px;
    margin-bottom: 15px;
    ${props => props.$indented && 'margin-left: 10px'}
  }
`;

const { useForm } = Form;

const EditModal = ({ organizationId, sso, connection, onClose = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = useForm();

  const handleSubmit = async () => {
    const payload = await form.validateFields();

    try {
      setError(null);
      setLoading(true);
      await updateFn(organizationId, connection.id, payload);

      onClose(true);
      message.success(t`SSO Connection saved successfully.`);
    } catch (err) {
      setError(
        ERROR_MESSAGES_BY_CODE()[err.errors?.[0]?.code] ??
          t`Unknown error occurred while saving SSO connection.`
      );
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t`Edit SSO Connection`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={() => onClose(false)}
      cancelText={t`Cancel`}
      okText={t`Save`}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        {error && <Alert showIcon type="error" message={error} />}
        {!error && (
          <Alert
            showIcon
            type="info"
            message={t`You can change connection name and linked events for the connection that has been created.`}
          />
        )}

        <Form form={form} layout="vertical" requiredMark={false} initialValues={connection}>
          <Field
            $indented
            name="displayName"
            label={<Label isRequired>{t`Connection Name`}</Label>}
            rules={[
              { required: true, message: t`Connection Name is required.` },
              { max: 35, message: t`Maximum of 35 characters.` },
            ]}
          >
            <Input placeholder={t`Enter connection name with up to 35 characters`} />
          </Field>

          <Field
            $indented
            name="events"
            label={<Label>{t`Event Codes`}</Label>}
            rules={[{ type: 'array' }]}
            normalize={ids => ids.map(id => ({ id }))}
            getValueProps={events => ({
              value: (events ?? []).sort((a, b) => a.order - b.order).map(({ id }) => id),
            })}
          >
            <Select
              mode="multiple"
              options={sso.ssoEnabledEvents.map(event => ({
                label: event.name,
                value: event.id,
              }))}
            />
          </Field>
        </Form>
      </Space>
    </Modal>
  );
};

EditModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  sso: PropTypes.shape({
    oidcSignOnUrl: PropTypes.string.isRequired,
    samlSignOnUrl: PropTypes.string.isRequired,
    audienceUri: PropTypes.string.isRequired,
    ssoEnabledEvents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }),
  connection: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

export default EditModal;
