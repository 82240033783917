import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { msgid, ngettext, t } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import DeleteModal from 'common/components/Modal/DeleteModal';
import { getId } from 'common/state/organization';

import usePermission, {
  CREATE_SENDER_EMAIL_DOMAINS,
  DELETE_SENDER_EMAIL_DOMAINS,
} from '@hooks/usePermission';

import AddModal from './AddModal';
import { useBulkDeleteEmailDomainMutation } from './queries';
import EmailDomainTable from './Table';
import ViewRecordsModal from './ViewRecordsModal';

const EmailDomain = ({ active = false }) => {
  const organizationId = useSelector(getId);
  const { mutateAsync: bulkDelete } = useBulkDeleteEmailDomainMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [viewRecordsFor, setViewRecordsFor] = useState(null);
  const { authorized } = usePermission();

  const onCloseAddModal = createdId => {
    setShowAddModal(false);

    if (createdId) {
      setViewRecordsFor(createdId);
    }
  };

  const onCloseViewRecordsModal = () => {
    setViewRecordsFor(null);
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const onSelect = (keys, rows) => setSelectedRows(rows);

  const onDelete = rows => {
    const { name } = rows[0];

    DeleteModal.confirm({
      label: {
        singular: t`Email Domain`,
        plural: t`Email Domains`,
      },
      itemName: name,
      additionalInfo: t`Any events using this email domain  will revert to the default email address using the @event-emails.com.`,
      count: rows.length,
      async onDelete() {
        await bulkDelete({ organizationId, ids: rows.map(({ id }) => id) });

        onCloseViewRecordsModal();
        setSelectedRows([]);
      },
    });
  };

  if (!active) {
    return null;
  }

  return (
    <>
      {showAddModal && <AddModal organizationId={organizationId} onClose={onCloseAddModal} />}
      {viewRecordsFor && (
        <ViewRecordsModal
          organizationId={organizationId}
          domainId={viewRecordsFor}
          onClose={onCloseViewRecordsModal}
          onDelete={onDelete}
        />
      )}

      <Row type="flex" css="margin: 20px 0;">
        <Col span={8}>
          {authorized(CREATE_SENDER_EMAIL_DOMAINS) && (
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowAddModal(true)}>
              {t`Add Email Domain`}
            </Button>
          )}
        </Col>
      </Row>

      <EmailDomainTable
        viewRecords={setViewRecordsFor}
        onDelete={onDelete}
        onRowSelection={onSelect}
        selectedRowKeys={selectedRows.map(({ id }) => id)}
      />

      {authorized(DELETE_SENDER_EMAIL_DOMAINS) && (
        <DeleteBar
          style={{ left: 0 }}
          open={active && selectedRows.length > 0}
          buttonText={ngettext(
            msgid`Delete ${selectedRows.length} Email Domain`,
            `Delete ${selectedRows.length} Email Domains`,
            selectedRows.length
          )}
          onClick={() => onDelete(selectedRows)}
        />
      )}
    </>
  );
};

EmailDomain.propTypes = {
  active: PropTypes.bool,
};

export default EmailDomain;
