import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space, Row, Col, Button, Typography, Radio } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';

import Empty from 'common/components/Empty';

import EmptyHiddenImage from '@assets/images/illustrations/live-results-hidden.svg';
import EmptyVisibleImage from '@assets/images/illustrations/live-results.svg';

import { QUESTION_TYPE_AAQ, QUESTION_TYPE_LIVE_POLL, QUESTION_TYPE_SURVEY } from '../constants';

import Layout from './Layout';
import Table from './Table';
import useModeration from './useModeration';

const { Text } = Typography;

export const Moderation = () => {
  const { eventId, hash, questionType, questionId, sessionId } = useParams();
  const { session, dataSource, surveyName, toggleVisibility, error } = useModeration(
    eventId,
    hash,
    questionType,
    questionId,
    sessionId
  );
  const [visible, setVisible] = useState(true);

  const onChangeVisibility = ({ target: { value } }) => {
    setVisible(value);
  };

  const visibleDataSource = dataSource && dataSource.filter(data => data.visible);
  const hiddenDataSource = dataSource && dataSource.filter(data => !data.visible);
  const totalVisible = visibleDataSource?.length ?? 0;
  const totalHidden = hiddenDataSource?.length ?? 0;
  const presentResponsesLink = (() => {
    switch (questionType) {
      case QUESTION_TYPE_SURVEY:
        return `/event/${eventId}/question/${questionId}/live-results/${hash}/${questionType}`;
      case QUESTION_TYPE_LIVE_POLL:
        return `/event/${eventId}/question/${questionId}/live-results/${hash}/${questionType}/${sessionId}`;
      default:
        return `/event/${eventId}/question/live-results/${hash}/${questionType}/${sessionId}`;
    }
  })();
  const options = [
    { label: `${t`Visible`} (${totalVisible})`, value: true },
    { label: `${t`Hidden`} (${totalHidden})`, value: false },
  ];
  const activeDataSource = visible ? visibleDataSource : hiddenDataSource;

  return (
    <Layout liveUpdates={!error} session={session} surveyName={surveyName}>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <Row justify="space-between">
          <Col>
            <Button href={presentResponsesLink} target="_blank" size="medium">
              <Space>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                {t`Present Responses`}
              </Space>
            </Button>
          </Col>
          <Col>
            <Space>
              <Text>{t`View`}:</Text>
              <Radio.Group
                size="medium"
                options={options}
                onChange={onChangeVisibility}
                value={visible}
                optionType="button"
                buttonStyle="solid"
              />
            </Space>
          </Col>
        </Row>
        {activeDataSource && activeDataSource.length > 0 && (
          <Table
            dataSource={activeDataSource}
            onToggleVisibility={toggleVisibility}
            hideUpvotes={questionType !== QUESTION_TYPE_AAQ}
          />
        )}
        {visibleDataSource && visibleDataSource.length === 0 && visible && (
          <Empty
            image={EmptyVisibleImage}
            title={t`There are no responses yet`}
            body={t`The page will auto update when responses are submitted.`}
          />
        )}
        {hiddenDataSource && hiddenDataSource.length === 0 && !visible && (
          <Empty
            image={EmptyHiddenImage}
            title={t`There are no hidden responses`}
            body={t`Responses that have been hidden will appear here.`}
          />
        )}
      </Space>
    </Layout>
  );
};

export default Moderation;
