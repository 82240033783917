import apiFn from 'common/services/api-service';

import { transform } from './page-service';

const api = token => {
  let header = { Accept: 'application/vnd.eventmobi+json; version=p.5' };
  const withCredentials = !token;

  if (token) {
    header = { ...header, Authorization: `Basic ${token}` };
  }

  return apiFn(`/api/uapi/widgets-page-templates`, header, withCredentials);
};

export function get(id, token) {
  return api(token)
    .get(id, { params: { include: 'widgets,background_image' } })
    .then(response => transform(response.data.data ?? {}));
}

export function list(onlyPublished = true, include = []) {
  const params = {};

  if (onlyPublished > 0) {
    params.isPublished = true;
  }

  if (include.length > 0) {
    params.include = include.join(',');
  }

  return api()
    .get(null, { params })
    .then(response => response.data.data);
}
