function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import axios from "axios";
import apiFn from "./api-service";
export var Status;
(function(Status) {
    Status["Finalized"] = "finalized";
    Status["Cancelled"] = "cancelled";
})(Status || (Status = {}));
var ALLOWED_MIME_TYPES = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp"
];
var ALLOWED_STATUSES = [
    Status.Finalized,
    Status.Cancelled
];
var api = function(resourceId, resourceType) {
    var token = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "";
    var headers = {};
    // If token is provided it is called from self edit and needs Authorization header
    if (token) {
        headers = {
            Authorization: "self-edit ".concat(token)
        };
    }
    return apiFn("/api/uapi/".concat(resourceType, "/").concat(resourceId, "/images/"), headers);
};
export var get = function(resourceId, imageId) {
    var params = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, token = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "", resourceType = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : "events";
    return api(resourceId, resourceType, token).get(imageId.toString(10), {
        params: params
    }).then(function(response) {
        return response.data.data;
    });
};
export var list = function(resourceId) {
    var params = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, resourceType = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "events";
    return api(resourceId, resourceType).get("", {
        params: _objectSpread({}, params)
    }).then(function(response) {
        return response.data.data;
    });
};
export var update = function(resourceId, id) {
    var params = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, token = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "", resourceType = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : "events";
    return api(resourceId, resourceType, token).patch(id, params).then(function(response) {
        return response.data.data;
    });
};
export var duplicate = function(resourceId, id) {
    var params = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, resourceType = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "events";
    return api(resourceId, resourceType).post("".concat(id, "/duplicate"), params).then(function(response) {
        return response.data.data;
    });
};
export var notify = function(resourceId, imageId) {
    var status = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : Status.Finalized, token = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "", resourceType = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : "events";
    if (typeof imageId === "undefined" || imageId === null) {
        throw new Error("file id cannot be either undefined or null");
    }
    if (!ALLOWED_STATUSES.includes(status)) {
        throw new Error("invalid status, allowed status: ".concat(ALLOWED_STATUSES.join(", ")));
    }
    return update(resourceId, imageId, {
        status: status
    }, token, resourceType);
};
export var crop = function(resourceId, imageId, param) {
    var cropX = param.x, cropY = param.y, cropHeight = param.height, cropWidth = param.width, token = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "", resourceType = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : "events";
    var payload = {
        cropX: cropX,
        cropY: cropY,
        cropHeight: cropHeight,
        cropWidth: cropWidth
    };
    return update(resourceId, imageId, payload, token, resourceType);
};
export var create = function(resourceId, name, mimeType, length, type) {
    var token = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : "", resourceType = arguments.length > 6 && arguments[6] !== void 0 ? arguments[6] : "events";
    if (typeof name === "undefined" || name === null) {
        throw new Error("image name cannot be either undefined or null");
    }
    if (typeof mimeType === "undefined" || mimeType === null) {
        throw new Error("image mime type cannot be either undefined or null");
    }
    if (!ALLOWED_MIME_TYPES.includes(mimeType)) {
        throw new Error("invalid mime type, allowed mime types: ".concat(ALLOWED_MIME_TYPES.join(", ")));
    }
    var payload = {
        name: name,
        length: length,
        type: type,
        mimeType: mimeType
    };
    return api(resourceId, resourceType, token).post(null, payload).then(function(response) {
        return response.data.data;
    });
};
export var upload = function(url, file) {
    var onProgress = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : function() {};
    if (typeof url === "undefined" || url === null) {
        throw new Error("image upload url cannot either undefined or null");
    }
    if ("File" in window && !_instanceof(file, window.File)) {
        throw new Error("invalid file: it should be instance of window.File");
    }
    if (typeof onProgress !== "function") {
        throw new Error("onProgress callback is not a function");
    }
    return axios.put(url, file, {
        headers: {
            "Content-Type": file.type
        },
        onUploadProgress: onProgress
    });
};
export var remove = function(resourceId, imageId) {
    var token = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "", resourceType = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "events";
    return api(resourceId, resourceType, token).delete(imageId).then(function(response) {
        return response.data.data;
    });
};
export var preview = function(resourceId, imageId) {
    var params = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, token = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "", resourceType = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : "events";
    if (typeof resourceId === "undefined") {
        throw new Error("resource id is required");
    }
    if (typeof imageId === "undefined") {
        throw new Error("image id is required");
    }
    if (typeof params.x === "undefined" || typeof params.y === "undefined" || typeof params.height === "undefined" || typeof params.width === "undefined") {
        throw new Error("params are required to have x, y, height, and width values");
    }
    var x = params.x, y = params.y, height = params.height, width = params.width;
    var start = [
        Number.parseInt(x, 10),
        Number.parseInt(y, 10)
    ];
    var end = [
        Number.parseInt(x + width, 10),
        Number.parseInt(y + height, 10)
    ];
    if (_toConsumableArray(start).concat(_toConsumableArray(end)).reduce(function(prev, next) {
        return prev || Number.isNaN(next);
    }, false)) {
        throw new Error("x, y, height, and width should be valid integers");
    }
    return get(resourceId, imageId, {
        pre_crop: _toConsumableArray(start).concat(_toConsumableArray(end)).join(",")
    }, token, resourceType);
};
