import { t } from 'ttag';

// following types need to be same as Flux
export const TYPE_IMAGE = 'image';
export const TYPE_GOOGLE_MAP = 'google_map';
export const TYPE_COUNTDOWN = 'countdown';
export const TYPE_AGENDA = 'agenda';
export const TYPE_GAMIFICATION = 'gamification';
export const TYPE_GROUP_DISCUSSION = 'group_discussion';
export const TYPE_SPACE = 'space';
export const TYPE_BANNER = 'banner';
export const TYPE_VIDEO = 'video';
export const TYPE_RICH_TEXT = 'rich_text';
export const TYPE_PAGE_BACKGROUND = 'background';
export const TYPE_BUTTON = 'button';

export const TYPES = () => ({
  [TYPE_BANNER]: t`Banner`,
  [TYPE_IMAGE]: t`Image`,
  [TYPE_RICH_TEXT]: t`Text Block`,
  [TYPE_BUTTON]: t`Button`,
  [TYPE_VIDEO]: t`Video`,
  [TYPE_SPACE]: t`Space`,
  [TYPE_COUNTDOWN]: t`Countdown`,
  [TYPE_AGENDA]: t`Personal Agenda`,
  [TYPE_GAMIFICATION]: t`Gamification`,
  [TYPE_GROUP_DISCUSSION]: t`Group Discussion`,
  [TYPE_GOOGLE_MAP]: t`Google Map`,
});

export const TYPE_MAX_COUNT = {
  [TYPE_BANNER]: 30,
  [TYPE_IMAGE]: 100,
  [TYPE_GOOGLE_MAP]: 5,
  [TYPE_COUNTDOWN]: 10,
  [TYPE_AGENDA]: 1,
  [TYPE_GAMIFICATION]: 1,
  [TYPE_GROUP_DISCUSSION]: 1,
  [TYPE_SPACE]: 100,
  [TYPE_VIDEO]: 10,
  [TYPE_RICH_TEXT]: 100,
  [TYPE_BUTTON]: 30,
};

export const MARGIN_X = 0;
export const MARGIN_Y = 0;
export const MARGIN = [MARGIN_X, MARGIN_Y];

export const MOBILE_MARGIN_X = 20;
export const MOBILE_MARGIN_Y = 0;
export const MOBILE_MARGIN = [MOBILE_MARGIN_X, MOBILE_MARGIN_Y];

export const MOBILE_PADDING_X = 20;
export const MOBILE_PADDING_Y = 0;
export const MOBILE_PADDING = [MOBILE_PADDING_X, MOBILE_PADDING_Y];

export const PADDING_X = 0;
export const PADDING_Y = 0;
export const PADDING = [PADDING_X, PADDING_Y];

export const MAX_COLUMNS = 24;
export const MAX_MOBILE_COLUMNS = 8;

export const MAX_WIDTH = 1200;
export const MAX_MOBILE_WIDTH = 432;

// Using the same formula RGL uses for calculating width in pixel, calculate row height for both mobile and desktop
// Reference: https://github.com/STRML/react-grid-layout/blob/2f9b745ffe5bebbf9df0e9a59f825e4eb871b13f/lib/calculateUtils.js#L17
export const ROW_HEIGHT = (MAX_WIDTH - MARGIN_Y * (MAX_COLUMNS - 1) - PADDING_Y * 2) / MAX_COLUMNS;
export const MOBILE_ROW_HEIGHT =
  (MAX_MOBILE_WIDTH - MOBILE_MARGIN_Y * (MAX_MOBILE_COLUMNS - 1) - MOBILE_PADDING_Y * 2) /
  MAX_MOBILE_COLUMNS;

export const DEFAULT_LIMITS = { minW: 4, maxW: MAX_COLUMNS, minH: 4, maxH: 16 };

export const TYPE_LIMITS = {
  [TYPE_IMAGE]: { minH: 1, minW: 2, maxH: 18, maxW: MAX_COLUMNS },
  [TYPE_BANNER]: { minH: 4, minW: MAX_COLUMNS, maxH: 18, maxW: MAX_COLUMNS },
  [TYPE_GOOGLE_MAP]: { minH: 4, minW: 8, maxH: 12, maxW: MAX_COLUMNS },
  [TYPE_COUNTDOWN]: { minH: 3, maxH: 4, minW: 8, maxW: MAX_COLUMNS },
  [TYPE_AGENDA]: { minH: 12, minW: 8, maxH: 18, maxW: MAX_COLUMNS },
  [TYPE_GAMIFICATION]: { minH: 12, minW: 8, maxH: 18, maxW: MAX_COLUMNS },
  [TYPE_GROUP_DISCUSSION]: { minH: 13, minW: 8, maxH: 18, maxW: MAX_COLUMNS },
  [TYPE_SPACE]: { minH: 1, minW: 1, maxH: 64, maxW: MAX_COLUMNS },
  [TYPE_VIDEO]: { minH: 5, minW: 8, maxH: 12, maxW: MAX_COLUMNS },
  [TYPE_RICH_TEXT]: { minH: 1, minW: 4, maxH: 18, maxW: MAX_COLUMNS },
  [TYPE_BUTTON]: { minH: 1, minW: 3, maxH: 18, maxW: MAX_COLUMNS },
};

// use it with caution, this will override all the other calculations for height and width for every
// defined widget type.
// For image, it was a product requirement
export const PREFERRED_DIMENSIONS = {
  [TYPE_SPACE]: { h: 1, w: MAX_COLUMNS },
  [TYPE_IMAGE]: { h: 4, w: 4 },
};

export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96];

export const FONT_STYLES = [
  'Helvetica',
  'Arial',
  'Georgia',
  'Times New Roman',
  'Impact',
  'Tahoma',
  'Verdana',
];

export const VIEW_DESKTOP = 'desktop';
export const VIEW_MOBILE = 'mobile';

export const BUTTON_TYPE_SECTION = 'section';
export const BUTTON_TYPE_WEBSITE = 'website';
