const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];

// eslint-disable-next-line import/prefer-default-export
export const formatBytes = bytes => {
  if (bytes === 0) return 'n/a';
  if (!bytes) return null;

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (i === 0) return `${bytes} ${sizes[i]}`;

  return `${(bytes / 1024 ** i).toFixed(0)} ${sizes[i]}`;
};
