import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import styled from 'styled-components';

import ChartLegend from './ChartLegend';

const COLUMN_CHART = 'column';
const PIE_CHART = 'pie';

const COLUMN_CHART_HEIGHT = 500;
const COLUMN_CHART_WIDTH = 1000;

const PIE_CHART_HEIGHT = 400;
const PIE_CHART_WIDTH = 400;

const columnChartOptions = (fontSize, color) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color,
        font: {
          size: fontSize,
        },
      },
    },
    y: {
      ticks: {
        color,
        beginAtZero: true,
        font: {
          size: fontSize,
        },
        callback: value => `${value}%`,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
});

const pieChartOptions = {
  responsive: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const ChartContainer = styled(Col)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const transformToChartData = dataSource => {
  const values = dataSource.reduce(
    (accum, item) => {
      accum.labels.push(item.label);
      accum.backgroundColor.push(item.color);
      accum.data.push(item.value);
      return accum;
    },
    { labels: [], backgroundColor: [], data: [] }
  );
  return {
    labels: values.labels,
    datasets: [
      {
        backgroundColor: values.backgroundColor,
        data: values.data,
      },
    ],
  };
};

const Chart = ({
  type,
  dataSource,
  textColor = '#4A4A4A',
  totalResponses,
  height,
  width,
  fontSize,
}) => {
  const chartData = transformToChartData(dataSource);
  return (
    <Row>
      <ChartContainer span={12} xl={12}>
        {type === COLUMN_CHART && (
          <Bar
            data={chartData}
            options={columnChartOptions(fontSize, textColor)}
            height={height || COLUMN_CHART_HEIGHT}
            width={width || COLUMN_CHART_WIDTH}
          />
        )}
        {type === PIE_CHART && (
          <Pie
            data={chartData}
            options={pieChartOptions}
            height={height || PIE_CHART_HEIGHT}
            width={width || PIE_CHART_WIDTH}
          />
        )}
      </ChartContainer>
      <Col xs={24} xl={12}>
        <ChartLegend
          fontSize={fontSize}
          height={height || COLUMN_CHART_HEIGHT}
          width={width || COLUMN_CHART_WIDTH}
          type={type}
          dataSource={dataSource}
          textColor={textColor}
          totalResponses={totalResponses}
        />
      </Col>
    </Row>
  );
};

Chart.propTypes = {
  type: PropTypes.oneOf([COLUMN_CHART, PIE_CHART]).isRequired,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      count: PropTypes.number,
    }).isRequired
  ),
  totalResponses: PropTypes.number,
  textColor: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  fontSize: PropTypes.number,
};

export default Chart;
