import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

import useTranslation from 'common/hooks/useTranslation';

import Layout from './Layout';

function HTTP500({ scoped, previousHref }) {
  useTranslation();
  return (
    <Layout
      scoped={scoped}
      title={t`Sorry, something went wrong`}
      body={t`We've encountered an issue. Please reload or try again later.`}
      support={false}
    >
      <Button type={scoped ? 'secondary' : 'primary'} onClick={() => window.location.reload()}>
        {t`Reload`}
      </Button>
      {!scoped && (
        <Button type="secondary" onClick={() => window.location.replace(previousHref)}>
          {t`Return to Home`}
        </Button>
      )}
    </Layout>
  );
}

HTTP500.propTypes = {
  scoped: PropTypes.bool,
  previousHref: PropTypes.string,
};

HTTP500.defaultProps = {
  scoped: false,
  previousHref: '/',
};

export default HTTP500;
