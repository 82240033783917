import undoable from 'common/state/undoable';

import hashFn from '@services/hash-service';

const initialModal = {
  id: null,
  type: null,
  isEdit: false,
};

const initialBackground = {
  color: null,
  image: { id: null, url: null },
  isFixed: null,
  size: null,
};

export const initialState = {
  id: '',
  name: '',

  updatedAt: null,
  createdAt: null,

  background: { ...initialBackground },
  modal: { ...initialModal },

  checksum: null,
  history: [],
  lookup: {},
  widgets: [],

  pristine: true,
  origin: {
    widgets: [],
    lookup: {},
    background: { ...initialBackground },
  },

  loading: true,
  isMobile: false,
  responsive: true,
};

export const ACTION_OPEN_MODAL = 'OPEN_MODAL';
export const ACTION_CLOSE_MODAL = 'CLOSE_MODAL';
export const ACTION_SET_MOBILE_MODE = 'SET_MOBILE_MODE';
export const ACTION_SET_LOADING = 'SET_LOADING';
export const ACTION_EDIT_BACKGROUND = 'EDIT_BACKGROUND';

export const ACTION_POPULATE = 'POPULATE';
export const ACTION_DISCARD = 'DISCARD';

export const ACTION_NEW_WIDGET = 'NEW_WIDGET';
export const ACTION_EDIT_WIDGET = 'EDIT_WIDGET';
export const ACTION_DELETE_WIDGET = 'DELETE_WIDGET';

export const ACTION_UPDATE_WIDGETS = 'UPDATE_WIDGETS';

const SKIP_ACTIONS_HISTORY = [
  ACTION_SET_LOADING,
  ACTION_OPEN_MODAL,
  ACTION_CLOSE_MODAL,
  ACTION_POPULATE,
  ACTION_SET_MOBILE_MODE,
];

function skipHistory({ type, prevType }) {
  if (SKIP_ACTIONS_HISTORY.includes(type)) {
    return true;
  }
  if (type === ACTION_UPDATE_WIDGETS && prevType !== ACTION_UPDATE_WIDGETS) {
    return true;
  }

  return false;
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_POPULATE: {
      const { background, widgets, id, name, updatedAt, responsive } = action.payload;

      // use white color as fallback background
      if (!background.color && !background.url) {
        background.color = '#FFFFFF';
      }

      const lookup = widgets.reduce((prev, next) => ({ ...prev, [next.id]: { ...next } }), {});
      const checksum = hashFn({ lookup, background });

      return {
        ...state,
        responsive,
        loading: false,
        pristine: true,
        history: [checksum],
        origin: JSON.parse(JSON.stringify({ background, widgets, lookup })),
        id,
        checksum,
        name,
        updatedAt,
        background,
        widgets,
        lookup,
      };
    }
    case ACTION_DISCARD: {
      const { background, lookup, widgets } = state.origin;
      const checksum = hashFn({ lookup, background });

      return {
        ...state,
        pristine: true,
        history: [checksum],
        background: { ...background },
        lookup: { ...lookup },
        widgets: [...widgets],
        checksum,
      };
    }
    case ACTION_SET_MOBILE_MODE: {
      return { ...state, isMobile: action.payload };
    }
    case ACTION_SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case ACTION_OPEN_MODAL: {
      return {
        ...state,
        modal: { ...state.modal, ...action.payload },
      };
    }
    case ACTION_CLOSE_MODAL: {
      return {
        ...state,
        modal: {
          ...initialModal,
        },
      };
    }
    case ACTION_NEW_WIDGET: {
      const widgets = [...state.widgets, { ...action.payload, new: true }];
      const lookup = widgets.reduce((prev, next) => ({ ...prev, [next.id]: { ...next } }), {});
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        pristine: false,
        history: Array.from(new Set([...state.history, checksum])),
        widgets,
        lookup,
        checksum,
      };
    }
    case ACTION_EDIT_WIDGET: {
      const { id, params, visible } = action.payload;

      const src = state.lookup[id];
      const updated = {
        ...src,
        params: { ...src.params, ...params },
        visible: visible ?? src.visible,
      };

      const index = state.widgets.findIndex(w => w.id === id);

      state.widgets.splice(index, 1, { ...updated });

      const widgets = [...state.widgets];
      const lookup = { ...state.lookup, [id]: { ...updated } };
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        pristine: state.checksum === null || state.history?.[0] === checksum,
        history: Array.from(new Set([...state.history, checksum])),
        checksum,
        widgets,
        lookup,
      };
    }
    case ACTION_DELETE_WIDGET: {
      const { id } = action.payload;

      const index = state.widgets.findIndex(w => w.id === id);

      state.widgets.splice(index, 1);

      const { [id]: _, ...lookup } = state.lookup;

      const widgets = [...state.widgets];
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        widgets,
        lookup,
        pristine: state.checksum === null || state.history?.[0] === checksum,
        history: Array.from(new Set([...state.history, checksum])),
      };
    }
    case ACTION_EDIT_BACKGROUND: {
      const background = { ...action.payload };
      const checksum = hashFn({ lookup: state.lookup, background });

      return {
        ...state,
        pristine: false,
        history: Array.from(new Set([...state.history, checksum])),
        background,
        checksum,
      };
    }
    case ACTION_UPDATE_WIDGETS: {
      const { widgets } = action.payload;
      const lookup = widgets.reduce((prev, next) => ({ ...prev, [next.id]: { ...next } }), {});
      const checksum = hashFn({ lookup, background: state.background });

      return {
        ...state,
        lookup,
        widgets,
        checksum,
        pristine: state.checksum === null || state.history?.[0] === checksum,
        history: Array.from(new Set([...state.history, checksum])),
      };
    }
    default:
      return { ...initialState };
  }
};

export default undoable(reducer, skipHistory, ['modal']);
