function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
var __generator = this && this.__generator || function(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return(g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g);
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
};
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "Something went wrong. Please try again."
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { t } from "ttag";
import * as Sentry from "@sentry/react";
import { get as getSessionFn } from "../../services/auth/api";
import { store } from "../../services/session-service";
export var ERROR_MESSAGES = function() {
    return {
        somethingWentWrong: t(_templateObject())
    };
};
var useInvite = function(token, id, checkInviteStatusFn, acceptInviteFn) {
    var _useState = _slicedToArray(useState(true), 2), isValidating = _useState[0], setIsValidating = _useState[1];
    var history = useHistory();
    var _useState1 = _slicedToArray(useState(), 2), status = _useState1[0], setStatus = _useState1[1];
    var _useState2 = _slicedToArray(useState(false), 2), isAcceptInProgress = _useState2[0], setIsAcceptInProgress = _useState2[1];
    useEffect(function() {
        function effect() {
            return _effect.apply(this, arguments);
        }
        function _effect() {
            _effect = _asyncToGenerator(function() {
                var invitationStatus, err;
                return __generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            _state.trys.push([
                                0,
                                2,
                                3,
                                4
                            ]);
                            if (!token || !id) {
                                message.error(ERROR_MESSAGES().somethingWentWrong);
                                return [
                                    2
                                ];
                            }
                            return [
                                4,
                                checkInviteStatusFn()
                            ];
                        case 1:
                            invitationStatus = _state.sent();
                            setStatus(invitationStatus);
                            return [
                                3,
                                4
                            ];
                        case 2:
                            err = _state.sent();
                            message.error(ERROR_MESSAGES().somethingWentWrong);
                            Sentry.captureException(err);
                            return [
                                3,
                                4
                            ];
                        case 3:
                            setIsValidating(false);
                            return [
                                7
                            ];
                        case 4:
                            return [
                                2
                            ];
                    }
                });
            });
            return _effect.apply(this, arguments);
        }
        effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        history,
        token,
        id
    ]);
    var accept = function() {
        var _ref = _asyncToGenerator(function(payload) {
            var response, session;
            return __generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        setIsAcceptInProgress(true);
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            ,
                            7,
                            8
                        ]);
                        if (!!status.isNewUser) return [
                            3,
                            3
                        ];
                        return [
                            4,
                            acceptInviteFn()
                        ];
                    case 2:
                        _state.sent();
                        return [
                            3,
                            6
                        ];
                    case 3:
                        return [
                            4,
                            acceptInviteFn(payload)
                        ];
                    case 4:
                        response = _state.sent();
                        return [
                            4,
                            getSessionFn()
                        ];
                    case 5:
                        session = _state.sent();
                        store(_objectSpread({}, response, session.data));
                        _state.label = 6;
                    case 6:
                        return [
                            3,
                            8
                        ];
                    case 7:
                        setIsAcceptInProgress(false);
                        return [
                            7
                        ];
                    case 8:
                        return [
                            2
                        ];
                }
            });
        });
        return function accept(payload) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        isValidating: isValidating,
        status: status,
        accept: accept,
        isAcceptInProgress: isAcceptInProgress
    };
};
export default useInvite;
