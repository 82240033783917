import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { listEventsWithFeature as listEventsWithFeatureFn } from './service';

export const shouldShowFeatureOptions = (organizationId, feature, queryOptions = {}) => ({
  ...queryOptions,
  queryKey: ['organization', organizationId, 'hasOrgEventsWithFeatureEnabled', feature],
  queryFn: () => listEventsWithFeatureFn(organizationId, feature, { limit: 0 }),
  select: data => data.meta.pagination.totalItemsCount > 0,
});

/**
 * This hook is used to check if a feature is enabled for an organization.
 * @param {string} organizationId
 * @param {string} feature This needs to be passed as the snake_case event config name.
 * @returns {UseQueryResult<boolean>} The RQ query state with `data` as a boolean indicating if
 *   the feature is enabled in any of the org's events.
 */
export const useShouldShowFeatureQuery = (organizationId, feature, queryOptions = {}) =>
  useQuery(shouldShowFeatureOptions(organizationId, feature, queryOptions));

export const listEventsWithFeatureOptions = (organizationId, feature, queryOptions = {}) => ({
  ...queryOptions,
  queryKey: ['organization', organizationId, 'orgEventsWithFeatureEnabled', feature],
  queryFn: () => listEventsWithFeatureFn(organizationId, feature),
  select: data => data.data,
});

/**
 * Return all events in an organization with a given feature enabled
 * @param {string} organizationId
 * @param {string} feature This needs to be passed as the snake_case event config name.
 * @returns {UseQueryResult<Event[]>}
 */
export const useEventsWithFeatureQuery = (feature, queryOptions = {}) => {
  const { organizationId } = useParams();

  return useQuery(listEventsWithFeatureOptions(organizationId, feature, queryOptions));
};
