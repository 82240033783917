/* global DEVELOPMENT */
import { reduxBatch } from '@manaflair/redux-batch';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import event from 'common/state/event';
import flags from 'common/state/flags';
import organization from 'common/state/organization';
import system from 'common/state/system';
import user from 'common/state/user';

const middleware = [];

if (DEVELOPMENT) {
  // eslint-disable-next-line global-require
  const { createLogger } = require('redux-logger');
  middleware.push(
    createLogger({
      actionTransformer: action =>
        Array.isArray(action) ? { type: '@redux-batch', actions: action } : action,
    })
  );
}

const enhancers = [reduxBatch];

enhancers.push(
  Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state) => {
      scope.setUser({
        id: state.user.id,
        email: state.user.email,
      });

      scope.setTags({
        'organization.id': state.organization.id,
        'organization.name': state.organization.name,
        'event.id': state.event.id,
        'event.name': state.event.name,
        'event.code': state.event.shortcode,
      });
    },
  })
);

const store = configureStore({
  reducer: { system, user, flags, organization, event },
  devTools: DEVELOPMENT,
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), ...middleware],
  enhancers,
});

export default store;
