import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import useTranslation from 'common/hooks/useTranslation';
import { user as flagFn, system as systemFlagFn } from 'common/services/flags/api';
import { retrieve as retrieveFn } from 'common/services/session-service';
import { setUser as setFlags, setSystem as setSystemFlags } from 'common/state/flags';
import { getIsLoggedIn } from 'common/state/system';
import { setUser } from 'common/state/user';

import { isAuthenticated as isAuthenticatedFn } from './service';

const RemoveTrailingSlash = () => {
  const location = useLocation();

  if (location.pathname[location.pathname.length - 1] === '/') {
    return <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />;
  }

  return null;
};

const PrivateRoute = ({ children, ...rest }) => {
  const { isLoggedIn } = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const { setLanguage } = useTranslation();

  const [isAuthenticated, setAuthenticated] = useState(isLoggedIn);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function effect() {
      setLoading(true);

      if (await isAuthenticatedFn()) {
        const { language, ...user } = await retrieveFn();
        const { data: systemFlags } = await systemFlagFn();
        const { data: flags } = await flagFn(user.userId);
        dispatch([setUser(user), setFlags(flags), setSystemFlags(systemFlags)]);
        setLanguage(language);

        setAuthenticated(true);
      }

      setLoading(false);
    }

    if (!isLoggedIn) {
      effect();
    } else {
      // we already have user information in the redux store.
      // no need to verify for the local session
      setLoading(false);
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = rest?.location?.pathname;
  const search = next ? `?next=${next}` : '';

  if (loading) {
    return null;
  }

  return (
    <Route {...rest}>
      <RemoveTrailingSlash />
      {isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: `/unauthorized`,
            search,
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
