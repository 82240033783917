import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/native_applications`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

export function get(organizationId, appId) {
  return api(organizationId, appId)
    .get('', {
      params: {
        multiEventAppId: appId,
      },
    })
    .then(response => response?.data?.data?.[0] ?? {});
}

export function create(organizationId, appId, payload) {
  return api(organizationId)
    .post('', { ...payload, multiEventAppId: appId })
    .then(response => response?.data?.data);
}

export function update(organizationId, appId, id, payload) {
  return api(organizationId)
    .patch(id, { ...payload, multiEventAppId: appId })
    .then(response => response?.data?.data);
}
