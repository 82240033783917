import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HTTP403 from 'common/components/ErrorRoutes/HTTP403';

import HTTP401 from './HTTP401';
import HTTP404 from './HTTP404';

export default function ErrorRoutes() {
  return (
    <Switch>
      <Route path="/unauthorized">
        <HTTP401 />
      </Route>
      <Route path="/forbidden">
        <HTTP403 />
      </Route>
      <Route path="*">
        <HTTP404 />
      </Route>
    </Switch>
  );
}
