import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .ant-dropdown-menu-item {
    white-space: nowrap;
  }

.ant-select-dropdown {
  ul[role=listbox] {
    margin-left: 0;
  }
}
`;
