/* global DEVELOPMENT, STAGE */ /* eslint-disable no-param-reassign */ function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { createSlice } from "@reduxjs/toolkit";
var generateEventUrls = function(eventCode) {
    var customDomain = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : undefined;
    var urls = [
        "https://eventmobi.com/".concat(eventCode)
    ];
    if (customDomain) {
        urls.push("https://".concat(customDomain, "/").concat(eventCode));
    }
    if (DEVELOPMENT) {
        urls.push("https://app.localhost/".concat(eventCode));
    }
    if (STAGE) {
        urls.push("https://release.eventmobi.com/".concat(eventCode));
    }
    return urls;
};
var initialState = {
    id: null,
    name: null,
    shortcode: null,
    description: null,
    timeFormat: "12 Hour",
    timezone: "America/New_York",
    creationDate: null,
    startDate: null,
    endDate: null,
    language: "english",
    alternativeLanguages: [],
    locationAddress: null,
    locationName: null,
    partnerId: 0,
    organizationId: null,
    eventType: null,
    eventWebsite: null,
    eventIconImageId: null,
    isTrial: false,
    multilingualEventApp: null,
    perPersonLimit: null,
    supportEmailAddress: null,
    outboundEmailAddress: null,
    useCustomOutboundEmailAddress: null,
    configurations: {},
    eventUrls: []
};
var slice = createSlice({
    name: "event",
    initialState: initialState,
    reducers: {
        setEvent: {
            prepare: function prepare() {
                var event = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
                var _event_configuration;
                var payload = {
                    id: event.id,
                    name: event.name,
                    shortcode: event.shortcode,
                    description: event.description,
                    timeFormat: event.timeFormat,
                    timezone: (_event_configuration = event.configuration) === null || _event_configuration === void 0 ? void 0 : _event_configuration.timezone,
                    creationDate: event.creationDate,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    language: event.language,
                    alternativeLanguages: event.alternativeLanguages,
                    locationAddress: event.locationAddress,
                    locationName: event.locationName,
                    partnerId: event.partnerId,
                    organizationId: event.organizationId,
                    eventType: event.eventType,
                    eventWebsite: event.eventWebsite,
                    eventIconImageId: event.eventIconImageId,
                    isTrial: event.isTrial,
                    multilingualEventApp: event.multilingualEventapp,
                    perPersonLimit: event.perPersonLimit,
                    supportEmailAddress: event.supportEmailAddress,
                    outboundEmailAddress: event.outboundEmailAddress,
                    useCustomOutboundEmailAddress: event.useCustomOutboundEmailAddress
                };
                return {
                    payload: payload,
                    meta: null,
                    error: null
                };
            },
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state.id = payload.id;
                state.name = payload.name;
                state.shortcode = payload.shortcode;
                state.description = payload.description;
                state.timeFormat = payload.timeFormat;
                state.timezone = payload.timezone;
                state.creationDate = payload.creationDate;
                state.startDate = payload.startDate;
                state.endDate = payload.endDate;
                state.language = payload.language;
                state.alternativeLanguages = payload.alternativeLanguages;
                state.locationAddress = payload.locationAddress;
                state.locationName = payload.locationName;
                state.partnerId = payload.partnerId;
                state.organizationId = payload.organizationId;
                state.eventType = payload.eventType;
                state.eventWebsite = payload.eventWebsite;
                state.eventIconImageId = payload.eventIconImageId;
                state.isTrial = payload.isTrial;
                state.multilingualEventApp = payload.multilingualEventApp;
                state.perPersonLimit = payload.perPersonLimit;
                state.supportEmailAddress = payload.supportEmailAddress;
                state.outboundEmailAddress = payload.outboundEmailAddress;
                state.useCustomOutboundEmailAddress = payload.useCustomOutboundEmailAddress;
            }
        },
        setConfigurations: {
            prepare: function prepare() {
                var defaults = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], configurations = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
                var payload = {};
                var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                try {
                    // eslint-disable-next-line no-restricted-syntax
                    for(var _iterator = defaults[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                        var _step_value = _step.value, key = _step_value.key, defaultValue = _step_value.default, maxExpiryInDays = _step_value.max_expiry_in_days, description = _step_value.description, displayName = _step_value.display_name, keyId = _step_value.id, type = _step_value.type, validation = _step_value.validation;
                        var next = {
                            keyId: keyId,
                            type: type,
                            maxExpiryInDays: maxExpiryInDays,
                            description: description,
                            displayName: displayName,
                            validation: validation,
                            value: null,
                            options: null,
                            defaultValue: defaultValue
                        };
                        switch(type){
                            case "boolean":
                                {
                                    next.defaultValue = defaultValue === "1";
                                    break;
                                }
                            case "integer":
                                {
                                    next.defaultValue = Number.parseInt(defaultValue, 10);
                                    break;
                                }
                            case "radio":
                            case "checkbox":
                                {
                                    next.options = defaultValue.split(",");
                                    next.defaultValue = null;
                                    break;
                                }
                            default:
                                next.defaultValue = defaultValue;
                        }
                        payload[key] = next;
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally{
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return != null) {
                            _iterator.return();
                        }
                    } finally{
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
                // we don't use keys `timeFormat` and `is_document_upload_enabled` with the `owner_type` value `agenda`
                // TODO: this isn't actually filtering because its not using the returned array
                configurations.filter(function(item) {
                    return !(item.owner_type === "agenda" && [
                        "timeFormat",
                        "is_document_upload_enabled"
                    ].includes(item.key));
                });
                var _iteratorNormalCompletion1 = true, _didIteratorError1 = false, _iteratorError1 = undefined;
                try {
                    // eslint-disable-next-line no-restricted-syntax
                    for(var _iterator1 = configurations[Symbol.iterator](), _step1; !(_iteratorNormalCompletion1 = (_step1 = _iterator1.next()).done); _iteratorNormalCompletion1 = true){
                        var _step_value1 = _step1.value, id = _step_value1.id, key1 = _step_value1.key, value = _step_value1.value;
                        payload[key1].id = id;
                        switch(payload[key1].type){
                            case "boolean":
                                {
                                    payload[key1].value = value === "1";
                                    break;
                                }
                            case "integer":
                                {
                                    payload[key1].value = Number.parseInt(value, 10);
                                    break;
                                }
                            default:
                                payload[key1].value = value;
                        }
                    }
                } catch (err) {
                    _didIteratorError1 = true;
                    _iteratorError1 = err;
                } finally{
                    try {
                        if (!_iteratorNormalCompletion1 && _iterator1.return != null) {
                            _iterator1.return();
                        }
                    } finally{
                        if (_didIteratorError1) {
                            throw _iteratorError1;
                        }
                    }
                }
                return {
                    payload: payload,
                    meta: null,
                    error: null
                };
            },
            reducer: function reducer(state, param) {
                var payload = param.payload;
                state.configurations = payload;
            }
        },
        setConfiguration: function setConfiguration(state, param) {
            var payload = param.payload;
            var key = payload.key, value = payload.value;
            state.configurations[key].value = value;
        },
        setCustomDomain: function setCustomDomain(state, param) {
            var payload = param.payload;
            var _state_configurations_is_custom_domain_enabled;
            var isCustomDomainEnabled = ((_state_configurations_is_custom_domain_enabled = state.configurations.is_custom_domain_enabled) === null || _state_configurations_is_custom_domain_enabled === void 0 ? void 0 : _state_configurations_is_custom_domain_enabled.value) === true;
            if (isCustomDomainEnabled) {
                state.eventUrls = generateEventUrls(state.shortcode, payload);
            } else {
                state.eventUrls = generateEventUrls(state.shortcode);
            }
        }
    }
});
export default slice.reducer;
export var getEvent = function(state) {
    return state[slice.name];
};
export var getId = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.id;
};
export var getName = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.name;
};
export var getShortcode = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.shortcode;
};
export var getDescription = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.description;
};
export var getTimezone = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.timezone;
};
export var getTimeFormat = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.timeFormat;
};
export var getLanguage = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.language;
};
export var getEventUrls = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.eventUrls;
};
export var getSupportEmailAddress = function(state) {
    var _state_slice_name;
    return (_state_slice_name = state[slice.name]) === null || _state_slice_name === void 0 ? void 0 : _state_slice_name.supportEmailAddress;
};
export var getLanguages = function(state) {
    var _state_slice_name = _slicedToArray(state[slice.name], 2), language = _state_slice_name[0], alternativeLanguages = _state_slice_name[1];
    return [
        language
    ].concat(_toConsumableArray(alternativeLanguages));
};
export var getDates = function(state) {
    var _state_slice_name = state[slice.name], start = _state_slice_name.startDate, end = _state_slice_name.endDate;
    return {
        start: start,
        end: end
    };
};
export var getLocation = function(state) {
    var _state_slice_name = state[slice.name], address = _state_slice_name.locationAddress, name = _state_slice_name.locationName;
    return {
        address: address,
        name: name
    };
};
export var getConfigurations = function(state) {
    return state[slice.name].configurations;
};
export var getConfiguration = function(state, key) {
    var _configurations_key, _configurations_key1;
    var configurations = state[slice.name].configurations;
    var _configurations_key_value;
    return (_configurations_key_value = configurations === null || configurations === void 0 ? void 0 : (_configurations_key = configurations[key]) === null || _configurations_key === void 0 ? void 0 : _configurations_key.value) !== null && _configurations_key_value !== void 0 ? _configurations_key_value : configurations === null || configurations === void 0 ? void 0 : (_configurations_key1 = configurations[key]) === null || _configurations_key1 === void 0 ? void 0 : _configurations_key1.defaultValue;
};
export var isEnabled = function(state, key) {
    // regex will check for any uppercase letters
    if (/[A-Z]/.test(key)) {
        // eslint-disable-next-line no-console
        console.warn("isEnabled only accepts snake_case config keys");
    }
    return getConfiguration(state, key) === true;
};
export var isDisabled = function(state, key) {
    return getConfiguration(state, key) !== true;
};
var _slice_actions = slice.actions;
export var setEvent = _slice_actions.setEvent, setConfigurations = _slice_actions.setConfigurations, setConfiguration = _slice_actions.setConfiguration, setCustomDomain = _slice_actions.setCustomDomain;
