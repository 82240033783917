import CountdownWidget from '@eventmobi/widgets/Countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import { getTimezone } from 'common/state/event';

import { Icons } from '@components/IconPicker/Icons';

// Wrapper around Widgets.Countdown to define the datetime in utc
const Countdown = ({ params }) => {
  const timezone = useSelector(getTimezone);

  // countdownWidget needs the date from event timezone in UTC
  // I am parsing the date to UTC using date and time from the API and adding the prop the Countdown Widget
  const getDateUTC = (date, time) => moment.tz(`${date} ${time}`, timezone).utc().toISOString();

  return (
    <CountdownWidget
      {...params}
      dateTimeUTC={getDateUTC(params?.date, params?.time)}
      buttonProps={{
        ...params.buttonProps,
        icon: params?.buttonProps?.icon && (
          <FontAwesomeIcon icon={Icons[params.buttonProps.icon]} />
        ),
      }}
      dayText={t`Day(s)`}
      hourText={t`Hour(s)`}
      minText={t`Minute(s)`}
      secText={t`Second(s)`}
    />
  );
};

Countdown.propTypes = {
  params: PropTypes.shape({
    // is not really relevant to define the object here
  }).isRequired,
};

export default Countdown;
