import { EditOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, Form, message, Row, Space, Typography, Modal, Divider } from 'antd';
import { merge } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { t, jt } from 'ttag';

import Loading from 'common/components/Loading';
import StickyFooter from 'common/components/StickyFooter';
import { getConfigurations, getShortcode, setConfigurations } from 'common/state/event';

import { CONF_SECTION_ORDER, CONF_VIRTUAL_EVENT } from './constants';
import EditCodeModal from './EditCodeModal';
import Field from './Field';
import { get as getFn, update as updateFn, config as configFn, mergeWithDefaults } from './service';
import {
  checkActivityFeedSetAsHomepage,
  checkLeadCaptureLicenseLimitExceeded,
} from './validations';
import VirtualEventModal from './VirtualEventModal';

const { Title, Text } = Typography;

const Head = styled.div`
  padding: 45px 0 35px 0;
  margin-left: 45px;

  & > h1 {
    margin: 0;
  }

  & > button {
    padding: 0;
  }
`;

const Wrap = styled.div`
  margin: 0 58px;
  padding-bottom: 65px;

  .ant-form-item {
    margin-bottom: 10px;
    width: 300px;
  }
`;

const useConfiguration = eventId => {
  const defaultConfigs = useSelector(getConfigurations);

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);

  const updateConfig = async payload => {
    if (!config) {
      throw new Error('Need to load config first');
    }

    await updateFn(eventId, payload, { previous: config });
    setConfig(merge(config, payload));
  };

  useEffect(() => {
    async function effect() {
      try {
        setLoading(true);
        const response = await getFn(eventId);

        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(response)) {
          if (response[key].expiry) {
            response[key].expiry = moment(response[key].expiry);
          }
        }

        // mergeWithDefaults is called to mainly add the maxExpiryInDays to the config from config defaults
        setConfig(mergeWithDefaults(response, defaultConfigs));
      } catch {
        message.error(t`Unknown error occurred while loading configurations.`);
      } finally {
        setLoading(false);
      }
    }

    effect();

    // `defaultConfigs` gets updated by `setConfigurations` below.
    // We do not want to execute this effect when that gets changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  return {
    loading,
    config,
    updateConfig,
  };
};

// eslint-disable-next-line react/prop-types
const Section = ({ section, config }) => (
  <div>
    <Title level={4}>{section.name}</Title>
    {section.data
      .sort((a, b) => config[a].name.localeCompare(config[b].name))
      .map(key => (
        <Field key={key} config={config} configKey={key} />
      ))}
  </div>
);

const Configuration = () => {
  const { organizationId, eventId } = useParams();
  const shortcode = useSelector(getShortcode);
  const { loading, config, updateConfig } = useConfiguration(eventId);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showVirtualEventModal, setShowVirtualEventModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [touched, setTouched] = useState(false);

  const [form] = Form.useForm();

  const handleVirtualEventModal = () => {
    setShowVirtualEventModal(false);
    const fieldsValues = form.getFieldsValue(CONF_VIRTUAL_EVENT);
    CONF_VIRTUAL_EVENT.forEach(field => {
      form.setFieldValue(field, { ...fieldsValues[field], value: true });
    });
    setTouched(true);
  };

  const validate = async payload => {
    const errors = [];

    const [isLeadCaptureLimitExceeding, isActivityFeedSetAsHomepage] = await Promise.all([
      // Lead Capture License Limit should not be less than the current number of companies with the licenses turned on
      checkLeadCaptureLicenseLimitExceeded(eventId, payload),

      // Activity Feed should not be allowed to be turned off if it is set as the Homepage
      checkActivityFeedSetAsHomepage(eventId, payload),
    ]);

    if (isLeadCaptureLimitExceeding) {
      const leadCaptureCompaniesLink = (
        <Typography.Link
          onClick={() => {
            Modal.destroyAll();
            history.replace(generatePath('onsite/lead-capture'));
          }}
        >
          {t`companies with Lead Capture License`}
        </Typography.Link>
      );

      errors.push(
        jt`The limit of Lead Capture Licenses cannot be less than the current number of companies with the licenses turned on. Please check current number of ${leadCaptureCompaniesLink} before you lower the limit.`
      );
    }

    if (isActivityFeedSetAsHomepage) {
      errors.push(t`The Activity Feed is currently set as the Homepage.`);
    }

    return errors;
  };

  const onFinish = async payload => {
    try {
      setUpdating(true);

      const validationErrors = await validate(payload);
      if (validationErrors.length > 0) {
        Modal.warning({
          title: t`Unable to save changes`,
          content: (
            <>
              <p>{t`Your changes cannot be saved for the following reason(s)`}:</p>

              <ul>
                {validationErrors.map(error => (
                  <li key={error}>
                    <p>{error}</p>
                  </li>
                ))}
              </ul>
            </>
          ),
          okText: t`Ok`,
        });
        return;
      }

      await updateConfig(payload);

      const conf = await configFn(eventId);
      dispatch(setConfigurations(...conf));
      queryClient.invalidateQueries({ queryKey: 'config' });
      setTouched(false);

      message.success(t`Successfully updated feature configuration.`);
    } catch (err) {
      message.error(t`Unable to update feature configuration.`);
      throw err;
    } finally {
      setUpdating(false);
    }
  };

  return (
    <>
      {showModal && (
        <EditCodeModal
          organizationId={organizationId}
          eventId={eventId}
          eventCode={shortcode}
          onClose={() => setShowModal(false)}
        />
      )}
      {showVirtualEventModal && (
        <VirtualEventModal
          config={config}
          onCancel={() => setShowVirtualEventModal(false)}
          onOk={handleVirtualEventModal}
        />
      )}
      <Head>
        <Title level={1}>{t`Event Configuration`}</Title>
        <Button type="link" onClick={() => setShowModal(true)}>
          <EditOutlined /> {t`Edit Event Code`}
        </Button>
      </Head>

      <Wrap>
        {loading && <Loading />}

        {!loading && (
          <Form
            hideRequiredMark
            form={form}
            colon={false}
            layout="vertical"
            initialValues={config}
            size="small"
            onFinish={onFinish}
            onFieldsChange={() => setTouched(true)}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space direction="vertical">
                <div>
                  <Title level={4}>{t`Quick Actions`} </Title>
                  <Text type="secondary">
                    {t`Use quick configs to easily turn on all related features.`}
                  </Text>
                </div>
                <Button onClick={() => setShowVirtualEventModal(true)}>
                  {t`Turn on Virtual Event Configs`}
                </Button>
              </Space>
              <Divider />
            </Space>
            <Space direction="vertical" style={{ width: '100%' }} size={20}>
              <Row>
                <Col span={12}>
                  <Space direction="vertical" style={{ width: '100%' }} size={20}>
                    {CONF_SECTION_ORDER.left.map(section => (
                      <Section key={section.name} section={section} config={config} />
                    ))}
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" style={{ width: '100%' }} size={20}>
                    {CONF_SECTION_ORDER.right.map(section => (
                      <Section key={section.name} section={section} config={config} />
                    ))}
                  </Space>
                </Col>
              </Row>
            </Space>
            <StickyFooter>
              <Button
                type="primary"
                htmlType="submit"
                size="default"
                loading={updating}
                disabled={loading || updating || !touched}
              >
                {updating ? t`Saving Configuration` : t`Save Configuration`}
              </Button>
            </StickyFooter>
          </Form>
        )}
      </Wrap>
    </>
  );
};

export default Configuration;
