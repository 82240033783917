import { lighten, darken } from 'polished';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
/* custom type for admin/staff select and it could be used as <Select className="ant-select-staff" /> */
.ant-select-staff:not(.ant-select-disabled) {
  & .ant-select-selector {
    border-color: #d41968;
  }

  &:hover .ant-select-selector {
    border-color: ${darken(0.1, '#d41968')};
  }

  &.ant-select-focused .ant-select-selector {
    border-color: ${lighten(0.1, '#d41968')} !important;
    box-shadow: 0 0 0 2px rgb(212 25 104 / 20%) !important;
  }
}
`;
