/* global APPCUES_ACCOUNT_ID */
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useTranslation from 'common/hooks/useTranslation';
import { getUser } from 'common/state/user';

import { getLaunchpad as getLaunchpadFn } from '@services/appcues-service';

const Context = createContext();

export const useAppcues = () => useContext(Context);

export const Provider = ({ children }) => {
  const [appcues, setAppcues] = useState(null);
  const user = useSelector(getUser);
  const { language } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    let timeout;

    // wait for Appcues to load
    const interval = setInterval(() => {
      if ('Appcues' in window) {
        clearInterval(interval);
        clearTimeout(timeout);
        setAppcues(window.Appcues);
        setLoading(false);
      }
    }, 300);

    // stop looking for Appcues if it cannot find it for a while
    timeout = setTimeout(() => {
      if (!('Appcues' in window)) {
        clearInterval(interval);
        setLoading(false);
        setError('Timedout loading Appcues');
      }
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  // track user
  useEffect(() => {
    if (!appcues) {
      return;
    }
    if (user.id) {
      appcues.identify(user.id, { language, email: user.email });
    } else {
      appcues.reset();
    }
  }, [appcues, user, language]);

  return <Context.Provider value={{ appcues, loading, error }}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLaunchpad = () => {
  const { appcues, loading: loadingAppcues } = useAppcues();
  const user = useSelector(getUser);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);

  useEffect(() => {
    async function effect() {
      try {
        setLoading(true);
        const res = await getLaunchpadFn(APPCUES_ACCOUNT_ID, user.id, window.location.href);
        setContent(res);
      } finally {
        setLoading(false);
      }
    }

    // there is no point in loading flows if appcues hasn't loaded yet, or failed to load
    if (appcues && user.id) {
      effect();
    }
  }, [appcues, user, location]);

  return {
    loading: loading || loadingAppcues,
    appcues,
    content,
  };
};
