import axios from 'axios';

import apiFn from 'common/services/api-service';

export const STATUS_FINALIZED = 'finalized';
export const STATUS_CANCELLED = 'cancelled';
export const ALLOWED_MIME_TYPES = [
  'application/msword',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/octet-stream',
  'application/json',
  'application/x-pkcs12',
  'application/pkix-cert',
  'application/x-x509-ca-cert',
  'application/x-apple-aspen-mobileprovision',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];
const ALLOWED_STATUSES = [STATUS_FINALIZED, STATUS_CANCELLED];

// Foundation will make this part of url instead of passing these values in body.
// eslint-disable-next-line no-unused-vars
const api = (resourceType, resourceId) => {
  return apiFn(`/api/uapi/${resourceType}/${resourceId}/files`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });
};

/**
 * In some cases (i.e. Windows) files may not have a listed MIME type.
 *
 * Flux will reject these, but depending on the extension we may be able
 * determine and set it ourselves.
 */
export const ensureMimeType = file => {
  // Windows doesn't always report a MIME type, or the correct one
  const extension = file.name.match(/\.(.+)$/)?.[1];

  if (!file.type && extension === 'pem') {
    // eslint-disable-next-line no-param-reassign
    file.derivedType = 'application/x-x509-ca-cert';
  }
};

export const get = (resourceType, resourceId, fileId) =>
  api(resourceType, resourceId)
    .get(fileId)
    .then(response => response.data.data);

export const getDownloadUrl = (resourceType, resourceId, fileId) =>
  api(resourceType, resourceId)
    .post(`${fileId}/download`)
    .then(response => response.data.data?.url);

export const update = (resourceType, resourceId, id, params = {}) =>
  api(resourceType, resourceId)
    .patch(id, params)
    .then(response => response.data.data);

export const notify = (resourceType, resourceId, fileId, status = STATUS_FINALIZED) => {
  if (typeof fileId === 'undefined' || fileId === null) {
    throw new Error('file id cannot be either undefined or null');
  }

  if (!ALLOWED_STATUSES.includes(status)) {
    throw new Error(`invalid status, allowed status: ${ALLOWED_STATUSES.join(', ')}`);
  }

  return update(resourceType, resourceId, fileId, { status });
};

export const create = (resourceType, resourceId, file) => {
  if (typeof file.name === 'undefined' || file.name === null) {
    throw new Error('file name cannot be either undefined or null');
  }

  const mimeType = file.derivedType || file.type;

  if (typeof mimeType === 'undefined' || mimeType === null) {
    throw new Error('file mime type cannot be either undefined or null');
  }

  if (!ALLOWED_MIME_TYPES.includes(mimeType)) {
    throw new Error(`invalid mime type, allowed mime types: ${ALLOWED_MIME_TYPES.join(', ')}`);
  }

  const payload = {
    name: file.name,
    length: file.size,
    mimeType,
    presignExpiresIn: 600,
  };

  return api(resourceType, resourceId)
    .post(null, payload)
    .then(response => response.data.data);
};

export const upload = (url, file, onProgress = () => {}) => {
  if (typeof url === 'undefined' || url === null) {
    throw new Error('file upload url cannot either undefined or null');
  }

  if ('File' in window && !(file instanceof window.File)) {
    throw new Error('invalid file: it should be instance of window.File');
  }

  if (typeof onProgress !== 'function') {
    throw new Error('onProgress callback is not a function');
  }

  return axios.put(url, file, {
    headers: { 'Content-Type': file.derivedType || file.type },
    onUploadProgress: onProgress,
  });
};

export const remove = (resourceType, resourceId, fileId) => {
  return api(resourceType, resourceId)
    .delete(fileId)
    .then(response => response.data.data);
};
