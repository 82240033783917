function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { createQueryDefinition, createQueryHook } from "../queryBuilder";
import { keys as keysFn, get as getFn } from "./api";
export var GetConfigKeysQuery = createQueryDefinition(function() {
    return [
        "config",
        "keys"
    ];
}, function() {
    return keysFn();
});
export var useConfigKeysQuery = createQueryHook(GetConfigKeysQuery, {
    select: function(param) {
        var data = param.data;
        return data;
    }
});
export var queryGetConfig = createQueryDefinition(function(eventId) {
    return [
        "config",
        eventId
    ];
}, function(param) {
    var _param_queryKey = _slicedToArray(param.queryKey, 2), eventId = _param_queryKey[1];
    return getFn(eventId);
});
export var useConfigQuery = createQueryHook(queryGetConfig, {
    // discard items we don't need to store
    select: function(param) {
        var data = param.data;
        return data.filter(function(item) {
            return !(item.ownerType !== "event" && item.key === "is_document_upload_enabled") && !(item.ownerType === "agenda" && item.key === "time_format");
        });
    }
});
