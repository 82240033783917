import apiFn from 'common/services/api-service';

const api = () => apiFn('/api/cms/v1/events/gamification-leaderboard');

// eslint-disable-next-line import/prefer-default-export
export const get = (eventId, hashVal, playersLimit) => {
  return api()
    .get(null, {
      params: {
        eventId,
        hashVal,
        playersLimit,
      },
    })
    .then(response => response.data.data[0].topPlayers);
};
