import { Typography, Form, Switch as AntSwitch, Row, Col, Button, message, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';

import Header from 'common/components/Header';
import ImageUpload from 'common/components/ImageUpload';
import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import Modal from 'common/components/Modal';
import StickyFooter from 'common/components/StickyFooter';

import ColorPicker, { VALID_HEX_COLOR_REGEX } from '@components/ColorPicker';
import ChartPreview from '@domains/Surveys/Questions/ChartPreview';

import { ACTION_SET_IMAGE_ID, ACTION_SET_LOADING, CHART_TYPE_BAR } from './constants';
import { reset as resetFn } from './service';
import useAudienceResponse from './useAudienceResponse';

const { Title: AntTitle, Text } = Typography;

const Wrap = styled.div`
  margin: 0 auto 50px;
  padding-bottom: 86px;
`;

const TitleAreaContainer = styled.div`
  padding-top: 45px;
  margin-left: 45px;
`;

const Title = styled(AntTitle)`
  && {
    margin-bottom: 0px;
  }
`;

const Container = styled.div`
  padding-left: 45px;
  padding-right: 45px;
`;

const Switch = styled(AntSwitch)`
  margin-left: 50px;
`;

const SwitchWrapper = styled(Col)`
  padding-bottom: 20px;
`;

const Colors = ({ dataSource }) => {
  return (
    <Row gutter={15}>
      {dataSource.map(item => {
        return (
          <Col key={item.name} span={8}>
            <Form.Item
              name={item.name}
              valuePropName="value"
              label={<Label>{item.label}</Label>}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: t`Invalid color`,
                },
                {
                  pattern: new RegExp(VALID_HEX_COLOR_REGEX),
                  message: t`Invalid color`,
                },
              ]}
            >
              <ColorPicker name={item.name} width="100%" />
            </Form.Item>
          </Col>
        );
      })}
    </Row>
  );
};

Colors.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const AudienceResponse = () => {
  const { eventId } = useParams();
  const {
    state: { loading, loadingConfig, saving, dataSource, config, imageId },
    dispatch,
    save,
  } = useAudienceResponse(eventId);

  const settings =
    dataSource &&
    config &&
    dataSource.reduce(
      (accum, item) => {
        const data = { ...accum };
        data[item.name] = item.value;
        return data;
      },
      { ...config }
    );

  const resetToDefaultColors = () => {
    Modal.confirm({
      title: t`Reset to Default Colors?`,
      content: t`This will reset the colors of Polls, Surveys and Ask a Question Result Screens. Changes will automatically be saved. Are you sure you want to continue?`,
      async onOk() {
        await resetFn(eventId);
        dispatch({ type: ACTION_SET_LOADING });
        message.success(t`Your changes are successfully saved`);
      },
    });
  };

  return (
    <Wrap>
      {(loading || loadingConfig) && <Loading />}
      {!(loading || loadingConfig) && (
        <>
          <TitleAreaContainer>
            <Title>{t`Audience Response`}</Title>
            <Text type="secondary">{t`Manage your default settings for Polls, Surveys, and Ask a Question across your event.`}</Text>
          </TitleAreaContainer>
          <Form colon={false} initialValues={settings} onFinish={save} requiredMark={false}>
            <Header title={t`Settings`} padding="10px 45px" />
            <Container>
              <Row>
                <Col>
                  <Row>
                    <SwitchWrapper>
                      <Text>{t`Allow attendees to change their responses`}</Text>
                    </SwitchWrapper>
                  </Row>
                  <Row>
                    <Col>
                      <Text>{t`Show unmoderated text answers from live results by default`}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <SwitchWrapper>
                      <Form.Item noStyle name="allowAttendees" valuePropName="checked">
                        <Switch data-testid="allow-attendees" />
                      </Form.Item>
                    </SwitchWrapper>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item noStyle name="showUnmoderatedText" valuePropName="checked">
                        <Switch data-testid="show-unmoderated-text" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Header
              title={t`Result Screen`}
              emphasis={t`Manage the default colors and display of your Polls, Surveys, and Ask a Question Result Screens. Default colors are applied to results pages that do not have customized colors.`}
              padding="10px 45px"
            />
            <Container>
              <Row>
                <Space align="start" size="large">
                  <Col>
                    <Colors
                      dataSource={[
                        { name: 'headerTextColor', label: t`Title Text` },
                        { name: 'headerBackgroundColor', label: t`Title Background` },
                      ]}
                    />
                    <Colors
                      dataSource={[
                        { name: 'bodyTextColor', label: t`Chart Text` },
                        { name: 'bodyBackgroundColor', label: t`Chart Background` },
                      ]}
                    />
                    <Space direction="vertical" size="large">
                      <Row>
                        <Col span={24}>
                          <Title level={5}>{t`Option Colors`}</Title>
                        </Col>
                        <Col span={24}>
                          <Text type="secondary">{t`Set up to 10 colors for survey and live poll options. If there are more than 10 options, the colors will repeat in order.`}</Text>
                        </Col>
                      </Row>
                      <Colors
                        dataSource={[...Array(10).keys()].map(i => ({
                          name: `answerColor${i + 1}`,
                          label: `Option ${i + 1}`,
                        }))}
                      />
                    </Space>
                    <Form.Item
                      label={<Label>{t`Sponsor Banner`}</Label>}
                      labelCol={{ span: 24 }}
                      validateTrigger="onComplete"
                      trigger="onComplete"
                    >
                      <>
                        <ImageUpload
                          resourceType="events"
                          resourceId={eventId}
                          value={{ id: imageId }}
                          onComplete={({ id }) =>
                            dispatch({ type: ACTION_SET_IMAGE_ID, payload: id })
                          }
                        />
                        {!imageId && (
                          <Text type="secondary">
                            {t`Image should be a JPEG, PNG, BMP or WEBP and have a maximum size of 24MB. Images will be scaled to 600 pixels wide by 100 pixels high.`}
                          </Text>
                        )}
                      </>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => (
                        <ChartPreview
                          chartType={CHART_TYPE_BAR}
                          colors={{
                            bodyBackgroundColor: getFieldValue('bodyBackgroundColor'),
                            bodyTextColor: getFieldValue('bodyTextColor'),
                            headerBackgroundColor: getFieldValue('headerBackgroundColor'),
                            headerTextColor: getFieldValue('headerTextColor'),
                          }}
                          selectOptions={[...Array(10).keys()].map(i => ({
                            optionText: `Option ${i + 1}`,
                            color: getFieldValue(`answerColor${i + 1}`),
                          }))}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Space>
              </Row>
            </Container>

            <StickyFooter>
              <Button type="primary" htmlType="submit" loading={saving}>{t`Save Settings`}</Button>
              <Button
                type="secondary"
                onClick={resetToDefaultColors}
              >{t`Reset to Default Colors`}</Button>
            </StickyFooter>
          </Form>
        </>
      )}
    </Wrap>
  );
};

export default AudienceResponse;
