import { useQuery } from '@tanstack/react-query';
import snakeCaseKeys from 'snakecase-keys';

import { list as listFn, getMap as getMapFn } from '@services/permission-service';

const permissionSetOptions = enabled => ({
  enabled,
  // permission sets are not expected to change frequently, they are almost like static data. which would hardly change in weeks,
  // so we can keep the stale time to 4 hours on safe side if it ever gets updated and user doesn't refresh the page it can only live upto 4 hours.
  staleTime: 4 * 1000 * 60 * 60, // 4 hours
  queryKey: ['permission_sets'],
  queryFn: () => getMapFn(),
  // we need to snake case the keys because the `/me/permissions` endpoint returns permission sets keys in snake case.
  select: ({ data }) => snakeCaseKeys(data, { deep: true }),
});

const listOptions = (organizationId, enabled) => ({
  enabled,
  // permissions are not expected to change frequently either, but it can change more frequently than permission sets.
  // so we can keep the stale time to 5 minutes. Although APIs would still throw 403 if user doesn't have the permission.
  staleTime: 5 * 1000 * 60, // 5 minutes
  queryKey: ['organization', organizationId, 'permissions'],
  queryFn: () => listFn(organizationId),
  select: ({ data }) => data,
});

export const useMapQuery = enabled => useQuery(permissionSetOptions(enabled));

export const useListQuery = (organizationId, enabled) =>
  useQuery(listOptions(organizationId, enabled));
