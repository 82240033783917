import { useMemo } from "react";
import { useLocation } from "react-router-dom";
/**
 * Provide a stable location object, since the location object from useLocation changes on every
 * render due to the addition of a `key` prop. This may change in a newer version of
 * react-router-dom (currently using 5.1.2).
 */ var useStableLocation = function() {
    var unstableLocation = useLocation();
    return useMemo(function() {
        return {
            pathname: unstableLocation.pathname,
            search: unstableLocation.search,
            hash: unstableLocation.hash,
            state: unstableLocation.state
        };
    }, [
        unstableLocation.pathname,
        unstableLocation.search,
        unstableLocation.hash,
        unstableLocation.state
    ]);
};
export default useStableLocation;
