import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Collapse as AntCollapse, Divider, Alert, Typography } from 'antd';
import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Header from './Header';
import Item from './Item';
import { useUploadingDrawer, STATUS_COMPLETED, STATUS_FAILED } from './Provider';

const Wrap = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  margin: 20px;
  width: 445px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
`;

const GroupDivider = styled(Divider)`
  border-width: 6px;
  margin: 0;
`;

const Collapse = styled(AntCollapse)`
  border: none;
`;

const Panel = styled(AntCollapse.Panel)`
  && .ant-collapse-content-box {
    padding: 0;
  }
`;

const List = styled.div`
  max-height: 312px;
  min-height: 100px;
  overflow-y: ${props => (props.blocked ? 'hidden' : 'auto')};
`;

// eslint-disable-next-line react/prop-types
function ExpandIcon({ isActive, onComplete, completed }) {
  // we show `arrow` icon only when it's not completed or its drawer is minimized
  if (!completed || !isActive) {
    return <RightOutlined rotate={isActive ? 90 : -90} className="ant-collapse-arrow" />;
  }

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onComplete();
  };

  return <CloseOutlined onClick={onClick} className="ant-collapse-arrow" />;
}

function Drawer() {
  const { items, onAbort, onComplete, error } = useUploadingDrawer();
  const [selected, setSelected] = useState(null);
  const [drawer, setDrawer] = useState(['drawer']);

  useEffect(() => {
    function onLeave(e) {
      // ask confirmation only when there are some items with non-final status
      if (items.some(item => ![STATUS_COMPLETED, STATUS_FAILED].includes(item.status))) {
        e.preventDefault();

        // some browsers expect non-empty string to show default alert
        e.returnValue = '¯\\_(ツ)_/¯';
      }
    }

    window.addEventListener('beforeunload', onLeave);
    return () => {
      window.removeEventListener('beforeunload', onLeave);
    };
  }, [items]);

  if (!items.length) {
    return null;
  }

  const blocked = !!selected;
  const completed = items.every(item => [STATUS_COMPLETED, STATUS_FAILED].includes(item.status));

  const onSelect = (id, state) => {
    if (state) {
      setSelected(id);
    } else {
      setSelected(null);
    }
  };

  const onChange = key => {
    // normal behaviour when it's not completed yet
    if (!completed) {
      setDrawer(key);
      return;
    }

    // when it's competed, we allow only 'expand'
    if (!drawer.length) {
      setDrawer(key);
    }
  };

  const getActions = id => {
    // it's the same item as selected one, then always show the actions
    if (selected === id) {
      return 'visible';
    }
    // if another item was selected, then no actions at all
    if (selected) {
      return 'none';
    }
    // by default, show actions on hover only
    return 'hover';
  };

  return (
    <Wrap>
      <Collapse
        expandIconPosition="end"
        activeKey={drawer}
        onChange={onChange}
        expandIcon={({ isActive }) => (
          <ExpandIcon isActive={isActive} onComplete={onComplete} completed={completed} />
        )}
      >
        <Panel key="drawer" header={<Header items={items} error={error} />} blocked={blocked}>
          {completed && (
            <Alert
              message={t`Refresh the page to update the video library.`}
              action={
                <Typography.Link onClick={() => window.location.reload()}>
                  {t`Refresh`}
                </Typography.Link>
              }
              type="info"
              showIcon
            />
          )}
          <List>
            {items.map((item, i) => {
              const showDivider = i > 0 && items[i - 1].group !== item.group;
              return (
                <Fragment key={item.id}>
                  {showDivider && <GroupDivider />}
                  <Item
                    name={item.name}
                    group={item.group}
                    error={item.error}
                    status={item.status}
                    sizeTotal={item.sizeTotal}
                    sizeUploaded={item.sizeUploaded}
                    thumbnail={item.thumbnail}
                    onAbort={() => onAbort(item.id)}
                    actions={getActions(item.id)}
                    onSelect={state => onSelect(item.id, state)}
                  />
                </Fragment>
              );
            })}
          </List>
        </Panel>
      </Collapse>
    </Wrap>
  );
}

export default Drawer;
