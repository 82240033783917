import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/cms/multieventapp/v1/organizations/${organizationId}/multi-event-apps`);

export const MODE_SINGLE = 'single';
export const MODE_MULTI = 'multi';

export function addEvent(organizationId, id, eventId) {
  return api(organizationId)
    .post(`${id}/action/add-event-apps`, {
      eventIds: [eventId],
    })
    .then(response => response.data.data.addedEventApps[0] ?? null);
}

export function list(organizationId) {
  return api(organizationId)
    .get('')
    .then(response => response.data.data);
}

export function get(organizationId, id) {
  return api(organizationId)
    .get(id)
    .then(response => response.data.data);
}

export function create(organizationId, payload) {
  return api(organizationId)
    .post('', payload)
    .then(async response => {
      if (payload.modeId === MODE_SINGLE) {
        await addEvent(organizationId, response.data.data.id, payload.launchEventId);
      }
      return response?.data?.data;
    });
}

export function update(organizationId, id, payload) {
  return api(organizationId)
    .patch(id, payload)
    .then(response => response?.data?.data);
}

export function remove(organizationId, id) {
  return api(organizationId)
    .delete(id)
    .then(response => response?.data?.data);
}
