import apiFn from 'common/services/api-service';

export const STATUS_SCHEDULED = 'scheduled';
export const STATUS_ACTIVE = 'active';
export const STATUS_EXPIRED = 'expired';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/contracts`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const list = (organizationId, { status, limit, sort } = { sort: '-start_date' }) => {
  const params = {
    sort,
    limit,
    status: status ? status.join(',') : undefined,
    // we always need credits_usage_by_organization
    include: 'credits_usage_by_organization',
  };

  return api(organizationId)
    .get('', { params })
    .then(response => response.data.data.map(data => data));
};

export const getLatestContract = (organizationId, { status } = {}) =>
  list(organizationId, { status, limit: 1 }).then(response => response[0]);

export const create = (organizationId, payload) => {
  return api(organizationId)
    .post('', payload)
    .then(response => response.data.data);
};

export const update = (organizationId, id, payload) => {
  return api(organizationId)
    .patch(id, payload)
    .then(response => response.data.data);
};

export const remove = (organizationId, id) => {
  return api(organizationId).delete(id);
};

export const events = (organizationId, contractId) => {
  const params = {
    include: 'credits_usage_by_events',
    id: contractId,
  };

  return api(organizationId)
    .get(``, { params })
    .then(response => response.data);
};
