import { Alert as AntAlert } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ReactGridLayout from 'react-grid-layout';
import styled, { createGlobalStyle, css } from 'styled-components';
import { t } from 'ttag';

import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';

import {
  MAX_COLUMNS,
  TYPE_LIMITS,
  MAX_WIDTH,
  MAX_MOBILE_COLUMNS,
  MAX_MOBILE_WIDTH,
  ROW_HEIGHT,
  TYPES,
  TYPE_IMAGE,
  TYPE_SPACE,
  MARGIN,
  PADDING,
  MOBILE_ROW_HEIGHT,
  MOBILE_MARGIN,
  MOBILE_PADDING,
} from './constants';

const Style = createGlobalStyle`
  .react-grid-item.react-grid-placeholder {
    && {
      background: #000000;
      opacity: 0.2;
      border-radius: 2px;
    }
  }

  .react-draggable-dragging {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  .react-resizable-handle {
    z-index: 99;
  }

  .react-grid-item > .react-resizable-handle {
    background: none;

    &::before, &::after {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
    }

    &::before {
      right: 4px;
      bottom: 4px;

      z-index: 2;

      border-right: 2px solid rgba(0, 0, 0, 0.75);
      border-bottom: 2px solid rgba(0, 0, 0, 0.75);
    }

    &::after {
      right: 3px;
      bottom: 3px;

      border-right: 2px solid rgba(255, 255, 255, 0.5);
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    }
  }
`;

const Alert = styled(AntAlert)`
  position: fixed;
  border: 0;
  background: none;
  color: #fff;
  margin-top: 10px;

  .ant-alert-message {
    color: #fff;
    margin: 0;
  }

  .ant-alert-description {
    margin-top: -5px;
  }

  .anticon {
    color: #fff;
  }
`;

const GridLayout = styled(ReactGridLayout)`
  ${props =>
    !props.readonly &&
    (!props.isMobile || props.mobileFocused) &&
    `
      padding: 1px;
      &:before {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237D7D7DFF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        content: '';
        z-index: 999;
        position: absolute;
        height: 100%;
        width: 100%;
        pointer-events: none;
      }
    `}
`;

const Desktop = styled.div`
  background-color: ${props => props.color};
  background-image: url('${props => props.image?.url ?? ''}');
  background-attachment: ${props => (props.isFixed ? 'fixed' : 'initial')};
  background-size: ${({ size }) => size};
`;

const Mobile = styled.div`
  background: #292a2d;
`;

const Base = ({ children, isMobile, mobileFocused, readonly, ...props }) =>
  isMobile && !mobileFocused && !readonly ? (
    <Mobile {...props}>
      <Alert
        message={t`Reorder widgets for mobile view.`}
        description={t`Return to desktop view to add widgets, or hide them from mobile view.`}
        type="info"
        showIcon
      />
      {children}
    </Mobile>
  ) : (
    <Desktop {...props}>{children}</Desktop>
  );

Base.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
  mobileFocused: PropTypes.bool,
  readonly: PropTypes.bool,
};

const Wrap = styled.div`
  width: ${props => props.width}px;
  margin-left: auto;
  margin-right: auto;

  ${props =>
    props.isMobile &&
    !props.mobileFocused &&
    css`
      overflow: hidden;

      background-color: ${({ color }) => color};
      background-image: url('${({ image }) => image?.url ?? ''}');
      background-attachment: ${({ isFixed }) => (isFixed ? 'fixed' : 'initial')};
      background-size: ${({ size }) => size};
    `}
`;

const Grid = ({
  children,
  widgets = [],
  isMobile = false,
  mobileFocused = false,
  background = {},
  onChange = () => {},
  readonly = false,
}) => {
  const source = useMemo(() => {
    if (!isMobile) {
      return widgets.map(({ id, type, layout }) => ({
        ...TYPE_LIMITS[type],
        ...layout,
        i: id,
        isDraggable: !readonly,
        isResizable: !readonly,
      }));
    }

    // check if any of the widget has mobile dimensions set
    if (widgets.some(({ layout: { mX = null, mY = null } = {} }) => mX !== null && mY !== null)) {
      return widgets.map(({ id, type, layout }) => ({
        ...TYPE_LIMITS[type],
        ...{ x: layout.mX, y: layout.mY, w: layout.mW, h: layout.mH },
        i: id,
        isDraggable: !readonly,
        isResizable: !readonly,
      }));
    }

    // when no widget has mobile dimensions set, then calculate mobile layout based
    // on a now deprecated algorithm for backward compatibility
    return widgets
      .sort((a, b) => {
        if (a.layout.y === b.layout.y) {
          // when both are on the same row, sort horizontally
          return a.layout.x - b.layout.x;
        }

        return a.layout.y - b.layout.y;
      })
      .reduce((prev, { id, type, layout }, index) => {
        let { w, h } = layout;

        // Every widget on mobile takes the max width
        w = MAX_MOBILE_COLUMNS;

        // for square image widgets, keep the same proportions
        if (type === TYPE_IMAGE && layout.w === layout.h) {
          h = w;
        }

        // when space widget is taking the full width, reduce the height to be minimum
        if (type === TYPE_SPACE && w === MAX_MOBILE_COLUMNS) {
          h = TYPE_LIMITS[TYPE_SPACE].minH;
        }

        // all other values that need to be set except (x, y)
        const vals = { w, h, i: id, type, isDraggable: !readonly, isResizable: !readonly };

        // for zeroth index, use (0, 0) for (x, y)
        if (index === 0) {
          return [{ ...vals, x: 0, y: 0 }];
        }

        // last widget
        const p = prev[index - 1];

        // when either previous widget or current widget taking up all width, use a new row
        if (p.w === MAX_MOBILE_COLUMNS || w === MAX_MOBILE_COLUMNS) {
          return [...prev, { ...vals, x: 0, y: p.y + 1 }];
        }

        // space left on the last row
        const l = MAX_MOBILE_COLUMNS - (p.x + p.w);

        // when current row has enough space to accommodate the current widget use the same row but with
        // x set to wherever previous widget ends
        if (l >= w) {
          return [...prev, { ...vals, x: l, y: p.y }];
        }

        // for all other cases, put the widget on next row
        return [...prev, { ...vals, x: 0, y: p.y + 1 }];
      }, []);
  }, [isMobile, widgets, readonly]);

  const structure = {
    ...background,
    isMobile,
    mobileFocused,
    readonly,
  };
  const width = isMobile ? MAX_MOBILE_WIDTH : MAX_WIDTH;

  const props = {
    width,
    cols: isMobile ? MAX_MOBILE_COLUMNS : MAX_COLUMNS,
    rowHeight: isMobile ? MOBILE_ROW_HEIGHT : ROW_HEIGHT,
    isDraggable: !readonly,
    isResizable: !readonly,
    onLayoutChange: layout => onChange(layout, isMobile),
    layout: source,
    margin: isMobile ? MOBILE_MARGIN : MARGIN,
    containerPadding: isMobile ? MOBILE_PADDING : PADDING, // even though it's zero, we still need to pass it to override default padding
    readonly,
    isMobile,
    mobileFocused,
  };

  return (
    <Base {...structure}>
      <Wrap {...structure} width={width}>
        <Style />
        <GridLayout {...props}>{children}</GridLayout>
      </Wrap>
    </Base>
  );
};

Grid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  isMobile: PropTypes.bool,
  mobileFocused: PropTypes.bool,
  background: PropTypes.shape({
    color: PropTypes.string,
    image: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
    isFixed: PropTypes.bool,
  }),
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      layout: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number,
      }),
      id: PropTypes.string,
      type: PropTypes.oneOf(Object.keys(TYPES())),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

export default Grid;
