import { ExclamationCircleFilled, SyncOutlined, CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PROCESSING,
  STATUS_UPLOADING,
} from '@components/UploadingDrawer/Provider';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
`;

const Error = styled.div`
  color: #f44336;
`;

function Header({ items, error }) {
  const total = items.length;
  const pending = items.filter(item => ![STATUS_COMPLETED, STATUS_FAILED].includes(item.status))
    .length;

  const valid = items.filter(item =>
    [STATUS_COMPLETED, STATUS_PROCESSING, STATUS_UPLOADING].includes(item.status)
  ).length;

  const finished = items.filter(item => item.status === STATUS_COMPLETED).length;
  const warning = !!error || items.every(item => item.status === STATUS_FAILED);

  return (
    <Wrapper>
      <Title>
        {!warning && pending > 0 && <SyncOutlined spin style={{ marginRight: 12 }} />}
        {!warning && pending === 0 && (
          <CheckOutlined style={{ color: '#4CAF50', marginRight: 12 }} />
        )}
        {warning && <ExclamationCircleFilled style={{ color: '#f44336', marginRight: 12 }} />}
        {pending > 0 && <span>{t`Uploading ${valid} of ${total} videos`}</span>}
        {pending === 0 && <span>{t`Upload Completed ${finished} of ${total} videos`}</span>}
      </Title>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
}

Header.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.string,
};

export default Header;
