function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var __generator = this && this.__generator || function(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return(g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g);
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
};
import Portal from "../../types/Portal";
import apiFn from "../api-service";
import { retrieve } from "../session-service";
var authApi = function(endpoint) {
    return apiFn("/api/uapi/".concat(endpoint));
};
var identityApi = function(endpoint) {
    return apiFn("/api/uapi/identity/".concat(endpoint));
};
export var isAuthenticated = function() {
    var _ref = _asyncToGenerator(function() {
        var session;
        return __generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        retrieve()
                    ];
                case 1:
                    session = _state.sent();
                    return [
                        2,
                        session !== null && typeof session.userId !== "undefined" && session.userId !== null
                    ];
            }
        });
    });
    return function isAuthenticated() {
        return _ref.apply(this, arguments);
    };
}();
export var validateToken = function(token, type) {
    return identityApi("validate-token").get("?type=".concat(type, "&token=").concat(token));
};
export var resetPassword = function(payload) {
    return identityApi("reset-password").post("", _objectSpread({}, payload));
};
export var forgotPassword = function(payload) {
    return identityApi("forgot-password").post("", _objectSpread({}, payload));
};
export var checkAdminInviteStatus = function(payload) {
    return identityApi("admin-invite-status").post("", _objectSpread({}, payload)).then(function(response) {
        return response.data;
    });
};
export var acceptAdminInvite = function(payload) {
    return identityApi("accept-admin-invite").post("", _objectSpread({}, payload)).then(function(response) {
        return response.data;
    });
};
export var logout = function(portalType) {
    var type = portalType === Portal.CompanyPortal ? "company-portal" : "";
    return authApi(type).post("logout").then(function(response) {
        return response.data;
    });
};
export var update = function(payload) {
    return identityApi("me").patch("", _objectSpread({}, payload)).then(function(param) {
        var data = param.data;
        return data;
    });
};
export var get = function() {
    return identityApi("me").get("").then(function(param) {
        var data = param.data;
        return data;
    });
};
