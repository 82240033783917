/*
 * This util maps the EXP section icons to EA's Font Awesome 5 icons.
 */
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFlickr } from '@fortawesome/free-brands-svg-icons/faFlickr';
import { faFortAwesome } from '@fortawesome/free-brands-svg-icons/faFortAwesome';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faPiedPiper } from '@fortawesome/free-brands-svg-icons/faPiedPiper';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons/faProductHunt';
import { faSnapchatGhost } from '@fortawesome/free-brands-svg-icons/faSnapchatGhost';
import { faTumblr } from '@fortawesome/free-brands-svg-icons/faTumblr';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faVimeoV } from '@fortawesome/free-brands-svg-icons/faVimeoV';
import { faXing } from '@fortawesome/free-brands-svg-icons/faXing';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faArrowAltCircleDown as faArrowAltCircleDownRegular } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleDown';
import { faArrowAltCircleLeft as faArrowAltCircleLeftRegular } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight as faArrowAltCircleRightRegular } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleRight';
import { faArrowAltCircleUp as faArrowAltCircleUpRegular } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleUp';
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons/faBell';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons/faBookmark';
import { faBuilding as faBuildingRegular } from '@fortawesome/free-regular-svg-icons/faBuilding';
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar';
import { faChartBar } from '@fortawesome/free-regular-svg-icons/faChartBar';
import { faCheckCircle as faCheckCircleRegular } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faCheckSquare as faCheckSquareRegular } from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faClone } from '@fortawesome/free-regular-svg-icons/faClone';
import { faComment as faCommentRegular } from '@fortawesome/free-regular-svg-icons/faComment';
import { faCommentDots as faCommentDotsRegular } from '@fortawesome/free-regular-svg-icons/faCommentDots';
import { faComments as faCommentsRegular } from '@fortawesome/free-regular-svg-icons/faComments';
import { faCompass } from '@fortawesome/free-regular-svg-icons/faCompass';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { faCopyright } from '@fortawesome/free-regular-svg-icons/faCopyright';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons/faCreditCard';
import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faFile } from '@fortawesome/free-regular-svg-icons/faFile';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import { faFlag as faFlagRegular } from '@fortawesome/free-regular-svg-icons/faFlag';
import { faFolder as faFolderRegular } from '@fortawesome/free-regular-svg-icons/faFolder';
import { faGem } from '@fortawesome/free-regular-svg-icons/faGem';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons/faHeart';
import { faHospital } from '@fortawesome/free-regular-svg-icons/faHospital';
import { faHourglass } from '@fortawesome/free-regular-svg-icons/faHourglass';
import { faImage } from '@fortawesome/free-regular-svg-icons/faImage';
import { faKeyboard } from '@fortawesome/free-regular-svg-icons/faKeyboard';
import { faLifeRing } from '@fortawesome/free-regular-svg-icons/faLifeRing';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { faListAlt } from '@fortawesome/free-regular-svg-icons/faListAlt';
import { faMap } from '@fortawesome/free-regular-svg-icons/faMap';
import { faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons/faMoneyBillAlt';
import { faMoon } from '@fortawesome/free-regular-svg-icons/faMoon';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons/faNewspaper';
import { faPaperPlane as faPaperPlaneRegular } from '@fortawesome/free-regular-svg-icons/faPaperPlane';
import { faPlusSquare as faPlusSquareRegular } from '@fortawesome/free-regular-svg-icons/faPlusSquare';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faSmile } from '@fortawesome/free-regular-svg-icons/faSmile';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons/faStar';
import { faStickyNote as faStickyNoteRegular } from '@fortawesome/free-regular-svg-icons/faStickyNote';
import { faSun } from '@fortawesome/free-regular-svg-icons/faSun';
import { faThumbsDown as faThumbsDownRegular } from '@fortawesome/free-regular-svg-icons/faThumbsDown';
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/free-regular-svg-icons/faThumbsUp';
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser';
import { faAnchor } from '@fortawesome/free-solid-svg-icons/faAnchor';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons/faAngleDoubleDown';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons/faAngleDoubleUp';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import { faArrowAltCircleDown as faArrowAltCircleDownSolid } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleDown';
import { faArrowAltCircleLeft as faArrowAltCircleLeftSolid } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight as faArrowAltCircleRightSolid } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleRight';
import { faArrowAltCircleUp as faArrowAltCircleUpSolid } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBed } from '@fortawesome/free-solid-svg-icons/faBed';
import { faBeer } from '@fortawesome/free-solid-svg-icons/faBeer';
import { faBell as faBellSolid } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBicycle } from '@fortawesome/free-solid-svg-icons/faBicycle';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons/faBinoculars';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons/faBirthdayCake';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons/faBookmark';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faBuilding as faBuildingSolid } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faBullseye } from '@fortawesome/free-solid-svg-icons/faBullseye';
import { faBus } from '@fortawesome/free-solid-svg-icons/faBus';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons/faCameraRetro';
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar';
import { faCertificate } from '@fortawesome/free-solid-svg-icons/faCertificate';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheckSquare as faCheckSquareSolid } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons/faChevronCircleLeft';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons/faChevronCircleUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChild } from '@fortawesome/free-solid-svg-icons/faChild';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons/faCloudDownloadAlt';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { faCoffee } from '@fortawesome/free-solid-svg-icons/faCoffee';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faComment as faCommentSolid } from '@fortawesome/free-solid-svg-icons/faComment';
import { faCommentDots as faCommentDotsSolid } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { faComments as faCommentsSolid } from '@fortawesome/free-solid-svg-icons/faComments';
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube';
import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes';
import { faCut } from '@fortawesome/free-solid-svg-icons/faCut';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEraser } from '@fortawesome/free-solid-svg-icons/faEraser';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper';
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax';
import { faFemale } from '@fortawesome/free-solid-svg-icons/faFemale';
import { faFighterJet } from '@fortawesome/free-solid-svg-icons/faFighterJet';
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm';
import { faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask';
import { faFolder as faFolderSolid } from '@fortawesome/free-solid-svg-icons/faFolder';
import { faFutbol } from '@fortawesome/free-solid-svg-icons/faFutbol';
import { faGamepad } from '@fortawesome/free-solid-svg-icons/faGamepad';
import { faGavel } from '@fortawesome/free-solid-svg-icons/faGavel';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { faGlassMartini } from '@fortawesome/free-solid-svg-icons/faGlassMartini';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons/faHeadphones';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons/faHeartbeat';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons/faHourglassStart';
import { faInbox } from '@fortawesome/free-solid-svg-icons/faInbox';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop';
import { faLeaf } from '@fortawesome/free-solid-svg-icons/faLeaf';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons/faLongArrowAltDown';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons/faLongArrowAltUp';
import { faMagic } from '@fortawesome/free-solid-svg-icons/faMagic';
import { faMagnet } from '@fortawesome/free-solid-svg-icons/faMagnet';
import { faMale } from '@fortawesome/free-solid-svg-icons/faMale';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker';
import { faMapPin } from '@fortawesome/free-solid-svg-icons/faMapPin';
import { faMapSigns } from '@fortawesome/free-solid-svg-icons/faMapSigns';
import { faMedkit } from '@fortawesome/free-solid-svg-icons/faMedkit';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faMotorcycle } from '@fortawesome/free-solid-svg-icons/faMotorcycle';
import { faMusic } from '@fortawesome/free-solid-svg-icons/faMusic';
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons/faPaintBrush';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faPaperPlane as faPaperPlaneSolid } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faPaste } from '@fortawesome/free-solid-svg-icons/faPaste';
import { faPaw } from '@fortawesome/free-solid-svg-icons/faPaw';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faPlane } from '@fortawesome/free-solid-svg-icons/faPlane';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faPlusSquare as faPlusSquareSolid } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons/faPuzzlePiece';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';
import { faRecycle } from '@fortawesome/free-solid-svg-icons/faRecycle';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { faShip } from '@fortawesome/free-solid-svg-icons/faShip';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faSignal } from '@fortawesome/free-solid-svg-icons/faSignal';
import { faSitemap } from '@fortawesome/free-solid-svg-icons/faSitemap';
import { faSpaceShuttle } from '@fortawesome/free-solid-svg-icons/faSpaceShuttle';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons/faStethoscope';
import { faStickyNote as faStickyNoteSolid } from '@fortawesome/free-solid-svg-icons/faStickyNote';
import { faSubway } from '@fortawesome/free-solid-svg-icons/faSubway';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons/faSuitcase';
import { faTablet } from '@fortawesome/free-solid-svg-icons/faTablet';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faTaxi } from '@fortawesome/free-solid-svg-icons/faTaxi';
import { faThumbsDown as faThumbsDownSolid } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons/faThumbtack';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons/faTicketAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTint } from '@fortawesome/free-solid-svg-icons/faTint';
import { faTrain } from '@fortawesome/free-solid-svg-icons/faTrain';
import { faTree } from '@fortawesome/free-solid-svg-icons/faTree';
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy';
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck';
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
import { faUmbrella } from '@fortawesome/free-solid-svg-icons/faUmbrella';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faUnlink } from '@fortawesome/free-solid-svg-icons/faUnlink';
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons/faUnlockAlt';
import { faUserMd } from '@fortawesome/free-solid-svg-icons/faUserMd';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { faUtensilSpoon } from '@fortawesome/free-solid-svg-icons/faUtensilSpoon';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faWheelchair } from '@fortawesome/free-solid-svg-icons/faWheelchair';
import { faWifi } from '@fortawesome/free-solid-svg-icons/faWifi';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';

export const Icons = {
  about: faInfoCircle,
  agenda: faCalendar,
  alerts: faBellSolid,
  analytics: faChartArea,
  anchor: faAnchor,
  angleDoubleDown: faAngleDoubleDown,
  angleDoubleLeft: faAngleDoubleLeft,
  angleDoubleRight: faAngleDoubleRight,
  angleDoubleUp: faAngleDoubleUp,
  angleDown: faAngleDown,
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  angleUp: faAngleUp,
  announcements: faBullhorn,
  archive: faArchive,
  arrowCircleDown: faArrowAltCircleDownSolid,
  arrowCircleDownOpen: faArrowAltCircleDownRegular,
  arrowCircleLeft: faArrowAltCircleLeftSolid,
  arrowCircleLeftOpen: faArrowAltCircleLeftRegular,
  arrowCircleRight: faArrowAltCircleRightSolid,
  arrowCircleRightOpen: faArrowAltCircleRightRegular,
  arrowCircleUp: faArrowAltCircleUpSolid,
  arrowCircleUpOpen: faArrowAltCircleUpRegular,
  arrowDown: faArrowDown,
  arrowLeft: faArrowLeft,
  arrowRight: faArrowRight,
  arrowUp: faArrowUp,
  asterisk: faAsterisk,
  at: faAt,
  balanceScale: faBalanceScale,
  ban: faBan,
  bank: faUniversity,
  barChart: faChartBar,
  bed: faBed,
  beer: faBeer,
  bellOpen: faBellRegular,
  bicycle: faBicycle,
  binoculars: faBinoculars,
  birthdayCake: faBirthdayCake,
  bolt: faBolt,
  book: faBook,
  bookmark: faBookmarkSolid,
  bookmarkOpen: faBookmarkRegular,
  briefcase: faBriefcase,
  building: faBuildingSolid,
  buildingOpen: faBuildingRegular,
  bullseye: faBullseye,
  bus: faBus,
  cab: faTaxi,
  calculator: faCalculator,
  camera: faCamera,
  cameraRetro: faCameraRetro,
  car: faCar,
  castle: faFortAwesome,
  certificate: faCertificate,
  chain: faLink,
  chainBroken: faUnlink,
  check: faCheck,
  checkCircle: faCheckCircleSolid,
  checkCircleOpen: faCheckCircleRegular,
  checkSquare: faCheckSquareSolid,
  checkSquareOpen: faCheckSquareRegular,
  chevronCircleDown: faChevronCircleDown,
  chevronCircleLeft: faChevronCircleLeft,
  chevronCircleRight: faChevronCircleRight,
  chevronCircleUp: faChevronCircleUp,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  child: faChild,
  clipboard: faPaste,
  clock: faClock,
  clone: faClone,
  close: faTimes,
  cloud: faCloud,
  cloudDownload: faCloudDownloadAlt,
  code: faCode,
  coffee: faCoffee,
  cog: faCog,
  cogs: faCogs,
  columns: faColumns,
  comment: faCommentSolid,
  commenting: faCommentDotsSolid,
  commentingOpen: faCommentDotsRegular,
  commentOpen: faCommentRegular,
  comments: faCommentsSolid,
  commentsOpen: faCommentsRegular,
  companies: faFlagSolid,
  compass: faCompass,
  copy: faCopy,
  copyright: faCopyright,
  creditCard: faCreditCard,
  cube: faCube,
  cubes: faCubes,
  cut: faCut,
  cutlery: faUtensils,
  dashboard: faTachometerAlt,
  database: faDatabase,
  desktop: faDesktop,
  diamond: faGem,
  directlink: faExternalLinkAlt,
  download: faDownload,
  edit: faEdit,
  envelope: faEnvelopeSolid,
  envelopeOpen: faEnvelopeRegular,
  eraser: faEraser,
  eventFeed: faRss,
  exclamation: faExclamation,
  exclamationCircle: faExclamationCircle,
  exclamationTriangle: faExclamationTriangle,
  externalLink: faExternalLinkAlt,
  eye: faEye,
  eyedropper: faEyeDropper,
  facebookOfficial: faFacebook,
  fax: faFax,
  feed: faRss,
  female: faFemale,
  fighterJet: faFighterJet,
  file: faFile,
  film: faFilm,
  flag: faFlagSolid,
  flagCheckered: faFlagCheckered,
  flagOpen: faFlagRegular,
  flash: faBolt,
  flask: faFlask,
  flickr: faFlickr,
  folder: faFolderSolid,
  folderOpen: faFolderRegular,
  futbolOpen: faFutbol,
  gamepad: faGamepad,
  gavel: faGavel,
  gift: faGift,
  glass: faGlassMartini,
  googlePlus: faGooglePlusG,
  graduationCap: faGraduationCap,
  group: faUsers,
  hashtag: faHashtag,
  headphones: faHeadphones,
  heart: faHeartSolid,
  heartbeat: faHeartbeat,
  heartOpen: faHeartRegular,
  help: faQuestionCircle,
  history: faHistory,
  home: faHome,
  hospitalOpen: faHospital,
  hotel: faBed,
  hourglass: faHourglass,
  hourglass1: faHourglassStart,
  image: faImage,
  inbox: faInbox,
  info: faInfo,
  instagram: faInstagram,
  key: faKey,
  keyboardOpen: faKeyboard,
  laptop: faLaptop,
  leaf: faLeaf,
  lifeSaver: faLifeRing,
  lightbulbOpen: faLightbulb,
  lineChart: faChartLine,
  link: faLink,
  linkedin: faLinkedinIn,
  list: faList,
  locationArrow: faLocationArrow,
  lock: faLock,
  longArrowDown: faLongArrowAltDown,
  longArrowLeft: faLongArrowAltLeft,
  longArrowRight: faLongArrowAltRight,
  longArrowUp: faLongArrowAltUp,
  magic: faMagic,
  magnet: faMagnet,
  male: faMale,
  mapOpen: faMap,
  mapPin: faMapPin,
  maps: faMapMarker,
  mapSigns: faMapSigns,
  medkit: faMedkit,
  microphone: faMicrophone,
  minus: faMinus,
  mobilePhone: faMobileAlt,
  money: faMoneyBillAlt,
  moonOpen: faMoon,
  motorcycle: faMotorcycle,
  music: faMusic,
  newspaperOpen: faNewspaper,
  page: faFileAlt,
  paintBrush: faPaintBrush,
  paperclip: faPaperclip,
  paperPlane: faPaperPlaneSolid,
  paperPlaneOpen: faPaperPlaneRegular,
  paw: faPaw,
  pencil: faPencilAlt,
  phone: faPhone,
  photo: faImage,
  pieChart: faChartPie,
  piedPiper: faPiedPiper,
  pinterest: faPinterest,
  plane: faPlane,
  play: faPlay,
  playCircle: faPlayCircle,
  plug: faPlug,
  plus: faPlus,
  plusCircle: faPlusCircle,
  plusSquare: faPlusSquareSolid,
  plusSquareOpen: faPlusSquareRegular,
  productHunt: faProductHunt,
  puzzlePiece: faPuzzlePiece,
  question: faQuestion,
  quoteLeft: faQuoteLeft,
  quoteRight: faQuoteRight,
  recycle: faRecycle,
  road: faRoad,
  rocket: faRocket,
  scissors: faCut,
  shareAlt: faShareAlt,
  shield: faShieldAlt,
  ship: faShip,
  shoppingCart: faShoppingCart,
  signal: faSignal,
  sitemap: faSitemap,
  smileOpen: faSmile,
  snapchatGhost: faSnapchatGhost,
  soccerBall: faFutbol,
  spaceShuttle: faSpaceShuttle,
  spoon: faUtensilSpoon,
  star: faStarSolid,
  starOpen: faStarRegular,
  stethoscope: faStethoscope,
  stickyNote: faStickyNoteSolid,
  stickyNoteOpen: faStickyNoteRegular,
  subway: faSubway,
  suitcase: faSuitcase,
  sunOpen: faSun,
  survey: faListAlt,
  tablet: faTablet,
  tachometer: faTachometerAlt,
  tag: faTag,
  tags: faTags,
  television: faTv,
  thumbsDown: faThumbsDownSolid,
  thumbsDownOpen: faThumbsDownRegular,
  thumbsUp: faThumbsUpSolid,
  thumbsUpOpen: faThumbsUpRegular,
  thumbTack: faThumbtack,
  ticket: faTicketAlt,
  tint: faTint,
  train: faTrain,
  tree: faTree,
  trophy: faTrophy,
  truck: faTruck,
  tumblr: faTumblr,
  twitter: faTwitter,
  umbrella: faUmbrella,
  university: faUniversity,
  unlock: faUnlock,
  unlockAlt: faUnlockAlt,
  user: faUser,
  userMd: faUserMd,
  users: faUsers,
  videoCamera: faVideo,
  vimeo: faVimeoV,
  warning: faExclamationTriangle,
  wheelchair: faWheelchair,
  wifi: faWifi,
  wrench: faWrench,
  xing: faXing,
  youtube: faYoutube,
};

export const ICON_KEYS = Object.keys(Icons);
