import {
  SettingFilled,
  AppleFilled,
  AndroidFilled,
  RightOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  Button,
  Row,
  Col,
  Typography,
  Collapse as AntCollapse,
  Form,
  Alert,
  message,
  Input,
  Space,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';

import ImageUpload from 'common/components/ImageUpload';
import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import StickyFooter from 'common/components/StickyFooter';

import FileUpload from '@components/FileUpload';

import { get as getFn, create as createFn, update as updateFn } from './configuration-service';

const { Text } = Typography;
const { Password } = Input;
const { Item, useForm } = Form;
const Collapse = styled(AntCollapse)`
  margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
  background: #fff;
`;
const Panel = styled(AntCollapse.Panel)`
  color: white;
  border: 1px solid #d9d9d9;
  margin-bottom: 16px;
`;
const Base = styled.div`
  padding: 23px 0;
`;
const Heading = styled(Text)`
  font-size: 16px;
`;

const RESOURCE_TYPE_ORGANIZATIONS = 'organizations';
const GENERAL_FIELDS = ['icon_image', 'splash_image'];
const IOS_FIELDS = [
  'ios_app_store_connect_issuer_id',
  'ios_app_store_connect_key_identifier',
  'ios_app_store_connect_private_key_file',
  'ios_certificate_private_key_file',
  'ios_push_signing_key_file',
  'ios_push_signing_key_id',
  'ios_push_team_id',
];
const ANDROID_FIELDS = [
  'android_keystore_file',
  'android_keystore_password',
  'android_key_password',
  'android_key_alias',
  'android_google_credentials_file',
];
const EM_IOS_STORE_URL = 'https://apps.apple.com/app/eventmobi/id1276348688';
const EM_ANDROID_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.eventmobi.multieventapp.meaidfa33d52eb4374fdb891ab905fa3a72e7';

function General({ organizationId, getValidationProps, isEditing }) {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={18} xl={14} xxl={10}>
        <Item
          name="appIdInStores"
          label={<Label isRequired>{t`App ID`}</Label>}
          rules={[
            { required: 'true', message: t`App ID is required` },
            {
              validator: async (rule, value) => {
                // Although AppID field is disabled in editing mode,
                // we still only want to apply this validation explicitly
                // in non-editing mode, because some apps might still have
                // AppID in non-lowercase. In those cases, when the form is
                // submitted, this validation will prevent it from saving the
                // form. So we want to ignore the validation in editing mode.
                // So essentially, AppID lowercase validation will only trigger
                // when it's being saved for the first time.
                if (!isEditing && value && value !== value.toLowerCase()) {
                  throw new Error('Must be in lowercase');
                }
              },
              message: t`Must be in lowercase`,
            },
          ]}
        >
          <Input placeholder="com.companyname.appname" maxLength={255} disabled={isEditing} />
        </Item>
        <Item
          name="appNameInStores"
          label={<Label isRequired>{t`App Name`}</Label>}
          rules={[{ required: 'true', message: t`App Name is required` }]}
        >
          <Input placeholder="EventMobi" maxLength={255} />
        </Item>
        <Item
          name="iconImage"
          label={<Label>{t`App Icon`}</Label>}
          trigger="onComplete"
          {...getValidationProps('icon_image', t`App Icon`)}
        >
          <ImageUpload
            enableCrop
            resourceType={RESOURCE_TYPE_ORGANIZATIONS}
            resourceId={organizationId}
          />
        </Item>
        <Item
          name="splashImage"
          label={<Label>{t`Splash`}</Label>}
          trigger="onComplete"
          {...getValidationProps('splash_image', t`Splash`)}
        >
          <ImageUpload
            enableCrop
            resourceType={RESOURCE_TYPE_ORGANIZATIONS}
            resourceId={organizationId}
          />
        </Item>
      </Col>
    </Row>
  );
}

General.propTypes = {
  organizationId: PropTypes.string.isRequired,
  getValidationProps: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

function Ios({ organizationId, getValidationProps }) {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={18} xl={14} xxl={10}>
        <Item
          name={['iosBuildCredentials', 'iosAppStoreConnectIssuerId']}
          label={<Label>{t`App Store Connect Issuer ID`}</Label>}
          {...getValidationProps('ios_app_store_connect_issuer_id', t`App Store Connect Issuer ID`)}
        >
          <Input maxLength={36} placeholder="ABCD1234-AB12-34CD-XYZ1-ABCD1234WXYZ" />
        </Item>

        <Item
          name={['iosBuildCredentials', 'iosAppStoreConnectKeyIdentifier']}
          label={<Label>{t`App Store Connect Key ID`}</Label>}
          {...getValidationProps(
            'ios_app_store_connect_key_identifier',
            t`App Store Connect Key ID`
          )}
        >
          <Input maxLength={10} placeholder="123ABCD789" />
        </Item>

        <Item
          name={['iosBuildCredentials', 'iosAppStoreConnectPrivateKeyFile']}
          label={<Label>{t`App Store Connect Private Key`}</Label>}
          {...getValidationProps(
            'ios_app_store_connect_private_key_file',
            t`App Store Connect Private Key`
          )}
        >
          <FileUpload
            resourceType={RESOURCE_TYPE_ORGANIZATIONS}
            resourceId={organizationId}
            supportedTypes={['.pem']}
          />
        </Item>

        <Item
          name={['iosBuildCredentials', 'iosCertificatePrivateKeyFile']}
          label={<Label>{t`Certificate Private Key`}</Label>}
          {...getValidationProps('ios_certificate_private_key_file', t`Certificate Private Key`)}
        >
          <FileUpload
            resourceType={RESOURCE_TYPE_ORGANIZATIONS}
            resourceId={organizationId}
            supportedTypes={['.pem']}
          />
        </Item>

        <Item
          name={['iosBuildCredentials', 'iosPushSigningKeyFile']}
          label={<Label>{t`APNS Key`}</Label>}
          {...getValidationProps('ios_push_signing_key_file', t`APNS Key`)}
        >
          <FileUpload
            resourceType={RESOURCE_TYPE_ORGANIZATIONS}
            resourceId={organizationId}
            supportedTypes={['.pem']}
          />
        </Item>

        <Item
          name={['iosBuildCredentials', 'iosPushSigningKeyId']}
          label={<Label>{t`APNS Key ID`}</Label>}
          {...getValidationProps('ios_push_signing_key_id', t`APNS Key ID`)}
        >
          <Input maxLength={10} placeholder="123ABCD789" />
        </Item>

        <Item
          name={['iosBuildCredentials', 'iosPushTeamId']}
          label={<Label>{t`Team ID`}</Label>}
          {...getValidationProps('ios_push_team_id', t`Team ID`)}
        >
          <Input maxLength={10} placeholder="123ABCD789" />
        </Item>

        <Item name={['iosBuildCredentials', 'iosStoreUrl']} label={<Label>{t`Store URL`}</Label>}>
          <Input placeholder={EM_IOS_STORE_URL} />
        </Item>
      </Col>
    </Row>
  );
}

Ios.propTypes = {
  organizationId: PropTypes.string.isRequired,
  getValidationProps: PropTypes.func.isRequired,
};

function Android({ organizationId, getValidationProps }) {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={18} xl={14} xxl={10}>
        <Item
          name={['androidBuildCredentials', 'androidKeystoreFile']}
          label={<Label>{t`Keystore`}</Label>}
          {...getValidationProps('android_keystore_file', t`Keystore`)}
        >
          <FileUpload
            resourceType={RESOURCE_TYPE_ORGANIZATIONS}
            resourceId={organizationId}
            supportedTypes={['.pem']}
          />
        </Item>
        <Item
          name={['androidBuildCredentials', 'androidKeystorePassword']}
          label={<Label>{t`Keystore Password`}</Label>}
          {...getValidationProps('android_keystore_password', t`Keystore Password`)}
        >
          <Password autoComplete="new-password" maxLength={255} />
        </Item>
        <Item
          name={['androidBuildCredentials', 'androidKeyPassword']}
          label={<Label>{t`Key Password`}</Label>}
          {...getValidationProps('android_key_password', t`Key Password`)}
        >
          <Password autoComplete="new-password" maxLength={255} />
        </Item>
        <Item
          name={['androidBuildCredentials', 'androidKeyAlias']}
          label={<Label>{t`Key Alias`}</Label>}
          {...getValidationProps('android_key_alias', t`Key Alias`)}
        >
          <Input maxLength={255} />
        </Item>
        <Item
          name={['androidBuildCredentials', 'androidGoogleCredentialsFile']}
          label={<Label>{t`Google Credentials (For Publishing)`}</Label>}
          {...getValidationProps('android_google_credentials_file', t`Google Credentials`)}
        >
          <FileUpload
            resourceType={RESOURCE_TYPE_ORGANIZATIONS}
            resourceId={organizationId}
            supportedTypes={['.json']}
          />
        </Item>

        <Item
          name={['androidBuildCredentials', 'androidStoreUrl']}
          label={<Label>{t`Store URL`}</Label>}
        >
          <Input placeholder={EM_ANDROID_STORE_URL} />
        </Item>
      </Col>
    </Row>
  );
}

Android.propTypes = {
  organizationId: PropTypes.string.isRequired,
  getValidationProps: PropTypes.func.isRequired,
};

const useConfiguration = (organizationId, appId) => {
  const [isEditing, setIsEditing] = useState(false);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function load() {
    try {
      setLoading(true);
      const value = await getFn(organizationId, appId);
      setIsEditing(!!value?.id);
      setConfig(value);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    load();
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, appId]);

  return {
    config,
    loading,
    isEditing,
    error,
    setError,
    forceReload: load,
  };
};

export default function Configuration({ organizationId, appId }) {
  const { config, loading, isEditing, error, setError, forceReload } = useConfiguration(
    organizationId,
    appId
  );
  const [saving, setSaving] = useState(false);
  const [form] = useForm();
  const { validateFields } = form;
  const [activeKey, setActiveKey] = useState(null);
  const { state } = useLocation();

  const missingFields = state?.missingFields;
  const missingGeneralFields = missingFields?.some(x => GENERAL_FIELDS.includes(x));
  const missingIosFields = missingFields?.some(x => IOS_FIELDS.includes(x));
  const missingAndroidFields = missingFields?.some(x => ANDROID_FIELDS.includes(x));

  useEffect(() => {
    if (missingFields?.length > 0) {
      setActiveKey(null);
    }
  }, [missingFields]);

  const getValidationProps = (fieldName, label) =>
    missingFields?.includes(fieldName)
      ? {
          help: t`${label} is missing`,
          validateStatus: 'warning',
        }
      : {};

  const onSave = async () => {
    const payload = await validateFields();
    try {
      setError(null);
      setSaving(true);

      if (isEditing) {
        await updateFn(organizationId, appId, config.id, payload);
      } else {
        await createFn(organizationId, appId, payload);
      }
      await forceReload();
      message.success(t`Successfully saved configuration`);
    } catch (e) {
      if (!e.errorFields) {
        setError(e?.errors?.[0]?.message ?? t`An unknown error occurred while saving event.`);
        throw e;
      }
    } finally {
      setSaving(false);
    }
  };

  const Footer = () => (
    <StickyFooter fullWidth>
      <Space>
        <Button type="primary" loading={saving} disabled={saving} onClick={onSave}>
          {t`Save Changes`}
        </Button>
        <Button loading={loading} disabled={saving} onClick={forceReload}>
          {t`Cancel`}
        </Button>
      </Space>
    </StickyFooter>
  );

  const MissingFieldsAlert = () => (
    <Space>
      <ExclamationCircleFilled style={{ fontSize: '21px', color: '#FAAD14' }} />
      <Text>{t`Warning: Missing Information`}</Text>
    </Space>
  );

  return (
    <Base>
      {(loading || !organizationId) && <Loading />}

      {!loading && config && (
        <>
          {error && (
            <Row css="margin-bottom: 20px">
              <Col span={24}>
                <Alert type="error" message={error} showIcon />
              </Col>
            </Row>
          )}

          <Form
            layout="vertical"
            name="info"
            requiredMark={false}
            form={form}
            initialValues={{
              ...config,
              iconImage: config.iconImage ?? undefined,
              splashImage: config.splashImage ?? undefined,
            }}
          >
            <Collapse
              accordion="true"
              expandIconPosition="end"
              expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />}
              activeKey={activeKey}
              onChange={key => setActiveKey(key)}
            >
              <Panel
                key="general"
                header={
                  <Space>
                    <SettingFilled style={{ fontSize: '16px' }} />
                    <Heading>{t`General`}</Heading>
                  </Space>
                }
                extra={missingGeneralFields && <MissingFieldsAlert />}
              >
                <General
                  organizationId={organizationId}
                  getValidationProps={getValidationProps}
                  isEditing={isEditing}
                />
              </Panel>
              <Panel
                key="ios"
                header={
                  <Space>
                    <AppleFilled style={{ fontSize: '16px', color: '#BFBFBF' }} />
                    <Heading>{t`iOS`}</Heading>
                  </Space>
                }
                extra={missingIosFields && <MissingFieldsAlert />}
              >
                <Ios organizationId={organizationId} getValidationProps={getValidationProps} />
              </Panel>
              <Panel
                key="android"
                header={
                  <Space>
                    <AndroidFilled style={{ fontSize: '16px', color: '#3DDB85' }} />
                    <Heading>{t`Android`}</Heading>
                  </Space>
                }
                extra={missingAndroidFields && <MissingFieldsAlert />}
              >
                <Android organizationId={organizationId} getValidationProps={getValidationProps} />
              </Panel>
            </Collapse>
            <Footer />
          </Form>
        </>
      )}
    </Base>
  );
}

Configuration.propTypes = {
  organizationId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
};
