import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { COLUMN_SIZE } from '../constants';

const EDITOR_MODE_VALUE = 'https://eventmobi.com';

const Container = styled.div`
  margin: 0 auto;
  width: ${props => props.width * COLUMN_SIZE}px;
  height: ${props => props.height * COLUMN_SIZE}px;

  // we need this because we render twice the size of QRCode in
  // QRCode.toCanvas to minimize blurring across contrasting edges
  canvas {
    width: ${props => props.width * COLUMN_SIZE}px !important;
    height: ${props => props.height * COLUMN_SIZE}px !important;
  }
`;

const PersonalCode = ({ readOnly, width, height, fillColor, person }) => {
  const canvas = useRef(null);

  useEffect(() => {
    if (!canvas.current) {
      return;
    }

    const value = readOnly && person ? person.checkinCode ?? person.id : EDITOR_MODE_VALUE;
    QRCode.toCanvas(canvas.current, value, {
      margin: 0,
      width: width * COLUMN_SIZE * 2,
      color: {
        dark: fillColor,
        light: '#00000000',
      },
    });
  }, [width, fillColor, person, readOnly]);

  return (
    <Container width={width} height={height}>
      <canvas ref={canvas} />
    </Container>
  );
};

PersonalCode.propTypes = {
  readOnly: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fillColor: PropTypes.string.isRequired,
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    checkinCode: PropTypes.string,
  }),
};

export default PersonalCode;
