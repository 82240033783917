import React, { useCallback, useState, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'ttag';

import AuthLogin from 'common/components/Auth/Login';
import useSearchParams from 'common/hooks/useSearchParams';
import useTranslation from 'common/hooks/useTranslation';
import { system as systemFlagFn, user as userFlagFn } from 'common/services/flags/api';
import { retrieve as retrieveFn } from 'common/services/session-service';
import { setUser as setUserFlags, setSystem as setSystemFlags } from 'common/state/flags';
import { setUser } from 'common/state/user';

import { clearAlertCookies } from '@components/AppAlerts';
import { clearAllConfigs as clearAllBadgeDesignerConfigsFn } from '@domains/Badges/Download/service';

import { login as loginFn, isAuthenticated } from './service';

const Login = () => {
  const dispatch = useDispatch();
  const [initializing, setInitializing] = useState(true);
  const history = useHistory();
  const query = useSearchParams();
  const { setLanguage } = useTranslation();

  const redirect = useCallback(() => {
    const next = query.get('next');
    if (next && next !== '/login') {
      return history.push(next);
    }

    return history.push('/organization');
  }, [history, query]);

  useLayoutEffect(() => {
    async function effect() {
      if (!(await isAuthenticated())) {
        const { data: systemFlags } = await systemFlagFn();
        dispatch(setSystemFlags(systemFlags));
        setInitializing(false);
        return;
      }

      const { language, ...user } = await retrieveFn();

      const { data: flags } = await userFlagFn(user.userId);

      dispatch([setUser(user), setUserFlags(flags)]);

      setLanguage(language);

      redirect();
    }

    effect();
  }, [dispatch, setLanguage, redirect]);

  const onFinish = async user => {
    setLanguage(user.language);

    await clearAllBadgeDesignerConfigsFn();
    clearAlertCookies();
  };

  if (initializing) {
    return null;
  }

  return (
    <AuthLogin
      title={t`Log in to Experience Manager`}
      loginFn={loginFn}
      redirectUrl="/organization"
      afterLogin={onFinish}
    />
  );
};

export default Login;
