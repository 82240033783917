import { Typography, Input, DatePicker as AntDatePicker, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { t } from 'ttag';

import Label from 'common/components/Label';

import { LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT } from '@domains/Organization/service';

const { RangePicker: AntRangePicker } = AntDatePicker;

const LOCALE_DATE = 'L';

const RangePicker = styled(AntRangePicker)`
  width: 100%;
`;

// over riding styles for range picker popup for this component
const PickerPopupStyles = createGlobalStyle`
.ant-picker {
  .ant-picker-input > input {
    text-align: center;
 }

 .ant-calendar-range-middle {
   padding: 0;
 }
}
`;

const { Item } = Form;

const Details = ({
  currentMode,
  name,
  dates,
  code,
  credit,
  isReserved,
  setName,
  setDates,
  setCode,
}) => {
  const maxDuration = credit?.event_max_duration_days;

  return (
    // This form is used just for layout purposes
    <Form layout="vertical">
      {/* For RangePicker */}
      <PickerPopupStyles />

      {currentMode === LICENSE_TYPE_USER_CREDIT && (
        <Typography.Title level={5}>{t`Event Details`}</Typography.Title>
      )}

      <Item label={<Label isRequired>{t`Event Name`}</Label>}>
        <Input data-testid="name" value={name} onChange={e => setName(e.target.value)} />
      </Item>
      <Item label={<Label isRequired>{t`Event Start and End Date`}</Label>}>
        <RangePicker
          onChange={e => setDates(e)}
          value={dates}
          showTime={false}
          onCalendarChange={d => {
            setDates(d);
          }}
          disabledDate={current => {
            if (credit?.expires_at && current.isAfter(credit?.expires_at, 'days')) {
              return true;
            }

            if (current.isBefore(moment(), 'days')) {
              return true;
            }

            if (!dates || dates.length === 0 || !maxDuration) {
              return false;
            }
            const tooLate = dates[0] && current.diff(dates[0], 'days') > maxDuration;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxDuration;
            return tooEarly || tooLate;
          }}
          format={LOCALE_DATE}
          separator="→"
        />
      </Item>
      <Item
        label={
          <Label
            isRequired
            info={t`This is used to create a unique URL for your Event Space, Registration Site and Live Display. Once set, this can only be changed by EventMobi Support.`}
          >
            {t`Event Code`}
          </Label>
        }
        help={isReserved ? t`Event Code can not be one of reserved words` : ''}
        validateStatus={isReserved ? 'error' : 'success'}
      >
        <Input
          data-testid="code"
          prefix="https://www.eventmobi.com/"
          value={code}
          onChange={e => setCode(e.target.value)}
        />
      </Item>
    </Form>
  );
};

Details.propTypes = {
  currentMode: PropTypes.oneOf([LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT]).isRequired,
  name: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.shape({})),
  code: PropTypes.string,
  credit: PropTypes.shape({}),
  setName: PropTypes.func.isRequired,
  setDates: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
};

export default Details;
