/* global ENV */
import { Alert } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';
import logger from 'use-reducer-logger';

import useSearchParams from 'common/hooks/useSearchParams';

import { BADGES, BADGES_TEMPLATES } from '@domains/Capture/constants';

import Builder from './Builder';
import { transform as transformFn } from './designer-service';
import reducer, { ACTION_POPULATE, ACTION_SET_LOADING, initialState } from './reducer';
import { get as getBadgeFn } from './service';
import { get as getBadgeTemplateFn } from './templates-service';

const NOT_FOUND_ERROR_CODES = ['badges_templates_not_found', 'badges_not_found'];

const Capture = () => {
  const query = useSearchParams();
  const token = query.get('token');
  const { eventId, resourceType, resourceId } = useParams();
  const [state, dispatch] = useReducer(ENV === 'development' ? logger(reducer) : reducer, {
    present: {
      ...initialState,
    },
  });
  const [error, setError] = useState();

  useEffect(() => {
    async function effect() {
      try {
        let payload;
        if (resourceType === BADGES) {
          payload = await getBadgeFn(eventId, resourceId, token);
        } else if (resourceType === BADGES_TEMPLATES) {
          payload = await getBadgeTemplateFn(resourceId, token);
        } else {
          throw new Error('Unknown resource type');
        }

        dispatch({ type: ACTION_POPULATE, payload: transformFn(payload) });
      } catch (err) {
        const notFound = NOT_FOUND_ERROR_CODES.includes(err?.errors?.[0]?.code);
        setError(notFound ? t`Resource was not found.` : t`Unknown error occurred.`);

        dispatch({ type: ACTION_SET_LOADING, payload: false });
      }
    }

    effect();
  }, [eventId, resourceType, resourceId, token]);

  if (error) {
    return <Alert type="error" message={error} showIcon />;
  }

  return <Builder state={state} readonly />;
};

export default Capture;
