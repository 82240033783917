import { t } from 'ttag';

export const ERROR_MESSAGES_BY_CODE = () => ({
  sso_identity_provider_exists: t`The connection name already exists. Please input different name.`,
});

export const IDP_TYPE_OIDC = 'OIDC';
export const IDP_TYPE_SAML = 'SAML';

export const IDP_TYPES = () => ({
  // order of the keys changes the order of appearance at relevant places
  [IDP_TYPE_SAML]: t`SAML`,
  [IDP_TYPE_OIDC]: t`OpenID`,
});
