import { Table as AntTable, Button as AntButton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

const { Column } = AntTable;

const Button = styled(AntButton)`
  padding: 0px;
`;

const Table = ({ dataSource, hideUpvotes = false, onToggleVisibility }) => {
  return (
    <AntTable dataSource={dataSource} pagination={false} rowKey="id">
      <Column title={t`Responses`} dataIndex="text" />
      {!hideUpvotes && (
        <Column
          title={t`Votes`}
          dataIndex="upvotes"
          sorter={(a, b) => a.upvotes - b.upvotes}
          width="100px"
        />
      )}
      <Column
        title={t`Action`}
        dataIndex="visible"
        render={(visible, record) => (
          <Button type="link" loading={record.loading} onClick={() => onToggleVisibility(record)}>
            {visible ? t`Hide` : t`Show`}
          </Button>
        )}
        width="100px"
      />
    </AntTable>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      visible: PropTypes.bool.isRequired,
      loading: PropTypes.bool,
      upvotes: PropTypes.number,
    })
  ),
  onToggleVisibility: PropTypes.func.isRequired,
  hideUpvotes: PropTypes.bool,
};

export default Table;
