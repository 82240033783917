import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import { isEnabled } from 'common/state/flags';

import {
  injectHubspotScript,
  interceptCookies,
  removeAllCookies,
  removeHubspotFrame,
} from '@utils/cookieUtils';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

const DEFAULT_PRIMARY_COLOR = '#6EBB83';
const DEFAULT_PRIMARY_HOVER_COLOR = '#91CBA1';
const DEFAULT_LINK_COLOR = '#1890ff';
const SECONDARY_COLOR = '#EAEFF2';
const SECONDARY_HOVER_COLOR = '#D8E0E6';
const SECONDARY_TEXT_COLOR = '#2D4156';

export const updateCookieConsentLanguage = () => {
  const cookieConsentApi = window.initCookieConsent();
  cookieConsentApi.updateLanguage('ttag', true /* force update */);
};

export const openCookieConsentSettings = () => {
  const cookieConsentApi = window.initCookieConsent();
  cookieConsentApi.showSettings();
};

const useCookieConsent = () => {
  const isExpCookieConsentEnabled = useSelector(state => isEnabled(state, 'global_cookie_consent'));
  const [cookieLocalAccepted, setCookieLocalAccepted] = useState(false);

  useEffect(() => {
    if (!isExpCookieConsentEnabled) {
      // If cookie consent does not exist we still want to load hubspot
      injectHubspotScript();
      return;
    }

    if (!cookieLocalAccepted) {
      // Remove all cookies except cc_cookie
      removeAllCookies();

      // Remove hubspot chat
      removeHubspotFrame();
    }

    // Set-up cookie interceptor
    interceptCookies(cookieLocalAccepted);

    if (cookieLocalAccepted) {
      // Load hubspot scripts after the interceptor is set, so we can catch any cookies set by hubspot
      injectHubspotScript();
    }
  }, [cookieLocalAccepted, isExpCookieConsentEnabled]);

  useEffect(() => {
    if (!isExpCookieConsentEnabled) {
      return;
    }

    const privacyPolicy = `
      <a
        id="privacy-link-cookie-consent"
        href="https://eventmobi.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >${t`Privacy Policy`}</a>`;

    const settings = `<a
        id="cookie-settings-button"
        href="#"
        onclick="((event) => {
          event.preventDefault();
          window.CookieConsentApi.showSettings();
        })(event)"
      >${t`Manage Preferences`}</a>`;

    if (document.getElementById('cc--main')) {
      return;
    }

    window.CookieConsentApi = window.initCookieConsent();
    window.CookieConsentApi.run({
      autorun: true,
      current_lang: 'ttag',
      autoclear_cookies: true,
      page_scripts: true,

      onFirstAction: userPreferences => {
        if (userPreferences.accept_type === 'all') {
          setCookieLocalAccepted(true);
        }
      },

      onAccept: cookie => {
        // Check if analytics is accepted by looking at the accepted categories
        if (cookie.categories.findIndex(category => category === 'analytics') === -1) {
          setCookieLocalAccepted(false);
        } else {
          setCookieLocalAccepted(true);
        }
      },

      onChange: cookie => {
        // Check if analytics is accepted by looking at the accepted categories
        if (cookie.categories.findIndex(category => category === 'analytics') === -1) {
          setCookieLocalAccepted(false);
        } else {
          setCookieLocalAccepted(true);
        }
      },

      languages: {
        ttag: {
          consent_modal: {
            title: t`Cookies`,
            description: t`We use cookies and other technologies on our website to help improve your experience. You can find more information about the use of your data in our ${privacyPolicy} and can mange your cookie selection at any time under the Settings page. ${settings}`,
            primary_btn: {
              text: t`Accept`,
              role: 'accept_all',
            },
            secondary_btn: {
              text: t`Decline`,
              role: 'accept_necessary',
            },
          },
          settings_modal: {
            title: t`Manage preferences`,
            save_settings_btn: t`Save settings`,
            accept_all_btn: t`Accept all`,
            reject_all_btn: t`Reject all`,
            cookie_table_headers: [
              { col1: t`Name` },
              { col2: t`Domain` },
              { col3: t`Expiration` },
              { col4: t`Description` },
              { col5: t`Type` },
            ],
            blocks: [
              {
                title: t`Cookie usage`,
                description: t`We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.`,
              },
              {
                title: t`Strictly necessary cookies`,
                description: t`These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly.`,
                toggle: {
                  value: t`necessary`,
                  enabled: true,
                  readonly: true,
                },
              },
              {
                title: t`Analytics cookies`,
                description: t`These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.`,
                toggle: {
                  value: t`analytics`,
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: t`More information`,
                description: t`You can find more information about the use of your data in our ${privacyPolicy}.`,
              },
            ],
          },
        },
      },
    });
  }, [isExpCookieConsentEnabled]);
};

export default function CookieConsent() {
  useCookieConsent();

  return (
    <Helmet>
      <style type="text/css">
        {`
          :root {
            --cc-btn-primary-bg: ${DEFAULT_PRIMARY_COLOR};
            --cc-btn-primary-hover-bg: ${DEFAULT_PRIMARY_HOVER_COLOR};
          }
          .cc_div a {
            color: ${DEFAULT_LINK_COLOR};
          }
          #c-s-bn.c-bn{
            background-color: ${DEFAULT_PRIMARY_COLOR};
            color: #FFFFFF;
          }
          #c-s-bn.c-bn:hover {
            background-color: ${DEFAULT_PRIMARY_HOVER_COLOR};
          }
          #s-sv-bn.c-bn {
            background-color: ${DEFAULT_PRIMARY_COLOR};
            color: #FFFFFF;
          }
          #s-sv-bn.c-bn:hover {
            background-color: ${DEFAULT_PRIMARY_HOVER_COLOR};
          }
          #s-bns button:first-child {
            background-color: ${SECONDARY_COLOR};
            color: ${SECONDARY_TEXT_COLOR};
          }
          #s-bns button:first-child:hover {
            background-color: ${SECONDARY_HOVER_COLOR};
          }
        `}
      </style>
    </Helmet>
  );
}
