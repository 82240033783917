import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Space, Button, Layout, Typography, message } from 'antd';
import { parseJSON } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-cool-inview';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';

import useSearchParams from 'common/hooks/useSearchParams';
import { getBasicData } from 'common/services/events/api';

import EmptyPostImg from '@assets/images/illustrations/activity-feed.svg';

import Post from './Post';
import { list as listFn, update as updateFn } from './post-service';

const LAST_SEEN_POST_PREFIX = 'em-last-seen-post';

const { Header: AntHeader, Content: AntContent } = Layout;

const { Text, Title } = Typography;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FullCenter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const GreyLine = styled.hr`
  background-color: #bfbfbf;
  margin-right: 20px;
  height: 1px;
  border: 0;
  width: 100%;
`;

const GreyLineRight = styled.hr`
  background-color: #bfbfbf;
  height: 1px;
  border: 0;
  width: 100%;
`;

const LinkButton = styled(Button)`
  padding: 0;
  margin: 0;
  margin-left: 6px;
  height: 21;
  color: #1890ff;
  border: none;
`;

const Content = styled(AntContent)`
  background: #fff;
  padding: 48px;
`;

const Logo = styled.img`
  height: 26px;
  margin-right: 8px;
`;

const PageTitle = styled(Title)`
  margin-bottom: 48px;
`;

const PageSubHeading = styled(Text)`
  display: block;
  margin-bottom: 24px;
`;

const SubTitle = styled(Text)`
  padding: 0;
  margin-right: 8px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
`;

const Header = styled(AntHeader)`
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  height: auto;
  padding: 0;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  z-index: 1000;
`;

const PAGE_SIZE = 6;

const LastSeen = () => {
  return (
    <FlexCol>
      <FullCenter>
        <GreyLine />
        <CheckCircleOutlined style={{ marginRight: 20, fontSize: 44, color: '#6DBB83' }} />
        <GreyLineRight />
      </FullCenter>
      <Title level={5} style={{ marginBottom: 0 }}>
        {t`You’ve seen all the latest posts since your last refresh`}
      </Title>
      <Text>
        <LinkButton
          onClick={() => window.location.reload()}
        >{t`Refresh the page now to see new posts`}</LinkButton>
      </Text>
    </FlexCol>
  );
};

const ErrorPost = () => {
  return (
    <FlexCol>
      <FullCenter>
        <GreyLine />
        <CloseCircleOutlined style={{ marginRight: 20, fontSize: 44, color: '#F5222D' }} />
        <GreyLineRight />
      </FullCenter>
      <Title level={5} style={{ marginBottom: 0 }}>
        {t`Unable to load posts`}
      </Title>
      <Text>
        <Text>{t`Check your internet connection and`}</Text>
        <LinkButton onClick={() => window.location.reload()}>{t`click here to retry`}</LinkButton>
      </Text>
    </FlexCol>
  );
};

const EmptyPosts = () => {
  return (
    <FlexCol>
      <img style={{ width: 400 }} src={EmptyPostImg} alt="Empty posts" />
      <Title level={3}>{t`There are no posts yet`}</Title>
      <Text>{t`There are currently no posts to moderate.`}</Text>
    </FlexCol>
  );
};

// Helper function to update an item in the array
const setItemInFeed = (feed, id, props) => {
  const cloneFeed = cloneDeep(feed).map(post => {
    if (post.id === id) {
      return {
        ...post,
        ...props,
      };
    }
    return post;
  });
  return cloneFeed;
};

const useFeed = () => {
  const { eventId } = useParams();
  const query = useSearchParams();
  const token = query.get('token');
  const [lastSeenPostId, setLastSeenPostId] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [feed, setFeed] = useState(null);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState(false);
  // Hold the last seen postID to show indicator on subsequent page load
  // Hold event ID with post ID so same browser can manage multiple events
  useEffect(() => {
    localStorage.setItem(`${LAST_SEEN_POST_PREFIX}-${eventId}`, lastSeenPostId);
  }, [lastSeenPostId, eventId]);

  useEffect(() => {
    const loadFeed = async () => {
      // Load last seen post from localStorage, make sure its from this event
      const lastSeenPostIdOfEvent = localStorage.getItem(`${LAST_SEEN_POST_PREFIX}-${eventId}`);
      if (lastSeenPostIdOfEvent) {
        setLastSeenPostId(lastSeenPostIdOfEvent);
      }

      try {
        const {
          data: [data],
        } = await getBasicData(eventId);
        setEventData(data);

        // Fetch the first 6 posts.
        const {
          data: { data: feedFromData },
        } = await listFn(eventId, token, PAGE_SIZE, 0);

        setFeed(feedFromData);
        if (feedFromData.length > 0) {
          setLastSeenPostId(feedFromData[feedFromData.length - 1].id);
        }
      } catch (e) {
        setError(true);
      }
    };

    loadFeed();
  }, [token, eventId]);

  const eventName = eventData?.eventName;
  const eventLogo = eventData?.appIconUrl;

  const { observe } = useInView({
    // When the last item comes to the viewport
    onEnter: async ({ unobserve }) => {
      // Pause observe when loading data
      if (!feed?.length) {
        return;
      }
      unobserve();

      try {
        // Load next page of posts, once we reach the end of the feed
        const {
          data: { data: nextFeedFromData },
        } = await listFn(eventId, token, PAGE_SIZE, offset + PAGE_SIZE);

        setOffset(offset + PAGE_SIZE);
        const feedFromData = [...feed, ...nextFeedFromData];

        setFeed(feedFromData);
        if (feedFromData?.length > 0) {
          setLastSeenPostId(feedFromData[feedFromData.length - 1].id);
        }
      } catch (e) {
        setError(true);
      }

      observe();
    },
  });

  const togglePost = async (id, isHidden) => {
    // Show loading indicator on the button we just pressed
    setFeed(updatedFeed => setItemInFeed(updatedFeed, id, { loading: true }));
    try {
      // Toggle loading and visibility of post
      await updateFn(
        eventId,
        id,
        {
          hidden: !isHidden,
        },
        token
      );
      setFeed(updatedFeed => setItemInFeed(updatedFeed, id, { loading: false, hidden: !isHidden }));
    } catch (e) {
      setFeed(updatedFeed =>
        setItemInFeed(updatedFeed, id, {
          loading: false,
          hidden: isHidden,
          error: t`We were unable to complete your request. Please reload the page to try again.`,
        })
      );
    }
  };

  return { togglePost, observe, error, eventLogo, eventName, feed, lastSeenPostId };
};

const Moderation = () => {
  const { togglePost, observe, error, eventLogo, eventName, feed, lastSeenPostId } = useFeed();
  const onHideShow = post => {
    if (post.hidden) {
      message.success(t`Successfully unhid the post. Refresh the Event Space to see the change.`);
    } else {
      message.success(t`Successfully hid the post. This will be applied immediately.`);
    }
    return togglePost(post.id, post.hidden);
  };

  return (
    <Layout>
      <Header>
        {eventLogo && <Logo alt={eventName} src={eventLogo} />}
        <SubTitle level={5}>{eventName}</SubTitle>
        <Text>{t`Moderating`}</Text>
      </Header>
      <Content>
        <>
          <PageTitle>{t`Activity Feed`}</PageTitle>
          <PageSubHeading>{t`Most recent posts are shown first.`}</PageSubHeading>
          {feed && (
            <Space size="large" direction="vertical" style={{ display: 'flex' }}>
              {feed.map(post => (
                <React.Fragment key={post.id}>
                  <Post
                    onHideShow={() => onHideShow(post)}
                    isHidden={post.hidden}
                    authorImageUrl={post.person.profileImage?.thumbnailUrl}
                    date={parseJSON(post.createdAt)}
                    authorId={post.person.id}
                    authorName={`${post.person.firstName} ${post.person.lastName}`}
                    contentItems={post.contentItems}
                    comment={post.content}
                    eventId={post.eventId}
                    hideShowInProgress={post.loading}
                    error={post.error}
                  />
                  {post.id === lastSeenPostId && !error ? <LastSeen /> : null}
                </React.Fragment>
              ))}
              <div ref={observe} />
            </Space>
          )}
          {feed?.length === 0 && !error && <EmptyPosts />}
          {error && <ErrorPost />}
        </>
      </Content>
    </Layout>
  );
};

export default Moderation;
