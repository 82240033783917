import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

import { formatBytes } from '@services/file-service';

const { Dragger } = Upload;

const MAXIMUM_ALLOWED_FILE_SIZE = 1024 * 1024 * 24;

const FileString = ({ supportedTypes, onChange = () => {} }) => {
  const supportedTypesForTranslation = supportedTypes.map(ext => `*${ext}`).join(', ');
  const maxFileSize = formatBytes(MAXIMUM_ALLOWED_FILE_SIZE);

  const handleChange = file => {
    if (file.size > MAXIMUM_ALLOWED_FILE_SIZE) {
      message.error(t`${file.name} exceeds maximum size limit (${maxFileSize})`);
      // eslint-disable-next-line no-param-reassign
      file.status = 'error';
      return false;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      onChange(reader.result);
    };

    return false; // do not upload
  };

  return (
    <Dragger maxCount={1} accept={supportedTypes.join(',')} beforeUpload={handleChange}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t`Click or drag file to this area to upload`}</p>
      <p className="ant-upload-hint">{t`Supported file types: ${supportedTypesForTranslation}`}</p>
      <p className="ant-upload-hint">{t`The maximum file size per file is ${maxFileSize}.`}</p>
    </Dragger>
  );
};

FileString.propTypes = {
  supportedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
};

export default FileString;
