import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import AppDetails from './App/Details';
import Details, { TAB_EVENTS } from './Details';
import List from './List';

const Routes = () => {
  const {
    path,
    url,
    params: { organizationId },
  } = useRouteMatch();

  if (!organizationId) {
    return <List />;
  }

  return (
    <Switch>
      <Route path={`${path}/apps/:appId`} exact>
        <AppDetails />
      </Route>
      <Route path={`${path}/:tab`} exact>
        <Details />
      </Route>
      <Route>
        <Redirect to={`${url}/${TAB_EVENTS}`} />
      </Route>
    </Switch>
  );
};

export default Routes;
