const CONF_AUTHENTICATION = ['is_sso_enabled', 'ea_max_concurrent_logins'];
const CONF_PRODUCTS = [
  'badge_designer_enabled',
  'is_livedisplay_enabled',
  'is_checkin_enabled',
  'is_lead_capture_enabled',
  'is_registration_v2_enabled',
  'is_video_library_enabled',
  'is_communications_enabled',
  'is_website_builder_enabled',
];
const CONF_EVENT_APP_FEATURES = [
  'attendee_analytics_enabled',
  'attendee_chat_support',
  'events_community',
  'is_a2a_messaging_enabled',
  'is_aaq_enabled',
  'is_activity_feed_enabled',
  'is_checkin_code_enabled',
  'is_gamify_app_enabled',
  'is_group_discussions_enabled',
  'is_live_poll_enabled',
  'is_sponsorship_enabled',
  'is_survey_enabled',
  'per_person_licensing',
  'eventapp_precached_content_enabled',
  'session_chat',
];
const CONF_EVENT_DATA = [
  'is_companies_enabled',
  'is_document_upload_enabled',
  'is_interactive_maps_enabled',
];
const CONF_GENERAL = [
  'is_for_internal_use',
  'is_custom_domain_enabled',
  'is_whitelabel_sender_email_domain_enabled',
  'event_max_duration_days',
  'eventapp_post_event_access_days',
  'enable_white_label',
  'es_session_cache_stale_timer_seconds',
];
const CONF_PROFESSIONAL_SERVICES = ['is_social_monitoring_enabled'];
const CONF_VIDEO_EXPERIENCE = [
  'native_livestream',
  'interactive_sessions',
  'session_experience_enable_pre_recorded_video_url',
  'rtmp_streaming',
  'session_experience_enable_video_conference_url',
];

export const CONF_SECTION_ORDER = {
  left: [
    { name: 'Authentication', data: CONF_AUTHENTICATION },
    { name: 'Event Data', data: CONF_EVENT_DATA },
    { name: 'General', data: CONF_GENERAL },
    { name: 'Products', data: CONF_PRODUCTS },
    { name: 'Professional Services', data: CONF_PROFESSIONAL_SERVICES },
  ],
  right: [
    { name: 'Event Space Features', data: CONF_EVENT_APP_FEATURES },
    { name: 'Video Experience', data: CONF_VIDEO_EXPERIENCE },
  ],
};

// CONF_OPTIONS are configs with type='radio'
export const CONF_OPTIONS = {
  // 'config_key': [
  //  { label: 'label', value: 'value', subtext: 'subtext' },
  // ],
};

// The keys for CONF_FIELDS has to be boolean type
export const CONF_FIELDS = {
  is_lead_capture_enabled: ['lead_capture_license_limit'],
  per_person_licensing: ['per_person_limit'],
  is_a2a_messaging_enabled: ['is_appointments_enabled', 'appointment_booking_video'],
  badge_designer_enabled: ['badge_ordering'],
  is_registration_v2_enabled: ['reg_payments'],
  is_checkin_enabled: ['badge_printing_onsite_labels_enabled'],
};

// Config 'key' is disabled and can't be toggled unless 'value' is enabled
export const CONF_DEPENDENTS = {
  is_appointments_enabled: 'is_a2a_messaging_enabled',
  appointment_booking_video: 'is_appointments_enabled',
  badge_ordering: 'badge_designer_enabled',
  badge_printing_onsite_labels_enabled: ['is_checkin_enabled', 'badge_designer_enabled'],
};

export const CONF_DEFAULT_VALUES = {
  event_max_duration_days: 14,
  eventapp_post_event_access_days: 21,
};

export const CONF_CAN_EXPIRE = ['is_custom_domain_enabled'];

export const CONF_VIRTUAL_EVENT = [
  'is_video_library_enabled',
  'is_a2a_messaging_enabled',
  'is_appointments_enabled',
  'appointment_booking_video',
  'rtmp_streaming',
  'native_livestream',
  'interactive_sessions',
  'session_experience_enable_pre_recorded_video_url',
  'session_experience_enable_video_conference_url',
];

export const CONF = [
  ...CONF_AUTHENTICATION,
  ...CONF_EVENT_APP_FEATURES,
  ...CONF_EVENT_DATA,
  ...CONF_GENERAL,
  ...CONF_PRODUCTS,
  ...CONF_PROFESSIONAL_SERVICES,
  ...CONF_VIDEO_EXPERIENCE,
];
