import apiFn from 'common/services/api-service';
import { store, retrieve, clean } from 'common/services/session-service';

const authApi = endpoint => apiFn(`/api/cms/v1/session/action/${endpoint}`);

// TODO: need this one to get language settings, remove this one after https://eventmobi.atlassian.net/browse/EXP-9313
const sessionApi = () =>
  apiFn(`/api/uapi/organizers/accounts/me`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

export const AUTH_APP = 'experience';
export const AUTH_APP_ONSITE = 'onsite_app';

export const isAuthenticated = async () => {
  const session = await retrieve();

  return session !== null && typeof session.userId !== 'undefined' && session.userId !== null;
};

export const login = async (payload = {}) => {
  const user = await authApi('login')
    .post('', payload)
    .then(response => response.data.data);

  const session = await sessionApi()
    .get()
    .then(response => response.data.data);

  const data = { ...session, ...user };
  await store(data);
  return data;
};

export const logout = async () => {
  await authApi('logout').post();
  await clean();
};
