import { useQuery } from '@tanstack/react-query';

import { list as listFn } from '../../contracts-service';

export const contractOptions = (organizationId, isEnabled = false, queryParams = {}) => ({
  queryKey: ['people-credits', organizationId],
  queryFn: () => listFn(organizationId, queryParams),
  select: contracts =>
    contracts.map(({ creditsUsageByOrganization, userCredits, ...contract }) => ({
      used: creditsUsageByOrganization,
      limit: userCredits,
      ...contract,
    })),
  isEnabled,
});

export const useContracts = (organizationId, isEnabled, queryParams) =>
  useQuery(contractOptions(organizationId, isEnabled, queryParams));
