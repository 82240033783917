import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { t } from 'ttag';

import AcceptInvite from 'common/components/Auth/AcceptInvite';
import useSearchParams from 'common/hooks/useSearchParams';

import {
  checkInviteStatus as checkInviteStatusFn,
  acceptInvite as acceptInviteFn,
} from '@domains/Organization/Organizer/service';

export default () => {
  const history = useHistory();
  const query = useSearchParams();
  const token = query?.get('token');
  const email = query?.get('email');
  const organizationId = query?.get('organization_id');
  const [organizationName, setOrganizationName] = useState('');
  const location = useLocation();

  return (
    <AcceptInvite
      token={token}
      id={organizationId}
      description={t`Accept the invitation to gain access to ${organizationName}`}
      checkInviteStatusFn={async () => {
        const status = await checkInviteStatusFn(organizationId, { token });
        setOrganizationName(status.organizationName);
        return status;
      }}
      acceptInviteFn={payload => acceptInviteFn(organizationId, { ...payload, token, email })}
      onAcceptInvite={() => history.replace('/organization')}
      onAccept={() => {
        const queryParams = new URLSearchParams(location.search).toString();
        history.push(`/activate?${queryParams}`);
      }}
      onLogin={() => history.replace('/login')}
    />
  );
};
