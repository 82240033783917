import apiFn from 'common/services/api-service';

const api = eventId => apiFn(`/api/uapi/events/${eventId}/people/groups`);

export function get(eventId, id) {
  return api(eventId)
    .get(id)
    .then(response => response?.data?.data);
}

export function list(eventId, payload = {}, query = null) {
  const params = [];

  if (query !== null && query.length > 1) {
    params.push(query.substr(1, query.length));
  }

  if (typeof payload.offset !== 'undefined' && payload.offset > 0) {
    params.push(`page=${payload.offset / payload.limit}`);
  }

  return api(eventId)
    .get(`?${params.join('&')}`)
    .then(response => response?.data);
}

export function create(eventId, params) {
  return api(eventId)
    .post('', params)
    .then(response => response?.data?.data);
}

export function update(eventId, payload) {
  const { id } = payload;

  if (typeof id === 'undefined') {
    throw new Error('group id is not provided');
  }

  return api(eventId)
    .patch(id, payload)
    .then(response => response?.data?.data);
}

export function remove(eventId, id) {
  return api(eventId)
    .delete(id)
    .then(response => response?.data?.data);
}
