import { useEffect, useState } from 'react';

// Some places have the same address, so we should save the `name`
// along with the `address` to better ensure the correct place is saved.
// note: `name` may be in the `address` already, so we skip combining in those cases.
export const getFormattedAddress = (name, address) => {
  if (name && address && !address.includes(name)) {
    return `${name}, ${address}`;
  }
  return address ?? name;
};

// It's pseudo-safe way to check if API was loaded, since some features can be in loading process (still).
// Ideally we, should use app-wide solution around callback or `@googlemaps/js-api-loader`
/* eslint-disable import/prefer-default-export */
const useGoogleMaps = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded || window.google) return;

    const handler = setInterval(() => {
      if (window.google) {
        clearInterval(handler);
        setLoaded(true);
      }
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(handler);
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoaded]);

  return window.google;
};

export const useAutocomplete = (onChange, options) => {
  const googleApi = useGoogleMaps();
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!googleApi || !ref) {
      return;
    }

    const autocomplete = new googleApi.maps.places.Autocomplete(ref, options);
    const listener = googleApi.maps.event.addListener(autocomplete, 'place_changed', () => {
      const name = getFormattedAddress(
        autocomplete.getPlace()?.name,
        autocomplete.getPlace()?.formatted_address
      );
      ref.value = name;
      onChange(name);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (googleApi && ref) {
        googleApi.maps.event.removeListener(listener);
        googleApi.maps.event.clearInstanceListeners(autocomplete);
      }
    };
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleApi, ref]);

  return setRef;
};
