import * as UpChunk from '@mux/upchunk';
import { message } from 'antd';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useRef,
  useState,
  useEffect,
} from 'react';
import { t } from 'ttag';

const UPLOAD_CHUNK_SIZE = 5 * 1024; // Uploads the file in ~5mb chunks
const STATUS_POLLING_TIMEOUT = 30 * 1000; // How often to check status of the file (is it ready or not)

export const STATUS_WAITING = 'waiting';
export const STATUS_FAILED = 'failed';
export const STATUS_UPLOADING = 'uploading';
export const STATUS_COMPLETED = 'completed';
export const STATUS_PROCESSING = 'processing';

/**
 * Callback to get meta info for File
 *
 * @callback getFileInfo
 * @param {File} file
 * @return {Promise<{url: string, id?:any, group?:string, name?: string}>}
 */

/**
 * Callback to get status of file (ready or not)
 *
 * @callback getFileStatus
 * @param {id:*} ID of file (part of getFileInfo payload)
 * @return {Promise<{ready: boolean, thumbnail:*}>}
 */

/**
 * @param {File} file
 * @param {getFileInfo} getFileInfo
 * @param {getFileStatus} getFileStatus
 * @param {Number} index
 * @return {Promise<{id:*, group:string, name: string, uploader:*, thumbnail:*, size:number, progress: number, error:*, status: string}>}
 */
export async function uploadFile(file, getFileInfo, getFileStatus, index) {
  const info = {
    id: index,
    status: STATUS_WAITING,
    name: file.name,
    sizeTotal: file.size,
    sizeUploaded: 0,
    error: null,
    thumbnail: null,
    group: '',
  };

  try {
    const { name = file.name, id = index, group = '', url } = await getFileInfo(file);
    info.name = name;
    info.id = id;
    info.group = group;
    info.uploader = UpChunk.createUpload({
      file,
      endpoint: url,
      chunkSize: UPLOAD_CHUNK_SIZE,
    });

    // check for file's ready status to wait for the end of processing
    const onCheckReady = async forceReload => {
      const prevStatus = info.status;
      try {
        const { ready, thumbnail } = await getFileStatus(info.id);
        info.status = ready ? STATUS_COMPLETED : STATUS_PROCESSING;
        info.thumbnail = thumbnail;
      } catch (e) {
        info.status = STATUS_FAILED;
        info.error = e.toString(); // TODO: should we distinct mux's errors?
      }

      // no need to watch anymore, status is final
      if ([STATUS_COMPLETED, STATUS_FAILED].includes(info.status)) {
        clearInterval(info.watcher);
        info.watcher = null;
      }

      // refresh only when something changed, otherwise we can get performance with a bunch of concurrent watchers
      if (prevStatus !== info.status) {
        forceReload();
      }
    };

    info.watcher = forceReload =>
      setInterval(() => onCheckReady(forceReload), STATUS_POLLING_TIMEOUT);
  } catch (e) {
    info.status = STATUS_FAILED;
    info.error = e.toString();
  }

  return info;
}

const Context = createContext();
export const useUploadingDrawer = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useUploadingDrawer must be used within a UploadingDrawer');
  }

  return context;
};

function Provider({ children }) {
  const [, forceReload] = useReducer(n => n + 1, 0);
  const items = useRef([]);
  const [error, setError] = useState(null); // global error, e.g. `connection error`

  const onAbort = useCallback(id => {
    const itemIndex = items.current.findIndex(next => next.id === id);
    if (itemIndex === -1) {
      return;
    }
    const item = items.current[itemIndex];
    if (item.uploader && item.status === STATUS_UPLOADING) {
      item.uploader.abort();
      delete item.uploader;

      items.current.splice(itemIndex, 1);

      // in case if user manually canceled all files
      if (items.current.length === 0) {
        message.success(t`The uploading process has been cancelled.`);
      }

      forceReload();
    }
  }, []);

  const onComplete = useCallback(() => {
    items.current = [];
    forceReload();
  }, []);

  const onUpload = useCallback((files, getFileInfo, getFileStatus) => {
    files.forEach(async file => {
      const info = await uploadFile(file, getFileInfo, getFileStatus, items.current.length);

      if (info.uploader) {
        info.uploader.on('error', err => {
          info.status = STATUS_FAILED;
          info.error = err.detail;
          forceReload();
        });

        info.uploader.on('progress', progress => {
          info.status = STATUS_UPLOADING;
          info.sizeUploaded = Math.min(progress.detail * 0.01 * info.sizeTotal, info.sizeTotal);
          forceReload();
        });

        // file was uploaded, but still should be processed
        info.uploader.on('success', () => {
          info.status = STATUS_PROCESSING;
          // uploading was done, so from now we should check the status of file - if it's ready or not.
          info.watcher = info.watcher(forceReload);
          forceReload();
        });
      }

      const groupIndex = items.current.findIndex(item => item.group === info.group);
      if (groupIndex !== -1) {
        items.current.splice(groupIndex, 0, info);
      } else {
        items.current.push(info);
      }

      forceReload();
    });
  }, []);

  // listen for connection errors
  useEffect(() => {
    const online = () => setError(null);
    const offline = () => setError(t`Connection Error`);

    window.addEventListener('online', online);
    window.addEventListener('offline', offline);

    return () => {
      window.removeEventListener('online', online);
      window.removeEventListener('offline', offline);
    };
  }, []);
  return (
    <Context.Provider value={{ items: items.current, error, onUpload, onAbort, onComplete }}>
      {children}
    </Context.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
