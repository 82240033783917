import { useMemo } from "react";
import { useLocation } from "react-router-dom";
var useSearchParams = function() {
    var location = useLocation();
    return useMemo(function() {
        return new URLSearchParams(location.search);
    }, [
        location
    ]);
};
export default useSearchParams;
