export const STATUS_SUCCESS = 'success';
export const STATUS_WARNING = 'warning';
export const STATUS_ERROR = 'error';

export const EXPORT_PENDING = 'pending';
export const EXPORT_COMPLETED = 'completed';
export const EXPORT_FAILED = 'failed';

export const URL_PROTOCOL_REGEXP = /^https?:\/\//;
export const HOLD_POLLING = 'holdPolling';
export const START_POLLING = 'startPolling';

export const EXPORT_PROFILE_IMAGES = 'profile_images';
export const EXPORT_ACTIVITY_FEED_PICTURES = 'activity_feed_pictures';
export const EXPORT_ANALYTICS = 'analytics';
export const EXPORT_ANALYTICS_OPERATION = 'analytics_operation';
export const EXPORT_ANNOUNCEMENTS = 'announcements';
export const EXPORT_CHECK_INS = 'checkins';
export const EXPORT_PEOPLE = 'people';
export const EXPORT_COMPANIES = 'companies';
export const EXPORT_SESSIONS = 'sessions';
export const EXPORT_CHALLENGES = 'challenges';
export const EXPORT_SESSION_CHAT = 'session_chat';
export const EXPORT_GROUP_DISCUSSION = 'group_discussion';
export const EXPORT_GAMIFICATION = 'gamification';
export const EXPORT_BADGE_DESIGNER_PDF = 'badge_designer_pdf';
export const EXPORT_USER_CREDITS_USAGE = 'user_credits_usage';
