import { Input } from 'antd';
import { rgbToColorString } from 'polished';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';

const RETURN_KEY_CODE = 13;
export const VALID_HEX_COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

// Generates an integer from 0 to provided number
// eslint-disable-next-line no-bitwise
const randomInteger = n => (Math.random() * n) << 0;

// Generates a random hexcode color
export const randomColor = () =>
  rgbToColorString({
    red: randomInteger(256),
    green: randomInteger(256),
    blue: randomInteger(256),
  });

const Wrapper = styled.div`
  display: inline-flex;
  width: 100%;
  height: 32px;
  align-items: center;
  text-transform: uppercase;
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
  vertical-align: middle;
`;

const ColorSquare = styled.div`
  width: 34px;
  height: 32px;
  background: ${props => props.color};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: ${props => (props.readOnly ? '4px' : '0px')};
  border-bottom-right-radius: ${props => (props.readOnly ? '4px' : '0px')};
  margin-right: ${props => (props.readOnly ? '10px' : '0px')};
  border: ${props => (props.readOnly ? '' : '1px solid #d9d9d9')};
  border-right: none;

  ${props =>
    props.empty &&
    `
      background: rgb(248, 248, 248);
      box-shadow: inset rgba(0, 0, 0, 0.37) 0px 1px 4px 0px;
    `}
`;

const ColorHex = styled.span`
  width: 70%;
  height: 22px;
`;

const validColor = color => color !== '' && VALID_HEX_COLOR_REGEX.test(color);

const Box = ({ color }) => (
  <>
    <ColorSquare color={color} empty={!validColor(color)} readOnly />
    <ColorHex>{color}</ColorHex>
  </>
);

Box.propTypes = {
  color: PropTypes.string.isRequired,
};

const EditBox = ({ name, color, onChange, disabled, width = '70%' }) => (
  <>
    <ColorSquare color={color} empty={!validColor(color)} />
    <Input
      name={name}
      value={color}
      onChange={onChange}
      maxLength={7}
      disabled={disabled}
      css={`
        && {
          width: ${width};
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          text-transform: uppercase;
        }
      `}
    />
  </>
);

EditBox.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  width: PropTypes.string,
};

const normalizeColor = color => {
  if (color.length === 4) {
    return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
  }

  return color;
};

const ColorPicker = ({ name, value: initialColor, readOnly, onChange, disabled, width }) => {
  const [color, setColor] = useState(initialColor ?? undefined); // Throws error if initalColor is null
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    if (!readOnly && !disabled) {
      setDisplayColorPicker(!displayColorPicker);
    }
  };

  const handleChange = arg => {
    const newColor = arg.hex ? arg.hex : arg.target.value;

    setColor(newColor);
    // update the new color value for the parent component through callback
    if (onChange) {
      onChange(normalizeColor(newColor));
    }
  };

  const handleKeyDown = event => {
    if (event.keyCode !== RETURN_KEY_CODE) {
      return;
    }

    handleClick();
  };

  return (
    <>
      <Wrapper onClick={handleClick} readOnly={readOnly}>
        {readOnly ? (
          <Box color={initialColor} />
        ) : (
          <EditBox
            name={name}
            color={color}
            onChange={handleChange}
            disabled={disabled}
            width={width}
          />
        )}
      </Wrapper>

      {displayColorPicker && (
        <div css="position: absolute; z-index: 2">
          <div
            id="color-picker-div"
            css="position: fixed; top: 0px; right: 0px; bottom: 0px; left: 0px;"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex="0"
            role="button"
          />
          <ChromePicker disableAlpha color={color} onChange={handleChange} />
        </div>
      )}
    </>
  );
};

ColorPicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
};

export default ColorPicker;
