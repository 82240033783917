import { Col, Progress, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const { Text: AntText } = Typography;

const ProgressContainer = styled.div`
  margin-bottom: 20px;
`;

const Text = styled(AntText)`
  margin-bottom: 5px;
  font-weight: normal;
  font-size: ${({ fontSize }) => fontSize}px;
  color: ${({ color }) => color};
  font-weight: ${({ $isHighlight }) => ($isHighlight ? 800 : 'initial')};
`;

const BarChart = ({ dataSource, textColor, fontSize = 18 }) => {
  const maxValue = useMemo(() => {
    return Math.max(...dataSource.map(({ value }) => value));
  }, [dataSource]);
  return (
    <>
      {dataSource.map(({ id, title, value, color }) => {
        const rounded = Math.round(value * 100) / 100;

        return (
          <ProgressContainer key={id}>
            <Row justify="space-between">
              <Col>
                <Text fontSize={fontSize} color={textColor} $isHighlight={value === maxValue}>
                  {title}
                </Text>
              </Col>
              <Col>
                <Text fontSize={fontSize} color={textColor} $isHighlight={value === maxValue}>
                  {rounded}%
                </Text>
              </Col>
            </Row>
            <Progress
              key={id}
              percent={value}
              strokeColor={color}
              strokeWidth={20}
              showInfo={false}
            />
          </ProgressContainer>
        );
      })}
    </>
  );
};

BarChart.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string,
    }).isRequired
  ),
  fontSize: PropTypes.number,
  textColor: PropTypes.string,
};

export default BarChart;
