import {
  CloseCircleOutlined,
  EllipsisOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Image, Popconfirm, Progress as AntProgress } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import { formatBytes } from '@services/file-service';

import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PROCESSING,
  STATUS_UPLOADING,
  STATUS_WAITING,
} from './Provider';

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Meta = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
`;

const Progress = styled(AntProgress)`
  margin-left: 15px;
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: ${props => (props.actions === 'visible' ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

const Wrap = styled.div`
  padding: 12px;
  position: relative;
  box-sizing: border-box;
  &&:hover ${Actions} {
    display: ${props => (['hover', 'visible'].includes(props.actions) ? 'flex' : 'none')};
  }
  border-bottom: 1px solid #d9d9d9;
`;

const Thumbnail = styled.div`
  min-width: 82px;
  width: 82px;
  height: 45px;
  margin-right: 12px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  && .ant-image-img {
    height: 45px;
  }
`;

const Info = styled.div`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
`;

const Name = styled.div`
  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #595959;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Group = styled.div`
  color: #8c8c8c;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const State = styled.div`
  color: ${props =>
    [STATUS_COMPLETED, STATUS_PROCESSING].includes(props.status) ? '#4caf50' : '#595959'};
`;

const Error = styled.span`
  font-family: 'Open Sans', sans-serif;
  color: #f44336;
  padding-left: 13px;
`;

function getError() {
  // TODO: We have a mix of errors - mux (something happened, e.g. could not convert) and browser (e.g. file was deleted). Need to list all possible errors. For right now, we just render general text.
  return t`Error`;
}

function getStatusLabel(status, sizeUploaded, sizeTotal) {
  const uploadedLabel = formatBytes(sizeUploaded || 0);
  const totalLabel = formatBytes(sizeTotal);
  switch (status) {
    case STATUS_WAITING:
      return t`Waiting...`;
    case STATUS_UPLOADING:
    case STATUS_FAILED:
      return t`${uploadedLabel} of ${totalLabel}`;
    case STATUS_PROCESSING:
      return t`Processing...`;
    case STATUS_COMPLETED:
      return t`Completed`;
    default:
      return null;
  }
}

function getStatusColor(status) {
  switch (status) {
    case STATUS_FAILED:
      return '#FF4D4F';
    case STATUS_UPLOADING:
      return '#1890FF';
    case STATUS_WAITING:
      return '#D9D9D9';
    default:
      return '#4CAF50';
  }
}

function getStatusIcon(status, color) {
  switch (status) {
    case STATUS_FAILED:
      return <CloseOutlined style={{ color }} />;
    case STATUS_COMPLETED:
      return <CheckOutlined style={{ color }} />;
    case STATUS_PROCESSING:
      return <EllipsisOutlined style={{ color }} />;
    default:
      return null;
  }
}

function Item({
  name,
  group,
  status,
  error,
  sizeTotal,
  sizeUploaded,
  thumbnail,
  onAbort,
  onSelect,
  actions = 'hover',
}) {
  const ref = useRef();
  const progress = [STATUS_WAITING, STATUS_UPLOADING, STATUS_FAILED].includes(status)
    ? Math.floor(((sizeUploaded || 0) * 100) / sizeTotal)
    : 100;
  const statusColor = getStatusColor(status);
  const statusIcon = getStatusIcon(status, statusColor);

  return (
    <Wrap actions={actions} ref={ref}>
      <Card>
        <Meta>
          <Thumbnail>
            {thumbnail ? (
              <Image src={thumbnail} preview={false} />
            ) : (
              <FontAwesomeIcon icon={faVideo} size="lg" style={{ color: '#fff' }} />
            )}
          </Thumbnail>
          <Info>
            <Name>{name}</Name>
            <Group>{group}</Group>
            <State status={status}>
              {getStatusLabel(status, sizeUploaded, sizeTotal)}
              {status === STATUS_FAILED && <Error>{getError(error)}</Error>}
            </State>
          </Info>
        </Meta>
        <Progress
          type="circle"
          percent={progress}
          width={32}
          strokeWidth={18}
          strokeColor={statusColor}
          format={() => statusIcon}
        />
      </Card>
      {status === STATUS_UPLOADING && (
        <Actions actions={actions}>
          <Popconfirm
            onConfirm={onAbort}
            okText={t`Yes`}
            cancelText={t`No`}
            onOpenChange={onSelect}
            title={t`Are you sure you want to cancel the upload? This cannot be undone.`}
            getPopupContainer={() => ref.current}
          >
            <Button type="primary" danger icon={<CloseCircleOutlined />}>{t`Cancel Upload`}</Button>
          </Popconfirm>
        </Actions>
      )}
    </Wrap>
  );
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onAbort: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  group: PropTypes.string,
  thumbnail: PropTypes.string,
  error: PropTypes.string,
  sizeTotal: PropTypes.number,
  sizeUploaded: PropTypes.number,
  actions: PropTypes.oneOf(['none', 'hover', 'visible']),
};

export default Item;
