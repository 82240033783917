import { Alert, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';

import {
  EXPORT_ACTIVITY_FEED_PICTURES,
  EXPORT_ANALYTICS,
  EXPORT_ANALYTICS_OPERATION,
  EXPORT_ANNOUNCEMENTS,
  EXPORT_BADGE_DESIGNER_PDF,
  EXPORT_CHALLENGES,
  EXPORT_CHECK_INS,
  EXPORT_COMPANIES,
  EXPORT_GAMIFICATION,
  EXPORT_GROUP_DISCUSSION,
  EXPORT_USER_CREDITS_USAGE,
  EXPORT_PEOPLE,
  EXPORT_PROFILE_IMAGES,
  EXPORT_SESSIONS,
  EXPORT_SESSION_CHAT,
  STATUS_ERROR,
  STATUS_SUCCESS,
  STATUS_WARNING,
} from './constants';

const Title = ({ status }) => {
  let text;
  if (status === STATUS_WARNING) {
    text = t`Exporting...`;
  } else {
    text = t`Export`;
  }

  return <Typography.Text>{text}</Typography.Text>;
};

Title.propTypes = {
  status: PropTypes.string,
};

const buildState = (type, status) => {
  switch (status) {
    case STATUS_WARNING: {
      return {
        alertText: t`Please do not close this window while the export is in progress.`,
        description: t`This process may take several minutes depending on the number and size of your files.`,
        downloadBtn: {
          disabled: true,
          loading: true,
          text: t`Download`,
        },
        cancelBtn: {
          text: t`Cancel`,
        },
      };
    }

    case STATUS_SUCCESS: {
      return {
        alertText: t`Your files are ready to download.`,
        downloadBtn: {
          disabled: false,
          loading: false,
          text: t`Download`,
        },
        cancelBtn: {
          text: t`Close`,
        },
      };
    }

    case STATUS_ERROR: {
      return {
        alertText: t`There was an error exporting your file. Please try again.`,
        downloadBtn: {
          disabled: false,
          loading: false,
          text: t`Try Again`,
        },
        cancelBtn: {
          text: t`Cancel`,
        },
      };
    }

    default: {
      throw new Error('Export Status not recognized, please check [status] property being passed.');
    }
  }
};

const useExportModalData = (type, status) => {
  const [modalState, setModalState] = useState(buildState(type, status));

  useEffect(() => {
    setModalState(buildState(type, status));
  }, [type, status]);

  return modalState;
};

const ExportModal = ({ type, status, open, href, tryAgainFn, onClose }) => {
  const getHref = () => (status === STATUS_SUCCESS ? href : null);

  const { downloadBtn, cancelBtn, alertText, description } = useExportModalData(type, status);

  return (
    <Modal
      title={<Title status={status} />}
      open={open}
      maskClosable={false}
      onCancel={() => onClose(false)}
      okButtonProps={{
        href: getHref(),
        style: { marginLeft: '8px' },
        disabled: downloadBtn.disabled,
        loading: downloadBtn.loading,
      }}
      cancelText={cancelBtn.text}
      okText={downloadBtn.text}
      onOk={status === STATUS_ERROR ? tryAgainFn : () => onClose(true)}
      destroyOnClose
    >
      <Typography.Paragraph>
        <Alert type={status} message={alertText} showIcon />
      </Typography.Paragraph>

      {status === STATUS_WARNING && description && (
        <Typography.Paragraph>{description}</Typography.Paragraph>
      )}
      {type === EXPORT_ANNOUNCEMENTS && (
        <>
          <Typography.Paragraph>
            {t`Please be aware of the following:`}
            <ul>
              <li>{t`Only Scheduled Announcements are exported.`}</li>
              <li>{t`There are multiple sheets in the excel file. Each sheet corresponds to a different audience.`}</li>
              <li>{t`If the Announcement ID does not match an existing Announcement, a new Announcement will be created.`}</li>
            </ul>
          </Typography.Paragraph>
        </>
      )}
    </Modal>
  );
};

ExportModal.propTypes = {
  type: PropTypes.oneOf([
    EXPORT_PROFILE_IMAGES,
    EXPORT_ACTIVITY_FEED_PICTURES,
    EXPORT_ANALYTICS,
    EXPORT_ANALYTICS_OPERATION,
    EXPORT_ANNOUNCEMENTS,
    EXPORT_CHECK_INS,
    EXPORT_PEOPLE,
    EXPORT_COMPANIES,
    EXPORT_SESSIONS,
    EXPORT_CHALLENGES,
    EXPORT_SESSION_CHAT,
    EXPORT_GROUP_DISCUSSION,
    EXPORT_GAMIFICATION,
    EXPORT_BADGE_DESIGNER_PDF,
    EXPORT_USER_CREDITS_USAGE,
  ]).isRequired,
  status: PropTypes.oneOf([STATUS_WARNING, STATUS_SUCCESS, STATUS_ERROR]).isRequired,
  open: PropTypes.bool.isRequired,
  href: PropTypes.string,
  tryAgainFn: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default ExportModal;
