import { Modal, Steps, Alert, message, Button, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { jt, t } from 'ttag';

import Loading from 'common/components/Loading';
import { getContract, getLicenseType } from 'common/state/organization';
import { getIsStaff } from 'common/state/user';

import { LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT } from '@domains/Organization/service';

import Content, { SOURCE_DUPLICATE } from './Content';
import {
  redeemCredit as redeemCreditFn,
  getCredits as getCreditsFn,
  getBlueprints as getBlueprintsFn,
  CREDITS_STATUS_EXPIRED,
  CREDITS_STATUS_AVAILABLE,
} from './credit-service';
import Credits from './Credits';
import Details from './Details';

const FORMAT_DATE = 'YYYY-MM-DD';

const STEP_CREDIT = 0;
const STEP_CONTENT = 1;
const STEP_DETAILS = 2;

const RESERVED_WORDS = [
  'reg',
  'registration',
  'register',
  'page',
  'page-builder',
  'pagebuilder',
  'website',
  'web',
  'websitebuilder',
  'website-builder',
];

const ERROR_MESSAGES_BY_CODE = () => ({
  '292a9a29-c548-4ea2-bcfe-3b0c130c5b52': t`The Event Code is already in use. Please enter a different code.`,
  '64ac0696-430f-463d-ae4a-583a37c6832e': t`Event Duration cannot exceed more than 7 days`,
  'db2f571d-bf28-4e1e-a006-d7f403290159': t`The event to be duplicated of not found.`,
  '99ca0907-59cd-462e-89b5-031642339521': t`Please enter valid information for Event.`,
});

const initialState = {
  createMode: null,
  currentStep: STEP_CREDIT,
  credit: null,
  source: null,
  name: '',
  dates: [],
  code: '',
  sourceEvent: null,
  loading: false,
  error: null,
  isReserved: false,
};

const ACTION_SET_CREATE_MODE = 'SET_CREATE_MODE';
const ACTION_SET_STEP = 'SET_STEP';
const ACTION_SET_CREDIT = 'SET_CREDIT';
const ACTION_SET_LOADING = 'SET_LOADING';
const ACTION_SET_SOURCE = 'SET_SOURCE';
const ACTION_SET_NAME = 'SET_NAME';
const ACTION_SET_DATES = 'SET_DATES';
const ACTION_SET_CODE = 'SET_CODE';
const ACTION_SET_SOURCE_EVENT = 'SET_EVENT_ID';
const ACTION_SET_ERROR = 'SET_ERROR';
const ACTION_RESET = 'RESET';

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_SET_CREATE_MODE: {
      const newStep = action.payload === LICENSE_TYPE_EVENT_CREDIT ? STEP_CREDIT : STEP_CONTENT;
      return {
        ...state,
        createMode: action.payload,
        currentStep: newStep,
        error: null,
      };
    }
    case ACTION_SET_STEP: {
      return { ...state, currentStep: action.payload };
    }
    case ACTION_SET_CREDIT: {
      return {
        ...state,
        credit: action.payload,
      };
    }
    case ACTION_SET_SOURCE: {
      return { ...state, source: action.payload };
    }
    case ACTION_SET_SOURCE_EVENT: {
      return {
        ...state,
        sourceEvent: action.payload,
      };
    }
    case ACTION_SET_NAME: {
      return { ...state, name: action.payload };
    }
    case ACTION_SET_CODE: {
      return {
        ...state,
        code: action.payload,
        isReserved: RESERVED_WORDS.includes(action.payload?.toLowerCase()),
      };
    }
    case ACTION_SET_DATES: {
      return { ...state, dates: action.payload };
    }
    case ACTION_SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case ACTION_SET_ERROR: {
      return { ...state, error: action.payload };
    }
    case ACTION_RESET: {
      return { ...initialState };
    }
    default:
      return { ...state };
  }
};

const selector = createSelector(
  getIsStaff,
  getLicenseType,
  getContract,
  (isStaff, licenseType, contract) => ({
    isStaff,
    licenseType,
    contract,
  })
);

const Switcher = ({ children, index }) => children[index];

const useCredits = orgId => {
  const { isStaff } = useSelector(selector);
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function effect() {
      try {
        let res = await getCreditsFn(orgId);

        let blueprints;
        if (isStaff) {
          blueprints = await getBlueprintsFn();
        }

        res = res
          .map(credit => {
            const obj = { ...credit };

            if (blueprints) {
              obj.packageName = blueprints.find(c => c.id === credit.blueprintId)?.name;
            }

            return obj;
          })
          .sort((a, b) => new Date(a.expiresAt) - new Date(b.expiresAt))
          .reduce(
            (acc, cur) => {
              const k = cur.isExpired ? CREDITS_STATUS_EXPIRED : CREDITS_STATUS_AVAILABLE;
              acc[k].push(cur);

              return acc;
            },
            { [CREDITS_STATUS_AVAILABLE]: [], [CREDITS_STATUS_EXPIRED]: [] }
          );

        setCredits(res);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    effect();
  }, [orgId, isStaff]);

  return { credits, loading, error };
};

const CreateModeSwitcher = ({ currentMode, onChange, hasCredits }) => {
  const { licenseType } = useSelector(selector);

  // switching modes is not possible when org has credits-based license type
  if (licenseType === LICENSE_TYPE_EVENT_CREDIT) {
    return null;
  }

  if (licenseType === LICENSE_TYPE_USER_CREDIT) {
    if (currentMode === LICENSE_TYPE_USER_CREDIT && hasCredits) {
      const switchModeLink = (
        <Typography.Link key="switch-link" onClick={() => onChange(LICENSE_TYPE_EVENT_CREDIT)}>
          {t`Click here to use your event credits`}
        </Typography.Link>
      );
      return (
        <Alert
          showIcon
          message={t`Event Credits Available`}
          description={jt`You can utilize available event credits to create an event. ${switchModeLink}.`}
        />
      );
    }

    // we don't allow switching to 'event credit' mode when there are no credits
    if (currentMode === LICENSE_TYPE_USER_CREDIT && !hasCredits) {
      return null;
    }

    if (currentMode === LICENSE_TYPE_EVENT_CREDIT) {
      const switchModeLink = (
        <Typography.Link key="switch-link" onClick={() => onChange(LICENSE_TYPE_USER_CREDIT)}>
          {t`Create an event without using event credits`}
        </Typography.Link>
      );
      return (
        <Alert
          showIcon
          message={jt`You are using event credits to create an event. ${switchModeLink}.`}
        />
      );
    }
  }

  return null;
};

CreateModeSwitcher.propTypes = {
  currentMode: PropTypes.oneOf([LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT]).isRequired,
  onChange: PropTypes.func.isRequired,
  hasCredits: PropTypes.bool.isRequired,
};

const CreateModal = ({ open, onClose, orgId, navigateToEvent }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { credits, loading, error } = useCredits(orgId);
  const { licenseType, contract } = useSelector(selector);
  // set the initial create mode based on license type
  useEffect(() => {
    dispatch({ type: ACTION_SET_CREATE_MODE, payload: licenseType });
  }, [licenseType]);

  const isBackEnabled = useMemo(
    () =>
      // do not show back btn if it's first step for 'event credit' mode
      (state.createMode === LICENSE_TYPE_EVENT_CREDIT && state.currentStep !== STEP_CREDIT) ||
      // do not show back btn if it's first step for 'user credit' mode
      (state.createMode === LICENSE_TYPE_USER_CREDIT && state.currentStep !== STEP_CONTENT),
    [state]
  );

  const isContinueDisabled = useMemo(() => {
    if (state.currentStep === STEP_CREDIT) {
      return !state.credit;
    }
    if (state.currentStep === STEP_CONTENT) {
      return !state.source || (state.source === SOURCE_DUPLICATE && !state.sourceEvent?.id);
    }
    return !state.name || !state.dates?.[0] || !state.dates?.[1] || !state.code || state.isReserved;
  }, [state]);

  const handleSubmit = async () => {
    if (state.currentStep === STEP_CREDIT) {
      dispatch({ type: ACTION_SET_STEP, payload: STEP_CONTENT });
      return;
    }
    if (state.currentStep === STEP_CONTENT) {
      dispatch({ type: ACTION_SET_STEP, payload: STEP_DETAILS });
      return;
    }

    if (state.error) {
      dispatch({ type: ACTION_SET_ERROR, payload: null });
    }

    dispatch({ type: ACTION_SET_LOADING, payload: true });
    const [startDate, endDate] = state.dates;
    const payload = { ...state };
    if (state.createMode === LICENSE_TYPE_USER_CREDIT) {
      delete payload.credit;
    }
    // Rewrite form dates to follow api format
    payload.startDate = startDate.format(FORMAT_DATE);
    payload.endDate = endDate.format(FORMAT_DATE);

    try {
      const res = await redeemCreditFn(payload, orgId);
      if (res && res.eventId) {
        const tadaEmoji = '\uD83C\uDF89';
        message.success(t`Congratulations. Your event has been created. ${tadaEmoji}`);
        navigateToEvent(res.eventId);
      }
    } catch (err) {
      dispatch({
        type: ACTION_SET_ERROR,
        payload: ERROR_MESSAGES_BY_CODE()[err?.errors?.[0]?.code],
      });
      message.error(t`Error Creating Event.`);
    } finally {
      dispatch({ type: ACTION_SET_LOADING, payload: false });
    }
  };

  const handleBack = () => {
    dispatch({ type: ACTION_SET_STEP, payload: state.currentStep - 1 });
  };

  const handleCancel = () => {
    dispatch({ type: ACTION_RESET });
    onClose();
  };

  // wait for `useEffect` to set `createMode`
  if (!state.createMode) {
    return null;
  }

  return (
    <Modal
      title={t`Create an Event`}
      open={open}
      width={700}
      destroyOnClose
      maskClosable={false}
      onCancel={handleCancel}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCancel}>{t`Cancel`}</Button>
          <Space>
            {isBackEnabled && <Button onClick={handleBack}>{t`Back`}</Button>}
            <Button
              type="primary"
              loading={state.loading}
              disabled={isContinueDisabled}
              onClick={handleSubmit}
            >
              {state.currentStep === STEP_DETAILS ? t`Create Event` : t`Continue`}
            </Button>
          </Space>
        </div>
      }
    >
      {loading && <Loading />}
      {!loading && error && (
        <Alert type="error" message={t`Something went wrong. Please try again.`} />
      )}
      {!loading && !error && (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <CreateModeSwitcher
            currentMode={state.createMode}
            onChange={newMode => dispatch({ type: ACTION_SET_CREATE_MODE, payload: newMode })}
            hasCredits={credits[CREDITS_STATUS_AVAILABLE].length > 0}
          />
          {state.createMode === LICENSE_TYPE_EVENT_CREDIT && (
            <Steps
              size="small"
              current={state.currentStep}
              onChange={current => dispatch({ type: ACTION_SET_STEP, payload: current })}
              items={[
                { title: t`Select Credit` },
                {
                  title: `Content`,
                  disabled: state.currentStep === STEP_DETAILS ? false : isContinueDisabled,
                },
                {
                  title: `Event Details`,
                  disabled: isContinueDisabled,
                },
              ]}
            />
          )}
          {state.error && <Alert message={state.error} type="error" showIcon />}
          <Switcher index={state.currentStep}>
            <Credits
              selected={state.credit?.id}
              credits={credits}
              onSelect={credit => dispatch({ type: ACTION_SET_CREDIT, payload: credit })}
            />
            <Content
              currentMode={state.createMode}
              hasContract={!!contract}
              orgId={orgId}
              setSource={v => dispatch({ type: ACTION_SET_SOURCE, payload: v })}
              setSourceEvent={e => dispatch({ type: ACTION_SET_SOURCE_EVENT, payload: e })}
              sourceEvent={state.sourceEvent}
              source={state.source}
            />
            <Details
              currentMode={state.createMode}
              credit={state.credit}
              name={state.name}
              code={state.code}
              dates={state.dates}
              isReserved={state.isReserved}
              setDates={d => dispatch({ type: ACTION_SET_DATES, payload: d })}
              setCode={c => dispatch({ type: ACTION_SET_CODE, payload: c })}
              setName={n => dispatch({ type: ACTION_SET_NAME, payload: n })}
            />
          </Switcher>
        </Space>
      )}
    </Modal>
  );
};

CreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
  navigateToEvent: PropTypes.func.isRequired,
};

export default CreateModal;
