import apiFn from 'common/services/api-service';

const apiPermission = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/me/permissions`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });
const apiPermissionSet = () =>
  apiFn(`/api/uapi/permission_sets`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const list = organizationId =>
  apiPermission(organizationId)
    .get('')
    .then(response => response.data);

export const getMap = () =>
  apiPermissionSet()
    .get('')
    .then(response => response.data);
