import { useEffect, useRef } from 'react';

const useInterval = (callback, delay, { setInterval, clearInterval } = window) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay, setInterval, clearInterval]);
};

export default useInterval;
