import AntIcon from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

const Icon = styled(AntIcon)`
  padding-right: 10px;
`;

// Custom empty circle Icon. Ant does not have one.
const EmptyCircle = () => (
  <Icon
    component={() => (
      <svg height="1em" width="1em" viewBox="64 64 896 896" fill="currentColor">
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
      </svg>
    )}
  />
);

export default EmptyCircle;
