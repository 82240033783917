import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t, jt } from 'ttag';

import { SALES_EMAIL } from 'common/services/support/types';
import { getDates, isEnabled, getConfiguration } from 'common/state/event';

const EVENT_ACCESS_NOTIFICATION_DAYS = 7;

const trialEmail = {
  subject: encodeURIComponent('Provide me with more info on how to upgrade my trial'),
  body: encodeURIComponent(
    `I would like to talk to an EventMobi sales representative to discuss my event. My contact information is:\nFirst Name:\nLast Name:\nPhone Number:`
  ),
};

const ALERT_CONTENT_MAPPING = () => {
  const links = {
    trial: (
      <a
        key="trial"
        href={`mailto:${SALES_EMAIL}?subject=${trialEmail.subject}&body=${trialEmail.body}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t`Upgrade Now`}
      </a>
    ),
    access: (
      <a key="access" href={`mailto:${SALES_EMAIL}`} target="_blank" rel="noopener noreferrer">
        {SALES_EMAIL}
      </a>
    ),
  };

  return {
    trial: {
      error: () => jt`Your trial is expired. ${links.trial}`,
      warning: days => jt`Your trial will expire in ${days} day(s). ${links.trial}`,
    },
    event: {
      error: () =>
        jt`Post-event access has expired and attendee access is restricted. Please reach out to ${links.access} if you wish to extend it.`,
      warning: days =>
        jt`Post-event access will expire in ${days} day(s) and attendee access will be restricted. Please reach out to ${links.access} if you wish to extend it.`,
    },
  };
};

const useEventAlerts = eventId => {
  // This includes native_livestream logic; it's an org alert now
  const isTrial = useSelector(state => isEnabled(state, 'is_trial'));
  const trialExpireAt = useSelector(state => getConfiguration(state, 'trial_expire_at'));
  const dates = useSelector(getDates);
  const eventappPostEventAccessDays = useSelector(state =>
    getConfiguration(state, 'eventapp_post_event_access_days')
  );

  /*
    Keeping this outside the useMemo to avoid an edge case - if a user is on
    at midnight, the trial/post-access days should update on the next render.
  */
  const endOfDay = moment().endOf('day');

  const alerts = useMemo(() => {
    const nextAlerts = [];

    if (!eventId) {
      return nextAlerts;
    }

    const addAlert = (id, type, days) => {
      const alert = {
        id,
        type,
        scope: 'event',
        resourceId: eventId,
        content: ALERT_CONTENT_MAPPING()[id][type](days),
      };
      nextAlerts.push(alert);
    };

    if (isTrial) {
      const diff = moment(trialExpireAt).diff(endOfDay, 'days');

      addAlert('trial', diff <= 0 ? 'error' : 'warning', diff);
    }

    if (eventappPostEventAccessDays) {
      // Add one days to the end of the event to account for the day the event ends
      const lockoutDate = moment(dates.end)
        .endOf('day')
        .add(Number(eventappPostEventAccessDays) + 1, 'days');
      const diff = lockoutDate.diff(endOfDay, 'days');
      // Only show warning after the event ends.
      if (diff <= EVENT_ACCESS_NOTIFICATION_DAYS) {
        addAlert('event', diff <= 0 ? 'error' : 'warning', diff);
      }
    }

    return nextAlerts;
  }, [eventId, dates, eventappPostEventAccessDays, isTrial, trialExpireAt, endOfDay]);

  return { alerts };
};

export default useEventAlerts;
