import { LayoutOutlined } from '@ant-design/icons';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons/faCommentDots';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import {
  faAddressBook,
  faAddressCard,
  faTicketAlt,
  faLanguage,
} from '@fortawesome/free-solid-svg-icons';
import { faAd } from '@fortawesome/free-solid-svg-icons/faAd';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faDraftingCompass } from '@fortawesome/free-solid-svg-icons/faDraftingCompass';
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faGamepad } from '@fortawesome/free-solid-svg-icons/faGamepad';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons/faObjectGroup';
import { faObjectUngroup } from '@fortawesome/free-solid-svg-icons/faObjectUngroup';
import { faPoll } from '@fortawesome/free-solid-svg-icons/faPoll';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faTools } from '@fortawesome/free-solid-svg-icons/faTools';
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUserShield } from '@fortawesome/free-solid-svg-icons/faUserShield';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Menu as AntMenu } from 'antd';
import React, { lazy, useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  matchPath,
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { t } from 'ttag';

import Loading from 'common/components/Loading';
import useConfig from 'common/hooks/useConfig';
import { getCmsEvent as getFn } from 'common/services/events/api';
import { event as flagFn } from 'common/services/flags/api';
import { setConfigurations, isEnabled, setEvent, setCustomDomain } from 'common/state/event';
import { setEvent as setFlags, isEnabled as isFlagEnabled } from 'common/state/flags';
import { getIsStaff } from 'common/state/user';

import AudienceResponse from '@domains/AudienceResponse';
import Configuration from '@domains/Configuration';
import { config as getConfigFn } from '@domains/Configuration/service';
import HTTP404 from '@domains/Errors/HTTP404';
import HTTP500 from '@domains/Errors/HTTP500';
import Layout from '@domains/Errors/Layout';
import { get as getCustomDomainFn } from '@domains/Settings/CustomDomain/service';

import Details from './Details';

const Privacy = lazy(() => import(/* webpackChunkName: "privacy" */ '@domains/Privacy'));
const People = lazy(() => import(/* webpackChunkName: "people" */ '@domains/People'));
const Sessions = lazy(() => import(/* webpackChunkName: "sessions" */ '@domains/Sessions'));
const Companies = lazy(() => import(/* webpackChunkName: "company" */ '@domains/Company'));
const Documents = lazy(() => import(/* webpackChunkName: "document" */ '@domains/Documents'));
const Videos = lazy(() => import(/* webpackChunkName: "videos" */ '@domains/Videos'));
const Maps = lazy(() => import(/* webpackChunkName: "maps" */ '@domains/Maps'));
const ImageMap = lazy(() => import(/* webpackChunkName: "maps" */ '@domains/Maps/ImageMap'));
const GoogleMap = lazy(() => import(/* webpackChunkName: "maps" */ '@domains/Maps/GoogleMap'));
const Surveys = lazy(() => import(/* webpackChunkName: "surveys" */ '@domains/Surveys'));
const WidgetPages = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages')
);
const WidgetPagesTemplates = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages/Templates')
);
const WidgetPagesPreview = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages/Preview')
);
const WidgetPagesDesigner = lazy(() =>
  import(/* webpackChunkName: "widgetpages" */ '@domains/WidgetPages/Designer')
);
const LoginPage = lazy(() => import(/* webpackChunkName: "loginpage" */ '@domains/LoginPage'));
const HomePage = lazy(() => import(/* webpackChunkName: "homepage" */ '@domains/HomePage'));
const Sections = lazy(() => import(/* webpackChunkName: "sections" */ '@domains/Sections'));
const Discussions = lazy(() =>
  import(/* webpackChunkName: "discussions" */ '@domains/Discussions')
);
const Studio = lazy(() => import(/* webpackChunkName: "studio" */ '@domains/Studio'));
const ActivityFeed = lazy(() =>
  import(/* webpackChunkName: "activityFeed" */ '@domains/ActivityFeed')
);
const BannerAd = lazy(() =>
  import(/* webpackChunkName: "bannerAd" */ '@domains/BannerAd').catch(error => {
    if (error.name !== 'ChunkLoadError') {
      throw error;
    }
    return {
      default: () => (
        <Layout
          scoped
          title={t`Ad Blocker Detected`}
          body={t`This page could not be loaded. Please disable ad blockers to access this page.`}
          support={false}
        />
      ),
    };
  })
);

const Settings = lazy(() => import(/* webpackChunkName: "settings" */ '@domains/Settings'));
const Badges = lazy(() => import(/* webpackChunkName: "badges" */ '@domains/Badges'));
const BadgeDesigner = lazy(() =>
  import(/* webpackChunkName: "badges" */ '@domains/Badges/Designer')
);
const BadgeTemplates = lazy(() =>
  import(/* webpackChunkName: "badges" */ '@domains/Badges/Templates')
);
const BadgeOrder = lazy(() => import(/* webpackChunkName: "badges" */ '@domains/Badges/Order'));
const BadgeOrderSuccess = lazy(() =>
  import(/* webpackChunkName: "badges" */ '@domains/Badges/Order/Success')
);
const Gamification = lazy(() =>
  import(/* webpackChunkName: "gamification" */ '@domains/Gamification')
);
const LiveDisplay = lazy(() =>
  import(/* webpackChunkName: "liveDisplay" */ '@domains/LiveDisplay')
);
const Announcements = lazy(() =>
  import(/* webpackChunkName: "announcements" */ '@domains/Announcements')
);
const Onsite = lazy(() => import(/* webpackChunkName: "onsite" */ '@domains/Onsite'));
const LeadCapture = lazy(() =>
  import(/* webpackChunkName: "lead-capture" */ '@domains/LeadCapture')
);
const Integration = lazy(() =>
  import(/* webpackChunkName: "integration" */ '@domains/Integration')
);
const Analytics = lazy(() => import(/* webpackChunkName: "analytics" */ '@domains/Analytics'));
const Audit = lazy(() => import(/* webpackChunkName: "audit" */ '@domains/Audit'));
const CommunicationEmails = lazy(() =>
  import(/* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails')
);
const CommunicationEmailDetails = lazy(() =>
  import(/* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/Details')
);
const SelectEmailTemplate = lazy(() =>
  import(
    /* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/Templates/Selection/SelectEmailTemplate'
  )
);
const SelectTemplateDesign = lazy(() =>
  import(
    /* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/Templates/Selection/SelectTemplateDesign'
  )
);
const EmailEditor = lazy(() =>
  import(
    /* webpackChunkName: "Communications-emails" */ '@domains/Communications/Emails/EmailEditor'
  )
);
const EmailAnalytics = lazy(() =>
  import(/* webpackChunkName: "Communications-analytics" */ '@domains/Communications/Analytics')
);

const RegistrationDetails = lazy(() =>
  import(/* webpackChunkName: "reg-settings" */ '@domains/Registration/Details')
);
// TODO: EXP-17450 - Remove once the website builder has been launched.
const RegistrationStudioOld = lazy(() =>
  import(/* webpackChunkName: "reg-studio" */ '@domains/Registration/StudioOld')
);
const RegistrationEmails = lazy(() =>
  import(/* webpackChunkName: "reg-emails" */ '@domains/Registration/Emails')
);
const RegistrationOrders = lazy(() =>
  import(/* webpackChunkName: "reg-orders" */ '@domains/Registration/Orders')
);
const RegistrationOrder = lazy(() =>
  import(/* webpackChunkName: "reg-order" */ '@domains/Registration/Orders/Details')
);
const ConfirmationEmailEditor = lazy(() =>
  import(
    /* webpackChunkName: "reg-confirmation-email" */ '@domains/Registration/ConfirmationEmailEditor'
  )
);
const RegistrationPromoCodes = lazy(() =>
  import(/* webpackChunkName: "reg-promo-codes" */ '@domains/Registration/PromoCodes')
);
const RegistrationPromoCode = lazy(() =>
  import(/* webpackChunkName: "reg-promo-code" */ '@domains/Registration/PromoCodes/Details')
);
const RegistrationForm = lazy(() =>
  import(/* webpackChunkName: "reg-form" */ '@domains/Registration/Form')
);
const RegistrationTickets = lazy(() =>
  import(/* webpackChunkName: "reg-tickets" */ '@domains/Registration/Tickets')
);
const RegistrationTicketDetails = lazy(() =>
  import(/* webpackChunkName: "reg-ticket" */ '@domains/Registration/Tickets/Details')
);
const WebsiteBuilder = lazy(() =>
  import(/* webpackChunkName: "website-builder" */ '@domains/WebsiteBuilder')
);
const WebsiteBuilderEditor = lazy(() =>
  import(/* webpackChunkName: "website-builder-editor" */ '@domains/WebsiteBuilder/PageEditor')
);
const SelectPageTemplate = lazy(() =>
  import(
    /* webpackChunkName: "website-builder" */ '@domains/WebsiteBuilder/Templates/SelectTemplate/SelectPageTemplate'
  )
);
// we don't really need to lazy load following routes but I'm going to do it
// either way as it's an easy _route_
const Profile = lazy(() => import(/* webpackChunkName: "people" */ '@domains/People/Profile'));
const Session = lazy(() => import(/* webpackChunkName: "sessions" */ '@domains/Sessions/Session'));
const Company = lazy(() => import(/* webpackChunkName: "company" */ '@domains/Company/Profile'));
const Survey = lazy(() => import(/* webpackChunkName: "surveys" */ '@domains/Surveys/Details'));

const ITEM_EVENT_DETAILS = 'ITEM_EVENT_DETAILS';
const ITEM_PRIVACY_AND_TERMS = 'ITEM_PRIVACY_AND_TERMS';
const ITEM_PEOPLE = 'ITEM_PEOPLE';
const ITEM_SESSIONS = 'ITEM_SESSIONS';
const ITEM_COMPANIES = 'ITEM_COMPANIES';
const ITEM_DOCUMENTS = 'ITEM_DOCUMENTS';
const ITEM_VIDEOS = 'ITEM_VIDEOS';
const ITEM_MAPS = 'ITEM_MAPS';
const ITEM_SURVEYS = 'ITEM_SURVEYS';
const ITEM_WIDGET_PAGES = 'ITEM_WIDGET_PAGES';
const ITEM_EVENT_APP = 'ITEM_EVENT_APP';
const ITEM_EVENT_APP_LOGIN_PAGE = 'ITEM_EVENT_APP_LOGIN_PAGE';
const ITEM_EVENT_APP_HOME_PAGE = 'ITEM_EVENT_APP_HOME_PAGE';
const ITEM_EVENT_APP_SECTIONS = 'ITEM_EVENT_APP_SECTIONS';
const ITEM_EVENT_APP_GROUP_DISCUSSIONS = 'ITEM_EVENT_APP_GROUP_DISCUSSIONS';
const ITEM_EVENT_APP_DESIGN_STUDIO = 'ITEM_EVENT_APP_DESIGN_STUDIO';
const ITEM_EVENT_APP_ACTIVITY_FEED = 'ITEM_EVENT_APP_ACTIVITY_FEED';
const ITEM_EVENT_APP_BANNERS = 'ITEM_EVENT_APP_BANNERS';
const ITEM_EVENT_APP_SETTINGS = 'ITEM_EVENT_APP_SETTINGS';
const ITEM_BADGE_DESIGNER = 'ITEM_BADGE_DESIGNER';
const ITEM_GAMIFICATION = 'ITEM_GAMIFICATION';
const ITEM_AUDIENCE_RESPONSE = 'ITEM_AUDIENCE_RESPONSE';
const ITEM_LIVE_DISPLAY = 'ITEM_LIVE_DISPLAY';
const ITEM_ANNOUNCEMENTS = 'ITEM_ANNOUNCEMENTS';
// TODO: EXP-16988 - Remove announcements old route
const ITEM_ANNOUNCEMENTS_OLD = 'ITEM_ANNOUNCEMENTS_OLD';
const ITEM_ONSITE = 'ITEM_ONSITE';
const ITEM_ONSITE_CHECK_IN = 'ITEM_ONSITE_CHECK_IN';
const ITEM_LEAD_CAPTURE = 'ITEM_LEAD_CAPTURE';
const ITEM_ONSITE_LEAD_CAPTURE = 'ITEM_ONSITE_LEAD_CAPTURE';
const ITEM_INTEGRATIONS = 'ITEM_INTEGRATIONS';
const ITEM_ANALYTICS = 'ITEM_ANALYTICS';
const ITEM_AUDIT_LOG = 'ITEM_AUDIT_LOG';
const ITEM_EVENT_CONFIGURATION = 'ITEM_EVENT_CONFIGURATION';
const ITEM_COMMUNICATIONS = 'ITEM_EVENT_COMMUNICATIONS';
const ITEM_COMMUNICATIONS_EMAILS = 'ITEM_COMMUNICATIONS_EMAILS';
const ITEM_REGISTRATION = 'ITEM_REGISTRATION';
const ITEM_REGISTRATION_DETAILS = 'ITEM_REGISTRATION_DETAILS';
const ITEM_REGISTRATION_DESIGN_STUDIO = 'ITEM_REGISTRATION_DESIGN_STUDIO';
const ITEM_REGISTRATION_EMAILS = 'ITEM_REGISTRATION_EMAILS';
const ITEM_REGISTRATION_ORDERS = 'ITEM_REGISTRATION_ORDERS';
const ITEM_REGISTRATION_REG_FORM = 'ITEM_REGISTRATION_REG_FORM';
const ITEM_REGISTRATION_PROMO_CODES = 'ITEM_REGISTRATION_PROMO_CODES';
const ITEM_REGISTRATION_TICKETS = 'ITEM_REGISTRATION_TICKETS';
const ITEM_WEBSITE_BUILDER = 'ITEM_WEBSITE_BUILDER';

const StyleOverrides = createGlobalStyle`
  .ant-menu-item-group-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .ant-menu-item-divider {
    && {
      border-color: rgba(255, 255, 255, 0.25);
    }
  }
  `;

const Menu = styled(AntMenu)`
  height: 100%;
  overflow: auto;
  width: 230px;
  flex-shrink: 0;
  z-index: 999;
`;

const Content = styled.div`
  overflow: auto;
  width: 100%;
`;

const FontAwesomeIcon = styled(Icon)`
  width: 14px !important;
`;

const organizationPath = `/organization/:organizationId`;
const basepath = `${organizationPath}/event/:eventId`;
const routeNavMap = {
  [ITEM_EVENT_DETAILS]: {
    path: [basepath, `${basepath}/pages/details`],
    exact: true,
  },
  [ITEM_PRIVACY_AND_TERMS]: {
    path: `${basepath}/privacy`,
    exact: true,
  },
  [ITEM_PEOPLE]: {
    path: [`${basepath}/people`, `${basepath}/pages/people`],
    exact: false,
  },
  [ITEM_SESSIONS]: {
    path: [`${basepath}/sessions`, `${basepath}/pages/sessions`],
    exact: false,
  },
  [ITEM_COMPANIES]: {
    path: [`${basepath}/companies`, `${basepath}/pages/companies`],
    exact: false,
  },
  [ITEM_DOCUMENTS]: {
    path: [`${basepath}/documents`, `${basepath}/pages/documents`],
    exact: true,
  },
  [ITEM_VIDEOS]: {
    path: [`${basepath}/videos`, `${basepath}/pages/videos`],
    exact: false,
  },
  [ITEM_MAPS]: {
    path: [`${basepath}/maps`, `${basepath}/image-map`],
    exact: false,
  },
  [ITEM_SURVEYS]: {
    path: [`${basepath}/surveys`, `${basepath}/pages/surveys`],
    exact: false,
  },
  [ITEM_WIDGET_PAGES]: {
    path: `${basepath}/designer-pages`,
    exact: false,
  },
  [ITEM_EVENT_APP_LOGIN_PAGE]: {
    path: `${basepath}/event-app/login-page`,
    exact: true,
  },
  [ITEM_EVENT_APP_HOME_PAGE]: {
    path: `${basepath}/event-app/home-page`,
    exact: true,
  },
  [ITEM_EVENT_APP_SECTIONS]: {
    path: `${basepath}/event-app/sections`,
    exact: true,
  },
  [ITEM_EVENT_APP_GROUP_DISCUSSIONS]: {
    path: `${basepath}/event-app/discussions`,
    exact: true,
  },
  [ITEM_EVENT_APP_DESIGN_STUDIO]: {
    path: `${basepath}/event-app/studio`,
    exact: true,
  },
  [ITEM_EVENT_APP_ACTIVITY_FEED]: {
    path: `${basepath}/event-app/activity-feed`,
    exact: true,
  },
  [ITEM_EVENT_APP_BANNERS]: {
    path: `${basepath}/event-app/banner-ads`,
    exact: true,
  },
  [ITEM_EVENT_APP_SETTINGS]: {
    path: `${basepath}/event-app/settings`,
    exact: true,
  },
  [ITEM_BADGE_DESIGNER]: {
    path: `${basepath}/badge-designer`,
    exact: false,
  },
  [ITEM_GAMIFICATION]: {
    path: `${basepath}/gamification`,
    exact: true,
  },
  [ITEM_AUDIENCE_RESPONSE]: {
    path: `${basepath}/ars/settings`,
    exact: true,
  },
  [ITEM_LIVE_DISPLAY]: {
    path: [`${basepath}/live-display`, `${basepath}/pages/live-display`],
    exact: false,
  },
  [ITEM_ANNOUNCEMENTS_OLD]: {
    path: `${basepath}/announcements`,
    exact: true,
  },
  [ITEM_ONSITE_CHECK_IN]: {
    path: `${basepath}/onsite/check-in`,
    exact: true,
  },
  [ITEM_ONSITE_LEAD_CAPTURE]: {
    path: `${basepath}/onsite/lead-capture`,
    exact: true,
  },
  [ITEM_COMMUNICATIONS_EMAILS]: {
    path: `${basepath}/communications/emails`,
    exact: false,
  },
  [ITEM_INTEGRATIONS]: {
    path: [`${basepath}/integrations`, `${basepath}/pages/integrations`],
    exact: true,
  },
  [ITEM_ANNOUNCEMENTS]: {
    path: `${basepath}/communications/announcements`,
    exact: true,
  },
  [ITEM_ANALYTICS]: {
    path: `${basepath}/analytics`,
    exact: true,
  },
  [ITEM_AUDIT_LOG]: {
    path: `${basepath}/audit-log`,
    exact: true,
  },
  [ITEM_EVENT_CONFIGURATION]: {
    path: `${basepath}/configuration`,
    exact: true,
  },
  [ITEM_REGISTRATION_DETAILS]: {
    path: `${basepath}/registration/details`,
    exact: true,
  },
  // TODO: EXP-17450 - Remove once the website builder has been launched.
  [ITEM_REGISTRATION_DESIGN_STUDIO]: {
    path: `${basepath}/registration/studio`,
    exact: true,
  },
  [ITEM_REGISTRATION_EMAILS]: {
    path: `${basepath}/registration/email`,
    exact: true,
  },
  [ITEM_REGISTRATION_ORDERS]: {
    path: `${basepath}/registration/orders`,
    exact: false,
  },
  [ITEM_REGISTRATION_PROMO_CODES]: {
    path: `${basepath}/registration/promo-codes`,
    exact: true,
  },
  [ITEM_REGISTRATION_REG_FORM]: {
    path: `${basepath}/registration/form`,
    exact: true,
  },
  [ITEM_REGISTRATION_TICKETS]: {
    path: `${basepath}/registration/tickets`,
    exact: true,
  },
  [ITEM_WEBSITE_BUILDER]: {
    path: `${basepath}/website-builder`,
    exact: false,
  },
};

// List of paths where we don't want to show the navigation sidebar.
const noNavigationPaths = [
  `${routeNavMap.ITEM_COMMUNICATIONS_EMAILS.path}/templates/:emailDesignId/editor`,
  `${routeNavMap.ITEM_WEBSITE_BUILDER.path}/templates/:pageDesignId/editor`,
];

const isNoNavigationPath = path =>
  noNavigationPaths.some(noNavPath => matchPath(path, { path: noNavPath, exact: true }));

const getSelectedMenuKeys = url => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, route] of Object.entries(routeNavMap)) {
    if (matchPath(url, route)) {
      return [key];
    }
  }

  return [ITEM_EVENT_DETAILS];
};

const Navigation = () => {
  const { organizationId, eventId } = useParams();
  const { pathname } = useLocation();
  const config = useConfig();
  const isRegistrationEnabled = useSelector(state =>
    isEnabled(state, 'is_registration_v2_enabled')
  );
  const isLiveDisplayEnabled = useSelector(state => isEnabled(state, 'is_livedisplay_enabled'));
  const isGamificationEnabled = useSelector(state => isEnabled(state, 'is_gamify_app_enabled'));
  const isBadgeDesignerEnabled = useSelector(state => isEnabled(state, 'badge_designer_enabled'));
  const isCompaniesEnabled = useSelector(state => isEnabled(state, 'is_companies_enabled'));
  const isDocumentUploadEnabled = config.isEnabled('is_document_upload_enabled');
  const isSurveyEnabled = useSelector(state => isEnabled(state, 'is_survey_enabled'));
  const isGroupDiscussionsEnabled = useSelector(state =>
    isEnabled(state, 'is_group_discussions_enabled')
  );
  const isActivityFeedEnabled = useSelector(state => isEnabled(state, 'is_activity_feed_enabled'));
  const isCheckInEnabled = useSelector(state => isEnabled(state, 'is_checkin_enabled'));
  const isSponsorshipEnabled = useSelector(state => isEnabled(state, 'is_sponsorship_enabled'));
  const isCommunicationsEnabled = useSelector(state =>
    isEnabled(state, 'is_communications_enabled')
  );

  const isIntegrationDashboardEnabled = useSelector(state =>
    isFlagEnabled(state, 'integrations_dashboard')
  );
  const isLeadCaptureEnabled = useSelector(state => isEnabled(state, 'is_lead_capture_enabled'));
  const isRegFieldsEnabled = useSelector(state => isFlagEnabled(state, 'reg_field_updates'));
  const isVideoLibraryEnabled = useSelector(state => isEnabled(state, 'is_video_library_enabled'));
  const isNativeStreamEnabled = useSelector(state => isEnabled(state, 'native_livestream'));
  const isRtmpStreamingEnabled = useSelector(state => isEnabled(state, 'rtmp_streaming'));
  const isWebsiteBuilderEnabled = useSelector(state =>
    isEnabled(state, 'is_website_builder_enabled')
  );

  const isStaff = useSelector(getIsStaff);

  const getNavPath = key => {
    const firstPath = Array.isArray(routeNavMap[key].path)
      ? routeNavMap[key].path[0]
      : routeNavMap[key].path;
    return generatePath(firstPath, { organizationId, eventId });
  };

  if (isNoNavigationPath(pathname)) {
    return null;
  }

  /** @type {import('antd/lib/menu/hooks/useItems').MenuItemGroupType} */
  const libraryItem = {
    type: 'group',
    label: t`Libraries`,
    children: [
      {
        key: ITEM_PEOPLE,
        icon: <FontAwesomeIcon icon={faUsers} />,
        label: <Link to={getNavPath(ITEM_PEOPLE)}>{t`People`}</Link>,
      },
      {
        key: ITEM_SESSIONS,
        icon: <FontAwesomeIcon icon={faCalendar} />,
        label: <Link to={getNavPath(ITEM_SESSIONS)}>{t`Sessions`}</Link>,
      },
      isCompaniesEnabled && {
        key: ITEM_COMPANIES,
        icon: <FontAwesomeIcon icon={faBuilding} />,
        label: <Link to={getNavPath(ITEM_COMPANIES)}>{t`Companies`}</Link>,
      },
      isDocumentUploadEnabled && {
        key: ITEM_DOCUMENTS,
        icon: <FontAwesomeIcon icon={faBriefcase} />,
        label: <Link to={getNavPath(ITEM_DOCUMENTS)}>{t`Documents`}</Link>,
      },
      (isVideoLibraryEnabled || isNativeStreamEnabled || isRtmpStreamingEnabled) && {
        key: ITEM_VIDEOS,
        icon: <FontAwesomeIcon icon={faVideo} />,
        label: <Link to={getNavPath(ITEM_VIDEOS)}>{t`Videos`}</Link>,
      },
      {
        key: ITEM_MAPS,
        icon: <FontAwesomeIcon icon={faMap} />,
        label: <Link to={getNavPath(ITEM_MAPS)}>{t`Maps`}</Link>,
      },
      isSurveyEnabled && {
        key: ITEM_SURVEYS,
        icon: <FontAwesomeIcon icon={faPoll} />,
        label: <Link to={getNavPath(ITEM_SURVEYS)}>{t`Surveys`}</Link>,
      },
      {
        key: ITEM_WIDGET_PAGES,
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        label: <Link to={getNavPath(ITEM_WIDGET_PAGES)}>{t`Page Designer`}</Link>,
      },
    ],
  };

  /** @type {import('antd/lib/menu/hooks/useItems').MenuItemGroupType} */
  const productItem = {
    type: 'group',
    label: t`Products`,
    children: [
      isWebsiteBuilderEnabled && {
        key: ITEM_WEBSITE_BUILDER,
        icon: <LayoutOutlined />,
        label: <Link to={getNavPath(ITEM_WEBSITE_BUILDER)}>{t`Website Builder`}</Link>,
      },
      {
        key: ITEM_COMMUNICATIONS,
        icon: <FontAwesomeIcon icon={faBullhorn} />,
        label: t`Communications`,
        children: [
          isCommunicationsEnabled && {
            key: ITEM_COMMUNICATIONS_EMAILS,
            icon: <FontAwesomeIcon icon={faEnvelopeRegular} />,
            label: <Link to={getNavPath(ITEM_COMMUNICATIONS_EMAILS)}>{t`Email Campaigns`}</Link>,
          },
          {
            key: ITEM_ANNOUNCEMENTS,
            icon: <FontAwesomeIcon icon={faCommentDots} />,
            label: <Link to={getNavPath(ITEM_ANNOUNCEMENTS)}>{t`Announcements`}</Link>,
          },
        ],
      },
      isRegistrationEnabled && {
        key: ITEM_REGISTRATION,
        icon: <FontAwesomeIcon icon={faUserPlus} />,
        label: t`Registration`,
        children: [
          {
            key: ITEM_REGISTRATION_DETAILS,
            icon: <FontAwesomeIcon icon={faInfoCircle} />,
            label: <Link to={getNavPath(ITEM_REGISTRATION_DETAILS)}>{t`Details`}</Link>,
          },
          {
            key: ITEM_REGISTRATION_TICKETS,
            icon: <FontAwesomeIcon icon={faTicketAlt} />,
            label: <Link to={getNavPath(ITEM_REGISTRATION_TICKETS)}>{t`Tickets`}</Link>,
          },
          isRegFieldsEnabled && {
            key: ITEM_REGISTRATION_REG_FORM,
            icon: <FontAwesomeIcon icon={faClipboardList} />,
            label: <Link to={getNavPath(ITEM_REGISTRATION_REG_FORM)}>{t`Registration Form`}</Link>,
          },
          {
            key: ITEM_REGISTRATION_PROMO_CODES,
            icon: <FontAwesomeIcon icon={faLanguage} />,
            label: <Link to={getNavPath(ITEM_REGISTRATION_PROMO_CODES)}>{t`Promo Codes`}</Link>,
          },
          // TODO: EXP-17450 - Remove once the website builder has been launched.
          !isWebsiteBuilderEnabled && {
            key: ITEM_REGISTRATION_DESIGN_STUDIO,
            icon: <FontAwesomeIcon icon={faDraftingCompass} />,
            label: <Link to={getNavPath(ITEM_REGISTRATION_DESIGN_STUDIO)}>{t`Design`}</Link>,
          },
          {
            key: ITEM_REGISTRATION_EMAILS,
            icon: <FontAwesomeIcon icon={faEnvelopeSolid} />,
            label: <Link to={getNavPath(ITEM_REGISTRATION_EMAILS)}>{t`Emails`}</Link>,
          },
          {
            key: ITEM_REGISTRATION_ORDERS,
            icon: <FontAwesomeIcon icon={faShoppingCart} />,
            label: <Link to={getNavPath(ITEM_REGISTRATION_ORDERS)}>{t`Orders`}</Link>,
          },
        ],
      },
      {
        key: ITEM_EVENT_APP,
        icon: <FontAwesomeIcon icon={faMobileAlt} />,
        label: t`Event Space`,
        children: [
          {
            key: ITEM_EVENT_APP_LOGIN_PAGE,
            icon: <FontAwesomeIcon icon={faUserLock} />,
            label: <Link to={getNavPath(ITEM_EVENT_APP_LOGIN_PAGE)}>{t`Login Page`}</Link>,
          },
          {
            key: ITEM_EVENT_APP_HOME_PAGE,
            icon: <FontAwesomeIcon icon={faObjectUngroup} />,
            label: <Link to={getNavPath(ITEM_EVENT_APP_HOME_PAGE)}>{t`Home Page`}</Link>,
          },
          {
            key: ITEM_EVENT_APP_SECTIONS,
            icon: <FontAwesomeIcon icon={faCopy} />,
            label: <Link to={getNavPath(ITEM_EVENT_APP_SECTIONS)}>{t`Sections`}</Link>,
          },
          isGroupDiscussionsEnabled && {
            key: ITEM_EVENT_APP_GROUP_DISCUSSIONS,
            icon: <FontAwesomeIcon icon={faCommentAlt} />,
            label: (
              <Link to={getNavPath(ITEM_EVENT_APP_GROUP_DISCUSSIONS)}>{t`Group Discussions`}</Link>
            ),
          },
          {
            key: ITEM_EVENT_APP_DESIGN_STUDIO,
            icon: <FontAwesomeIcon icon={faDraftingCompass} />,
            label: (
              <Link to={getNavPath(ITEM_EVENT_APP_DESIGN_STUDIO)}>
                {isRegFieldsEnabled ? t`Design Studio` : t`Event Design Studio`}
              </Link>
            ),
          },
          isActivityFeedEnabled && {
            key: ITEM_EVENT_APP_ACTIVITY_FEED,
            icon: <FontAwesomeIcon icon={faComments} />,
            label: <Link to={getNavPath(ITEM_EVENT_APP_ACTIVITY_FEED)}>{t`Activity Feed`}</Link>,
          },
          isSponsorshipEnabled && {
            key: ITEM_EVENT_APP_BANNERS,
            icon: <FontAwesomeIcon icon={faAd} />,
            label: <Link to={getNavPath(ITEM_EVENT_APP_BANNERS)}>{t`Banners`}</Link>,
          },
          {
            key: ITEM_EVENT_APP_SETTINGS,
            icon: <FontAwesomeIcon icon={faTools} />,
            label: <Link to={getNavPath(ITEM_EVENT_APP_SETTINGS)}>{t`Settings`}</Link>,
          },
        ],
      },
      isBadgeDesignerEnabled && {
        key: ITEM_BADGE_DESIGNER,
        icon: <FontAwesomeIcon icon={faAddressCard} />,
        label: <Link to={getNavPath(ITEM_BADGE_DESIGNER)}>{t`Badge Designer`}</Link>,
      },
      isLeadCaptureEnabled && {
        key: ITEM_LEAD_CAPTURE,
        icon: <FontAwesomeIcon icon={faMobileAlt} />,
        label: t`Lead Capture`,
        children: [
          {
            key: ITEM_ONSITE_LEAD_CAPTURE,
            icon: <FontAwesomeIcon icon={faAddressBook} />,
            label: <Link to={getNavPath(ITEM_ONSITE_LEAD_CAPTURE)}>{t`Onsite Lead Capture`}</Link>,
          },
        ],
      },
      isGamificationEnabled && {
        key: ITEM_GAMIFICATION,
        icon: <FontAwesomeIcon icon={faGamepad} />,
        label: <Link to={getNavPath(ITEM_GAMIFICATION)}>{t`Gamification`}</Link>,
      },
      {
        key: ITEM_AUDIENCE_RESPONSE,
        icon: <FontAwesomeIcon icon={faChartBar} />,
        label: <Link to={getNavPath(ITEM_AUDIENCE_RESPONSE)}>{t`Audience Response`}</Link>,
      },
      isLiveDisplayEnabled && {
        key: ITEM_LIVE_DISPLAY,
        icon: <FontAwesomeIcon icon={faTv} />,
        label: <Link to={getNavPath(ITEM_LIVE_DISPLAY)}>{t`Live Display`}</Link>,
      },
      isCheckInEnabled && {
        key: ITEM_ONSITE,
        icon: <FontAwesomeIcon icon={faMobileAlt} />,
        label: t`Onsite App`,
        children: [
          {
            key: ITEM_ONSITE_CHECK_IN,
            icon: <FontAwesomeIcon icon={faUser} />,
            label: <Link to={getNavPath(ITEM_ONSITE_CHECK_IN)}>{t`Check In`}</Link>,
          },
        ],
      },
      isIntegrationDashboardEnabled && {
        key: ITEM_INTEGRATIONS,
        icon: <FontAwesomeIcon icon={faObjectGroup} />,
        label: <Link to={getNavPath(ITEM_INTEGRATIONS)}>{t`Integrations`}</Link>,
      },
    ],
  };

  /** @type {import('antd/lib/menu/hooks/useItems').MenuItemGroupType} */
  const reportingItem = {
    type: 'group',
    label: t`Reporting`,
    children: [
      {
        key: ITEM_ANALYTICS,
        icon: <FontAwesomeIcon icon={faChartPie} />,
        label: <Link to={getNavPath(ITEM_ANALYTICS)}>{t`Analytics`}</Link>,
      },
      {
        key: ITEM_AUDIT_LOG,
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        label: <Link to={getNavPath(ITEM_AUDIT_LOG)}>{t`Audit Log`}</Link>,
      },
    ],
  };

  /** @type {import('antd/lib/menu/hooks/useItems').MenuItemGroupType} */
  const administrationItem = isStaff && {
    type: 'group',
    label: t`Administration`,
    children: [
      {
        key: ITEM_EVENT_CONFIGURATION,
        icon: <FontAwesomeIcon icon={faCogs} />,
        label: <Link to={getNavPath(ITEM_EVENT_CONFIGURATION)}>{t`Event Configuration`}</Link>,
      },
    ],
  };

  /** @type {import('antd/lib/menu/hooks/useItems').ItemType} */
  const items = [
    {
      key: ITEM_EVENT_DETAILS,
      icon: <FontAwesomeIcon icon={faInfoCircle} />,
      label: <Link to={getNavPath(ITEM_EVENT_DETAILS)}>{t`Event Details`}</Link>,
    },
    {
      key: ITEM_PRIVACY_AND_TERMS,
      icon: <FontAwesomeIcon icon={faUserShield} />,
      label: <Link to={getNavPath(ITEM_PRIVACY_AND_TERMS)}>{t`Privacy & Terms`}</Link>,
    },
    // join all groups with a divider
    ...[libraryItem, productItem, reportingItem, administrationItem]
      .filter(Boolean)
      .flatMap(item => [{ type: 'divider' }, item]),
  ];

  return [
    <StyleOverrides key="overrides" />,
    <Menu
      key="left-nav"
      selectedKeys={getSelectedMenuKeys(pathname)}
      mode="inline"
      theme="dark"
      items={items}
    />,
  ];
};

const useEvent = () => {
  const { organizationId, eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  // this is to make sure we don't render the page until we have the configs
  const { loading: loadingConfig } = useConfig();

  useEffect(() => {
    if (eventId === null || typeof eventId === 'undefined') {
      return;
    }

    if (organizationId === null || typeof organizationId === 'undefined') {
      return;
    }

    async function effect() {
      try {
        // clear up the data before loading
        dispatch([setEvent(), setConfigurations(), setFlags()]);

        const [{ data }, [defaultConfig, config], { data: flags }] = await Promise.all([
          getFn(organizationId, eventId),
          getConfigFn(eventId),
          flagFn(eventId),
        ]);

        let customDomain;
        const isCustomDomainEnabledConf = config.find(
          conf => conf.key === 'is_custom_domain_enabled'
        );
        if (isCustomDomainEnabledConf?.value === '1' && isCustomDomainEnabledConf?.expires_at) {
          customDomain = await getCustomDomainFn(eventId)
            .then(response => response.domainCnameKey)
            .catch(err => {
              const code = err.errors?.[0]?.code;
              if (code === 'custom_domain_not_found') {
                return null;
              }

              throw err;
            });
        }

        dispatch([
          setEvent(data),
          setConfigurations(defaultConfig, config),
          setFlags(flags),
          setCustomDomain(customDomain),
        ]);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    effect();
  }, [organizationId, eventId, dispatch]);

  return { loading: loading || loadingConfig, error, organizationId };
};

export default () => {
  const { path } = useRouteMatch();

  const { loading, error, organizationId } = useEvent();

  if (loading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    if (error?.status === 400 || error?.status === 404) {
      return (
        <HTTP404
          scoped
          ctaText={t`Return to Organization`}
          ctaHref={generatePath(organizationPath, { organizationId })}
        />
      );
    }
    return <HTTP500 />;
  }

  return [
    <Navigation key="nav" />,
    <Content key="body">
      {!error && (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path={[path, `${path}/pages/details`]} exact>
              <Details />
            </Route>
            <Route path={`${path}/privacy`} exact>
              <Privacy />
            </Route>
            <Route path={[`${path}/people`, `${path}/pages/people`]} exact>
              <People />
            </Route>
            <Route path={[`${path}/people/:peopleId`, `${path}/pages/people/:peopleId`]} exact>
              <Profile />
            </Route>
            <Route path={[`${path}/sessions/details/:sessionId`, `${path}/sessions/:sessionId`]}>
              <Session />
            </Route>
            <Route path={[`${path}/sessions`, `${path}/pages/sessions`]} exact>
              <Sessions />
            </Route>
            <Route path={[`${path}/companies`, `${path}/pages/companies`]} exact>
              <Companies />
            </Route>
            <Route
              path={[`${path}/companies/:companyId`, `${path}/pages/companies/:companyId`]}
              exact
            >
              <Company />
            </Route>
            <Route path={[`${path}/documents`, `${path}/pages/documents`]} exact>
              <Documents />
            </Route>
            <Route path={[`${path}/videos`, `${path}/pages/videos`]} exact>
              <Videos />
            </Route>
            <Route path={[`${path}/surveys`, `${path}/pages/surveys`]} exact>
              <Surveys />
            </Route>
            <Route path={[`${path}/surveys/:surveyId`, `${path}/pages/surveys/:surveyId`]} exact>
              <Survey />
            </Route>
            <Route path={`${path}/ars/settings`} exact>
              <AudienceResponse />
            </Route>
            <Route path={`${path}/designer-pages`} exact>
              <WidgetPages />
            </Route>
            <Route path={`${path}/designer-pages/:pageId/templates`} exact>
              <WidgetPagesTemplates />
            </Route>
            <Route path={`${path}/designer-pages/:pageId/templates/:templateId`} exact>
              <WidgetPagesPreview />
            </Route>
            <Route path={`${path}/designer-pages/:pageId`}>
              <WidgetPagesDesigner />
            </Route>
            <Route path={`${path}/event-app/login-page`} exact>
              <LoginPage />
            </Route>
            <Route path={`${path}/event-app/home-page`} exact>
              <HomePage />
            </Route>
            <Route path={`${path}/event-app/sections`} exact>
              <Sections />
            </Route>
            <Route path={`${path}/event-app/discussions`} exact>
              <Discussions />
            </Route>
            <Route path={`${path}/event-app/activity-feed`} exact>
              <ActivityFeed />
            </Route>
            <Route path={`${path}/event-app/banner-ads`} exact>
              <BannerAd />
            </Route>
            <Route path={`${path}/event-app/studio`} exact>
              <Studio />
            </Route>
            <Route path={`${path}/event-app/settings`} exact>
              <Settings />
            </Route>
            <Route path={`${path}/badge-designer`} exact>
              <Badges />
            </Route>
            <Route path={`${path}/badge-designer/badge-order`} exact>
              <BadgeOrder />
            </Route>
            <Route path={`${path}/badge-designer/badge-order/success`} exact>
              <BadgeOrderSuccess />
            </Route>
            <Route path={`${path}/badge-designer/:badgeId`} exact>
              <BadgeDesigner />
            </Route>
            <Route path={`${path}/badge-designer/:badgeId/templates`} exact>
              <BadgeTemplates />
            </Route>
            <Route path={`${path}/gamification`} exact>
              <Gamification />
            </Route>
            <Route path={[`${path}/live-display`, `${path}/pages/live-display`]} exact>
              <LiveDisplay />
            </Route>
            {/* TODO: EXP-16988 - Remove announcements old route */}
            <Route path={`${path}/announcements`} exact>
              <Announcements />
            </Route>
            <Route path={`${path}/onsite/check-in`} exact>
              <Onsite />
            </Route>
            <Route path={`${path}/onsite/lead-capture`} exact>
              <LeadCapture />
            </Route>
            <Route path={`${path}/website-builder`} exact>
              <WebsiteBuilder />
            </Route>
            <Route path={`${path}/website-builder/pages/:pageId/templates`} exact>
              <SelectPageTemplate />
            </Route>
            <Route path={`${path}/communications/emails`} exact>
              <CommunicationEmails />
            </Route>
            <Route path={`${path}/communications/announcements`} exact>
              <Announcements />
            </Route>
            <Route path={`${path}/communications/emails/:emailId`} exact>
              <CommunicationEmailDetails />
            </Route>
            <Route path={`${path}/communications/emails/:emailId/templates`} exact>
              <SelectEmailTemplate />
            </Route>
            <Route path={`${path}/communications/emails/templates/:emailDesignId/designs`} exact>
              <SelectTemplateDesign />
            </Route>
            <Route path={`${path}/communications/emails/templates/:emailDesignId/editor`} exact>
              <EmailEditor />
            </Route>
            <Route path={`${path}/communications/emails/:emailId/analytics`}>
              <EmailAnalytics />
            </Route>
            <Route path={[`${path}/integrations`, `${path}/pages/integrations`]} exact>
              <Integration />
            </Route>
            <Route path={`${path}/analytics`} exact>
              <Analytics />
            </Route>
            <Route path={`${path}/audit-log`} exact>
              <Audit />
            </Route>
            <Route path={`${path}/configuration`} exact>
              <Configuration />
            </Route>
            <Route path={`${path}/maps`} exact>
              <Maps />
            </Route>
            <Route path={`${path}/maps/:mapId/image`}>
              <ImageMap />
            </Route>
            <Route path={`${path}/maps/:mapId/google`}>
              <GoogleMap />
            </Route>
            {/* TODO: EXP-17450 - Remove once the website builder has been launched */}
            <Route path={`${path}/registration/studio`}>
              <RegistrationStudioOld />
            </Route>
            <Route path={`${path}/registration/email/edit/:emailId`}>
              <ConfirmationEmailEditor />
            </Route>
            <Route path={`${path}/registration/email`}>
              <RegistrationEmails />
            </Route>
            <Route path={`${path}/registration/orders`} exact>
              <RegistrationOrders />
            </Route>
            <Route path={`${path}/registration/orders/:orderId`}>
              <RegistrationOrder />
            </Route>
            <Route path={`${path}/registration/details`}>
              <RegistrationDetails />
            </Route>
            <Route path={`${path}/registration/promo-codes/:promoCodeId`}>
              <RegistrationPromoCode />
            </Route>
            <Route path={`${path}/registration/promo-codes`}>
              <RegistrationPromoCodes />
            </Route>
            <Route path={`${path}/registration/form`}>
              <RegistrationForm />
            </Route>
            <Route path={`${path}/registration/tickets`} exact>
              <RegistrationTickets />
            </Route>
            <Route path={`${path}/registration/tickets/:ticketId`}>
              <RegistrationTicketDetails />
            </Route>
            <Route path={`${path}/website-builder/details`}>
              <WebsiteBuilder />
            </Route>
            <Route path={`${path}/website-builder/templates/:pageDesignId/editor`} exact>
              <WebsiteBuilderEditor />
            </Route>
            <Route path="*">
              <HTTP404 scoped ctaText={null} />
            </Route>
          </Switch>
        </Suspense>
      )}
    </Content>,
  ];
};
