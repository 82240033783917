import { Button, Table as AntTable, Space, Divider } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { t, ngettext, msgid } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import DeleteModal from 'common/components/Modal/DeleteModal';
import ShowMore from 'common/components/ShowMore';
import { PAGE_SIZE } from 'common/hooks/useTable';

import SearchFilter, { SearchFilterIcon } from '@components/SearchFilter';
import usePermission, { DELETE_ORGANIZERS, UPDATE_ORGANIZERS } from '@hooks/usePermission';

import { ROLE_LABEL } from './constants';
import { remove as removeFn, ADMIN_ID, COLLABORATOR_ID } from './service';

const { Column } = AntTable;

const Table = ({
  dataSource,
  events,
  loading,
  onRowSelection,
  selectedRowKeys,
  onDelete,
  onEdit,
}) => {
  const rowSelection = {
    columnWidth: 50,
    selectedRowKeys,
    onChange: onRowSelection,
  };
  const { authorized, authorizedAny } = usePermission();
  const sorter = (row1, row2, fieldName) => {
    return row1[fieldName]?.localeCompare(row2[fieldName]);
  };

  const onFilter = (value, record, fieldName) =>
    record[fieldName]?.toString().toLowerCase().includes(value?.toLowerCase());

  return (
    <AntTable
      rowKey="id"
      pagination={{
        showTotal: (total, [from, to]) => t`${from}-${to} of ${total} items`,
        showSizeChanger: false,
        pageSize: PAGE_SIZE,
      }}
      dataSource={dataSource}
      tableLayout="auto"
      loading={loading}
      rowSelection={authorized(DELETE_ORGANIZERS) && rowSelection}
    >
      <Column
        title={t`First Name`}
        dataIndex="firstName"
        sorter={(a, b) => sorter(a, b, 'firstName')}
        filterDropdown={SearchFilter}
        filterIcon={SearchFilterIcon}
        onFilter={(value, record) => onFilter(value, record, 'firstName')}
      />
      <Column
        title={t`Last Name`}
        dataIndex="lastName"
        sorter={(a, b) => sorter(a, b, 'lastName')}
        filterDropdown={SearchFilter}
        filterIcon={SearchFilterIcon}
        onFilter={(value, record) => onFilter(value, record, 'lastName')}
      />
      <Column
        title={t`Email`}
        dataIndex="email"
        sorter={(a, b) => sorter(a, b, 'email')}
        defaultSortOrder="ascend"
        filterDropdown={SearchFilter}
        filterIcon={SearchFilterIcon}
        onFilter={(value, record) => onFilter(value, record, 'email')}
      />
      <Column
        title={t`Role`}
        dataIndex="roleId"
        filters={[ADMIN_ID, COLLABORATOR_ID].map(roleId => ({
          text: ROLE_LABEL()[roleId],
          value: roleId,
        }))}
        onFilter={(value, record) => onFilter(value, record, 'roleId')}
        render={roleId => ROLE_LABEL()[roleId]}
      />
      <Column
        title={t`Event Access`}
        dataIndex="eventIds"
        filters={[{ text: t`All Events`, value: 'all' }].concat(
          events.map(({ shortcode, id }) => ({ text: shortcode, value: id }))
        )}
        onFilter={(value, record) => record.eventIds?.includes(value)}
        render={(eventIds, record) => {
          if (!eventIds || eventIds[0] === 'all' || record.roleId === ADMIN_ID) {
            return t`All Events`;
          }

          const organizerEvents = events
            .filter(({ id }) => eventIds.includes(id))
            .map(({ shortcode, id }) => ({ name: shortcode, id }));

          return <ShowMore items={organizerEvents} isTag />;
        }}
      />
      {authorizedAny(UPDATE_ORGANIZERS, DELETE_ORGANIZERS) && (
        <Column
          title={t`Action`}
          render={(_, record) => (
            <Space size="small" split={<Divider type="vertical" />}>
              {authorized(UPDATE_ORGANIZERS) && (
                <Button type="link" onClick={() => onEdit(record)} style={{ padding: 0 }}>
                  {t`Edit`}
                </Button>
              )}
              {authorized(DELETE_ORGANIZERS) && (
                <Button
                  type="link"
                  danger
                  onClick={() => onDelete(record?.id)}
                  style={{ padding: 0 }}
                >
                  {t`Delete`}
                </Button>
              )}
            </Space>
          )}
        />
      )}
    </AntTable>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  events: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  onRowSelection: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

const Active = ({ organizationId, events, list, loading, onDelete, onEdit }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const handleDelete = keys => {
    const item = keys.length === 1 && list.find(({ id }) => id === keys[0]);
    DeleteModal.confirm({
      label: {
        singular: t`Organizer`,
        plural: t`Organizers`,
      },
      itemName: item && `${item.firstName} ${item.lastName}`,
      count: keys.length,
      additionalInfo: t`This will instantly remove their access and they will no longer have any editing capabilities.`,
      async onDelete() {
        await Promise.all(keys.map(key => removeFn(organizationId, key)));
        onDelete();
        setSelectedRows(selectedRows.filter(v => !keys.includes(v)));
      },
    });
  };

  return (
    <>
      <Table
        dataSource={list}
        events={events}
        loading={loading}
        onRowSelection={keys => setSelectedRows([...keys])}
        selectedRowKeys={selectedRows}
        onDelete={key => handleDelete([key])}
        onEdit={onEdit}
      />

      <DeleteBar
        style={{ left: 0 }}
        open={selectedRows.length > 0}
        buttonText={ngettext(
          msgid`Delete ${selectedRows.length} Organizer`,
          `Delete ${selectedRows.length} Organizers`,
          selectedRows.length
        )}
        onClick={() => handleDelete(selectedRows)}
      />
    </>
  );
};

Active.propTypes = {
  organizationId: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(Object).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
};

export default Active;
