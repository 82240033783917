import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/sso`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

const idpApi = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/sso/idp`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

export const get = async organizationId => {
  return api(organizationId)
    .get()
    .then(response => response.data.data);
};

export const setup = async organizationId => {
  return api(organizationId)
    .post()
    .then(response => response.data.data);
};

export const list = async (organizationId, payload = {}, query = '', include = []) => {
  const params = new URLSearchParams(query);

  if (include.length > 0) {
    params.set('include', include.join(','));
  }

  if (payload.offset > 0) {
    params.set('page', payload.offset / payload.limit);
  }

  return idpApi(organizationId)
    .get(null, { params })
    .then(response => response.data);
};

export const create = async (organizationId, payload) => {
  return idpApi(organizationId)
    .post(null, payload)
    .then(response => response.data.data);
};

export const update = async (organizationId, connectionId, payload) => {
  return idpApi(organizationId)
    .patch(connectionId, payload)
    .then(response => response.data.data);
};

export const remove = async (organizationId, idpId) => {
  return idpApi(organizationId)
    .delete(idpId)
    .then(response => response.data.data);
};
