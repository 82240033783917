import React from 'react';
import { Route } from 'react-router-dom';

import ForgotPassword from 'common/components/Auth/ForgotPassword';
import { forgotPassword as forgotPasswordFn } from 'common/services/auth/api';

import Login from './Login';
import ResetPassword from './ResetPassword';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { AUTH_APP } from './service';

// We need to export an array of routes, otherwise react-router will use the
// first component that it sees in the switch, and the error routes won't match.
// In components where routes are nested under a path (/parent/a, /parent/b),
// we can use a nested switch
const routes = [
  <Route key="login" path="/login" exact>
    <Login />
  </Route>,
  <Route key="forgot-password" path="/forgot-password">
    <ForgotPassword
      forgotPasswordFn={payload => forgotPasswordFn({ ...payload, type: AUTH_APP })}
    />
  </Route>,
  <Route key="reset-password" path={['/reset-password', '/reset-password-onsite']}>
    <ResetPassword />
  </Route>,
  <Route key="reset-password-success" path="/reset-password-success">
    <ResetPasswordSuccess />
  </Route>,
];

export default routes;
