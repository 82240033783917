import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Typography } from 'antd';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { msgid, ngettext, t } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import Empty from 'common/components/Empty';
import ShowMore from 'common/components/ShowMore';
import useSearchParams from 'common/hooks/useSearchParams';

import EmptyImage from '@assets/images/illustrations/shop-delivery-service-online-retail.svg';
import usePermission, {
  CREATE_PAYMENT_GATEWAY,
  ARCHIVE_PAYMENT_GATEWAY,
  UPDATE_PAYMENT_GATEWAY,
  VERIFY_PAYMENT_GATEWAY,
} from '@hooks/usePermission';

import { useEventsWithFeatureQuery } from '../queries';

import AddModal from './AddModal';
import { CONNECTION_STATUS } from './constants';
import EditModal from './EditModal';
import LinkEventsModal from './LinkEventsModal';
import { useCheckStatusMutation, useConnectionsTableQuery, useSupportedGateways } from './queries';
import useArchiveConnections from './useArchiveConnections';

const Base = styled.div`
  margin-top: 20px;

  /* accommodate deleteBar component's height */
  margin-bottom: 40px;
`;

const MODAL_TYPES = {
  Add: 'add',
  Edit: 'edit',
  LinkEvents: 'linkEvents',
};

const Index = ({ active }) => {
  const urlParams = useSearchParams();

  // We don't need to handle usePermission.loading here; the parent page is
  // already blocked on it
  const { authorized, authorizedAny } = usePermission();

  const { mutate: mutateCheckStatus } = useCheckStatusMutation();
  const { data: supportedGateways, isLoading: isSupportedGatewaysLoading } = useSupportedGateways();
  const {
    data: eventsWithPayments,
    isLoading: isEventsWithPaymentsLoading,
  } = useEventsWithFeatureQuery('reg_payments');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const clearSelectedRowKeys = useCallback(() => setSelectedRowKeys([]), []);

  const [modalState, setModalState] = useState(null);
  const handleAdd = useCallback(() => setModalState({ type: MODAL_TYPES.Add }), []);
  const handleEdit = useCallback(
    connection => setModalState({ type: MODAL_TYPES.Edit, connection }),
    []
  );
  const handleLinkEvents = useCallback(
    connection => setModalState({ type: MODAL_TYPES.LinkEvents, connection }),
    []
  );
  const handleModalClose = useCallback(() => setModalState(null), []);

  const handleArchiveConnections = useArchiveConnections(clearSelectedRowKeys);

  const columns = useMemo(
    () => [
      {
        title: t`Name`,
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: t`Gateway`,
        dataIndex: 'type',
        width: 250,
        render: type =>
          supportedGateways?.find(({ gatewayType }) => gatewayType === type)?.name ??
          t`Unknown Gateway`,
      },
      {
        title: t`Linked Events`,
        dataIndex: 'events',
        render: events => <ShowMore items={events} isTag />,
        width: 250,
        filters: eventsWithPayments
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id, name }) => ({ text: name, value: id })),
      },
      {
        title: t`Created Date`,
        dataIndex: 'createdAt',
        sorter: true,
        render: date => format(parseISO(date), 'PP'),
        width: 250,
      },
      {
        title: t`Status`,
        dataIndex: 'status',
        render: (_, { status, lastCheckedAt }) =>
          status === CONNECTION_STATUS.Processing ? (
            <Typography.Text type="secondary">{t`Processing...`}</Typography.Text>
          ) : (
            <Space direction="vertical">
              {status === CONNECTION_STATUS.Active && (
                <Tag color="success" icon={<CheckCircleOutlined />}>{t`Active`}</Tag>
              )}
              {status === CONNECTION_STATUS.Inactive && (
                <Tag color="error" icon={<CloseCircleOutlined />}>{t`Inactive`}</Tag>
              )}
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {t`Checked on:`} {format(parseISO(lastCheckedAt), 'PP')}
              </Typography.Text>
            </Space>
          ),
        width: 250,
        filters: Object.entries(CONNECTION_STATUS).map(([text, value]) => ({ text, value })),
      },
      {
        title: t`Action`,
        render: row => (
          <Space direction="vertical">
            {authorized(UPDATE_PAYMENT_GATEWAY) && (
              <Space>
                <Button type="link" onClick={() => handleEdit(row)}>{t`Edit`}</Button>
                <Button
                  type="link"
                  onClick={() => handleLinkEvents(row)}
                >{t`Linked Events`}</Button>
              </Space>
            )}
            {authorizedAny(VERIFY_PAYMENT_GATEWAY, ARCHIVE_PAYMENT_GATEWAY) && (
              <Space>
                {authorized(VERIFY_PAYMENT_GATEWAY) && (
                  <Button
                    type="link"
                    disabled={row.status === CONNECTION_STATUS.Processing}
                    onClick={() => mutateCheckStatus({ gatewayId: row.id })}
                  >{t`Check Status`}</Button>
                )}
                {authorized(ARCHIVE_PAYMENT_GATEWAY) && (
                  <Button
                    type="link"
                    danger
                    onClick={() => handleArchiveConnections(row)}
                  >{t`Delete`}</Button>
                )}
              </Space>
            )}
          </Space>
        ),
        width: 250,
      },
    ],
    [
      supportedGateways,
      eventsWithPayments,
      handleEdit,
      handleLinkEvents,
      handleArchiveConnections,
      mutateCheckStatus,
      authorized,
      authorizedAny,
    ]
  );

  const {
    tableProps,
    query: { data, isLoading: isTableLoading },
  } = useConnectionsTableQuery(
    { enabled: active && !!supportedGateways },
    {
      columns,
      rowKey: 'id',
      // If you aren't allowed to delete anything, there's no reason to select items
      rowSelection: authorized(ARCHIVE_PAYMENT_GATEWAY)
        ? {
            columnWidth: 50,
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }
        : undefined,
    }
  );

  const isLoading = isEventsWithPaymentsLoading || isSupportedGatewaysLoading || isTableLoading;
  const hasFilter = [...urlParams.keys()].some(key => key.startsWith('filter'));
  const showEmpty = !isLoading && !data?.data.length && !hasFilter;

  if (!active) {
    return null;
  }

  return (
    <Base>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        {authorized(CREATE_PAYMENT_GATEWAY) && (
          <Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
              {t`Add Connection`}
            </Button>
          </Space>
        )}

        {showEmpty && (
          <Empty
            image={EmptyImage}
            title={t`Start Accepting Payments`}
            body={t`Once you connect a Payment Gateway, you can start receiving payments.`}
          />
        )}

        {!showEmpty && <Table {...tableProps} loading={isLoading} />}
      </Space>

      {modalState?.type === MODAL_TYPES.Add && <AddModal onClose={handleModalClose} />}
      {modalState?.type === MODAL_TYPES.Edit && (
        <EditModal
          onClose={handleModalClose}
          onDelete={clearSelectedRowKeys}
          connection={modalState.connection}
        />
      )}
      {modalState?.type === MODAL_TYPES.LinkEvents && (
        <LinkEventsModal onClose={handleModalClose} connection={modalState.connection} />
      )}
      <DeleteBar
        open={selectedRowKeys.length > 0}
        buttonText={ngettext(
          msgid`Delete 1 Gateway`,
          `Delete ${selectedRowKeys.length} Gateways`,
          selectedRowKeys.length
        )}
        onClick={() =>
          handleArchiveConnections(
            selectedRowKeys.length > 1
              ? selectedRowKeys
              : data.data.find(row => row.id === selectedRowKeys[0])
          )
        }
        style={{ left: 0 }}
      />
    </Base>
  );
};

Index.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Index;
