function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
export var ActionType;
(function(ActionType) {
    ActionType[ActionType["Undo"] = 0] = "Undo";
    ActionType[ActionType["Redo"] = 1] = "Redo";
    ActionType[ActionType["UpdateRemovedWidgets"] = 2] = "UpdateRemovedWidgets";
})(ActionType || (ActionType = {}));
export default function undoable(reducer) {
    var skip = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : function() {
        return false;
    }, ignoreProps = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    // Call the reducer with empty action to populate the initial state
    var initialState = {
        past: [],
        present: reducer(undefined, {}),
        future: [],
        type: ""
    };
    // Return a reducer that handles undo and redo
    return function() {
        var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : initialState, action = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : undefined;
        var past = state.past, present = state.present, future = state.future, prevType = state.type;
        var type = action.type, payload = action.payload;
        switch(type){
            case ActionType.Undo:
                {
                    var previous = past[past.length - 1];
                    previous.history = present.history;
                    var newPast = past.slice(0, past.length - 1);
                    return {
                        past: newPast,
                        present: previous,
                        future: [
                            present
                        ].concat(_toConsumableArray(future))
                    };
                }
            case ActionType.Redo:
                {
                    var next = future[0];
                    next.history = present.history;
                    var newFuture = future.slice(1);
                    return {
                        past: _toConsumableArray(past).concat([
                            present
                        ]),
                        present: next,
                        future: newFuture
                    };
                }
            case ActionType.UpdateRemovedWidgets:
                {
                    var update = function(items) {
                        return items.map(function(item) {
                            var widgets = item.widgets.map(function(widget) {
                                var exist = payload.widgets.find(function(param) {
                                    var id = param.id;
                                    return id === widget.id;
                                });
                                if (!exist) {
                                    return _objectSpreadProps(_objectSpread({}, widget), {
                                        deleted: true
                                    });
                                }
                                return widget;
                            });
                            return _objectSpreadProps(_objectSpread({}, item), {
                                widgets: widgets
                            });
                        });
                    };
                    return _objectSpreadProps(_objectSpread({}, state), {
                        past: _toConsumableArray(update(state.past)),
                        future: _toConsumableArray(update(state.future))
                    });
                }
            default:
                {
                    var oldPresent = cloneDeep(omit(present, ignoreProps));
                    var newPresent = reducer(present !== null && present !== void 0 ? present : state, action);
                    if (present === newPresent) {
                        return state;
                    }
                    if (skip({
                        type: type,
                        prevType: prevType
                    })) {
                        return {
                            type: type,
                            past: past,
                            present: newPresent,
                            future: future
                        };
                    }
                    return {
                        type: type,
                        past: _toConsumableArray(past !== null && past !== void 0 ? past : []).concat([
                            oldPresent
                        ]),
                        present: newPresent,
                        future: []
                    };
                }
        }
    };
}
