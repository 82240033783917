import { t } from 'ttag';

const EVENT_LANGUAGES = () => [
  {
    label: t`Arabic`,
    value: 'arabic',
  },
  {
    label: t`Chinese`,
    value: 'mandarin',
  },
  {
    label: t`Chinese (Traditional)`,
    value: 'chinese(trad)',
  },
  {
    label: t`Czech`,
    value: 'czech',
  },
  {
    label: t`Danish`,
    value: 'danish',
  },
  {
    label: t`Dutch`,
    value: 'dutch',
  },
  {
    label: t`English (US)`,
    value: 'english',
  },
  {
    label: t`English (UK)`,
    value: 'english(uk)',
  },
  {
    label: t`Estonian`,
    value: 'estonian',
  },
  {
    label: t`Finnish`,
    value: 'finnish',
  },
  {
    label: t`French`,
    value: 'french',
  },
  {
    label: t`French (CA)`,
    value: 'french(ca)',
  },
  {
    label: t`German (formal)`,
    value: 'german',
  },
  {
    label: t`Hungarian`,
    value: 'hungarian',
  },
  {
    label: t`Indonesian`,
    value: 'indonesian',
  },
  {
    label: t`Italian`,
    value: 'italian',
  },
  {
    label: t`Japanese`,
    value: 'japanese',
  },
  {
    label: t`Korean`,
    value: 'korean',
  },
  {
    label: t`Norwegian`,
    value: 'norwegian',
  },
  {
    label: t`Polish`,
    value: 'polish',
  },
  {
    label: t`Portuguese`,
    value: 'portuguese',
  },
  {
    label: t`Portuguese (Brazilian)`,
    value: 'portuguese(brazilian)',
  },
  {
    label: t`Russian`,
    value: 'russian',
  },
  {
    label: t`Spanish`,
    value: 'spanish',
  },
  {
    label: t`Swedish`,
    value: 'swedish',
  },
  {
    label: t`Turkish`,
    value: 'turkish',
  },
];

const MULTI_EVENT_APP_LANGUAGES = () => [
  {
    label: t`Arabic`,
    value: 'ar',
  },
  {
    label: t`Catalan`,
    value: 'ca_ES',
  },
  {
    label: t`Chinese (Simplified)`,
    value: 'zh',
  },
  {
    label: t`Chinese (Traditional)`,
    value: 'zh-Hant',
  },
  {
    label: t`Danish`,
    value: 'da',
  },
  {
    label: t`Dutch`,
    value: 'nl',
  },
  {
    label: t`English (US)`,
    value: 'en',
  },
  {
    label: t`English (UK)`,
    value: 'en_GB',
  },
  {
    label: t`Finnish`,
    value: 'fi',
  },
  {
    label: t`French`,
    value: 'fr',
  },
  {
    label: t`French (CA)`,
    value: 'fr_CA',
  },
  {
    label: t`German (DE)`,
    value: 'de_DE',
  },
  {
    label: t`Indonesian`,
    value: 'id',
  },
  {
    label: t`Italian`,
    value: 'it',
  },
  {
    label: t`Japanese`,
    value: 'ja',
  },
  {
    label: t`Korean`,
    value: 'ko',
  },
  {
    label: t`Polish`,
    value: 'pl',
  },
  {
    label: t`Portuguese`,
    value: 'pt',
  },
  {
    label: t`Portuguese (BR)`,
    value: 'pt_BR',
  },
  {
    label: t`Russian`,
    value: 'ru',
  },
  {
    label: t`Spanish`,
    value: 'es',
  },
  {
    label: t`Swedish`,
    value: 'sv',
  },
  {
    label: t`Turkish`,
    value: 'tr',
  },
];

export const list = (sorted = true, locale = 'en') => {
  const src = EVENT_LANGUAGES();

  if (!sorted) {
    return src;
  }

  if (sorted && (typeof locale === 'undefined' || locale === null)) {
    throw new Error('locale is require for sorting');
  }

  return src.sort((first, second) =>
    first.label.localeCompare(second.label, locale, { sensitivity: 'accent' })
  );
};

export const listMEA = () => MULTI_EVENT_APP_LANGUAGES();
