import { toQuery } from 'common/hooks/query-service';
import { list as getPeopleFn } from 'common/services/people/api';

import { APPLY_TO_GROUPS, APPLY_TO_PEOPLE, APPLY_TO_ROLES } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const list = async (eventId, badge, { token, limit, offset = 0, sort, search }) => {
  const filter = {};
  switch (badge.appliedTo.peopleEntityType) {
    case APPLY_TO_PEOPLE:
      filter.id = badge.appliedTo.peopleEntityIds;
      break;
    case APPLY_TO_GROUPS:
      filter.groupId = badge.appliedTo.peopleEntityIds;
      break;
    case APPLY_TO_ROLES:
      filter.sessionRoleId = badge.appliedTo.peopleEntityIds;
      break;
    default:
  }

  const query = toQuery({ filter, search, sort: { field: sort, order: 'ascend' } });
  // if limit=0, that means we only need `total` - so no need to request `custom_fields`
  const include = limit === 0 ? [] : ['custom_fields'];
  const res = await getPeopleFn(eventId, { limit, offset }, query, include, token);

  return {
    total: res.meta.pagination.totalItemsCount,
    data: res.data,
  };
};
