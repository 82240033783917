import { useCallback, useEffect, useState } from 'react';

import useInterval from './useInterval';

const usePolling = (
  initialValue,
  pollingFn,
  frequency,
  { setInterval, clearInterval } = window
) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [actualFrequency, setActualFrequency] = useState(frequency);

  const pause = () => {
    setActualFrequency(null);
  };

  const fn = useCallback(
    () =>
      pollingFn()
        .then(items => {
          setError(null);
          setValue(items);
        })
        .catch(err => setError(err)),
    [pollingFn]
  );

  const resume = async () => {
    setActualFrequency(frequency);
    await fn();
  };

  // poll the function "immediately" for the first time
  useEffect(() => {
    fn();
  }, [fn]);

  // keep polling the function
  useInterval(fn, actualFrequency, { setInterval, clearInterval });

  return { value, setValue, error, pause, resume };
};

export default usePolling;
