import { Typography, Switch as AntSwitch, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const { Text } = Typography;

const Switch = ({ label, checked: source = false, onChange = () => {}, ...props }) => {
  const [checked, setChecked] = useState(source);

  useEffect(() => {
    if (source !== checked) {
      setChecked(source);
    }
    // TODO Remove the eslint-disable and fix the problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const handler = (value, event) => {
    if (props.disabled) return;
    setChecked(value);

    onChange(value, event);
  };

  return (
    <Space>
      <AntSwitch {...props} onChange={(value, event) => handler(value, event)} checked={checked} />
      <Text onClick={event => handler(!checked, event)}>{label}</Text>
    </Space>
  );
};

Switch.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
