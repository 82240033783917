import {
  EyeOutlined,
  EyeInvisibleOutlined,
  UserOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Comment as AntComment,
  Row,
  Typography,
  Affix as AntAffix,
  Alert,
  Image as AntImage,
  Carousel as AntCarousel,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import TimeAgo from '@components/TimeAgo';
import slashBg from '@images/slash-bg.svg';

const { Paragraph: AntParagraph, Text: AntText } = Typography;

const CommentContainer = styled(Col)`
  border: 1px solid #f0f0f0;
  padding-left: 10px;
  ${({ $isHidden }) =>
    $isHidden &&
    `
     background-image: url(${slashBg});
     color:#8c8c8c;
     background-color: #F5F5F5;
  `}
`;

const Comment = styled(AntComment)`
  background: none;
`;

const Paragraph = styled(AntParagraph)`
  margin-top: 15px;
  max-width: 85%;
  ${({ $isHidden }) => $isHidden && `color: #bfbfbf`}
`;
const CommentDate = styled(AntText)`
  color: rgba(0, 0, 0, 0.45);
`;
const Affix = styled(AntAffix)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
const Image = styled(AntImage)`
  max-height: 300px;
  max-width: 1001px;
`;
const ClockCircleOutlinedIcon = styled(ClockCircleOutlined)`
  padding-right: 5px;
`;
const Carousel = styled(AntCarousel)`
  background: black;
  max-width: 300px;
  max-height: 300px;

  &.slick-slider {
    ${({ $isHidden }) => $isHidden && 'opacity: 0.6'}
  }
  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-track .slick-slide {
    display: flex;
    justify-content: inherit;
    align-items: inherit;
  }
  .slick-dots li button {
    height: 5px;
  }
`;

const Post = ({
  isHidden,
  comment,
  contentItems,
  authorImageUrl,
  authorName,
  date,
  hideShowInProgress,
  onHideShow,
  error,
  onErrorReload,
}) => {
  const images = contentItems?.filter(({ image }) => image).map(({ image }) => image);
  return (
    <>
      <Row>
        <CommentContainer $isHidden={isHidden} span={24}>
          <Comment
            author={authorName}
            avatar={
              authorImageUrl ? (
                <Avatar src={authorImageUrl} alt={authorName} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )
            }
            content={
              <>
                {comment && <Paragraph $isHidden={isHidden}>{comment}</Paragraph>}
                {!!images?.length && (
                  <Image.PreviewGroup>
                    {/* Carousel passes `infinite` down to react-slick lib */}
                    <Carousel $isHidden={isHidden} infinite={false}>
                      {images.map(image => (
                        <Image key={image.id} src={image.fullSizeUrl} />
                      ))}
                    </Carousel>
                  </Image.PreviewGroup>
                )}
              </>
            }
            datetime={
              <CommentDate>
                <ClockCircleOutlinedIcon />
                <TimeAgo time={date} />
              </CommentDate>
            }
          />
          <Affix offsetTop={10}>
            <Button
              size="small"
              type="default"
              icon={isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={onHideShow}
              loading={hideShowInProgress}
            >
              {isHidden ? t`Show` : t`Hide`}
            </Button>
          </Affix>
        </CommentContainer>
      </Row>
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          action={
            <Button size="small" type="link" onClick={onErrorReload}>
              {t`Reload`}
            </Button>
          }
        />
      )}
    </>
  );
};

Post.propTypes = {
  isHidden: PropTypes.bool,
  comment: PropTypes.string,
  contentItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string.isRequired,
        fullSizeUrl: PropTypes.string.isRequired,
      }),
    })
  ),
  authorImageUrl: PropTypes.string,
  authorName: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  hideShowInProgress: PropTypes.bool,
  onHideShow: PropTypes.func,
  error: PropTypes.string,
  onErrorReload: PropTypes.func,
};

export default Post;
