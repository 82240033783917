import { Alert, Button, Col, Form, Modal as AntModal, Row, Radio, message, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { t, jt } from 'ttag';

import Label from 'common/components/Label';
import { getStaffId } from 'common/state/user';

import Switch from '@components/Switch';

import { create as createFn, getStaffDetail as getStaffDetailFn } from './builds-service';

const RadioButton = styled(Radio.Button)`
  width: 105px;
  text-align: center;
`;

export const MAJOR_VERSION = 6;
export const MINOR_VERSION = 0;

const { Item, useForm } = Form;
const PLATFORM_IOS = 'ios';
const PLATFORM_ANDROID = 'android';
const ENG_STAFF_GROUP_ID = '575fb69c-da9c-4d10-a959-839c82a43a67';

const getPlatformName = platform =>
  ({
    [PLATFORM_IOS]: t`iOS`,
    [PLATFORM_ANDROID]: t`Android`,
  }[platform] ?? '');

const MISSING_FIELDS_ERROR_CODE = 'native_application_build_incomplete_build_credentials';

const Modal = ({ onClose, organizationId, nativeAppId, platform, version, publish }) => {
  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isEngStaff, setIsEngStaff] = useState(false);
  const staffId = useSelector(getStaffId);
  const [form] = useForm();
  const { validateFields } = form;

  useEffect(() => {
    const effect = async () => {
      const staff = await getStaffDetailFn(staffId);
      setIsEngStaff(staff.staffGroupIds?.includes(ENG_STAFF_GROUP_ID));
    };

    if (staffId) {
      effect();
    }
  }, [staffId]);

  const onSave = async () => {
    setError(null);
    const { buildVersion: patchVersion, ...payload } = await validateFields();
    try {
      setSaving(true);

      await createFn(organizationId, nativeAppId, {
        ...payload,
        buildVersion: `${MAJOR_VERSION}.${MINOR_VERSION}.${patchVersion}`,
      });
      message.success(t`Successfully created build`);
      onClose(true);
    } catch (e) {
      if (e?.errors?.[0]?.code === MISSING_FIELDS_ERROR_CODE) {
        const link = (
          <Link
            key="link_build_conf"
            to={{
              search: '?tab=conf',
              state: {
                missingFields: e?.errors?.[0]?.message?.split(','),
              },
            }}
            onClick={() => onClose(false)}
          >
            {t`Go to Build Configuration`}
          </Link>
        );
        const platformName = getPlatformName(payload.buildPlatform);
        setError(
          jt`Missing Build Configuration details for ${platformName}. Please fix the missing details in the Build Configuration tab before generating the build. ${link}`
        );
      } else {
        setError(t`An unknown error occurred while creating build.`);
      }
      setSaving(false);
    }
  };

  return (
    <AntModal
      title={t`New Build`}
      open
      maskClosable
      destroyOnClose
      onCancel={() => onClose(false)}
      footer={
        <>
          <Button onClick={() => onClose(false)}>{t`Cancel`}</Button>
          <Button type="primary" loading={saving} disabled={saving} onClick={onSave}>
            {t`Save`}
          </Button>
        </>
      }
      width={700}
    >
      {error && (
        <Row css="margin-bottom: 20px">
          <Col span={24}>
            <Alert type="error" message={error} showIcon />
          </Col>
        </Row>
      )}

      <Form
        layout="vertical"
        name="build"
        requiredMark={false}
        form={form}
        initialValues={{
          buildPlatform: platform,
          buildVersion: version,
          publishToStore: publish,
        }}
      >
        <Item
          name="buildPlatform"
          label={<Label isRequired>{t`Platform`}</Label>}
          rules={[{ required: 'true', message: t`Platform is required` }]}
        >
          <Radio.Group>
            <RadioButton value={PLATFORM_IOS}>{getPlatformName(PLATFORM_IOS)}</RadioButton>
            <RadioButton value={PLATFORM_ANDROID}>{getPlatformName(PLATFORM_ANDROID)}</RadioButton>
          </Radio.Group>
        </Item>
        <Item
          name="buildVersion"
          label={<Label isRequired>{t`App Version`}</Label>}
          rules={[{ required: true, message: t`App Version is required` }]}
        >
          <Input placeholder="0 - 999" />
        </Item>
        {isEngStaff && (
          <Item name="branch" label={<Label>{t`Branch`}</Label>}>
            <Input placeholder="master" />
          </Item>
        )}
        <Item name="publishToStore" valuePropName="checked">
          <Switch label={t`Publish`} />
        </Item>
      </Form>
    </AntModal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  nativeAppId: PropTypes.string.isRequired,
  platform: PropTypes.oneOf([PLATFORM_IOS, PLATFORM_ANDROID]),
  version: PropTypes.number,
  publish: PropTypes.bool,
};

export default Modal;
