function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "No, Cancel"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { Modal as AntModal } from "antd";
import { t } from "ttag";
var confirm = function(_param) {
    var title = _param.title, content = _param.content, onOk = _param.onOk, onCancel = _param.onCancel, cancelText = _param.cancelText, confirmDisabled = _param.confirmDisabled, otherProps = _objectWithoutProperties(_param, [
        "title",
        "content",
        "onOk",
        "onCancel",
        "cancelText",
        "confirmDisabled"
    ]);
    return AntModal.confirm(_objectSpread({
        title: title,
        content: content,
        cancelText: cancelText !== null && cancelText !== void 0 ? cancelText : t(_templateObject()),
        okButtonProps: {
            type: "primary",
            danger: true,
            disabled: confirmDisabled
        },
        onOk: onOk,
        onCancel: onCancel
    }, otherProps));
};
var Modal = {
    confirm: confirm
};
export default Modal;
