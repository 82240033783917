import React from 'react';
import { useHistory } from 'react-router-dom';

import ActivateAccount from 'common/components/Auth/ActivateAccount';
import useSearchParams from 'common/hooks/useSearchParams';

import {
  checkInviteStatus as checkInviteStatusFn,
  acceptInvite as acceptInviteFn,
} from '@domains/Organization/Organizer/service';

export default () => {
  const query = useSearchParams();
  const history = useHistory();
  const token = query.get('token');
  const organizationId = query.get('organization_id');

  return (
    <ActivateAccount
      id={organizationId}
      token={token}
      checkInviteStatusFn={() => checkInviteStatusFn(organizationId, { token })}
      acceptInviteFn={payload => acceptInviteFn(organizationId, { ...payload, token })}
      onActivated={() => history.replace('/organization')}
      onUserAlreadyActivated={() => history.replace('/login')}
    />
  );
};
