import { Table as AntTable, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

const { Text: AntText } = Typography;

const Color = styled.div`
  background-color: ${({ color }) => color};
  width: 15px;
  height: 25px;
`;

const Text = styled(AntText)`
  font-weight: normal;
  @media screen and (min-width: 320px) {
    /*
      fluid typography
      Please refer https://css-tricks.com/simplified-fluid-typography/ for more details
    */
    font-size: ${({ fontSize }) =>
      fontSize ? `${fontSize}px` : 'calc(14px + 6 * ((100vw - 320px) / 680))'};
  }
`;

const Table = styled(AntTable)`
  .ant-table {
    background-color: unset;
  }
  .ant-table-cell {
    padding: 10px;
    color: ${({ color }) => color};
  }
  .ant-typography {
    color: ${({ color }) => color};
  }
  .highlight-row .ant-table-cell,
  .highlight-row .ant-typography {
    font-weight: 800;
  }
`;

const ChartLegend = ({ dataSource, textColor, totalResponses, height, fontSize }) => {
  const maxValue = useMemo(() => {
    return Math.max(...dataSource.map(({ value }) => value));
  }, [dataSource]);
  const columns = [
    {
      dataIndex: 'color',
      render: color => <Color height={height} color={color} />,
    },
    {
      dataIndex: 'label',
      render: label => label && <Text fontSize={fontSize}>{label}</Text>,
    },
    {
      dataIndex: 'title',
      render: title => <Text fontSize={fontSize}>{title}</Text>,
    },
    {
      dataIndex: 'value',
      render: value =>
        value !== undefined && <Text fontSize={fontSize}>{Math.round(value * 10) / 10}%</Text>,
      width: '15%',
    },
    {
      dataIndex: 'count',
      render: count => <Text fontSize={fontSize}>{count}</Text>,
      width: '10%',
    },
  ];
  const legendValues = [
    ...dataSource,
    ...(totalResponses
      ? [
          {
            id: 'totalResponses',
            title: t`Total Responses`,
            count: totalResponses,
          },
        ]
      : []),
  ];
  return (
    <Table
      data-testid="chart-legend-table"
      fontSize={fontSize}
      columns={columns}
      dataSource={legendValues}
      pagination={false}
      showHeader={false}
      color={textColor}
      rowClassName={record => record.value === maxValue && 'highlight-row'}
      rowKey={record => record.id}
    />
  );
};
ChartLegend.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }).isRequired
  ),
  height: PropTypes.number,
  fontSize: PropTypes.number,
  totalResponses: PropTypes.number,
  textColor: PropTypes.string,
};

export default ChartLegend;
