import React from 'react';
import { useHistory } from 'react-router-dom';

import AuthResetPassword from 'common/components/Auth/ResetPassword';
import useSearchParams from 'common/hooks/useSearchParams';
import {
  validateToken as validateTokenFn,
  resetPassword as resetPasswordFn,
} from 'common/services/auth/api';

import { AUTH_APP, AUTH_APP_ONSITE } from './service';

export default function ResetPassword() {
  const history = useHistory();
  const query = useSearchParams();
  const appType = query?.get('app-type') === AUTH_APP ? AUTH_APP : AUTH_APP_ONSITE;

  return (
    <AuthResetPassword
      showReturnToLogin
      validateTokenFn={token => validateTokenFn(token, appType)}
      resetPasswordFn={payload => resetPasswordFn({ ...payload, type: appType })}
      afterReset={() => {
        if (appType !== AUTH_APP) {
          history.replace(`/reset-password-success`);
        } else {
          history.replace(`/`);
        }
      }}
    />
  );
}
