import { message } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { t } from 'ttag';

import usePolling from '@hooks/usePolling';

import { POLLING_FREQUENCY, QUESTION_TYPE_AAQ, QUESTION_TYPE_SURVEY } from '../constants';
import { list as listFn, toggleVisibility as toggleVisibilityFn } from '../service';

const useModeration = (eventId, hash, questionType, questionId, sessionId) => {
  const listFunc = useCallback(async () => {
    const response = await listFn(eventId, hash, questionId, questionType, sessionId);
    return {
      surveyName: questionType === QUESTION_TYPE_SURVEY ? response[0].surveyName : '',
      session: sessionId && {
        name: response[0].sessionName,
        date: response[0].sessionDate,
        startTime: response[0].sessionStartTime,
        endTime: response[0].sessionEndTime,
      },
      dataSource:
        questionType === QUESTION_TYPE_AAQ ? response[0].responses : response[0].textAnswers,
    };
  }, [eventId, hash, questionId, questionType, sessionId]);
  const {
    value: { surveyName, session, dataSource },
    error,
    pause,
    resume,
  } = usePolling({ surveyName: null, dataSource: null }, listFunc, POLLING_FREQUENCY);
  const hideLiveUpdatesMessage = useRef();
  const loadingIds = useRef([]);

  const toggleVisibility = async value => {
    try {
      loadingIds.current.push(value.id);
      pause();
      await toggleVisibilityFn(
        value.id,
        eventId,
        hash,
        questionId,
        questionType,
        sessionId,
        !value.visible
      );
      await resume();
      message.success(value.visible ? t`Response has been hidden` : t`Response is now visible`);
    } catch {
      resume();
      message.error(
        t`We were unable to complete your request. Please reload the page to try again.`
      );
    } finally {
      loadingIds.current = loadingIds.current.filter(id => id !== value.id);
    }
  };

  useEffect(() => {
    if (error && !hideLiveUpdatesMessage.current) {
      hideLiveUpdatesMessage.current = message.error(
        t`There was a problem live updating the responses. Please check your internet connection, reload and try again.`,
        0
      );
    } else if (!error && hideLiveUpdatesMessage.current) {
      hideLiveUpdatesMessage.current();
      hideLiveUpdatesMessage.current = null;
    }
  }, [error]);

  return {
    dataSource:
      dataSource &&
      dataSource.map(post => ({
        ...post,
        loading: loadingIds.current.includes(post.id),
      })),
    surveyName,
    session,
    toggleVisibility,
    error,
  };
};

export default useModeration;
