import { snakeCase } from "snake-case";
import apiFn from "../api-service";
var api = function(eventId) {
    return apiFn("/api/uapi/events/".concat(eventId, "/companies"));
};
export var list = function(eventId) {
    var payload = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, query = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "", include = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : [
        "groups",
        "logoImage",
        "location"
    ];
    var params = new URLSearchParams(query);
    if (include.length > 0) {
        params.set("include", include.map(function(v) {
            return snakeCase(v);
        }).join(","));
    }
    if (typeof payload.offset !== "undefined" && payload.offset > 0) {
        params.set("page", "".concat(payload.offset / payload.limit));
    }
    return api(eventId).get(null, {
        params: params
    }).then(function(response) {
        return response.data;
    });
};
export var create = function(eventId, payload) {
    return api(eventId).post("", payload).then(function(response) {
        var _response_data;
        return response === null || response === void 0 ? void 0 : (_response_data = response.data) === null || _response_data === void 0 ? void 0 : _response_data.data;
    });
};
export var get = function(eventId, companyId, include) {
    return api(eventId).get(companyId, {
        params: {
            include: include.map(function(v) {
                return snakeCase(v);
            }).join(",")
        }
    }).then(function(response) {
        return response.data;
    });
};
export var remove = function(eventId, companyId) {
    return api(eventId).delete(companyId).then(function(response) {
        return response.data;
    });
};
export var update = function(eventId, companyId, payload) {
    return api(eventId).patch(companyId, payload).then(function(response) {
        return response.data;
    });
};
