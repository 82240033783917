/**
 * injects the script in the body of the document
 * @param {String} url
 * @param {Object} document
 * @returns {Promise<*>}
 */
export default function injectScript(url, inlineSrc, onLoad, externalId) {
  let id = externalId;
  if (!id) {
    id = btoa(url);
  }
  const injected = document.getElementById(id);
  if (injected) {
    return Promise.resolve(injected);
  }

  const script = document.createElement('script');
  if (url) {
    script.src = url;
  }
  if (inlineSrc) {
    script.innerHTML = inlineSrc;
  }
  if (onLoad) {
    script.onload = onLoad;
  }

  script.type = 'text/javascript';
  script.async = true;
  if (id) {
    script.id = id;
  }

  const body = document.getElementsByTagName('body')[0];

  return new Promise(resolve => {
    body.appendChild(script);
    resolve(script);
  });
}
