export const ACTION_SET_LOADING = 'ACTION_SET_LOADING';
export const ACTION_SET_LOADING_CONFIG = 'ACTION_SET_LOADING_CONFIG';
export const ACTION_SET_SAVING = 'ACTION_SET_SAVING';
export const ACTION_SET_DATA = 'ACTION_SET_DATA';
export const ACTION_SET_CONFIG = 'ACTION_SET_CONFIG';
export const ACTION_SET_IMAGE_ID = 'ACTION_SET_IMAGE_ID';
export const OWNER_TYPE = 'event';
export const ALLOW_ATTENDEES_TO_CHANGE_THEIR_RESPONSES = 'poll_change_answers';
export const SHOW_UNMODERATED_TEXT_ANSWERS = 'poll_default_visibility';
export const SPONSOR_BANNER_IMAGE_ID = 'live_polls_sponsor_banner_image_id';
export const CHART_TYPE_BAR = 'column';
