import apiFn from 'common/services/api-service';

import { QUESTION_TYPE_AAQ } from './constants';

const api = type => apiFn(`/api/cms/v1/events/liveresults/${type}`);
const moderateApi = type => apiFn(`/api/cms/v1/events/${type}/moderate`);

export const list = (eventId, hash, questionId, questionType, sessionId) => {
  return api(questionType === QUESTION_TYPE_AAQ ? '/ask-a-question' : '/questions')
    .get(null, {
      params: {
        eventId,
        hash,
        questionId,
        questionType,
        sessionId,
      },
    })
    .then(response => response.data.data);
};

export const toggleVisibility = (
  id,
  eventId,
  hash,
  questionId,
  questionType,
  sessionId = null,
  visible
) => {
  return moderateApi(questionType === QUESTION_TYPE_AAQ ? 'aaq' : 'text-results').post(null, {
    id,
    eventId,
    hash,
    questionType,
    questionId,
    sessionId,
    visible,
  });
};
