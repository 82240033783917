import PropTypes from 'prop-types';
import React from 'react';

import { FILL_DOWN } from './constants';

const roundFloat = v => parseFloat(v.toFixed(1));

export const getGridProps = (width, height, spacing, boxSize) => {
  // we need forced rounding here because floating points are hard - even with fixed decimal places..
  // for example, 101.6 - 25.4 - 0 is an irrational number for floating point unit :/
  const widthCorrected = roundFloat(Math.max(0, width));
  const heightCorrected = roundFloat(Math.max(0, height));

  const rows = Math.floor((heightCorrected + spacing.row) / (boxSize.height + spacing.row));
  const cols = Math.floor((widthCorrected + spacing.column) / (boxSize.width + spacing.column));

  return {
    rows,
    cols,
    spacing,
    boxSize,
  };
};

const sortChildren = ({ children, cols, rows, fill }) => {
  const badgeCount = cols * rows;
  const badges = [...children];
  if (fill === FILL_DOWN) {
    for (let i = 0; i < badgeCount; i += 1) {
      const rowIndex = Math.floor(i / cols) % rows;
      const colIndex = i % cols;

      const cellOffset = rowIndex + colIndex * rows;
      badges[i] = children[cellOffset];
    }
  }
  return badges;
};

const Grid = ({ children, fill, rows, cols, spacing, boxSize }) => {
  const sortedChildren = sortChildren({ children, fill, rows, cols });
  return (
    <>
      {new Array(rows).fill(0).map((_, rowIndex) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`row-${rowIndex}`}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            height: boxSize.height,
            columnGap: spacing.column,
            marginBottom: spacing.row,
          }}
        >
          {new Array(cols).fill(0).map(
            (__, colIndex) =>
              sortedChildren[rowIndex * cols + colIndex] && (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`col-${colIndex}`} style={{ width: boxSize.width }}>
                  {sortedChildren[rowIndex * cols + colIndex]}
                </div>
              )
          )}
        </div>
      ))}
    </>
  );
};

Grid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  rows: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  cols: PropTypes.number.isRequired,
  spacing: PropTypes.shape({
    row: PropTypes.number.isRequired,
    column: PropTypes.number.isRequired,
  }).isRequired,
  boxSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default Grid;
