import { Space, Alert, Radio, Typography, Row, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t, jt } from 'ttag';

import { SALES_EMAIL } from 'common/services/support/types';
import { getIsStaff } from 'common/state/user';

import { CREDITS_STATUS_AVAILABLE, CREDITS_STATUS_EXPIRED } from './credit-service';

const creditShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  expiresAt: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  packageName: PropTypes.string,
});

const { Title, Text } = Typography;

const StyledRadio = styled(Radio)`
  width: 100%;
  margin-right: 0;
  text-align: left;
  height: auto;
  padding: 5px 24px;
  border: 1px solid #d9d9d9;

  &.ant-radio-wrapper-checked {
    border-color: #1890ff;
  }

  // Whatever children we gave it are wrapped here
  > span:last-child {
    flex: 1;
    padding-right: 0;
  }

  .ant-row {
    min-height: 60px;
    width: 100%;

    // Credit name
    .ant-col:first-child {
      align-items: center;
      display: flex;
      padding-left: 8px;
    }
  }
`;

const RadioGroup = styled(Radio.Group)`
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreditInfo = styled(Text)`
  font-size: 12px;
  color: ${props => props.color};
  display: block;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const noCreditsMailToSubject = encodeURIComponent('Purchase Event Credits');

const ContactLink = () => (
  <a
    href={`mailto:${SALES_EMAIL}?subject=${noCreditsMailToSubject}`}
    target="_blank"
    rel="noreferrer noopener"
  >
    {t`Contact us`}
  </a>
);

const EmailLink = () => (
  <a href={`mailto:${SALES_EMAIL}`} target="_blank" rel="noreferrer noopener">
    {SALES_EMAIL}
  </a>
);

const CreditItem = ({
  credit: { id: value, name, createdAt, expiresAt, isExpired, packageName },
  isStaff,
}) => {
  /*
    expiresAt is just a YYYY-MM-DD string, so it can have timezone issues
    compared to the formatted creation date.

    e.g.
    expiresAt: 2023-05-03
    createdAt: 2022-05-03T20:20:04

    Just copying the creation time + zone from createdAt for consistency, so
    if the strings say the same day they format to the same day.
  */
  const createdTime = createdAt.split('T')[1];
  const createdDate = moment(createdAt).format('YYYY-MM-DD');
  const expiryDate = moment(`${expiresAt}T${createdTime}`).format('YYYY-MM-DD');

  const textType = isExpired ? 'secondary' : 'default';

  return (
    <StyledRadio value={value}>
      <Row align="middle">
        <Col span={16}>
          <Text type={textType}>{name}</Text>
        </Col>
        <Col span={8} align="right">
          {isStaff && (
            <>
              <CreditInfo type="danger">{packageName}</CreditInfo>
              <CreditInfo type="danger">{t`Created: ${createdDate}`}</CreditInfo>
            </>
          )}
          <CreditInfo type={textType}>{t`Expiry: ${expiryDate}`}</CreditInfo>
        </Col>
      </Row>
    </StyledRadio>
  );
};

CreditItem.propTypes = {
  credit: creditShape.isRequired,
  isStaff: PropTypes.bool.isRequired,
};

const CreditsStatusRadio = ({ credits, value, onChange }) => {
  const options = [
    {
      label: t`Available (${credits.available.length})`,
      value: CREDITS_STATUS_AVAILABLE,
    },
    {
      label: t`Expired (${credits.expired.length})`,
      value: CREDITS_STATUS_EXPIRED,
      disabled: credits.expired.length === 0,
    },
  ];

  return (
    <Radio.Group
      options={options}
      value={value}
      onChange={e => onChange(e.target.value)}
      optionType="button"
    />
  );
};

CreditsStatusRadio.propTypes = {
  credits: PropTypes.shape({
    available: PropTypes.arrayOf(creditShape),
    expired: PropTypes.arrayOf(creditShape),
  }).isRequired,
  value: PropTypes.oneOf([CREDITS_STATUS_AVAILABLE, CREDITS_STATUS_EXPIRED]).isRequired,
  onChange: PropTypes.func.isRequired,
};

const Credit = ({ selected, credits, onSelect }) => {
  const isStaff = useSelector(getIsStaff);
  const [creditsStatus, setCreditsStatus] = useState(CREDITS_STATUS_AVAILABLE);

  const getCredit = (status, id) => credits[status].find(c => c.id === id);
  const visibleCredits = credits[creditsStatus];

  const handleSelect = e => {
    onSelect(getCredit(creditsStatus, e.target.value));
  };

  const handleCreditsStatusChange = status => {
    setCreditsStatus(status);
    onSelect(null);
  };

  const contactLink = <ContactLink key="contact-link" />;
  const emailLink = <EmailLink key="email-link" />;

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Header>
        <Title level={5}>{t`Credits`}</Title>
        <CreditsStatusRadio
          credits={credits}
          value={creditsStatus}
          onChange={handleCreditsStatusChange}
        />
      </Header>
      {creditsStatus === CREDITS_STATUS_AVAILABLE && credits.available.length === 0 && (
        <Alert
          type="error"
          showIcon
          message={t`No Event Credits`}
          description={jt`You do not have any event credits available. Please ${contactLink} to purchase new events.`}
        />
      )}
      {creditsStatus === CREDITS_STATUS_EXPIRED && (
        <Alert
          type="warning"
          showIcon
          message={t`Expired Event Credits`}
          description={jt`Please contact your Account Manager to renew your licensing. Not sure who your Account Manager is? Contact us at ${emailLink}.`}
        />
      )}
      <RadioGroup
        onChange={handleSelect}
        value={selected}
        disabled={creditsStatus === CREDITS_STATUS_EXPIRED}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          {visibleCredits.map(credit => (
            <CreditItem key={credit.id} isStaff={isStaff} credit={credit} />
          ))}
        </Space>
      </RadioGroup>
    </Space>
  );
};

Credit.propTypes = {
  selected: PropTypes.string,
  credits: PropTypes.shape({
    available: PropTypes.array.isRequired,
    expired: PropTypes.array.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Credit;
