function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "An unknown error occurred"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { useEffect, useReducer, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import snakeCaseKeys from "snakecase-keys";
import { message } from "antd";
import { t } from "ttag";
import { fromQuery, toQuery, compareQuery, mergeQueries } from "./query-service";
// max rows per page
export var PAGE_SIZE = 20;
var initialState = {
    total: -1,
    page: 1,
    data: [],
    loading: false,
    limit: PAGE_SIZE,
    search: "",
    filter: {},
    filterKeys: [],
    sort: {
        field: "",
        order: ""
    }
};
var Actions;
(function(Actions) {
    Actions[Actions["RequestPending"] = 0] = "RequestPending";
    Actions[Actions["RequestSuccess"] = 1] = "RequestSuccess";
    Actions[Actions["SetPage"] = 2] = "SetPage";
    Actions[Actions["SetFilter"] = 3] = "SetFilter";
    Actions[Actions["SetSort"] = 4] = "SetSort";
    Actions[Actions["SetSearch"] = 5] = "SetSearch";
    Actions[Actions["Reset"] = 6] = "Reset";
    Actions[Actions["RequestError"] = 7] = "RequestError";
})(Actions || (Actions = {}));
var format = function(response) {
    var data = response.data, source = response.pagination;
    var _source_totalRecordCount, _ref;
    var pagination = _objectSpread({
        total: (_ref = (_source_totalRecordCount = source === null || source === void 0 ? void 0 : source.totalRecordCount) !== null && _source_totalRecordCount !== void 0 ? _source_totalRecordCount : source === null || source === void 0 ? void 0 : source.totalItemsCount) !== null && _ref !== void 0 ? _ref : -1
    }, source);
    return {
        data: data,
        pagination: pagination
    };
};
var reducer = function(state, action) {
    switch(action.type){
        case Actions.RequestPending:
            {
                return _objectSpreadProps(_objectSpread({}, state), {
                    loading: true
                });
            }
        case Actions.RequestError:
            {
                return _objectSpreadProps(_objectSpread({}, state), {
                    loading: false
                });
            }
        case Actions.RequestSuccess:
            {
                var _format = format(action.payload), total = _format.pagination.total, data = _format.data;
                return _objectSpreadProps(_objectSpread({}, state), {
                    loading: false,
                    data: data,
                    total: total
                });
            }
        case Actions.SetPage:
            {
                return _objectSpreadProps(_objectSpread({}, state), {
                    page: action.payload.page
                });
            }
        case Actions.SetFilter:
            {
                var payload = action.payload;
                var _ref = [
                    Object.keys(payload),
                    Object.values(payload)
                ], keys = _ref[0], values = _ref[1];
                if (values.length === 0) {
                    return _objectSpreadProps(_objectSpread({}, state), {
                        filter: {},
                        filterKeys: _toConsumableArray(keys)
                    });
                }
                var _ref1;
                var nextState = _objectSpreadProps(_objectSpread({}, state), {
                    filter: keys.filter(function(key) {
                        var _payload_key;
                        return (_ref1 = ((_payload_key = payload[key]) === null || _payload_key === void 0 ? void 0 : _payload_key.length) > 0) !== null && _ref1 !== void 0 ? _ref1 : false;
                    }).reduce(function(prev, key) {
                        return _objectSpreadProps(_objectSpread({}, prev), _defineProperty({}, key, payload[key]));
                    }, {}),
                    filterKeys: Array.from(new Set(_toConsumableArray(state.filterKeys).concat(_toConsumableArray(keys))))
                });
                var prevFilter = Object.values(state.filter);
                if (prevFilter.length > 0) {
                    // if there was a filter applied before then let the page and total be the same
                    // as it was before so that it would allow to change pages with filters being
                    // applied
                    return _objectSpread({}, nextState);
                }
                // if a filter is applied for the first time, then reset the value of page and total
                var page = initialState.page, total1 = initialState.total;
                return _objectSpreadProps(_objectSpread({}, nextState), {
                    page: page,
                    total: total1
                });
            }
        case Actions.SetSort:
            {
                return _objectSpreadProps(_objectSpread({}, state), {
                    sort: action.payload
                });
            }
        case Actions.SetSearch:
            {
                var page1 = initialState.page, total2 = initialState.total;
                return _objectSpreadProps(_objectSpread({}, state), {
                    page: page1,
                    total: total2,
                    search: action.payload
                });
            }
        case Actions.Reset:
            {
                return _objectSpread({}, state, initialState);
            }
        default:
            return _objectSpread({}, state);
    }
};
// extract payload required to build query with provided state
var extractPayload = function(state) {
    var search = state.search, filter = state.filter, sort = state.sort, page = state.page, limit = state.limit;
    var offset = (page - 1) * Number(limit);
    var payload = {
        search: search,
        filter: filter,
        sort: sort,
        limit: limit,
        offset: offset
    };
    return payload;
};
// history should only be updated if table has invoked `onChange` callback
var shouldUpdateHistory = function(payload) {
    return [
        "offset",
        "search",
        "filter",
        "sort"
    ].reduce(function(prev, next) {
        return prev || payload[next];
    }, false);
};
/**
 * useTable is a hook that facilitates any table based component to have
 * pagination, sorting, filtering and searching by taking an API function and
 * page size (which is set to 20 by default)
 *
 * @param {function} `fn` _must_ be a stable reference; i.e. a top-level function or
 * `useCallback`. It will be called every time the reference changes.
 * @param {object} `pageSettings` TODO: Document what this does and what it accepts
 *
 * @example
 * ```
 * const fn = useCallback((some, args) => anotherFn(eventId, some, args), [eventId]);
 * const props = useTable(fn);
 * -- snip --
 * <Table {...props} />
 * ```
 *
 * TODO: make this a return param
 *
 * it returns the following:
 * - `pagination`: an object contains configuration specifically Antd's Pagination component
 *   - `total`: total number of records available
 *   - `pageSize`: page size that is being use to set the `offset`
 *   - `current`: current page (Note: it is `current` instead of `page` because that's how Ant expects)
 *   - `onChange`: a callback that changes the value of current page to update the data
 * - `dataSource`: data coming from data intended for Antd's Table component
 * - `loading`: set to `true` when an API call is in progress
 * - `onChange`: should be directly attached to Table component for searching and sorting
 * - `onSearch`: callback to set search and invoke API call to fetch data based on provided keywords
 * - `reset`: resets everything back to default
 * - `forceReload`: a callback function to reload data from API using the previous settings
 *                    it is mostly to load updated data after performing any action (add/update/delete)
 *
 * Note: the returned object is formatted in a way it could be directly passed to Antd's table like:
 *
 * @deprecated For any new code, please switch to `useTableQuery` instead.
 */ var useTable = function(fn) {
    var pageSettings = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var history = useHistory();
    var location = useLocation();
    var queryState = fromQuery(location.search);
    var _useReducer = _slicedToArray(useReducer(reducer, _objectSpreadProps(_objectSpread({}, initialState, pageSettings, queryState, (queryState === null || queryState === void 0 ? void 0 : queryState.filter) ? {
        filterKeys: Object.keys(queryState.filter)
    } : {}), {
        page: queryState.offset ? Number(queryState.offset) / Number(queryState.limit) + 1 : initialState.page
    })), 2), state = _useReducer[0], dispatch = _useReducer[1];
    if (typeof fn !== "function") {
        throw new Error("fn needs to be a promise based function");
    }
    var onChange = useCallback(function(pagination, filter, sort) {
        if (filter || Object.keys(filter !== null && filter !== void 0 ? filter : {}).length > 0) {
            dispatch({
                type: Actions.SetFilter,
                payload: _objectSpread({}, state.filter, filter)
            });
        }
        if (sort && ((sort === null || sort === void 0 ? void 0 : sort.order) !== state.sort.order || (sort === null || sort === void 0 ? void 0 : sort.field) !== state.sort.field)) {
            var order = sort.order, field = sort.field;
            dispatch({
                type: Actions.SetSort,
                payload: {
                    order: order,
                    field: field
                }
            });
        }
        if (pagination && (pagination === null || pagination === void 0 ? void 0 : pagination.current) !== state.page) {
            dispatch({
                type: Actions.SetPage,
                payload: {
                    page: pagination.current
                }
            });
        }
    }, [
        state.filter,
        state.page,
        state.sort.field,
        state.sort.order
    ]);
    var syncQuery = useCallback(function(query) {
        var prev = toQuery(extractPayload(state));
        if (compareQuery(query, prev)) {
            return;
        }
        var next = mergeQueries(query, prev);
        history.replace(_objectSpreadProps(_objectSpread({}, location), {
            search: next.toString()
        }));
    }, [
        state,
        history,
        location
    ]);
    var onSearch = useCallback(function(value) {
        dispatch({
            type: Actions.SetSearch,
            payload: value
        });
    }, []);
    var reset = useCallback(function() {
        dispatch({
            type: Actions.Reset
        });
    }, []);
    var makeRequest = useCallback(function() {
        var force = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        dispatch({
            type: Actions.RequestPending
        });
        var payload = extractPayload(state);
        var query = toQuery(payload);
        // the sort value will be camel case throught
        // except when sending to API, we convert that to snake case
        // the below code does exactly that
        var queryParams = new URLSearchParams(query);
        var sortBy = queryParams.get("sort");
        if (sortBy) {
            // the custom fields sort values are in the format cf[3347] for ascending and -cf[3347] for descending
            // the snakeCaseKeys convert them to cf_3347 in both ascending and descending case
            // the exclude regex tells the snakeCaseKeys to ignore for the custom field sort values
            var keys = snakeCaseKeys(_defineProperty({}, sortBy, ""), {
                exclude: [
                    /^-?cf\[\d+\]$/
                ]
            });
            var sortValue = Object.keys(keys)[0];
            queryParams.set("sort", payload.sort.order === "descend" ? "-".concat(sortValue) : sortValue);
        }
        fn(payload, "?".concat(queryParams.toString())).then(function(response) {
            var data = response.data, pagination = response.meta.pagination;
            if ((data === null || data === void 0 ? void 0 : data.length) === 0 && (payload === null || payload === void 0 ? void 0 : payload.offset) > 0) {
                // if data array is empty and offset is greater than zero then
                // it means all contents of the page has been deleted and when
                // that happens then it should go back to previous page.
                dispatch({
                    type: Actions.SetPage,
                    payload: {
                        page: state.page - 1
                    }
                });
                // early return to not execute then rest as it doesn't matter
                return;
            }
            dispatch({
                type: Actions.RequestSuccess,
                payload: {
                    pagination: pagination,
                    data: data
                }
            });
            // only update the history when it's not a _forced_ request
            // as forced request would use the same params as previous
            // one so there's no point of creating a duplicate history
            // item. Furthermore, history should only be updated if table
            // has invoked `onChange` callback by changing either one of
            // these properties: 'offset', 'search', 'filter', 'sort'.
            if (force || !shouldUpdateHistory(payload) || compareQuery(location.search, query)) {
                return;
            }
            var currentParams = new URLSearchParams(location.search);
            var tableParams = new URLSearchParams(query);
            // check if it has related entries in the current params
            var relatedEntries = Array.from(currentParams.entries()).filter(function(param) {
                var _param = _slicedToArray(param, 1), key = _param[0];
                return [
                    "offset",
                    "search",
                    "limit",
                    "sort"
                ].concat(_toConsumableArray(state.filterKeys)).includes(key);
            });
            if (relatedEntries.length > 0) {
                // remove related entries from current params
                relatedEntries.forEach(function(param) {
                    var _param = _slicedToArray(param, 1), key = _param[0];
                    return currentParams.delete(key);
                });
                // add new table params to current params
                Array.from(tableParams.entries()).forEach(function(param) {
                    var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
                    return currentParams.set(key, value);
                });
                // sort the params alphabetically
                currentParams.sort();
                history.replace(_objectSpreadProps(_objectSpread({}, location), {
                    search: currentParams.toString()
                }));
            }
        }).catch(function(err) {
            // eslint-disable-next-line no-console
            console.error(err);
            dispatch({
                type: Actions.RequestError
            });
            message.error(t(_templateObject()));
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        fn,
        state.page,
        state.filter,
        state.sort,
        state.search
    ]);
    var forceReload = useCallback(function() {
        return makeRequest(true);
    }, [
        makeRequest
    ]);
    useEffect(function() {
        return makeRequest();
    }, [
        makeRequest
    ]);
    var _Number;
    return {
        pagination: {
            total: state.total,
            pageSize: (_Number = Number(state.limit)) !== null && _Number !== void 0 ? _Number : PAGE_SIZE,
            current: state.page
        },
        dataSource: state.data,
        loading: state.loading,
        onChange: onChange,
        onSearch: onSearch,
        reset: reset,
        syncQuery: syncQuery,
        forceReload: forceReload
    };
};
export default useTable;
