import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
  padding: 0;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
`;

Image.propTypes = {
  /**
   * This is the src of image
   */
  src: PropTypes.string,
};

export default Image;
