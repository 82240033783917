import AgendaWidget, { Session, InfoBox } from '@eventmobi/widgets/Agenda';
import { Divider } from 'antd';
import Chance from 'chance';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

const noop = () => {};

export const Agenda = ({ title, footerProps, sessions }) => (
  <AgendaWidget title={title} footer={InfoBox} footerProps={{ onClick: noop, ...footerProps }}>
    {sessions.map(({ key, ...session }) => [
      <Session key={key} {...session} onClick={noop} />,
      <Divider key={`divider-${key}`} css="margin: 0" />,
    ])}
  </AgendaWidget>
);

Agenda.propTypes = {
  title: PropTypes.string,
  footerProps: PropTypes.shape({
    text: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      location: PropTypes.string,
      tracks: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          color: PropTypes.string,
        })
      ),
    })
  ),
};

const chance = new Chance();

const generate = () => ({
  key: chance.hash(),
  title: chance.sentence({ words: chance.integer({ min: 6, max: 15 }) }),
  startTime: `${chance.hour()}:${chance.minute()} ${chance.ampm()}`,
  endTime: `${chance.hour()}:${chance.minute()} ${chance.ampm()}`,
  location: chance.address(),
  tracks: chance.n(
    () => ({ name: chance.city(), color: chance.color({ format: 'hex' }) }),
    chance.integer({
      min: 1,
      max: 5,
    })
  ),
});

const sessions = chance.n(generate, 3);

const Mock = ({ title }) => (
  <Agenda
    title={title}
    footerProps={{ buttonText: t`View Your Full Schedule` }}
    sessions={sessions}
  />
);

Mock.propTypes = {
  title: PropTypes.string,
};

export default Mock;
