import {
  BarChartOutlined,
  LoadingOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from '@ant-design/icons';
import { Space, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { c, t } from 'ttag';

import Loading from 'common/components/Loading';

import { EVENT_SURVEY_QUESTION, LIVE_POLL } from '@domains/Surveys/constants';
import usePolling from '@hooks/usePolling';

import BarChart from './BarChart';
import Chart from './Chart';
import { POLLING_FREQUENCY, QUESTION_TYPE_AAQ } from './constants';
import { list as listFn } from './service';
import TextOption from './TextOption';

const { Text } = Typography;

const BAR_CHART = 'bar';
const PIE_CHART = 'pie';
const COLUMN_CHART = 'column';
const TEXT = 'text';

const StyledFullScreen = styled(FullScreen)`
  height: 100%;
`;

const Content = styled.div`
  overflow: auto;
  width: 100%;
`;

const Logo = styled.img`
  height: 26px;
  margin-right: 8px;
`;

const PaddedBox = styled.div`
  padding: 24px;
`;

const AnswerText = styled(Text)`
  display: block;
  font-size: 42px;
  margin: 14px 0;
`;

const CenterBox = styled(Space)`
  margin-top: 42px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const LiveBox = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 150px;
  background: #fff;
  padding: 24px;
  display: flex;
  gap: 12px;
  align-items: baseline;
  font-size: 16px;
  margin-top: auto;
`;

const ErrorBox = styled(Space)`
  padding: 24px;
  border: 1px solid #d91d2a;
  border-radius: 4px;
  background-color: #fce9ea;
`;

const ChartPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 150px);
  color: ${props => props.textcolor};
  overflow-y: auto;
  background-color: ${props => props.backgroundcolor};
`;

const ChartHeader = styled(Space)`
  width: 100%;
  padding: 24px 24px;
  background-color: ${props => props.backgroundcolor};
`;

const QuestionTitle = styled(Text)`
  font-weight: 600;
  font-size: 38px;
  color: ${props => props.headertextcolor};
`;

const BannerImageElement = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

// Component that displays when there are no responses
const Ready = () => {
  return (
    <CenterBox direction="vertical">
      <BarChartOutlined style={{ fontSize: '64px' }} />
      <AnswerText>
        <LoadingOutlined style={{ marginRight: 16 }} />
        {t`Ready for responses`}
      </AnswerText>
    </CenterBox>
  );
};

// Error component when question does not load
const LoadingError = () => {
  const ReloadPage = (
    <a key={1} href={window.location.pathname}>{c(
      'As in: We experienced a problem loading data. Please try reloading the page'
    ).t`reloading the page`}</a>
  );
  return (
    <CenterBox>
      <ErrorBox>{c('We experienced a problem loading data. Please try reloading the page')
        .jt`We experienced a problem loading data. Please try ${ReloadPage}.`}</ErrorBox>
    </CenterBox>
  );
};

const BannerImage = ({ src }) => {
  return (
    <BannerImageElement>
      <img src={src} alt="banner" />
    </BannerImageElement>
  );
};

BannerImage.propTypes = {
  src: PropTypes.string,
};

// Renders the full screen button
const FullScreenButton = ({ screen }) => {
  return (
    <Button onClick={screen.active ? screen.exit : screen.enter}>
      {!screen.active && <FullscreenOutlined />}
      {screen.active && <FullscreenExitOutlined />}
    </Button>
  );
};

const useLiveResults = ({ eventId, hash, questionId, questionType, sessionId }) => {
  const [liveResults, setLiveResults] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const listFunc = useCallback(async () => {
    const response = await listFn(eventId, hash, questionId, questionType, sessionId);
    return response[0];
  }, [eventId, hash, questionId, questionType, sessionId]);

  const { value: data, error, pause } = usePolling(null, listFunc, POLLING_FREQUENCY);

  useEffect(() => {
    if (data) {
      setLiveResults(data);
      setIsLoading(false);
    }
    if (error) {
      setIsLoading(false);
      pause();
    }
  }, [data, error, pause]);

  const optionData =
    questionType === LIVE_POLL || questionType === EVENT_SURVEY_QUESTION
      ? liveResults.options?.map((option, index) => {
          return {
            id: `option-${index}`,
            title: option.text,
            label: String.fromCharCode(65 + index),
            value: option.percentage * 100,
            count: option.count,
            color: `#${option.color}`,
          };
        })
      : null;

  const textOptionsWithVotes =
    questionType === QUESTION_TYPE_AAQ
      ? liveResults.responses
          ?.filter(response => response.visible)
          .map(response => ({
            title: response.text,
            count: response.upvotes,
          }))
      : null;

  const textOptions =
    liveResults.resultType === TEXT
      ? liveResults.textAnswers
          ?.filter(answer => answer.visible)
          .map(answer => ({
            title: answer.text,
          }))
      : null;

  return {
    liveResults,
    chartColors: liveResults.customChartColors ?? {},
    isLoading,
    optionData,
    textOptionsWithVotes,
    textOptions,
    questionType,
    error,
  };
};

export const LiveResults = () => {
  const screen = useFullScreenHandle();
  const { eventId, questionId, hash, questionType, sessionId } = useParams();
  const {
    liveResults,
    chartColors,
    isLoading,
    optionData,
    textOptionsWithVotes,
    textOptions,
    error,
  } = useLiveResults({ eventId, hash, questionId, questionType, sessionId });

  if (isLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  if (error) {
    return <LoadingError />;
  }

  return (
    <StyledFullScreen handle={screen}>
      <ChartPage
        textcolor={chartColors.bodyTextColor}
        backgroundcolor={chartColors.bodyBackgroundColor}
      >
        <ChartHeader backgroundcolor={chartColors.headerBackgroundColor}>
          {!!liveResults.appIconUrl && <Logo src={liveResults.appIconUrl} alt="eventAppIconUrl" />}
          <QuestionTitle headertextcolor={chartColors.headerTextColor}>
            {liveResults.questionName || liveResults.sessionName}
          </QuestionTitle>
        </ChartHeader>
        {liveResults.totalResponses === 0 && <Ready />}
        {liveResults.totalResponses > 0 && (
          <PaddedBox>
            {liveResults.resultType === BAR_CHART && <BarChart dataSource={optionData} />}
            {[PIE_CHART, COLUMN_CHART].includes(liveResults.resultType) && (
              <Chart
                textColor={chartColors.bodyTextColor}
                type={liveResults.resultType}
                dataSource={optionData}
                totalResponses={liveResults.totalResponses}
              />
            )}
            {questionType === QUESTION_TYPE_AAQ && (
              <TextOption textColor={chartColors.bodyTextColor} dataSource={textOptionsWithVotes} />
            )}
            {liveResults.resultType === TEXT && (
              <TextOption textColor={chartColors.bodyTextColor} dataSource={textOptions} />
            )}
          </PaddedBox>
        )}
        <LiveBox>
          <FullScreenButton screen={screen} />
          <Text>{t`Live`}</Text>
          {!!liveResults.bannerImgUrl && (
            <BannerImage src={liveResults.bannerImgUrl} alt="bannerImgUrl" />
          )}
        </LiveBox>
      </ChartPage>
    </StyledFullScreen>
  );
};

FullScreenButton.propTypes = {
  screen: PropTypes.shape({
    exit: PropTypes.func.isRequired,
    enter: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

export default LiveResults;
