import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  EXPORT_ACTIVITY_FEED_PICTURES,
  EXPORT_ANALYTICS_OPERATION,
  EXPORT_ANNOUNCEMENTS,
  EXPORT_BADGE_DESIGNER_PDF,
  EXPORT_COMPLETED,
  EXPORT_FAILED,
  EXPORT_GROUP_DISCUSSION,
  EXPORT_USER_CREDITS_USAGE,
  EXPORT_PENDING,
  EXPORT_PROFILE_IMAGES,
  EXPORT_SESSION_CHAT,
  HOLD_POLLING,
  START_POLLING,
  STATUS_ERROR,
  STATUS_SUCCESS,
  STATUS_WARNING,
  URL_PROTOCOL_REGEXP,
} from './constants';
import Modal from './Modal';
import { create as createFn, get as getFn } from './polling-service';

const xor = (a, b) => (a || b) && !(a && b);
const Polling = ({ open, organizationId, eventId, type, getExportId, onClose, payload }) => {
  const [href, setHref] = useState('');
  const [status, setStatus] = useState(STATUS_WARNING);
  const [pollingStatus, setPollingStatus] = useState(HOLD_POLLING);
  const [exportId, setExportId] = useState();

  const contextType = organizationId ? 'organizations' : 'events';
  const contextId = organizationId ?? eventId;

  // ↪ useEffect for [exportId] hook
  useEffect(() => {
    if (exportId) {
      setPollingStatus(START_POLLING);
    }
  }, [exportId]);

  // ↪ useEffect for [pollingStatus] hook
  useEffect(() => {
    if (pollingStatus === HOLD_POLLING) {
      return () => {};
    }

    const intervalId = setInterval(() => {
      getFn(contextType, contextId, exportId)
        .then(resp => {
          switch (resp.state) {
            case EXPORT_COMPLETED: {
              const downloadLink = resp.artifacts.find(artifact =>
                URL_PROTOCOL_REGEXP.test(artifact.url)
              ).url;
              setHref(downloadLink);
              setStatus(STATUS_SUCCESS);
              setPollingStatus(HOLD_POLLING);
              clearInterval(intervalId);
              return;
            }

            case EXPORT_FAILED: {
              throw new Error('Something happened with the export. Details: ', resp.args);
            }

            case EXPORT_PENDING: {
              return;
            }

            default:
              throw new Error('Something happened with the export. Details: ', resp.args);
          }
        })
        .catch(() => {
          setStatus(STATUS_ERROR);
          setPollingStatus(HOLD_POLLING);
          clearInterval(intervalId);
        });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [pollingStatus, contextType, contextId, exportId]);

  // 📦 useEffect for OPEN prop
  useEffect(() => {
    if (!open) {
      setStatus(STATUS_WARNING);
      return;
    }

    async function createAndSetExportId() {
      try {
        let response;
        if (getExportId) {
          response = await getExportId();
        } else {
          response = await createFn(contextType, contextId, type, payload);
        }

        setExportId(response.id);
      } catch {
        setStatus(STATUS_ERROR);
      }
    }

    createAndSetExportId();
  }, [open, contextType, contextId, payload, type, getExportId]);

  const tryAgain = () => {
    setHref('');
    setStatus(STATUS_WARNING);
    setPollingStatus(START_POLLING);
  };

  if (!xor(organizationId, eventId)) {
    throw new Error('Must provide either organizationId or eventId; not both, not none.');
  }

  return (
    <Modal
      type={type}
      status={status}
      open={open}
      href={href}
      tryAgainFn={tryAgain}
      onClose={onClose}
    />
  );
};

Polling.propTypes = {
  open: PropTypes.bool.isRequired,
  organizationId: PropTypes.string,
  eventId: PropTypes.number,
  type: PropTypes.oneOf([
    EXPORT_PROFILE_IMAGES,
    EXPORT_ACTIVITY_FEED_PICTURES,
    EXPORT_ANALYTICS_OPERATION,
    EXPORT_ANNOUNCEMENTS,
    EXPORT_SESSION_CHAT,
    EXPORT_GROUP_DISCUSSION,
    EXPORT_BADGE_DESIGNER_PDF,
    EXPORT_USER_CREDITS_USAGE,
  ]).isRequired,
  payload: PropTypes.shape({}),
  getExportId: PropTypes.func,
  onClose: PropTypes.func,
};

export default Polling;
