import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { t, jt } from 'ttag';

import useTranslation from 'common/hooks/useTranslation';
import { getContract } from 'common/state/organization';

import usePermission, { READ_DATA_USAGE } from '@hooks/usePermission';
import {
  get as dataUsageFn,
  LIVESTREAM_BROADCAST_HOURS,
  LIVESTREAM_PLAYBACK_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
} from '@services/data-usage-service';

const WARNING_PERCENTAGE = 80;
const ERROR_PERCENTAGE = 100;

const DE_VIDEO_LEARN_MORE =
  'https://help.eventmobi.com/de/knowledge/was-passiert-wenn-ich-mein-videolimit-%C3%BCberschreite';
const EN_VIDEO_LEARN_MORE =
  'https://help.eventmobi.com/en/knowledge/what-happens-when-i-exceed-my-video-limit';
const DE_USER_CREDITS_LEARN_MORE = 'https://help.eventmobi.com/de/knowledge/benutzerkredit-lizenz';
const EN_USER_CREDITS_LEARN_MORE = 'https://help.eventmobi.com/en/knowledge/user-credit-license';

const DataUsageLink = ({ section, children }) => {
  const { organizationId } = useParams();
  const path = `/organization/${organizationId}/data-usage?expanded=${section}`;

  return <Link to={path}>{children}</Link>;
};

DataUsageLink.propTypes = {
  section: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const PeopleCreditUsageLink = ({ children }) => {
  const { organizationId } = useParams();
  const path = `/organization/${organizationId}/people-credit`;

  return <Link to={path}>{children}</Link>;
};

PeopleCreditUsageLink.propTypes = {
  children: PropTypes.node.isRequired,
};

// eslint-disable-next-line react/prop-types
const LearnMore = ({ enUrl, deUrl }) => {
  const { locale } = useTranslation();
  const url = /de/.test(locale) ? deUrl : enUrl;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {t`Learn More`}
    </a>
  );
};

const ALERT_CONTENT_MAPPING = () => {
  const links = {
    peopleCreditsLearnMore: (
      <LearnMore
        key="user-credits-learn-more"
        enUrl={EN_USER_CREDITS_LEARN_MORE}
        deUrl={DE_USER_CREDITS_LEARN_MORE}
      />
    ),
    videoLearnMore: (
      <LearnMore key="video-learn-more" enUrl={EN_VIDEO_LEARN_MORE} deUrl={DE_VIDEO_LEARN_MORE} />
    ),
    peopleCredits: (
      <PeopleCreditUsageLink key="people-credits">{t`People Credit`}</PeopleCreditUsageLink>
    ),
    videoStorage: (
      <DataUsageLink key="video-storage" section="video-storage">
        {t`Video Hours`}
      </DataUsageLink>
    ),
    videoPlayback: (
      <DataUsageLink key="video-hours" section="video-hours">
        {t`On-Demand Viewer Hours`}
      </DataUsageLink>
    ),
    rtmp: (
      <DataUsageLink key="rtmp-hours" section="rtmp-hours">
        {t`Streaming Hours`}
      </DataUsageLink>
    ),
    livestream: (
      <DataUsageLink key="livestream-hours" section="livestream-hours">
        {t`Live Viewer Hours`}
      </DataUsageLink>
    ),
  };

  return {
    userCredits: {
      error: () =>
        jt`Your organization has used all the ${links.peopleCredits}. This does not affect functionality, but additional charges may apply.  ${links.peopleCreditsLearnMore}`,
      warning: percent =>
        jt`Your Organization has used ${percent}% of its ${links.peopleCredits}. Additional charges may apply if you go over limit. ${links.peopleCreditsLearnMore}`,
    },
    videoStorage: {
      error: () =>
        jt`Your Organization has used 100% of its ${links.videoStorage} and you will be unable to upload any new videos. ${links.videoLearnMore}`,
      warning: percent =>
        jt`Your Organization has used ${percent}% of its ${links.videoStorage}. This may affect the upload of any new videos. ${links.videoLearnMore}`,
    },
    videoPlayback: {
      error: () =>
        jt`Your Organization has used 100% of its ${links.videoPlayback} and all video library contents are disabled. ${links.videoLearnMore}`,
      warning: percent =>
        jt`Your Organization has used ${percent}% of its ${links.videoPlayback} and video library content will soon be disabled. ${links.videoLearnMore}`,
    },
    rtmp: {
      error: () =>
        jt`Your Organization has used 100% of its ${links.rtmp}. This does not affect functionality but additional charges may apply. ${links.videoLearnMore}`,
      warning: percent =>
        jt`Your Organization has used ${percent}% of its ${links.rtmp}. This does not affect functionality but additional charges may apply if you go over limit. ${links.videoLearnMore}`,
    },
    livestream: {
      error: () =>
        jt`Your Organization has used 100% of its ${links.livestream}. This does not affect functionality but additional charges may apply. ${links.videoLearnMore}`,
      warning: percent =>
        jt`Your Organization has used ${percent}% of its ${links.livestream}. This does not affect functionality but additional charges may apply if you go over limit. ${links.videoLearnMore}`,
    },
  };
};

const useOrgUsageAlerts = organizationId => {
  const activeContract = useSelector(getContract);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const { loading: isLoadingAuth, authorized } = usePermission();

  useEffect(() => {
    const effect = async () => {
      if (!organizationId || isLoadingAuth || !authorized(READ_DATA_USAGE)) {
        setLoading(false);
        setResults([]);
        return;
      }

      const wrapResult = async (id, promise) => ({ id, usage: await promise });

      try {
        setLoading(true);

        const nextResults = await Promise.all([
          wrapResult(
            'userCredits',
            Promise.resolve(
              activeContract
                ? {
                    limit: activeContract.userCredits,
                    used: activeContract.creditsUsageByOrganization,
                  }
                : null
            )
          ),
          wrapResult('rtmp', dataUsageFn(organizationId, LIVESTREAM_BROADCAST_HOURS)),
          wrapResult('livestream', dataUsageFn(organizationId, LIVESTREAM_PLAYBACK_HOURS)),
          wrapResult('videoStorage', dataUsageFn(organizationId, VIDEO_STORAGE_HOURS)),
          wrapResult('videoPlayback', dataUsageFn(organizationId, VIDEO_PLAYBACK_HOURS)),
        ]);

        setResults(nextResults);
      } catch (err) {
        // do nothing
      } finally {
        setLoading(false);
      }
    };

    effect();
  }, [organizationId, isLoadingAuth, activeContract, authorized]);

  const alerts = useMemo(() => {
    return results.reduce((accAlerts, { id, usage }) => {
      if (!usage) {
        return accAlerts;
      }

      const usagePercent = Math.round((usage.limit ? usage.used / usage.limit : 0) * 100);

      if (usagePercent >= WARNING_PERCENTAGE) {
        const type = usagePercent >= ERROR_PERCENTAGE ? 'error' : 'warning';
        const content = ALERT_CONTENT_MAPPING()[id][type](usagePercent);
        const alert = {
          id,
          type,
          scope: 'organization',
          resourceId: organizationId,
          content,
        };
        return [...accAlerts, alert];
      }

      return accAlerts;
    }, []);
  }, [organizationId, results]);

  return { loading, alerts };
};

export default useOrgUsageAlerts;
